import { forwardRef, useEffect, useState } from 'react';
import { PatternFormat } from 'react-number-format';

import type { PhoneFieldProps } from '../FormTypes';
import { TextFieldWrapper } from '../TextFieldWrapper/TextFieldWrapper';
import { generateInputStyles } from '../inputStylesHelper';

export const PhoneField = forwardRef<HTMLInputElement, PhoneFieldProps>((props, ref) => {
  const {
    className,
    value: propValue,
    onChange,
    label,
    rightHelperText,
    errorText,
    error,
    caption,
    required,
    readOnly = false,
    textAlign = 'left',
    inputSize = 'small',
    ...otherProps
  } = props;

  const [value, setValue] = useState(propValue);

  useEffect(() => {
    setValue(propValue);
  }, [propValue]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const userInput = e.target.value;
    let trimmedValue = userInput;

    if (userInput === '' && value.startsWith('+1')) {
      trimmedValue = value.trim();
    }

    setValue(trimmedValue);
    onChange(trimmedValue);
  };

  const isErrorVisible = !!error;

  const inputStyle = generateInputStyles({
    className,
    isErrorVisible,
    readOnly,
    inputSize,
    textAlign,
  });

  return (
    <TextFieldWrapper
      errorText={errorText}
      error={!!error}
      label={label}
      caption={caption}
      required={required}
      textAlign={'left'}
      rightHelperText={rightHelperText}
    >
      <PatternFormat
        getInputRef={ref}
        className={inputStyle}
        value={value}
        onChange={handleChange}
        format={!propValue || propValue.length < 1 ? '+1 (###) ###-####' : '+# (###) ###-####'}
        placeholder="+1 (234) 567890"
        allowEmptyFormatting
        disabled={readOnly}
        mask="_"
        {...otherProps}
      />
    </TextFieldWrapper>
  );
});
