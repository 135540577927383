import { cn } from 'src/util/cn';

import SvgStarSolid from '../../../icons/components/StarSolid';
import { Typography } from '../../Typography/Typography';

import client1 from './assets/person1.png';
import client2 from './assets/person2.png';
import client3 from './assets/person3.png';
import client4 from './assets/person4.png';

const clients = [client1, client2, client3, client4];

interface TrustedUsersProps {
  showRating?: boolean;
}

export const TrustedUsers = ({ showRating = true }: TrustedUsersProps) => {
  const clientsRounds = (
    <div className="flex">
      {clients.map((client, index) => {
        const roundStyle = cn('rounded-full border border-neutral overflow-hidden', {
          '-ml-4': index !== 0,
        });
        return (
          <div key={index} className={roundStyle}>
            <img src={client} alt="client" className="h-[40px] w-[40px]" />
          </div>
        );
      })}
    </div>
  );
  const stars = (
    <div className="flex">
      {Array.from({ length: 5 }).map((_, index) => {
        return <SvgStarSolid className="text-paprika" key={index} />;
      })}
    </div>
  );

  return (
    <div className="flex items-center gap-2">
      {clientsRounds}
      {showRating && (
        <div className="flex flex-col">
          <div className="flex">{stars}</div>
          <Typography variant={'bodySmall'}>rated on the AppStore</Typography>
        </div>
      )}
    </div>
  );
};
