export const ADMIN_DEBUGGER_LS_KEY = 'debuggerEnabled';
export const ACCESS_TOKEN_LS_KEY = 'access_token';
export const EXPIRES_AT_LS_KEY = 'expires_at';
export const UTM_STRING_LS_KEY = 'utmString';
export const UTM_SOURCE_LS_KEY = 'utmSource';
export const UTM_MEDIUM_LS_KEY = 'utmMedium';
export const UTM_CAMPAIGN_LS_KEY = 'utmCampaign';
export const UTM_CONTENT_LS_KEY = 'utmContent';
export const LG_SUB_ID_LS_KEY = 'lgSubId';
export const LG_LEAD_ID_LS_KEY = 'lgLeadId';
export const TM_SUB_ID_1_KEY = 'tmSubid1';
export const TM_SUB_ID_2_KEY = 'tmSubid2';
export const TM_SUB_ID_3_KEY = 'tmSubid3';
export const FLEX_REF_ID_LS_KEY = 'flexRefId';
export const GCLID_LS_KEY = 'gclid';
export const FBCLID_LS_KEY = 'fbclid';
export const MSCLKID_LS_KEY = 'msclkid';
export const REF_LS_KEY = 'affiliateRef';
export const SECURITY_TYPE_PARAM = 'ctype';
export const PESTOANONID_LS_KEY = 'pestoAnonId';
export const NERD_WALLET_REF_LS_KEY = 'nerdwalletRef';
export const REFERRAL_CODE_LS_KEY = 'referralCode';
export const REFERRAL_CODE_SENT = 'referralCodeSent';
export const PAYMENT_AMOUNT_LS_KEY = 'paymentAmount';
export const IS_INCREASE_CREDIT_FLOW_LS_KEY = 'isIncreaseCreditFlow';
export const IGNORE_SEGMENT_AND_PESTO_TRACKING_DEV_MODE_LS_KEY = 'isIgnoreSegmentAndPestoTrackingForDevMode';
export const PERSONAL_INFO_DEMO_LS_KEY = 'personalInfoDemoFeatureFlag';
export const NEW_SIGN_UP_DEMO_LS_KEY = 'newSignUpDemoFeatureFlag';
export const LANDING_FINDER_LS_KEY = 'landingFinderFeatureFlag';
export const SHOW_SCHEDULED_PAYMENTS_FLAG = 'showScheduledPayments';

export const QUICK_APPRAISAL_LS_KEY = 'quick_appraisal';
export const RETRY_LAZY_LS_KEY = 'retry-lazy-refreshed';
export const SIGN_UP_USER_DATA_LS_KEY = 'sign_up_user_data';

export const CASH_SECURED_APPLICATION_USER_FORM_LS_KEY = 'cash_secured_application';
export const CASH_SECURED_APPLICATION_AB_TEST = 'cash_secured_application_ab_test';

export const DISABLE_PROTECTED_ROUTES_SS_KEY = 'disableProtectedRoutesForTheSession';

export const SEGMENT_ANONYMOUS_ID_LS_KEY = 'ajs_anonymous_id';
export const PESTO_ANONYMOUS_EVENT_SENT_LS_KEY = 'pesto_anonymous_event_sent';
export const PESTO_IDENTIFY_EVENT_SENT_LS_KEY = 'pesto_identify_event_sent';
