import { datadogLogs } from '@datadog/browser-logs';
import { yupResolver } from '@hookform/resolvers/yup';
import { useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import type { GraphQLError } from 'graphql/error';
import type { CreditApplicationStatus } from 'src/__generated__/graphql/api';
import { CurrentUserDocument, useCurrentUserQuery, usePrequalifyMutation } from 'src/__generated__/graphql/api';
import { apolloClient } from 'src/api/Apollo';
import { useGoRoutes } from 'src/hooks/useGoRoutes';
import { addressSchema, incomeInfoSchema } from 'src/pages/ApplicationFlow/steps/utils/validationSchema';
import { SkipIntroContext } from 'src/providers/SkipIntroProvider';
import { useAnalytics } from 'src/providers/analytics/AnalyticsProvider';

import { SSN_DUPLICATE_ERROR_MESSAGE } from '../../../constants/errorMessages';
import { useSocure } from '../../../hooks/useSocure';
import { useModal } from '../../../providers/ModalProvider';
import { AnalyticsEvent } from '../../../providers/analytics/AnalyticsEvents';
import type { AddressFormValues } from '../CreditBilling/CreditBillingTypes';

export interface FormInputValues extends AddressFormValues {
  annualIncome: number;
  ssnOrTid: string;
  confirmSsnOrTid: string;
  agreement2: boolean;
  dobValid: boolean;
  deviceSessionId: string;
  dob: string;
}

export const useFormUser = (navigateOnStatus: (arg: CreditApplicationStatus) => string) => {
  const { navigate } = useGoRoutes();
  const { analytics } = useAnalytics();

  const { data } = useCurrentUserQuery();
  const user = data?.currentUser;

  const { setSkipIntroScreen } = useContext(SkipIntroContext);

  const socureDeviceSessionId = useSocure();
  const { openDuplicateSSNModal } = useModal();
  const {
    setValue,
    formState: { errors, isSubmitted },
    handleSubmit,
    watch,
    trigger,
    setFocus,
  } = useForm<FormInputValues>({
    resolver: yupResolver(
      yup.object().shape({
        ...addressSchema.fields,
        ...incomeInfoSchema.fields,
      }),
    ),
    defaultValues: {
      address: user?.billingAddress?.addressFirstLine || '',
      agreement2: false,
      city: user?.billingAddress?.city || '',
      confirmSsnOrTid: '',
      dob: '',
      annualIncome: 0,
      ssnOrTid: '',
      state: user?.billingAddress?.state || '',
      suite: user?.billingAddress?.suiteNumber || '',
      zip: user?.billingAddress?.zip || '',
    },
  });

  const formCreditBilling = {
    address: watch('address'),
    city: watch('city'),
    zip: watch('zip'),
    state: watch('state'),
    suite: watch('suite'),
  };

  const formErrorsCreditBilling = {
    address: errors.address,
    city: errors.city,
    state: errors.state,
    zip: errors.zip,
  };

  const formIncomeInfo = {
    ssnOrTid: watch('ssnOrTid'),
    annualIncome: watch('annualIncome'),
    confirmSsnOrTid: watch('confirmSsnOrTid'),
    agreement2: watch('agreement2'),
    dob: watch('dob'),
    deviceSessionId: socureDeviceSessionId,
  };

  const formErrorsIncomeInfo = {
    ssnOrTid: errors.ssnOrTid,
    annualIncome: errors.annualIncome,
    confirmSsnOrTid: errors.confirmSsnOrTid,
    agreement2: errors.agreement2,
    dob: errors.dob,
    deviceSessionId: errors.deviceSessionId,
  };
  const formData = {
    ...formCreditBilling,
    ...formIncomeInfo,
  };

  const { address, city, zip, state, ssnOrTid, annualIncome, confirmSsnOrTid, agreement2, dob, deviceSessionId } =
    formData;
  useEffect(() => {
    if (isSubmitted) {
      trigger();
    }
  }, [
    isSubmitted,
    trigger,
    address,
    city,
    zip,
    state,
    ssnOrTid,
    annualIncome,
    confirmSsnOrTid,
    agreement2,
    dob,
    deviceSessionId,
    errors.state?.type,
    errors.state?.message,
    user?.id,
    user?.email,
    user?.phone,
    user?.displayName,
  ]);

  const [prequalifyMutation, { loading, error }] = usePrequalifyMutation({
    variables: {
      creditApplicationId: user?.creditApplication?.id || '',
      ssnItin: ssnOrTid,
      dob: watch('dob'),
      annualIncome: watch('annualIncome'),
      deviceSessionId: socureDeviceSessionId,
      addressInput: {
        addressFirstLine: watch('address'),
        suiteNumber: watch('suite'),
        city: watch('city'),
        state: watch('state'),
        zip: watch('zip'),
        isPoBox: false,
        isBilling: true,
        isShipping: true,
      },
    },
    errorPolicy: 'all',
    client: apolloClient,
    refetchQueries: [{ query: CurrentUserDocument }],
    awaitRefetchQueries: true,
    context: { containsSensitiveData: true },
  });

  const sendPreqalErrorToDD = (error: GraphQLError) => {
    datadogLogs.logger.error('Error Prequalify mutation in DataDog:', {
      prequalifyMutationError: {
        userId: user?.id || '',
        email: user?.email || '',
        phone: user?.phone || '',
        displayName: user?.displayName || '',
        message: error,
      },
    });
    console.error('Error submitting income info userID:' + user?.id || '', error);
    return;
  };

  const onSubmit = (data: FormInputValues) => {
    prequalifyMutation()
      .then(response => {
        analytics.event(AnalyticsEvent.Shared.INCOME_INFORMATION_SUBMITTED, {
          full_billing_address: data.address,
          billing_city: data.city,
          billing_state: data.state,
          billing_zip: data.zip,
          total_income: data.annualIncome,
          birth_year: data.dobValid,
          ...(response.errors && {
            error: {
              userId: user?.id || '',
              email: user?.email || '',
              phone: user?.phone || '',
              displayName: user?.displayName || '',
              message: response.errors ? response.errors[0].message : '',
            },
          }),
        });

        if (response.errors) {
          if (response.errors[0].message.includes(SSN_DUPLICATE_ERROR_MESSAGE)) {
            return openDuplicateSSNModal(false, user?.id || '');
          }
          return sendPreqalErrorToDD(response.errors[0]);
        }

        if (response.data?.prequalify?.status) {
          setSkipIntroScreen(true);
          navigate(navigateOnStatus(response.data?.prequalify?.status));
        }
      })
      .catch(error => {
        return sendPreqalErrorToDD(error);
      });
  };

  return {
    loading,
    error,
    onSubmit,
    formErrorsIncomeInfo,
    formErrorsCreditBilling,
    setFocus,
    handleSubmit,
    formCreditBilling,
    formIncomeInfo,
    setValue,
  };
};
