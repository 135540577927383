import * as React from 'react';
import type { SVGProps } from 'react';

interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgUnknownIcon = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      stroke="#101820"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M19.237 3.225a1.5 1.5 0 0 0-1.2-.6h-12a1.5 1.5 0 0 0-1.2.6L1.5 7.676a1.5 1.5 0 0 0 .048 1.86l9.338 11.3a1.5 1.5 0 0 0 2.3 0l9.338-11.3a1.5 1.5 0 0 0 .048-1.86l-3.335-4.451Z"
    />
    <path
      stroke="#101820"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M8.644 8.972c0-1.766 1.413-3.356 3.356-3.356 1.767 0 3.356 1.413 3.356 3.356 0 1.766-1.413 3.356-3.356 3.356v1.966"
    />
    <circle cx={12} cy={16.75} r={0.75} fill="#101820" />
  </svg>
);
export default SvgUnknownIcon;
