import * as React from 'react';
import type { SVGProps } from 'react';

interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgPlatinumSolidIcon = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <g clipPath="url(#Platinum-solid-icon_svg__a)">
      <rect width={23} height={23} x={0.5} y={0.5} fill="#101820" stroke="#101820" rx={3.5} />
      <path
        fill="#FDFDFC"
        d="M4.048 17.551a.25.25 0 0 0 .247.291h1.55a.25.25 0 0 0 .247-.209l.631-3.798a.25.25 0 0 1 .247-.209h2.293c2.922 0 4.443-2.005 4.443-4.137 0-2.068-1.297-3.489-3.43-3.489H6.18a.25.25 0 0 0-.247.209L4.048 17.551Zm5.9-9.609c1.044 0 1.656.647 1.656 1.705 0 1.2-.865 2.037-2.177 2.037H7.38a.25.25 0 0 1-.246-.29l.53-3.242a.25.25 0 0 1 .246-.21h2.039ZM14.065 10.945a.25.25 0 0 0-.088.154l-.046.297a.25.25 0 0 0 .248.288h.814a.25.25 0 0 1 .246.292l-.547 3.23C14.41 16.91 15.334 18 17.003 18c.622 0 1.15-.137 1.647-.355a.242.242 0 0 0 .134-.286l-.302-1.138c-.04-.15-.207-.226-.354-.174a1.637 1.637 0 0 1-.558.106c-.716 0-1.059-.427-.925-1.232l.503-3.028a.25.25 0 0 1 .246-.209h2.11a.25.25 0 0 0 .247-.21l.202-1.252a.25.25 0 0 0-.247-.29h-1.944a.25.25 0 0 1-.246-.293l.167-.962a.25.25 0 0 0-.246-.293h-.195a.25.25 0 0 0-.159.058l-3.018 2.503Z"
      />
    </g>
    <defs>
      <clipPath id="Platinum-solid-icon_svg__a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgPlatinumSolidIcon;
