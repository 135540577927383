import * as React from 'react';
import type { SVGProps } from 'react';

interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgAsscherIcon = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 20 20"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      stroke="#101820"
      strokeWidth={1.5}
      d="M.75 4.898c0-.357.153-.697.42-.934l3.26-2.898-.498-.56.498.56A1.25 1.25 0 0 1 5.26.75h9.912c.331 0 .649.132.883.366l2.829 2.829c.234.234.366.552.366.883v10.844c0 .331-.132.649-.366.883l-2.329 2.329a1.25 1.25 0 0 1-.883.366H5.186a1.25 1.25 0 0 1-.767-.263l-3.186-2.478a1.25 1.25 0 0 1-.483-.987V4.898Z"
    />
  </svg>
);
export default SvgAsscherIcon;
