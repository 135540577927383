import clsx from 'clsx';
import type { HTMLAttributes, ReactNode } from 'react';

interface InnerDropButtonProps extends HTMLAttributes<HTMLButtonElement> {
  className?: string;
  onClick?: () => void;
  children?: ReactNode;
  isSelected?: boolean;
}
export const InnerDropButton = (props: InnerDropButtonProps) => {
  const { className, onClick, children, isSelected, ...otherProps } = props;
  const rootStyle = clsx(
    className,
    'flex flex-col items-center justify-between px-4 py-2 font-medium shadow-sm hover:shadow-md rounded-sm',
    {
      'border-2 border-basicSoft': isSelected,
      'border border-neutralInactive': !isSelected,
    },
  );

  return (
    <button className={rootStyle} type={'button'} onClick={onClick} {...otherProps}>
      {children}
    </button>
  );
};
