export type GoogleAddressTypes =
  | 'street_number'
  | 'route'
  | 'locality'
  | 'administrative_area_level_1'
  | 'country'
  | 'postal_code'
  | 'postal_code_suffix'
  | 'subpremise';

export interface ReMappedGoogleAddress {
  street?: string;
  city?: string;
  state?: string;
  zip?: string;
  country?: string;
  suite?: string;
}
interface GoogleAddressItem {
  long_name: string;
  short_name: string;
  types: GoogleAddressTypes[];
}
export const createAddressObject = (addressArray: GoogleAddressItem) => {
  const addressObject: ReMappedGoogleAddress = {};
  Object.values(addressArray).map(item => {
    if (item.types.includes('street_number')) {
      addressObject.street = item.short_name;
    }
    if (item.types.includes('route')) {
      addressObject.street = `${addressObject.street} ${item.short_name}`;
    }
    if (item.types.includes('locality')) {
      addressObject.city = item.short_name;
    }
    if (item.types.includes('administrative_area_level_1')) {
      addressObject.state = item.short_name;
    }
    if (item.types.includes('postal_code')) {
      addressObject.zip = item.short_name;
    }
    if (item.types.includes('country')) {
      addressObject.country = item.short_name;
    }
    if (item.types.includes('subpremise')) {
      addressObject.suite = item.short_name;
    }
  });
  return addressObject;
};
