import * as React from 'react';
import type { SVGProps } from 'react';

interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgOtherStar = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      stroke="currentColor"
      strokeWidth={1.5}
      d="M13.189 3.232c-.374-1.152-2.004-1.152-2.378 0L9.21 8.16H4.028c-1.21 0-1.714 1.55-.734 2.261l4.192 3.046-1.602 4.928c-.374 1.152.944 2.11 1.924 1.397L12 16.747l4.192 3.045c.98.712 2.298-.245 1.924-1.397l-1.602-4.928 4.192-3.046c.98-.712.477-2.261-.734-2.261H14.79l-1.601-4.928Zm3.27 10.062Z"
    />
  </svg>
);
export default SvgOtherStar;
