import type { Dispatch, SetStateAction } from 'react';
import React from 'react';

import { Typography } from '../../../../../../components/Typography/Typography';
import UserBlockedIcon from '../../../../../../images/user-new-diamond-blocked.svg';

interface NewDiamondUserBlockedProps {
  onButtonClick: Dispatch<SetStateAction<number>>;
}

const NewDiamondUserBlocked: React.FC<NewDiamondUserBlockedProps> = ({ onButtonClick }) => {
  return (
    <div className="py-4">
      <Typography variant="bodyLarge">
        Oh no! At this time, your item can't <br /> be run through our quick appraisal <br /> tool for a credit estimate
      </Typography>
      <img className="mx-auto pt-6" src={UserBlockedIcon} alt="user blocked in new diamond flow" />

      <Typography className="pt-6" variant="body">
        This may be because most of your item's <br /> value may come from a component <br /> Pesto does not currently
        accept—such <br /> as black or colored diamonds,
        <br /> moissanite, morganite, cubic zirconium, <br /> or another material.
      </Typography>

      <Typography variant="body" className="pt-6 font-bold">
        Want to try another item?
      </Typography>

      <div className="mt-4">
        <a
          href={''}
          className="cursor-pointer font-bold text-action underline hover:no-underline"
          onClick={e => {
            e.preventDefault();
            onButtonClick(0);
          }}
        >
          Add one here
        </a>
      </div>
    </div>
  );
};

export default NewDiamondUserBlocked;
