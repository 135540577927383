import * as React from 'react';
import type { SVGProps } from 'react';

interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgCoinIcon = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <g stroke="currentColor" strokeWidth={1.5} clipPath="url(#Coin-icon_svg__a)">
      <path d="M13.714 13.018a2 2 0 1 0-3.464-2 2 2 0 0 0 3.464 2Z" />
      <path d="M23 12c0-6.075-4.925-11-11-11S1 5.925 1 12s4.925 11 11 11 11-4.925 11-11Z" />
      <path strokeDasharray="3 3" strokeLinecap="round" d="M20.117 11.883a8 8 0 1 0-16 0 8 8 0 0 0 16 0Z" />
    </g>
    <defs>
      <clipPath id="Coin-icon_svg__a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgCoinIcon;
