import clsx from 'clsx';
import React from 'react';

import { ButtonContainer } from 'src/components/ButtonContainer/ButtonContainer';
import { BackButton } from 'src/components/UI/Button/BackButton';

import type { MetalType } from '../../../../../../__generated__/graphql/api';
import { ListSelection } from '../../../../../../components/ListSelection/ListSelection';
import { Typography } from '../../../../../../components/Typography/Typography';
import { metalTypes } from '../../../../../../data/metal';

interface MetalTypeScreenProps {
  className?: string;
  onNext: (data: MetalType) => void;
  handleBack: () => void;
}

export const MetalTypeScreen = (props: MetalTypeScreenProps) => {
  const { className, onNext, handleBack } = props;
  const rootStyles = clsx('flex flex-col gap-4 max-w-[400px] w-full', className);

  return (
    <div className={rootStyles}>
      <Typography variant={'headerMedium'}>What type of metal?</Typography>
      <div className=" flex w-full flex-col justify-center gap-2 text-center">
        {metalTypes.map((metalType, index) => (
          <ListSelection
            dataTestId={metalType.value}
            key={index}
            text={metalType.text}
            leftIcon={metalType.icon}
            onClick={() => onNext(metalType.value)}
          />
        ))}
      </div>

      <ButtonContainer leftButton={<BackButton rightButtonExist={false} onClick={handleBack} />} />
    </div>
  );
};
