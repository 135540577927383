import type { MutableRefObject } from 'react';
import React from 'react';
import type { UseFormSetValue } from 'react-hook-form';

import CheckBox from 'src/components/CheckBox/CheckBox';
import { Typography } from 'src/components/Typography/Typography';

import Button from '../../../../components/UI/Button/Button';
import type { CashSecuredApplicationForm, PaymentOptions } from '../../types';
import { ApplicationFormPersonalInfo } from '../ApplicationFormPersonalInfo';
import { ApplicationPersonalInfo } from '../ApplicationPersonalInfo';
import CreditLineForm from '../CreditLineForm';
import { SignInPrompt } from '../SignInPrompt';

interface VerifyApplicationFormProps {
  inputRef: MutableRefObject<HTMLInputElement | null>;
  targetRef: MutableRefObject<HTMLDivElement | null>;
  formData: CashSecuredApplicationForm;
  errors: any;
  editableForm: boolean;
  onSubmit: (e?: React.BaseSyntheticEvent) => Promise<void>;
  setValue: UseFormSetValue<CashSecuredApplicationForm>;
  onClick: () => void;
  handlePaymentSelection: (option: PaymentOptions) => void;
  gotoLogin: () => any;
}
export const VerifyApplicationForm = (props: VerifyApplicationFormProps) => {
  const { inputRef, formData, errors, editableForm, onSubmit, setValue, onClick, handlePaymentSelection, gotoLogin } =
    props;

  return (
    <div className="flex w-full max-w-4xl justify-center rounded-2xl sm:py-20">
      <div className="flex max-w-lg flex-col justify-center gap-4">
        <div>
          <form className={'flex flex-col gap-8'} onSubmit={onSubmit}>
            <div className="shadow-black-500/40 h-auto w-full rounded-2xl border-[1px] border-black bg-offWhite p-4 shadow-md sm:px-10 sm:py-8">
              <Typography variant="headerSmall" className="my-2 text-left !font-bold">
                Personal information
              </Typography>
              {editableForm ? (
                <ApplicationFormPersonalInfo formData={formData} setValue={setValue} errors={errors} />
              ) : (
                <ApplicationPersonalInfo formData={formData} />
              )}
            </div>

            {!editableForm && (
              <CheckBox value={!editableForm} label={''} onChange={onClick} text={'The above information is correct'} />
            )}

            {editableForm && (
              <CreditLineForm
                formData={formData}
                errors={errors}
                setValue={setValue}
                handlePaymentSelection={handlePaymentSelection}
                inputRef={inputRef}
              />
            )}

            <Button
              className="bg-black text-white"
              data-testid="sign-in-button-email"
              buttonType="primary"
              type="submit"
              text={'Continue'}
            />
          </form>
          <SignInPrompt onClick={gotoLogin} />
        </div>
      </div>
    </div>
  );
};
