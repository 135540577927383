import { Dialog, Transition } from '@headlessui/react';
import clsx from 'clsx';
import { Fragment } from 'react';

import { useUser } from 'src/hooks/useUser';

import SvgCloseWhite from '../../../icons/components/CloseWhite';

import { DebuggerHelper } from './DebuggerHelper';
import { FeatureFlags } from './FeatureFlags';
import { HideOnProd } from './HideOnProd';
import { RoutingController } from './RoutingContoller';
import { UsefulCopyPaste } from './UsefulCopyPaste';
import { UserTree } from './UserTree';

interface DebuggerDrawerProps {
  className?: string;
  open: boolean;
  onClose: (value: boolean) => void;
}

export const DebuggerDrawer = (props: DebuggerDrawerProps) => {
  const { className, open, onClose } = props;
  const { user } = useUser();

  const rootStyle = clsx(className, 'relative');

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className={rootStyle} onClose={() => onClose(false)}>
        <div className="fixed inset-0" />

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                  <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                    <div className="px-4 sm:px-6">
                      <div className="flex items-start justify-between">
                        <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
                          Admin Debugger
                        </Dialog.Title>
                        <div className="ml-3 flex h-7 items-center rounded bg-basic p-2 focus:outline-none focus:ring-indigo-500 focus:ring-offset-2">
                          <button
                            type="button"
                            className="rounded-md text-gray-400 hover:text-gray-500 "
                            onClick={() => onClose(false)}
                          >
                            <span className="sr-only">Close panel</span>

                            <SvgCloseWhite className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>
                    <FeatureFlags />
                    {/*// IMPORTANT: The RoutingController component MUST NOT be accessible in production*/}
                    <HideOnProd>
                      <UsefulCopyPaste />
                      <DebuggerHelper />
                      <RoutingController />
                      {user && (
                        <div className="relative mt-6 flex-1 px-4 sm:px-6">
                          <UserTree data={user} />
                        </div>
                      )}
                    </HideOnProd>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
