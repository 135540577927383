import type { ReactElement } from 'react';
import { createContext, useCallback, useContext, useMemo } from 'react';

import { pestoAnalytics } from 'src/providers/analytics/utils/pestoAnalytics';
import type { NonNullUtmParams } from 'src/util/URL/utmParamsHelper';
import { getNonNullUtmParamsForTrack } from 'src/util/URL/utmParamsHelper';

import type { CurrentUserQuery } from '../../__generated__/graphql/api';
import { useCurrentUserQuery } from '../../__generated__/graphql/api';
import { apolloClient } from '../../api/Apollo';
import { useUser } from '../../hooks/useUser';
import type { HeapCookie } from '../../util/cookies';
import { getHeapCookie } from '../../util/cookies';

import { googleTagManagerAnalytics } from './utils/googleTagManagerAnalytics';
import { segmentAnalytics } from './utils/segmentAnalytics';

/**
 * If there's a need in the future to add another capture engine, we can
 * add it to the interface and make it available through the hook
 * example:
 * {
 *   dataDog: {...},
 *   sentry: {...}
 * }
 */
window.analytics.load(process.env.REACT_APP_SEGMENT_KEY ?? '');
export interface AnalyticsHookReturn {
  event: (eventName: string, eventProperties: Record<string, unknown>, experiment?: Record<string, unknown>) => void;
  page: (pageName?: string, eventProperties?: Record<string, unknown>) => void;
}
export interface PestoAnalyticsHookReturn extends AnalyticsHookReturn {
  identifyPage: (eventProperties?: Record<string, unknown>) => void;
}
interface AnalyticsState {
  segment: AnalyticsHookReturn;
  pestoAnalytics: AnalyticsHookReturn;
  googleTagManager: AnalyticsHookReturn;
  analytics: AnalyticsHookReturn;
}

export interface AnalyticsHookProps {
  currentUser: CurrentUserQuery['currentUser'];
  marketingTracking: NonNullUtmParams;
  heapCookie: Partial<HeapCookie>;
}

const AnalyticsContext = createContext<AnalyticsState>({} as AnalyticsState);
export function AnalyticsProvider({ children }: { children: ReactElement | ReactElement[] }) {
  const { isLoggedIn } = useUser();
  const { data: currentUserData } = useCurrentUserQuery({
    client: apolloClient,
    skip: !isLoggedIn,
  });
  const currentUser = currentUserData?.currentUser;
  const heapCookie = getHeapCookie();

  const marketingTracking = getNonNullUtmParamsForTrack();

  const analyticsProps: AnalyticsHookProps = useMemo(() => {
    return {
      currentUser,
      marketingTracking,
      heapCookie,
    };
  }, [currentUser, marketingTracking, heapCookie]);

  const { event: segmentEvent, page: segmentPage } = segmentAnalytics(analyticsProps);
  const { event: googleTagManagerEvent, page: googleTagManagerPage } = googleTagManagerAnalytics(analyticsProps);
  const { event: pestoEvent, page: pestoPage, identifyPage: pestoIdentifyPage } = pestoAnalytics(analyticsProps);

  const analyticsEvent = useCallback(
    (eventName: string, eventProperties: Record<string, unknown>) => {
      segmentEvent(eventName, eventProperties);
      googleTagManagerEvent(eventName, eventProperties);
    },
    [googleTagManagerEvent, segmentEvent],
  );

  const analyticsPage = useCallback(
    (pageName?: string, eventProperties?: Record<string, unknown>) => {
      segmentPage(pageName, eventProperties);
      googleTagManagerPage();
      pestoIdentifyPage(eventProperties);
    },
    [googleTagManagerPage, pestoIdentifyPage, segmentPage],
  );

  const analyticsState: AnalyticsState = useMemo(
    () => ({
      segment: {
        event: segmentEvent,
        page: segmentPage,
      },
      googleTagManager: {
        event: googleTagManagerEvent,
        page: googleTagManagerPage,
      },
      pestoAnalytics: {
        event: pestoEvent,
        page: pestoPage,
      },
      analytics: {
        event: analyticsEvent,
        page: analyticsPage,
      },
    }),
    [
      analyticsEvent,
      analyticsPage,
      googleTagManagerEvent,
      googleTagManagerPage,
      pestoEvent,
      pestoPage,
      segmentEvent,
      segmentPage,
    ],
  );

  return <AnalyticsContext.Provider value={analyticsState}>{children}</AnalyticsContext.Provider>;
}

export function useAnalytics() {
  const context = useContext(AnalyticsContext);
  if (!context) {
    throw new Error('useAnalytics must be used within an analytics provider');
  }
  return context;
}
