import type { FC } from 'react';
import { useMemo } from 'react';

import type { CurrentUserQuery } from '../../../__generated__/graphql/api';
import LoadingSpinner from '../../LoadingSpinner/LoadingSpinner';
import { Typography } from '../../Typography/Typography';
import { AddressInnerForm } from '../AddressInnerForm/AddressInnerForm';
import { EditableInfo } from '../EditableInfo/EditableInfo';

import { useBillingAddress } from './hooks/useBillingAddress';

interface AddressInformationProps {
  className?: string;
  title?: string;
  user?: CurrentUserQuery['currentUser'];
  editable?: boolean;
  collapsed?: boolean;
}

interface FormValues {
  addressFirstLine?: string;
  suiteNumber?: string;
  city?: string;
  state?: string;
  zip?: string;
}

interface FormattedAddressProps {
  formValues?: FormValues;
}

const FormattedAddress: FC<FormattedAddressProps> = ({ formValues = {} }) => {
  const { addressFirstLine, suiteNumber, city, state, zip } = formValues;

  const isEmpty = useMemo(() => Object.values(formValues).every(val => val === ''), [formValues]);

  const formattedAddress = useMemo(
    () => `${addressFirstLine} ${suiteNumber}, ${city}, ${state}, ${zip}`,
    [addressFirstLine, suiteNumber, city, state, zip],
  );

  return <div className="ml-2 text-sm text-gray-600">{isEmpty ? <LoadingSpinner /> : formattedAddress}</div>;
};

export const BillingAddress = (props: AddressInformationProps) => {
  const { className, title = 'Address information', user, editable = false, collapsed = false } = props;

  const {
    edit,
    setEdit,
    isFetching,
    responseError,
    formValues,
    formErrors,
    isSaving,
    handleSubmitForm,
    setValue,
    handleReset,
    setFocus,
  } = useBillingAddress(user);

  return (
    <EditableInfo
      title={title}
      className={className}
      editable={editable}
      editState={edit}
      onEdit={(edit: boolean) => {
        setEdit(edit);
      }}
      onClose={handleReset}
      onSave={handleSubmitForm}
      isSaving={isSaving}
    >
      {isFetching ? (
        <LoadingSpinner />
      ) : (
        <>
          {collapsed && !edit ? (
            <div className="flex flex-col gap-y-2 text-left">
              <FormattedAddress formValues={formValues} />
            </div>
          ) : (
            <>
              {responseError && (
                <Typography variant="body" className="text-danger">
                  Something went wrong. Please try again.
                </Typography>
              )}
              <AddressInnerForm
                formValues={formValues}
                formErrors={formErrors}
                setFormValues={setValue}
                editable={edit}
                onSubmit={handleSubmitForm}
                setFocus={setFocus}
              />
            </>
          )}
        </>
      )}
    </EditableInfo>
  );
};
