import { Typography } from 'src/components/Typography/Typography';
import { Wrapper1280 } from 'src/components/UI/Wrapper/Wrapper1280';
import { cn } from 'src/util/cn';

import hiw1 from '../../../assets/howItWorks1.webp';
import hiw2 from '../../../assets/howItWorks2.webp';
import hiw3 from '../../../assets/howItWorks3.webp';
import hiw4 from '../../../assets/howItWorks4.webp';

const data = [
  {
    title: 'Estimate and Prequalify',
    description:
      'Use our calculator and quick online application to estimate your potential credit line based on the value of your eligible items. There is no minimum credit score required to get the Pesto Mastercard® and no impact to your credit score to see if you prequalify.',
    image: hiw1,
  },
  {
    title: 'Send it to us (Free Shipping Kit and Fully Insured)',
    description:
      'We offer free, fast, and convenient shipping methods through UPS. We can send you a free shipping kit with all the shipping materials included for you to safely send us your item. Your item will also be fully insured throughout the entire delivery and arrival process while under our care.',
    image: hiw2,
  },
  {
    title: 'Receive a Final Offer of Credit',
    description:
      'Our expert team of gemologists and horologists will begin a detailed review process to evaluate your item. After you accept your offer, you can start spending money without selling your valuables. Your digital card is available immediately for use and your physical card will arrive a few days later in the mail.',
    image: hiw3,
  },
  {
    title: 'Get Your Items Back',
    description:
      'You’ll be in the driver’s seat for however long you are a cardholder. When you no longer need your Pesto Mastercard®  just pay off the remaining card balance and we’ll send your items back to you free of charge.',
    image: hiw4,
  },
];

export const HowItWorks = () => {
  return (
    <div className={'flex flex-col gap-6 bg-gradient-to-b from-[#E8E8E840] to-[#E8E8E800] to-40% py-16 text-black'}>
      <Typography variant={'heroSmall'} className={''}>
        How it works
      </Typography>
      {data.map((item, index) => {
        const bgStyle = cn({
          '': index % 2 === 1,
        });
        const wrapperStyle = 'flex w-full flex-col gap-12 flex-col-reverse sm:flex-row ';
        const styleTextDiv = cn('flex w-full flex-row items-start sm:items-center justify-center gap-6 text-left', {
          'sm:order-2': index % 2 === 1,
        });
        return (
          <div className={bgStyle} key={index}>
            <Wrapper1280 className={wrapperStyle}>
              <div className={styleTextDiv}>
                <Typography variant={'heroLarge'} className={'hidden !font-bold sm:block sm:!text-8xl'}>
                  {`${index + 1}`}
                </Typography>
                <div className={'flex flex-col gap-1'}>
                  <Typography variant={'headerLarge'} className={'flex gap-2 !font-black'}>
                    <Typography variant={'headerLarge'} className={'!font-bold sm:hidden'}>
                      {`${index + 1}.`}
                    </Typography>
                    {item.title}
                  </Typography>
                  <Typography variant={'bodyLarge'}>{item.description}</Typography>
                </div>
              </div>
              <div className={'w-full'}>
                <img src={item.image} alt={`how it works illustration step ${index + 1}`} />
              </div>
            </Wrapper1280>
          </div>
        );
      })}
    </div>
  );
};
