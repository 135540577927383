import * as React from 'react';
import type { SVGProps } from 'react';

interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgItemRejected = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <g clipPath="url(#ItemRejected_svg__a)">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M11.75.75c-6.059 0-11 4.941-11 11s4.941 11 11 11 11-4.942 11-11c0-6.059-4.941-11-11-11Zm0 19.678c-4.78 0-8.678-3.898-8.678-8.678 0-4.78 3.898-8.678 8.678-8.678 4.78 0 8.678 3.898 8.678 8.678 0 4.78-3.898 8.678-8.678 8.678Zm3.995-12.673c.46.46.46 1.214 0 1.674l-2.32 2.32 2.32 2.322c.46.46.46 1.214 0 1.674-.46.46-1.214.46-1.674 0l-2.321-2.32-2.32 2.32c-.461.46-1.215.46-1.675 0a1.188 1.188 0 0 1 0-1.674l2.32-2.321-2.32-2.32a1.188 1.188 0 0 1 0-1.675c.46-.46 1.214-.46 1.674 0l2.32 2.32 2.322-2.32c.46-.46 1.214-.46 1.674 0Z"
        clipRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="ItemRejected_svg__a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgItemRejected;
