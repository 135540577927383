export const validateDOB = (dob: string) => {
  const dobRegex = /^(19|20)\d{2}[-](0[1-9]|1[012])[-](0[1-9]|[12][0-9]|3[01])$/;
  return dobRegex.test(dob);
};

export const isOver18DOB = (dob: string) => {
  const dobDate = new Date(dob);
  const today = new Date();
  const eighteenYearsAgo = new Date(today.getFullYear() - 18, today.getMonth(), today.getDate());
  return dobDate < eighteenYearsAgo;
};
