import { ItemCartSkeleton } from '../../../../../components/ItemCard/ItemCardSkeleton';

export const LoadingItemCart = () => {
  return (
    <div className="flex w-full max-w-[440px] flex-col items-center gap-4">
      <div className="h-[20px] w-[50%] rounded bg-gray-200" />
      <div className="h-[20px] w-full rounded bg-gray-100" />
      <ItemCartSkeleton />
      <ItemCartSkeleton />
      <ItemCartSkeleton />
    </div>
  );
};
