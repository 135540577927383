import type { ReactNode } from 'react';

import { cn } from 'src/util/cn';

import { useIsMobile } from '../../hooks/useIsMobile';
import CheckBox from '../CheckBox/CheckBox';
import { Divider } from '../Devider/Devider';
import { Typography } from '../Typography/Typography';

interface CheckDownProps {
  onChange: (checked: boolean) => void;
  checked: boolean;
  className?: string;
  title?: string;
  leftIcon?: ReactNode;
  children?: ReactNode;
  caption?: string;
  error?: string;
  dataTestId?: string;
}

export const CheckDown = (props: CheckDownProps) => {
  const { className, onChange, checked, leftIcon, title, children, error, caption, dataTestId } = props;
  const isMobile = useIsMobile();
  const rootStyle = cn(
    className,
    'flex w-full flex-col items-center shadow justify-between rounded-sm border border-neutralInactive bg-[#FAFAFC] text-lg text-left font-normal text-basic hover:bg-[#F2F2F7] active:bg-[#EDEDF2]',
    {
      'shadow-lg': checked,
      '!border-neutral': checked && children,
    },
  );

  const toggleView = () => {
    onChange(!checked);
  };

  return (
    <div className={rootStyle}>
      <button
        className="flex w-full items-center justify-between gap-4 px-4 py-4"
        onClick={e => {
          e.preventDefault();
          toggleView();
        }}
        data-testid={dataTestId}
      >
        <div className="flex items-center gap-4">
          {leftIcon}
          {title}
        </div>
        <CheckBox controlled={false} checked={checked} />
      </button>
      {checked && children && (
        <>
          <Divider className="px-4" lineColor={'neutralInactive'} />
          <div className={cn('w-full transform transition-all duration-700 ease-in-out')}>
            <div className="p-4">{children}</div>
          </div>
          {caption && (
            <Typography
              className="ml-[8px] mt-[8px] font-extralight text-neutralStrong"
              variant={isMobile ? 'captionSmall' : 'caption'}
            >
              {caption}
            </Typography>
          )}
          {error && <div className="mt-[8px] text-sm text-danger">{error}</div>}
        </>
      )}
    </div>
  );
};
