import * as React from 'react';
import type { SVGProps } from 'react';

interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgCalendar = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 40 40"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <g clipPath="url(#calendar_svg__a)">
      <path
        fill="currentColor"
        d="M32.277 3.08h-1.492V1.57a1.562 1.562 0 1 0-3.123 0v1.51H12.338V1.57a1.562 1.562 0 0 0-3.123 0v1.51H7.723C3.464 3.08 0 6.545 0 10.803v21.465c0 4.259 3.464 7.723 7.723 7.723h24.554c4.259 0 7.723-3.464 7.723-7.723V10.803c0-4.258-3.464-7.723-7.723-7.723ZM7.723 6.204h1.492V9.25a1.562 1.562 0 0 0 3.123 0V6.204h15.324V9.25a1.562 1.562 0 0 0 3.123 0V6.204h1.492c2.536 0 4.6 2.063 4.6 4.6v1.491H3.123v-1.492c0-2.536 2.063-4.599 4.599-4.599Zm24.554 30.663H7.723a4.604 4.604 0 0 1-4.6-4.599v-16.85h33.753v16.85c0 2.536-2.063 4.6-4.599 4.6ZM13.874 21.563c0 .862-.7 1.562-1.562 1.562H9.241a1.562 1.562 0 1 1 0-3.124h3.071c.863 0 1.562.7 1.562 1.562Zm18.447 0c0 .862-.7 1.562-1.562 1.562h-3.071a1.562 1.562 0 1 1 0-3.124h3.071c.863 0 1.562.7 1.562 1.562Zm-9.232 0c0 .862-.7 1.562-1.562 1.562h-3.072a1.562 1.562 0 1 1 0-3.124h3.072c.862 0 1.562.7 1.562 1.562Zm-9.215 9.214c0 .863-.7 1.562-1.562 1.562H9.241a1.562 1.562 0 0 1 0-3.123h3.071c.863 0 1.562.699 1.562 1.561Zm18.447 0c0 .863-.7 1.562-1.562 1.562h-3.071a1.562 1.562 0 0 1 0-3.123h3.071c.863 0 1.562.699 1.562 1.561Zm-9.232 0c0 .863-.7 1.562-1.562 1.562h-3.072a1.562 1.562 0 0 1 0-3.123h3.072c.862 0 1.562.699 1.562 1.561Z"
      />
    </g>
    <defs>
      <clipPath id="calendar_svg__a">
        <path fill="#fff" d="M0 0h40v40H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgCalendar;
