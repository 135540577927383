import { Content } from '../../components/Content';
import { Layout } from '../../components/Layout';
import { Sidebar } from '../../components/Sidebar';

interface FinderLandingPageProps {
  onCashClick: () => void;
  onJewelryClick: () => void;
}

export const FinderLandingPage = (props: FinderLandingPageProps) => {
  const { onCashClick, onJewelryClick } = props;

  return (
    <div className="w-full">
      <Layout
        leftContent={
          <div className="h-full">
            <Sidebar />
          </div>
        }
        rightContent={<Content onCashClick={onCashClick} onJewelryClick={onJewelryClick} />}
      />
    </div>
  );
};
