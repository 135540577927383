import { useEffect, useState } from 'react';

import type { CurrentUserQuery } from '../__generated__/graphql/api';
import { useCurrentUserLazyQuery, useCurrentUserQuery } from '../__generated__/graphql/api';
import { useAuth } from '../auth/AuthProvider';

export const useUser = () => {
  const { isAuthenticated } = useAuth();
  const [isLazyLoading, setIsLazyLoading] = useState(true);

  const { data } = useCurrentUserQuery({
    fetchPolicy: 'cache-only',
  });
  const [getUser] = useCurrentUserLazyQuery({
    errorPolicy: 'all',
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onError: () => {
      setIsLazyLoading(false);
    },
    onCompleted: () => {
      setIsLazyLoading(false);
    },
  });

  const user = data?.currentUser;
  const isLoggedIn = !!user;
  const isLoading = isLazyLoading;

  useEffect(() => {
    if (isAuthenticated()) {
      getUser();
    } else {
      setIsLazyLoading(false);
    }
  }, [getUser, isAuthenticated]);
  const getUserData = (): Promise<CurrentUserQuery['currentUser'] | null> => {
    return new Promise((resolve, reject) => {
      if (!isAuthenticated()) {
        return reject('User is not authenticated');
      }
      getUser()
        .then(user => {
          resolve(user.data?.currentUser);
        })
        .catch(err => {
          reject(err);
        });
    });
  };

  return {
    user,
    isLoggedIn,
    isLoading,
    getUserData,
  };
};
