import * as React from 'react';
import type { SVGProps } from 'react';

interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgModalFrameTop = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 128 178"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="#fff"
      d="M11.183 171.15c2.535 0 4.2-2.13 4.2-5.805 0-3.63-1.665-5.745-4.2-5.745-2.55 0-4.215 2.115-4.215 5.745 0 3.675 1.665 5.805 4.215 5.805Zm0-9.705c1.515 0 2.145 1.575 2.145 3.9 0 2.385-.615 3.96-2.145 3.96-1.56 0-2.175-1.575-2.175-3.96 0-2.325.63-3.9 2.175-3.9Zm6.974 9.705c.735 0 1.26-.48 1.26-1.215 0-.75-.526-1.245-1.26-1.245-.735 0-1.276.495-1.276 1.245 0 .735.54 1.215 1.276 1.215Zm10.178-2.055h-4.59l.976-.96c2.505-2.43 3.39-3.555 3.39-5.085 0-2.025-1.425-3.45-3.616-3.45-1.95 0-3.57 1.08-3.93 3.21l1.98.39c.135-1.215.99-1.755 1.86-1.755 1.035 0 1.65.675 1.65 1.665s-.584 1.875-2.924 4.11l-2.22 2.16V171h7.424v-1.905Zm9.088-1.815c0-2.115-1.41-3.63-3.63-3.63-.6 0-1.11.105-1.59.345l.375-2.43h4.335v-1.815h-5.955l-.915 5.985 1.695.45c.285-.525.945-.825 1.68-.825 1.155 0 1.95.765 1.95 1.98 0 1.155-.765 1.95-1.98 1.95-.975 0-1.83-.51-1.935-1.635l-2.01.51c.33 2.04 2.07 2.985 3.945 2.985 2.475 0 4.035-1.635 4.035-3.87Zm4.444-.03c0 2.25 1.65 3.9 3.9 3.9 1.89 0 3.33-1.17 3.6-2.715l-1.875-.315c-.18.795-.795 1.305-1.74 1.305-1.14 0-1.905-.84-1.905-2.175 0-1.35.795-2.19 1.935-2.19.84 0 1.455.48 1.65 1.29l1.89-.345c-.345-1.605-1.695-2.685-3.525-2.685-2.265 0-3.93 1.665-3.93 3.93Zm7.914-2.655v.54h1.425v3.63c0 1.5.945 2.385 2.535 2.385.645 0 1.29-.15 1.86-.435l-.42-1.56c-.375.165-.69.255-1.02.255-.675 0-1.065-.3-1.065-1.08v-3.195h2.61v-1.65h-2.61v-1.71h-.51l-2.805 2.82Z"
    />
    <g clipPath="url(#modal-frame-top_svg__a)" opacity={0.7}>
      <path
        fill="#FFD2B3"
        stroke="#101820"
        d="M5.324 26.5c0-14.36 11.64-26 26-26s26 11.64 26 26v110.05c0 .635-.421 1.193-1.032 1.368a49.488 49.488 0 0 1-46.7-10.8l-3.683-3.314a1.768 1.768 0 0 1-.585-1.314V26.5Z"
      />
      <g opacity={0.9}>
        <path
          fill="url(#modal-frame-top_svg__b)"
          d="M7.824 23.859c0-11.051 10.297-20.01 23-20.01 12.702 0 23 8.959 23 20.01V52.36c0 1.332-9.217 6.411-10.5 7.139-5.5 2.5-11 3.5-25.5-1l-7.362-2.742c-1.627-.778-2.638-2.266-2.638-3.881V23.859Z"
        />
        <path
          stroke="#101820"
          strokeOpacity={0.8}
          strokeWidth={1.5}
          d="M8.574 23.859c0-10.542 9.86-19.26 22.25-19.26s22.25 8.718 22.25 19.26V52.27c-.032.05-.092.127-.192.233a8.612 8.612 0 0 1-.973.836c-.855.652-2.023 1.42-3.25 2.18-2.111 1.308-4.341 2.56-5.332 3.117-.14.08-.257.144-.343.194-2.672 1.211-5.309 2.044-9.035 2.044-3.764 0-8.676-.85-15.883-3.086l-7.31-2.721c-1.386-.676-2.182-1.913-2.182-3.191V23.859Zm44.522 28.373-.003.007a.06.06 0 0 1 .003-.006Z"
        />
      </g>
      <path
        fill="#fff"
        fillOpacity={0.7}
        stroke="#494848"
        strokeWidth={1.5}
        d="M30.793 3.748h.025c6.316-.21 12.263 2.576 16.388 6.662 3.722 3.688 5.878 8.345 5.584 12.71-3.17-8.33-11.51-15.94-21.948-16.613l-.025-.002h-.024c-9.878 0-18.623 6.429-21.879 14.885a17.187 17.187 0 0 1 4.77-11.175c3.69-3.87 9.469-6.467 17.11-6.467Z"
      />
      <g filter="url(#modal-frame-top_svg__c)">
        <path fill="#FDFDFC" d="M42.824 55.5c0 1.38-4.477 4-10 4s-10-2.62-10-4 4.477-2.5 10-2.5 10 1.12 10 2.5Z" />
      </g>
      <path
        stroke="#101820"
        strokeLinecap="round"
        strokeOpacity={0.8}
        strokeWidth={2}
        d="M24.824 111h12M22.824 118h11M37.824 118h3M27.824 125h11"
      />
      <path
        fill="#101820"
        fillOpacity={0.06}
        fillRule="evenodd"
        d="M54.06 145.131c9.368.619 18.959-29.099 21.421-66.376 2.462-37.278-3.136-67.999-12.505-68.618-13.394 0-12.8 6.507-11.83 17.138.888 9.73 2.09 22.912-6.822 37.725-.857 12.982-1.073 26.565-.594 38.782-3.086-3.595-6.558-6.07-9.853-6.287-9.368-.619-17.553 7.815-18.281 18.836-.728 11.022 6.276 20.459 15.645 21.077l.497.033c5.465.363 11.722.779 16.094-1.144 1.607 5.407 3.675 8.666 6.228 8.834Z"
        clipRule="evenodd"
      />
    </g>
    <g filter="url(#modal-frame-top_svg__d)">
      <path
        fill="#DDEDFB"
        stroke="#AFA7A3"
        strokeWidth={1.5}
        d="M32.376 36.41a2.75 2.75 0 0 0-2.104 0l-6.046 2.504a2.75 2.75 0 0 0-1.488 1.488l-2.504 6.046a2.75 2.75 0 0 0 0 2.104l2.504 6.046a2.75 2.75 0 0 0 1.488 1.488l6.046 2.504a2.75 2.75 0 0 0 2.104 0l6.046-2.504a2.75 2.75 0 0 0 1.488-1.488l2.504-6.046a2.75 2.75 0 0 0 0-2.104l-2.504-6.046a2.75 2.75 0 0 0-1.488-1.488l-6.046-2.504Z"
      />
      <path
        fill="#91BBDA"
        stroke="#9BC4EE"
        strokeWidth={0.5}
        d="m31.324 38.24 1.863 4.306.097.223.225-.09 4.363-1.727-1.728 4.363-.089.225.223.096 4.306 1.864-4.306 1.864-.223.096.09.225 1.727 4.363-4.363-1.728-.225-.089-.096.223-1.864 4.306-1.864-4.306-.096-.223-.225.09-4.363 1.727 1.727-4.363.09-.225-.223-.096-4.307-1.864 4.307-1.864.223-.096-.09-.225-1.727-4.363 4.363 1.727.225.09.096-.223 1.864-4.307Z"
      />
      <path
        fill="#C8DDEC"
        stroke="#7EA2BC"
        strokeWidth={0.5}
        d="m28.601 44.777 2.723-1.127 2.723 1.127 1.127 2.723-1.127 2.722-2.723 1.128-2.723-1.128-1.127-2.722 1.127-2.723Z"
      />
      <path
        fill="#E3EEF5"
        stroke="#ADBBC6"
        strokeWidth={0.5}
        d="m29.205 47.5 2.12-2.12 2.118 2.12-2.119 2.119-2.119-2.12Z"
      />
    </g>
    <path
      fill="#fff"
      d="M83.115 171.15c2.535 0 4.2-2.13 4.2-5.805 0-3.63-1.665-5.745-4.2-5.745-2.55 0-4.215 2.115-4.215 5.745 0 3.675 1.664 5.805 4.215 5.805Zm0-9.705c1.514 0 2.145 1.575 2.145 3.9 0 2.385-.616 3.96-2.145 3.96-1.56 0-2.175-1.575-2.175-3.96 0-2.325.63-3.9 2.175-3.9Zm6.973 9.705c.735 0 1.26-.48 1.26-1.215 0-.75-.525-1.245-1.26-1.245s-1.275.495-1.275 1.245c0 .735.54 1.215 1.275 1.215Zm10.404-3.87c0-2.115-1.41-3.63-3.63-3.63-.6 0-1.11.105-1.59.345l.375-2.43h4.335v-1.815h-5.955l-.915 5.985 1.695.45c.285-.525.945-.825 1.68-.825 1.155 0 1.95.765 1.95 1.98 0 1.155-.765 1.95-1.98 1.95-.975 0-1.83-.51-1.935-1.635l-2.01.51c.33 2.04 2.07 2.985 3.945 2.985 2.475 0 4.035-1.635 4.035-3.87Zm4.444-.03c0 2.25 1.65 3.9 3.9 3.9 1.89 0 3.33-1.17 3.6-2.715l-1.875-.315c-.18.795-.795 1.305-1.74 1.305-1.14 0-1.905-.84-1.905-2.175 0-1.35.795-2.19 1.935-2.19.84 0 1.455.48 1.65 1.29l1.89-.345c-.345-1.605-1.695-2.685-3.525-2.685-2.265 0-3.93 1.665-3.93 3.93Zm7.914-2.655v.54h1.425v3.63c0 1.5.945 2.385 2.535 2.385.645 0 1.29-.15 1.86-.435l-.42-1.56c-.375.165-.69.255-1.02.255-.675 0-1.065-.3-1.065-1.08v-3.195h2.61v-1.65h-2.61v-1.71h-.51l-2.805 2.82Z"
    />
    <g clipPath="url(#modal-frame-top_svg__e)" opacity={0.7}>
      <path
        fill="#FFD2B3"
        stroke="#101820"
        d="M72.352 26.5c0-14.36 11.64-26 26-26 14.359 0 26 11.64 26 26v110.05c0 .635-.421 1.193-1.032 1.368a49.487 49.487 0 0 1-46.7-10.8l-3.683-3.314a1.768 1.768 0 0 1-.585-1.314V26.5Z"
      />
      <g opacity={0.9}>
        <path
          fill="url(#modal-frame-top_svg__f)"
          d="M74.852 23.859c0-11.051 10.297-20.01 23-20.01s23 8.959 23 20.01V52.36c0 1.332-9.217 6.411-10.5 7.139-5.5 2.5-11 3.5-25.5-1l-7.361-2.742c-1.628-.778-2.639-2.266-2.639-3.881V23.859Z"
        />
        <path
          stroke="#101820"
          strokeOpacity={0.8}
          strokeWidth={1.5}
          d="M75.602 23.859c0-10.542 9.86-19.26 22.25-19.26s22.25 8.718 22.25 19.26V52.27c-.032.05-.092.127-.192.233a8.645 8.645 0 0 1-.973.836c-.855.652-2.023 1.42-3.249 2.18-2.112 1.308-4.342 2.56-5.333 3.117-.141.08-.257.144-.343.194-2.672 1.211-5.309 2.044-9.035 2.044-3.764 0-8.676-.85-15.883-3.086l-7.309-2.721c-1.387-.676-2.183-1.913-2.183-3.191V23.859Zm44.522 28.373-.003.007a.13.13 0 0 0 .003-.006Z"
        />
      </g>
      <path
        fill="#fff"
        fillOpacity={0.7}
        stroke="#494848"
        strokeWidth={1.5}
        d="M97.821 3.748h.025c6.316-.21 12.263 2.576 16.388 6.662 3.722 3.688 5.878 8.345 5.584 12.71-3.171-8.33-11.51-15.94-21.948-16.613l-.025-.002h-.024c-9.878 0-18.623 6.429-21.879 14.885a17.187 17.187 0 0 1 4.771-11.175c3.688-3.87 9.468-6.467 17.108-6.467Z"
      />
      <g filter="url(#modal-frame-top_svg__g)">
        <path fill="#FDFDFC" d="M109.852 55.5c0 1.38-4.477 4-10 4s-10-2.62-10-4 4.477-2.5 10-2.5 10 1.12 10 2.5Z" />
      </g>
      <path
        stroke="#101820"
        strokeLinecap="round"
        strokeOpacity={0.8}
        strokeWidth={2}
        d="M91.852 111h12M89.852 118h11M104.852 118h3M94.852 125h11"
      />
      <path
        fill="#101820"
        fillOpacity={0.06}
        fillRule="evenodd"
        d="M121.088 145.131c9.368.619 18.959-29.099 21.421-66.376 2.462-37.278-3.136-67.999-12.505-68.618-13.394 0-12.8 6.507-11.83 17.138.888 9.73 2.091 22.912-6.822 37.725-.857 12.982-1.073 26.565-.594 38.782-3.086-3.595-6.558-6.07-9.853-6.287-9.368-.619-17.553 7.815-18.281 18.836-.728 11.022 6.276 20.459 15.645 21.077l.497.033c5.465.363 11.722.779 16.094-1.144 1.607 5.407 3.675 8.666 6.228 8.834Z"
        clipRule="evenodd"
      />
    </g>
    <g filter="url(#modal-frame-top_svg__h)">
      <path
        fill="#DDEDFB"
        stroke="#AFA7A3"
        strokeWidth={1.5}
        d="m85.087 46.553.689-.286-.689.286 3.141 7.583a2.75 2.75 0 0 0 1.489 1.488l7.583 3.141a2.75 2.75 0 0 0 2.104 0l7.583-3.141a2.75 2.75 0 0 0 1.489-1.488l3.141-7.583a2.751 2.751 0 0 0 0-2.105l-3.141-7.583a2.75 2.75 0 0 0-1.489-1.489l-7.583-3.14a2.75 2.75 0 0 0-2.104 0l.285.688-.285-.689-7.583 3.142a2.75 2.75 0 0 0-1.489 1.488l-3.14 7.583.688.285-.689-.285a2.75 2.75 0 0 0 0 2.105Z"
      />
      <path
        fill="#91BBDA"
        stroke="#9BC4EE"
        strokeWidth={0.5}
        d="m98.352 34.232 2.288 5.289.097.222.225-.089 5.358-2.122-2.122 5.358-.089.225.222.097 5.289 2.288-5.289 2.288-.222.097.089.225 2.122 5.358-5.358-2.122-.225-.09-.097.223-2.288 5.289-2.288-5.289-.096-.222-.226.089-5.358 2.122 2.122-5.358.09-.225-.223-.097-5.289-2.288 5.289-2.288.222-.097-.089-.225-2.122-5.358 5.358 2.122.225.09.097-.223 2.288-5.289Z"
      />
      <path
        fill="#C8DDEC"
        stroke="#7EA2BC"
        strokeWidth={0.5}
        d="m95.038 42.186 3.314-1.372 3.314 1.372 1.373 3.314-1.373 3.314-3.314 1.373-3.314-1.373-1.373-3.314 1.373-3.314Z"
      />
      <path
        fill="#E3EEF5"
        stroke="#ADBBC6"
        strokeWidth={0.5}
        d="m95.731 45.5 2.621-2.62 2.621 2.62-2.621 2.621-2.62-2.62Z"
      />
    </g>
    <defs>
      <filter
        id="modal-frame-top_svg__c"
        width={44}
        height={30.5}
        x={10.824}
        y={41}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation={6} />
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_1114_34175" />
        <feBlend in="SourceGraphic" in2="effect1_backgroundBlur_1114_34175" result="shape" />
      </filter>
      <filter
        id="modal-frame-top_svg__d"
        width={27}
        height={28}
        x={18.824}
        y={35}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
        <feOffset dx={1} dy={2} />
        <feGaussianBlur stdDeviation={0.5} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_1114_34175" />
        <feBlend in="SourceGraphic" in2="effect1_dropShadow_1114_34175" result="shape" />
        <feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
        <feOffset dx={-4} dy={-2} />
        <feComposite in2="hardAlpha" k2={-1} k3={1} operator="arithmetic" />
        <feColorMatrix values="0 0 0 0 0.0901961 0 0 0 0 0.137255 0 0 0 0 0.184314 0 0 0 0.1 0" />
        <feBlend in2="shape" result="effect2_innerShadow_1114_34175" />
      </filter>
      <filter
        id="modal-frame-top_svg__g"
        width={44}
        height={30.5}
        x={77.852}
        y={41}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation={6} />
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_1114_34175" />
        <feBlend in="SourceGraphic" in2="effect1_backgroundBlur_1114_34175" result="shape" />
      </filter>
      <filter
        id="modal-frame-top_svg__h"
        width={31}
        height={32}
        x={83.852}
        y={31}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
        <feOffset dx={1} dy={2} />
        <feGaussianBlur stdDeviation={0.5} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_1114_34175" />
        <feBlend in="SourceGraphic" in2="effect1_dropShadow_1114_34175" result="shape" />
        <feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
        <feOffset dx={-4} dy={-2} />
        <feComposite in2="hardAlpha" k2={-1} k3={1} operator="arithmetic" />
        <feColorMatrix values="0 0 0 0 0.0901961 0 0 0 0 0.137255 0 0 0 0 0.184314 0 0 0 0.1 0" />
        <feBlend in2="shape" result="effect2_innerShadow_1114_34175" />
      </filter>
      <linearGradient
        id="modal-frame-top_svg__b"
        x1={26.42}
        x2={41.453}
        y1={11.087}
        y2={50.521}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F2D1B9" stopOpacity={0.84} />
        <stop offset={0.291} stopColor="#E7E7E8" stopOpacity={0.44} />
        <stop offset={0.487} stopColor="#fff" stopOpacity={0.67} />
        <stop offset={0.682} stopColor="#fff" stopOpacity={0.33} />
        <stop offset={1} stopColor="#F5D3BB" />
      </linearGradient>
      <linearGradient
        id="modal-frame-top_svg__f"
        x1={93.448}
        x2={108.481}
        y1={11.087}
        y2={50.521}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F2D1B9" stopOpacity={0.84} />
        <stop offset={0.291} stopColor="#E7E7E8" stopOpacity={0.44} />
        <stop offset={0.487} stopColor="#fff" stopOpacity={0.67} />
        <stop offset={0.682} stopColor="#fff" stopOpacity={0.33} />
        <stop offset={1} stopColor="#F5D3BB" />
      </linearGradient>
      <clipPath id="modal-frame-top_svg__a">
        <path fill="#fff" d="M4.824 0h53v141h-53z" />
      </clipPath>
      <clipPath id="modal-frame-top_svg__e">
        <path fill="#fff" d="M71.852 0h53v141h-53z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgModalFrameTop;
