import * as yup from 'yup';

import { phoneRegex } from 'src/constants/regex';

import { isOver18DOB, validateDOB } from './dobHelper';
import { stringSpecialCharactersTest } from './stringSpecialCharactersTest';

export const ERROR_MESSAGES = {
  addressFirstLine: 'Please enter your address',
  city: 'Please enter your city',
  state: 'Please enter your state',
  zipCode: 'Please enter your zip code',
  addressTooLong: 'Address cannot be more than 35 characters',
  address: 'Please enter your address',
  zip: 'Please enter your zip code',
  income: 'Please provide your total annual income',
  dob: 'Please enter your date of birth',
  ssnOrTid: 'Please enter your SSN/ITIN',
  confirmSsnOrTid: 'SSN/ITIN does not match',
  agreement2: 'You must agree to the terms',
  agree: 'Please agree to the terms and conditions',
  code: 'Please enter a valid verification code',
};

export const firstNameValidation = stringSpecialCharactersTest('First name')
  .required('First name is required')
  .transform((value: string) => value.charAt(0).toUpperCase() + value.slice(1));
export const middleNameValidation = stringSpecialCharactersTest('Middle name').optional();
export const lastNameValidation = stringSpecialCharactersTest('Last name').required('Last name is required');

export const emailValidation = yup.string().email('Invalid email').required('Email is required');
export const zipValidation = yup
  .string()
  .required('Please enter a zip code')
  .test('valid-zip-code', 'Please enter a valid zip code', function (value) {
    if (value.length === 0) {
      return false;
    }
    return value.length === 5;
  });
export const stateValidation = yup.string().required(ERROR_MESSAGES.state);

export const cityValidation = yup.string().required(ERROR_MESSAGES.city);
export const addressFirstLineValidation = stringSpecialCharactersTest('Address')
  .max(35, 'Address cannot be more than 35 characters')
  .required('Please enter your street address')
  .test('is-not-undefined', 'Address cannot have undefined', value => {
    // search for 'undefined' in the string
    const isUndefinedInString = value?.includes('undefined');
    return !isUndefinedInString;
  })
  .test('is-not-po-box', 'Address cannot be a PO Box', value => {
    const poBoxValidation =
      /^ *((?:p\.?\s*o\.?|po(?:stal)?\s+office?|\bpo\b|\bp\.?\s*o\b)|(#\d+)|((box|bin)[-. /\\]?\d+)|(.*p[ .]? ?(o|0)[-. /\\]? *-?((box|bin)|b|(#|n|num|number)?\d+))|(p(ost|ostal)? *(o(ff(ice)?)?)? *((box|bin)|b)? *(#|n|num|number)*\d+)|(p *-?\/?(o)? *-?box)|post office box|((box|bin)|b) *(#|n|num|number)? *\d+|(#|n|num|number) *\d+)/i;
    return !poBoxValidation.test(value || '');
  });

export const dob = yup
  .string()
  .required('Date of Birth is required')
  .test('dob', 'Date of Birth is invalid', function (value) {
    return value ? validateDOB(value) : true;
  })
  .test('dob-age', 'You must be at least 18 years old', function (value) {
    return value ? isOver18DOB(value) : true;
  });

export const phoneNumberValidation = yup
  .string()
  .required('Please enter your phone')
  .transform((value: string) => (value ? value.replace(/\D/g, '') : ''))
  .matches(phoneRegex, 'Invalid phone number')
  .test('startsWithOne', 'Phone number must start with 1 (US country code)', value => value?.startsWith('1'));
