import { datadogLogs } from '@datadog/browser-logs';

import { useCurrentUserQuery } from 'src/__generated__/graphql/api';
import { Typography } from 'src/components/Typography/Typography';
import Button from 'src/components/UI/Button/Button';
import { useOnMount } from 'src/hooks/useOnMount';
import { Svg404Icon } from 'src/icons/components';

import { Header } from '../components/Header/Header';
import { routes } from '../constants/routes';

interface ErrorPageProps {
  error?: string;
}

const ErrorPage = (props: ErrorPageProps) => {
  const user = useCurrentUserQuery();
  const { error } = props;

  useOnMount(() => {
    datadogLogs.logger.error('ErrorBoundary log:', {
      email: user?.data?.currentUser?.email,
      firstName: user?.data?.currentUser?.firstName,
      lastName: user?.data?.currentUser?.lastName,
      userId: user?.data?.currentUser?.id,
      userStatus: user?.data?.currentUser?.creditApplication?.status,
      stackTraceError: error,
    });
  });

  const errorMessage = error
    ? "We're sorry, but an unexpected error has occurred. Please try reloading the page. If the issue persists, contact our support team for assistance."
    : 'Something went wrong. Please reload the page or contact support if the issue continues.';

  return (
    <>
      <Header />
      <div className="flex h-screen w-full flex-col items-center justify-center gap-4 p-[20px] text-center sm:gap-8">
        <Typography variant="heroSmall" className={'text-6xl'}>
          Whoops! An error occurred.
        </Typography>

        <Svg404Icon className="h-[240px] w-[240px]" />
        <div>
          <Typography variant="headerSmall" className={'text-6xl'}>
            Error:
          </Typography>

          <Typography variant="body" className="max-w-lg leading-8">
            {errorMessage}
          </Typography>
        </div>

        <Button
          buttonType="secondary"
          onClick={() => {
            return (window.location.href = routes.home);
          }}
          text="GO BACK"
        />
      </div>
    </>
  );
};

export default ErrorPage;
