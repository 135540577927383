import React from 'react';

interface BenefitItemProps {
  icon: React.ReactNode;
  text: React.ReactNode;
}

export const BenefitItem: React.FC<BenefitItemProps> = ({ icon, text }) => {
  return (
    <div className="flex items-center text-left">
      {icon}
      {text}
    </div>
  );
};
