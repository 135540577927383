import { ApolloLink } from '@apollo/client';
import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';

import type { CurrentUserQuery } from '../../__generated__/graphql/api';
import { CurrentUserDocument, SecurityType } from '../../__generated__/graphql/api';
import { apolloClient } from '../Apollo';

export const useHandleApolloLogs = () => {
  return new ApolloLink((operation, forward) => {
    if (operation.operationName === 'currentUser') {
      const user = apolloClient?.cache?.readQuery<CurrentUserQuery>({ query: CurrentUserDocument })?.currentUser;
      const userId = user?.id;
      const userName = user?.displayName;
      const userEmail = user?.email;
      const securityType = user?.creditApplication?.securityType;
      const creditApplicationId = user?.creditApplication?.id;
      const isSecurityTypeCash = securityType === SecurityType.Cash;
      const paymentPreference = user?.creditApplication?.paymentPreference;
      const status = user?.creditApplication?.status;
      const userContext = {
        id: userId || '',
        name: userName || '',
        email: userEmail || '',
        creditApplicationId: creditApplicationId || '',
        status: status || '',
        securityType: securityType || '',
        ...(isSecurityTypeCash && { paymentPreference: paymentPreference || '' }),
      };

      datadogLogs.setUser(userContext);
      datadogRum.setUser(userContext);
    }
    return forward(operation);
  });
};
