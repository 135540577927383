import * as React from 'react';
import type { SVGProps } from 'react';

interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgGucci = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 60 10"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M57.887.344H60c-.234.193-.374.43-.374.756v7.764c0 .324.146.556.374.75h-2.113c.228-.194.368-.432.368-.75V1.099c0-.325-.134-.562-.368-.755ZM8.915 3.679c-.346-.793-.726-1.574-1.364-2.142-.679-.612-1.54-.98-2.43-.98-2.364 0-3.424 2.279-3.424 4.521 0 2.168 1.013 4.36 3.296 4.36.93 0 1.838-.43 2.411-1.212V6.477c0-.474-.146-.78-.492-1.061H9.33c-.258.312-.415.63-.415 1.061v1.75C7.808 9.325 6.433 10 4.905 10 2.207 10 0 7.883 0 4.934 0 2.005 2.177 0 4.84 0c1.657 0 2.997.65 4.075 1.955V3.68ZM14.838.344h2.277c-.31.243-.416.518-.416.924v5.666c0 1.554 1.709 2.317 2.962 2.317 1.23 0 2.833-.768 2.833-2.286V1.268c0-.381-.088-.643-.328-.924h1.241c-.252.275-.345.55-.345.924v5.697c0 2.048-2.265 2.835-3.834 2.835-1.615 0-3.974-.761-3.974-2.866V1.268c0-.387-.147-.675-.416-.924ZM37.64 3.71c-.304-.818-.66-1.536-1.287-2.13C35.68.943 34.86.543 33.954.543c-2.34 0-3.54 2.187-3.54 4.454 0 2.28 1.246 4.435 3.558 4.435 1.72 0 2.95-1.293 3.67-2.849V8.27a5.343 5.343 0 0 1-3.963 1.705c-2.798 0-4.893-1.93-4.893-5.009 0-2.992 2.013-4.96 4.759-4.96 1.521 0 2.996.563 4.096 1.681V3.71Zm14.68 0c-.304-.818-.662-1.536-1.288-2.13C50.361.943 49.541.543 48.634.543c-2.342 0-3.536 2.187-3.536 4.454 0 2.28 1.241 4.435 3.554 4.435 1.72 0 2.955-1.293 3.67-2.849V8.27a5.335 5.335 0 0 1-3.963 1.705c-2.798 0-4.893-1.93-4.893-5.009 0-2.992 2.013-4.96 4.758-4.96 1.522 0 2.997.563 4.097 1.681V3.71Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgGucci;
