import * as React from 'react';
import type { SVGProps } from 'react';

interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgPearIcon = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 16 23"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      stroke="#101820"
      strokeWidth={1.5}
      d="M6.882 1.913c.46-.921 1.775-.921 2.236 0l4.878 9.756a6.703 6.703 0 1 1-11.992 0l4.878-9.756Z"
    />
  </svg>
);
export default SvgPearIcon;
