import type { ForwardedRef } from 'react';
import { forwardRef } from 'react';
import { PatternFormat } from 'react-number-format';

import { InfoTooltip } from '../../../InfoTooltip/InfoTooltip';
import type { CardNumberProps } from '../FormTypes';
import { TextFieldWrapper } from '../TextFieldWrapper/TextFieldWrapper';
import { generateInputStyles } from '../inputStylesHelper';

export const CardDate = forwardRef((props: CardNumberProps, ref: ForwardedRef<HTMLInputElement>) => {
  const {
    className,
    value,
    onChange,
    label,
    rightHelperText,
    errorText,
    error,
    caption,
    required,
    readOnly,
    textAlign,
    inputSize,
    infoEnabled,
    ...otherProps
  } = props;

  const isErrorVisible = !!error;
  const inputStyle = generateInputStyles({
    isErrorVisible,
    readOnly,
    textAlign,
    inputSize,
  });

  return (
    <TextFieldWrapper
      className={className}
      errorText={errorText}
      error={!!error}
      label={label}
      caption={caption}
      required={required}
      rightHelperText={rightHelperText}
      info={
        infoEnabled && (
          <InfoTooltip
            toolTipText={
              'Please enter the expiration date of your credit card. It is usually found in the format MM/YY, where MM represents the two-digit month and YY represents the two-digit year. Make sure the date is valid and not expired.'
            }
          />
        )
      }
    >
      <PatternFormat
        getInputRef={ref}
        className={inputStyle}
        value={value}
        onChange={e => {
          onChange(e.target.value);
        }}
        allowEmptyFormatting
        format="##/##"
        disabled={readOnly}
        mask="_"
        {...otherProps}
      />
    </TextFieldWrapper>
  );
});
