import React from 'react';

import CheckedSVG from '../../icons/icon/RadioChecked.svg';
import UncheckedSVG from '../../icons/icon/RadioUnchecked.svg';

interface RadioBoxProps {
  checked: boolean;
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const RadioBox: React.FC<RadioBoxProps> = ({ checked, value, onChange }) => {
  const size = 'w-[40px] h-[40px]';

  return (
    <div className="cursor-pointer">
      <input type="radio" value={value} checked={checked} onChange={onChange} style={{ display: 'none' }} />
      {checked ? <img className={size} src={CheckedSVG} /> : <img className={size} src={UncheckedSVG} />}
    </div>
  );
};
