import { useUser } from '../hooks/useUser';

export const useHasPersonalInfoData = () => {
  const { user } = useUser();

  const hasPersonalInfoData = ['firstName', 'lastName', 'middleName', 'phone'].some(
    key => user?.[key as keyof typeof user] !== null && user?.[key as keyof typeof user] !== '',
  );

  return hasPersonalInfoData;
};
