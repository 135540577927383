import { Suspense, useEffect } from 'react';
import { useLocation, useRoutes, useSearchParams } from 'react-router-dom';

import PromotionalBanner from 'src/components/PromotionalBanner/PromotionalBanner';
import { useUser } from 'src/hooks/useUser';
import CashSecuredApplication from 'src/pages/CashSecuredApplication/CashSecuredApplication';
import { CashSecuredActiveScreenProvider } from 'src/pages/CashSecuredApplication/context/CashSecuredActiveScreenProvider';
import QuickAppraisalPage from 'src/pages/QuickAppraisalPage';
import Settings from 'src/pages/Settings/Settings';
import { SocureDocVerification } from 'src/pages/SocureDocVerification/SocureDocVerification';
import { EmailElle } from 'src/pages/in-landing/elle/email-elle/EmailElle';
import { GracieElle } from 'src/pages/in-landing/elle/gracie/GracieElle';
import LandingPages from 'src/pages/landing/finder/LandingPages';
import { getOldUtmParams } from 'src/util/URL/oldUtmParams';
import { getUtmParams } from 'src/util/URL/utmParamsHelper';
import { generateConditionalRoute } from 'src/util/generateConditionalRoute';

import { LoaderSpinnerFull } from '../components/LoaderSpinnerFull';
import { routes } from '../constants/routes';
import { PaymentFrame } from '../pages/Iframe/PaymentFrame';
import LoginCallback from '../pages/LoginCallback';
import { useAnalytics } from '../providers/analytics/AnalyticsProvider';
import { lazyWithRetries } from '../util/lazyRetry';

import { ProtectedRoute } from './ProtectedRoute';

const HomePage = lazyWithRetries(() => import('../pages/Homepage/HomePage'));
const ApplicationFlow = lazyWithRetries(() => import('../pages/ApplicationFlow/ApplicationFlow'));
const NotFound = lazyWithRetries(() => import('../pages/NotFound'));
const LoginWrapper = lazyWithRetries(() => import('../pages/Login/LoginWrapper'));
const CashSecuredFlow = lazyWithRetries(() => import('../pages/CashSecuredFlow/CashSecuredFlow'));
const SilverLandingPage = lazyWithRetries(() => import('../pages/SilverLandingPage/SilverLandingPage'));
const InLanding = lazyWithRetries(() => import('src/pages/in-landing/InLanding'));
const FaqPage = lazyWithRetries(() => import('src/pages/faq/FaqPage'));

export const RouterProvider = () => {
  const location = useLocation();
  const { analytics } = useAnalytics();
  const { user } = useUser();
  const isCardholder = !!user?.cards?.length;
  const isInIframe = window.self !== window.top;

  const [searchParams] = useSearchParams();
  const isActiveParamPresent = searchParams.has('active');

  useEffect(() => {
    analytics.page();
    getUtmParams();
    getOldUtmParams();
  }, [analytics, location]);

  const silverLandingPageRoute = generateConditionalRoute(
    isActiveParamPresent,
    '/new-silver-landing-page',
    SilverLandingPage,
  );

  const globalRoutes = useRoutes([
    { path: '*', element: <NotFound /> },
    { path: routes.home, element: <HomePage /> },
    {
      path: routes.cashSecured + '/*',
      element: (
        <ProtectedRoute>
          <CashSecuredFlow />
        </ProtectedRoute>
      ),
    },
    { path: routes.quickAppraisal + '/*', element: <QuickAppraisalPage /> },
    {
      path: routes.application + '/*',
      element: (
        <ProtectedRoute>
          <ApplicationFlow />
        </ProtectedRoute>
      ),
    },
    {
      path: routes.landingFinder + '/*',
      element: <LandingPages />,
    },
    { path: routes.loginCallback, element: <LoginCallback /> },
    { path: routes.login + '/*', element: <LoginWrapper /> },
    { path: '/frame/payment', element: <PaymentFrame /> },
    {
      path: '/cash-secured-application',
      element: (
        <CashSecuredActiveScreenProvider>
          <CashSecuredApplication />
        </CashSecuredActiveScreenProvider>
      ),
    },
    {
      path: routes.socureVerification,
      element: (
        <ProtectedRoute>
          <SocureDocVerification />
        </ProtectedRoute>
      ),
    },
    {
      path: routes.settings + '/*',
      element: (
        <ProtectedRoute>
          <Settings />
        </ProtectedRoute>
      ),
    },
    {
      path: '/in-landing/*',
      element: <InLanding />,
    },
    {
      path: routes.faq,
      element: <FaqPage />,
    },
    {
      path: '/asset-card',
      element: <GracieElle />,
    },
    {
      path: '/e/asset-card',
      element: <EmailElle />,
    },

    ...silverLandingPageRoute,
  ]);

  return (
    <>
      {!isCardholder && !isInIframe && <PromotionalBanner />}
      <Suspense fallback={<LoaderSpinnerFull />}>{globalRoutes}</Suspense>
    </>
  );
};
