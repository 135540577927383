import { Modal2 } from '../../../../../../components/UI/Modal/Modal2';

import StoneSizeImage from './assets/stonesize.svg';

interface StoneSizeModalProps {
  isModalOpen: boolean;
  closeModal: () => void;
}
export const StoneSizeModal = (props: StoneSizeModalProps) => {
  const { isModalOpen, closeModal } = props;
  return (
    <Modal2 theme="dark" isOpen={isModalOpen} onClose={closeModal}>
      <div className="flex flex-col items-center justify-center gap-4">
        <div className="flex w-full flex-col items-center justify-center sm:flex-row">
          <img src={StoneSizeImage} alt="stone size" />
        </div>
        <p className="mb-[16px] text-center text-base text-altWhite">
          Gemstone size in reference to <br /> thickness of coins
        </p>
      </div>
    </Modal2>
  );
};
