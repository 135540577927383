import type { CreditApplicationStatus } from '../__generated__/graphql/api';
import {
  applicationRoutes,
  cashSecuredRoutes,
  cashSecuredStatusRoutes,
  routes,
  userStatusRoutes,
} from '../constants/routes';

export const STEP_OF_THE_APPLICATION_FLOW_PAGE: Record<CreditApplicationStatus, string> = {
  PREQUAL_ITEM_VALUED: applicationRoutes.itemsCart,
  FIN_INFO_NEEDED: applicationRoutes.addressAndIncome,
  PREQUALIFIED: applicationRoutes.preQualified,
  APPRAISAL_IN_PROGRESS: applicationRoutes.preQualified,
  NOT_PREQUALIFIED: userStatusRoutes.manualReview,
  MANUAL_REVIEW: userStatusRoutes.manualReview,
  CREDIT_DENIED: userStatusRoutes.manualReview,
  CANCELED_HARD: userStatusRoutes.manualReview,
  CANCELED_SOFT: userStatusRoutes.manualReview,
  CANCELED_DUPE: userStatusRoutes.loginToYourAccount,
  PREQUAL_INFO_GIVEN: applicationRoutes.addressAndIncome,

  OFFER_DECISION_NEEDED: applicationRoutes.offer,
  OFFER_ACCEPTED: applicationRoutes.completion,
  OFFER_REJECTED: userStatusRoutes.offerRejected,
  COMPLETE: applicationRoutes.completion,
  CREDIT_FREEZE: userStatusRoutes.creditFreeze,
  INVALID_STATE: userStatusRoutes.manualReview,
  ITEM_UNDER_REVIEW: userStatusRoutes.inReview,
  CREATED: applicationRoutes.itemsCart,
  SHIPPED: applicationRoutes.itemsShipped,
  // return to shipped page for now
  // when we'll have an approved page copy for this status, we can change this

  VERIFY_DOC: routes.socureVerification,
  VERIFY_PAYMENT: cashSecuredRoutes.verifyPayment,
  DELIVERED: applicationRoutes.itemsShipped,
};

export const STEP_OF_THE_CASH_FLOW_PAGE: Record<CreditApplicationStatus, string> = {
  CREATED: cashSecuredRoutes.creditLine,
  APPRAISAL_IN_PROGRESS: cashSecuredRoutes.personalInfo,
  PREQUAL_ITEM_VALUED: cashSecuredRoutes.personalInfo,
  FIN_INFO_NEEDED: cashSecuredRoutes.personalInfo,
  PREQUALIFIED: cashSecuredRoutes.payment,
  OFFER_DECISION_NEEDED: cashSecuredRoutes.agreements,
  OFFER_ACCEPTED: cashSecuredRoutes.congratulations,
  COMPLETE: cashSecuredRoutes.congratulations,
  NOT_PREQUALIFIED: cashSecuredStatusRoutes.manualReview,
  MANUAL_REVIEW: cashSecuredStatusRoutes.manualReview,
  CREDIT_DENIED: cashSecuredStatusRoutes.manualReview,
  OFFER_REJECTED: cashSecuredStatusRoutes.offerRejected,
  ITEM_UNDER_REVIEW: cashSecuredStatusRoutes.inReview,
  INVALID_STATE: cashSecuredStatusRoutes.manualReview,
  CREDIT_FREEZE: cashSecuredStatusRoutes.creditFreeze,
  CANCELED_HARD: cashSecuredStatusRoutes.manualReview,
  CANCELED_SOFT: cashSecuredStatusRoutes.manualReview,
  CANCELED_DUPE: cashSecuredStatusRoutes.loginToYourAccount,
  PREQUAL_INFO_GIVEN: cashSecuredRoutes.payment,
  VERIFY_DOC: routes.socureVerification,
  VERIFY_PAYMENT: cashSecuredRoutes.verifyPayment,

  SHIPPED: cashSecuredRoutes.payment,
  DELIVERED: cashSecuredRoutes.payment,
};
export const getURLPathForUserApplicationFlowStatus = (status: CreditApplicationStatus) => {
  return STEP_OF_THE_APPLICATION_FLOW_PAGE[status];
};

export const getURLPathForUserCashFlowStatus = (status: CreditApplicationStatus) => {
  return STEP_OF_THE_CASH_FLOW_PAGE[status as CreditApplicationStatus];
};
