import type { FC } from 'react';
import type { FormState } from 'react-hook-form';

import { CreditBilling } from 'src/components/FormUser/CreditBilling/CreditBilling';
import { Typography } from 'src/components/Typography/Typography';
import { PhoneField } from 'src/components/UI/Form/PhoneField/PhoneField';
import { TextField } from 'src/components/UI/Form/TextField/TextField';

import type { CashSecuredApplicationForm } from '../types';

type ApplicationFormPersonalInfoProps = {
  formData: CashSecuredApplicationForm;
  setValue: (name: keyof CashSecuredApplicationForm, value: string | number) => void;
  errors: FormState<CashSecuredApplicationForm>['errors'];
};

export const ApplicationFormPersonalInfo: FC<ApplicationFormPersonalInfoProps> = ({ formData, setValue, errors }) => {
  return (
    <div className="flex flex-col gap-8">
      <div className={'flex flex-col gap-4'}>
        <div className="flex flex-row justify-around gap-4 text-left">
          <TextField
            data-testid="first-name-field"
            name="firstName"
            type="text"
            label="First name"
            placeholder="First"
            value={formData.firstName}
            onChange={event => setValue('firstName', event.target.value)}
            onBlur={event => setValue('firstName', event.target.value.trim())}
            error={!!errors.firstName}
            errorText={errors.firstName?.message}
            required
          />
          <TextField
            data-testid="middle-name-field"
            name="middleName"
            type="text"
            label="Middle name"
            placeholder="Middle"
            value={formData.middleName}
            onChange={event => setValue('middleName', event.target.value)}
            onBlur={event => setValue('middleName', event.target.value.trim())}
            error={!!errors.middleName}
            errorText={errors.middleName?.message}
          />
        </div>
        <div className="flex flex-col gap-4 text-left">
          <TextField
            data-testid="last-name-field"
            name="lastName"
            type="text"
            label="Last name"
            placeholder="Last"
            value={formData.lastName}
            onChange={event => setValue('lastName', event.target.value)}
            onBlur={event => setValue('lastName', event.target.value.trim())}
            error={!!errors.lastName}
            errorText={errors.lastName?.message}
            required
          />

          <TextField
            data-testid="email-field"
            name="email"
            type="email"
            label="Email"
            value={formData.email}
            placeholder="email@example.com"
            onChange={event => setValue('email', event.target.value)}
            error={!!errors.email}
            errorText={errors.email?.message}
            required
          />
        </div>

        <div className="flex flex-col gap-4 text-left">
          <PhoneField
            data-testid="phone-number-field"
            label="Phone number"
            value={formData.phoneNumber}
            onChange={value => setValue('phoneNumber', value)}
            error={!!errors.phoneNumber}
            errorText={errors.phoneNumber?.message}
            required
          />
        </div>
      </div>
      <div className="flex flex-col gap-4 text-left">
        <Typography variant="headerSmall" className="mt-4 text-left !font-bold">
          Credit billing information
        </Typography>
        <CreditBilling formValues={formData} setFormValues={setValue} formErrors={errors} />
        <TextField
          type="number"
          value={typeof formData.income === 'number' ? String(formData.income) : 0}
          onChange={event => setValue('income', Number(event.target.value))}
          label="Total annual income"
          error={!!errors.income?.message}
          required
        />
      </div>
    </div>
  );
};
