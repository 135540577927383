import { useEffect, useState } from 'react';

export const useLocalStorage = <T>(key: string, initialValue: T): [T, (value: T) => void, () => void] => {
  const [storedValue, setStoredValue] = useState<T>(() => {
    const item = localStorage.getItem(key);
    try {
      return item ? (JSON.parse(item) as T) : initialValue;
    } catch (error) {
      console.error(`Error parsing the localStorage item "${key}":`, error);
      return initialValue;
    }
  });

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(storedValue));
  }, [key, storedValue]);

  const setValue = (value: T) => {
    setStoredValue(value);
    localStorage.setItem(key, JSON.stringify(value));
  };

  const clearValue = () => {
    localStorage.removeItem(key);
  };

  return [storedValue, setValue, clearValue];
};
