export const ItemCartSkeleton = () => {
  return (
    <div
      className={
        'border-inactive flex h-auto w-full animate-pulse justify-between rounded-lg border bg-gray-200 p-4 text-left'
      }
    >
      <div className={'flex w-full gap-4'}>
        <div className="flex h-[86px] w-[64px] overflow-hidden rounded bg-gray-300 object-cover object-center"></div>
        <div className="flex w-full flex-col justify-between gap-2">
          <div className="h-[20px] max-w-[200px] bg-gray-300"></div>
          <div className="h-[10px] w-full bg-gray-300"></div>
          <div className="h-[10px] w-full bg-gray-300"></div>
          <div className="h-[10px] w-full bg-gray-300"></div>
        </div>
      </div>
    </div>
  );
};
