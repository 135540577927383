import axios from 'axios';
import * as process from 'process';

import { ReactAppEnvIsNotProd } from 'src/constants/global';
import {
  IGNORE_SEGMENT_AND_PESTO_TRACKING_DEV_MODE_LS_KEY,
  PESTO_ANONYMOUS_EVENT_SENT_LS_KEY,
  PESTO_IDENTIFY_EVENT_SENT_LS_KEY,
  SEGMENT_ANONYMOUS_ID_LS_KEY,
} from 'src/constants/localStorageKeys';
import { AnalyticsEvent } from 'src/providers/analytics/AnalyticsEvents';
import { getAllUrlParams, urlObject } from 'src/util/getAllUrlParams';

import type { AnalyticsHookProps, PestoAnalyticsHookReturn } from '../AnalyticsProvider';

const ANALYTICS_URL = process.env.REACT_APP_PESTO_ANALYTICS_URL || '';
export const pestoAnalytics = (props: AnalyticsHookProps): PestoAnalyticsHookReturn => {
  const urlParams = getAllUrlParams();

  const { currentUser, heapCookie, marketingTracking } = props;
  const isSegmentTrackingIgnoredForDevMode =
    localStorage.getItem(IGNORE_SEGMENT_AND_PESTO_TRACKING_DEV_MODE_LS_KEY) === 'true';

  const sendToPestoAnalytics = (eventName: string, eventProperties: any) => {
    axios
      .post(ANALYTICS_URL, {
        eventName,
        eventProperties,
      })
      .catch(error => {
        console.error('Error sending event to Pesto Analytics', error, eventName, eventProperties);
      });
  };

  const pestoIdent = () => {
    if (currentUser && currentUser?.id) {
      sendToPestoAnalytics('identify', {
        name: currentUser?.displayName ?? '',
        email: currentUser?.email ?? '',
      });
    }
  };

  const segmentAnonymousId = localStorage.getItem(SEGMENT_ANONYMOUS_ID_LS_KEY);
  const urlData = {
    url: urlObject(),
    title: document.title,
    referrer: document.referrer,
    searchParams: urlParams,
  };
  const globalPayload = {
    segmentAnonymousId: segmentAnonymousId,
    sendAt: new Date().toISOString(),
    timeStamp: new Date().getTime(),
  };
  const event = (eventName: string, eventProperties: any) => {
    if (!isSegmentTrackingIgnoredForDevMode && ReactAppEnvIsNotProd) return;

    const eventPropertiesWithMarketingParams = {
      ...globalPayload,
      properties: {
        eventProperties,
        urlParams: urlParams,
        ...marketingTracking,
        heapCookie: heapCookie,
        urlData: urlData,
      },
    };
    sendToPestoAnalytics(eventName, eventPropertiesWithMarketingParams);
  };

  const page = (eventProperties?: any) => {
    if (!isSegmentTrackingIgnoredForDevMode && ReactAppEnvIsNotProd) return;

    const eventPropertiesWithMarketingParams = {
      ...globalPayload,
      properties: {
        eventProperties,
        ...marketingTracking,
        urlParams: urlParams,
        heapCookie: heapCookie,
        urlData: urlData,
        segmentAnonymousId: segmentAnonymousId,
      },
    };
    pestoIdent();
    sendToPestoAnalytics(AnalyticsEvent.Page.PAGE_LOADED, eventPropertiesWithMarketingParams);
  };

  const identifyPage = (eventProperties?: any) => {
    if (!isSegmentTrackingIgnoredForDevMode && ReactAppEnvIsNotProd) return;
    const anonymousEventSent = localStorage.getItem(PESTO_ANONYMOUS_EVENT_SENT_LS_KEY) !== null;
    const identifyEventSent = localStorage.getItem(PESTO_IDENTIFY_EVENT_SENT_LS_KEY) !== null;
    const canSendIdentifyEvent = !identifyEventSent && currentUser && currentUser?.id;
    const canSendAnonymousEvent = !anonymousEventSent && !currentUser;
    const timestampUtc = new Date().toISOString();

    const userInfo = {
      name: currentUser?.displayName ?? '',
      email: currentUser?.email ?? '',
      userId: currentUser?.id ?? '',
    };

    const eventPropertiesWithMarketingParams = {
      ...globalPayload,
      properties: {
        eventProperties,
        ...marketingTracking,
        urlParams: urlParams,
        heapCookie: heapCookie,
        urlData: urlData,
        segmentAnonymousId: segmentAnonymousId,
        ...(canSendIdentifyEvent && { ...userInfo }),
      },
    };

    if (canSendIdentifyEvent) {
      localStorage.setItem(PESTO_IDENTIFY_EVENT_SENT_LS_KEY, timestampUtc);
      return sendToPestoAnalytics(AnalyticsEvent.Page.PAGE_IDENTIFIED, eventPropertiesWithMarketingParams);
    }

    if (canSendAnonymousEvent) {
      localStorage.setItem(PESTO_ANONYMOUS_EVENT_SENT_LS_KEY, timestampUtc);
      return sendToPestoAnalytics(AnalyticsEvent.Page.PAGE_ANONYMOUS, eventPropertiesWithMarketingParams);
    }
  };

  return { page, event, identifyPage };
};
