import { cn } from 'src/util/cn';

import { IconCross } from '../../../icons/iconsComponents/IconCross';
import { IconEdit } from '../../../icons/iconsComponents/IconEdit';
import LoadingSpinner from '../../LoadingSpinner/LoadingSpinner';
import { Typography } from '../../Typography/Typography';

interface EditableInfoProps {
  className?: string;
  title: string;
  editable?: boolean;
  children?: React.ReactNode;
  editState?: boolean;
  onEdit?: (edit: boolean) => void;
  onSave?: () => void;
  onClose?: () => void;
  isSaving?: boolean;
}
export const EditableInfo = (props: EditableInfoProps) => {
  const { className, title, editable = false, children, onEdit, editState, onSave, onClose, isSaving = false } = props;
  const wrapperStyle = cn(className, 'flex flex-col gap-4 w-full border border-neutralSoft rounded-[8px] p-4');

  const toggleEdit = (edit: boolean) => {
    if (onEdit) onEdit(edit);
  };

  const buttonStyle = 'flex justify-center w-full px-2 py-2 hover:bg-gray-100 rounded-md';

  const saveStyle = cn(buttonStyle, 'text-action');

  return (
    <div className={wrapperStyle}>
      <div className="flex items-center justify-between gap-4">
        <Typography variant="headerSmall">{title}</Typography>
        {editable && (
          <div className="flex flex-row justify-center gap-2">
            {editState ? (
              <>
                <button
                  data-test-id="save-address"
                  type="submit"
                  className={saveStyle}
                  onClick={() => {
                    if (onSave) onSave();
                  }}
                  disabled={isSaving}
                >
                  {isSaving ? <LoadingSpinner /> : 'SAVE'}
                </button>
                <button
                  className={buttonStyle}
                  onClick={() => {
                    onClose && onClose();
                    toggleEdit(!editState);
                  }}
                >
                  <IconCross fill="black" />
                </button>
              </>
            ) : (
              <button
                data-test-id="edit-address"
                className={buttonStyle}
                onClick={() => {
                  toggleEdit(!editState);
                }}
              >
                <IconEdit stroke="black" />
              </button>
            )}
          </div>
        )}
      </div>
      {children}
    </div>
  );
};
