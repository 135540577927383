import React from 'react';
import { useNavigate } from 'react-router-dom';

import { cn } from 'src/util/cn';

interface NavItemProps {
  itemName: string;
  itemRoute: string;
  Icon: React.ComponentType;
}

const NavItem: React.FC<NavItemProps> = ({ itemName, itemRoute, Icon }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/settings' + itemRoute);
  };

  const isActive = location.pathname === '/settings' + itemRoute;

  return (
    <div
      onClick={handleClick}
      className={cn(
        'mt-2 flex w-full cursor-pointer items-center rounded border-[1px] px-4 py-2 text-[15px] font-bold text-basicSoft hover:bg-neutralInactive hover:text-basic',
        isActive ? 'bg-neutralInactive' : ' border-neutralInactive',
      )}
    >
      <span className="mr-3 inline-block">
        <Icon />
      </span>
      {itemName}
    </div>
  );
};

export default NavItem;
