import { ADMIN_DEBUGGER_LS_KEY } from 'src/constants/localStorageKeys';
import { parseURL } from 'src/util/URL/utmParamsHelper';

export const adminDebuggerUrlHelper = () => {
  const urlParams = parseURL(window.location.href).searchParams;

  // admin debugger with a secret key
  const adminDebugger = urlParams.get('ISolemnlySwearThatIAmUpToNoGood');

  if (adminDebugger) {
    localStorage.setItem(ADMIN_DEBUGGER_LS_KEY, adminDebugger);
    window.history.replaceState({}, document.title, window.location.pathname);
    window.location.reload();
  }
};
