import { useCallback } from 'react';

import type { FieldValues } from 'react-hook-form/dist/types/fields';
import type { UseFormSetFocus } from 'react-hook-form/dist/types/form';
import ZipCode from 'src/components/Zipcode/ZipCode';
import { cn } from 'src/util/cn';
import { ERROR_MESSAGES } from 'src/util/sharedFieldValidations';

import type { Address } from '../../../__generated__/graphql/api';
import { states } from '../../../data/states';
import type { ReMappedGoogleAddress } from '../../../pages/ApplicationFlow/steps/AddressAndIncome/utils/addressHelper';
import { InfoTooltip } from '../../InfoTooltip/InfoTooltip';
import { AutoCompleteAddress } from '../../UI/Form/AutoCompleteAddress/AutoCompleteAddress';
import { Dropdown } from '../../UI/Form/Dropdown/Dropdown';
import { TextField } from '../../UI/Form/TextField/TextField';

interface AddressInnerFormProps {
  className?: string;
  formValues: Address;
  setFormValues: (field: any, value: any) => void;
  editable?: boolean;
  onSubmit?: () => void;
  formErrors: any;
  setFocus?: UseFormSetFocus<FieldValues>;
}

export const AddressInnerForm = (props: AddressInnerFormProps) => {
  const { className, formValues, setFormValues, formErrors, editable, setFocus = false, onSubmit } = props;

  const rootStyle = cn(
    className,
    'text-left grid grid-cols-[repeat(auto-fill,_minmax(_max(_120px,_100%/3),_1fr))] sm:grid-cols-[repeat(auto-fill,_minmax(_200px,_1fr))] gap-x-2 gap-y-3 sm:gap-y-4 sm:gap-x-8',
  );

  const reAssignAddressValues = useCallback(
    (address: ReMappedGoogleAddress) => {
      const formattedStreet = address.street?.replace('undefined', '') || '';
      setFormValues('addressFirstLine', formattedStreet);
      setFormValues('city', address.city || '');
      setFormValues('state', address.state || '');
      setFormValues('zip', address.zip || '');
      setFormValues('suiteNumber', address.suite || '');
    },
    [setFormValues],
  );

  return (
    <form
      className={rootStyle}
      onSubmit={e => {
        e.preventDefault();

        if (onSubmit) {
          onSubmit();
        }
      }}
    >
      <AutoCompleteAddress
        label="Address"
        value={formValues.addressFirstLine || ''}
        data-testid="address-field"
        onChange={event => setFormValues('addressFirstLine', event.target.value)}
        error={formErrors.addressFirstLine}
        onPlaceSelected={address => {
          reAssignAddressValues(address);
          if (address.suite) {
            return null;
          }
          if (setFocus) return setFocus('suite');
        }}
        errorText={formErrors.addressFirstLine?.message || ERROR_MESSAGES.addressFirstLine}
        required={editable}
        disabled={!editable}
        readOnly={!editable}
      />
      <TextField
        onChange={event => setFormValues('suiteNumber', event.target.value)}
        label="Address line 2"
        placeholder={'Unit/Apt/Suite etc. & no.'}
        value={formValues.suiteNumber || ''}
        disabled={!editable}
        readOnly={!editable}
        info={
          <InfoTooltip toolTipText="Address Line 2 is for the apartment, suite, unit number, or other address designation that is not part of the physical address description on Address Line 1. Please include the designation and the number" />
        }
      />
      <TextField
        onChange={event => setFormValues('city', event.target.value)}
        label="City"
        value={formValues.city || ''}
        error={formErrors.city}
        errorText={ERROR_MESSAGES.city}
        required={editable}
        disabled={!editable}
        data-testid="city-field"
        readOnly={!editable}
      />
      <Dropdown
        label="State"
        placeholder={'Select state'}
        options={states}
        onChange={event => {
          setFormValues('state', event.value);
        }}
        selectedValue={formValues.state}
        error={formErrors.state}
        errorText={formErrors?.state?.message || ERROR_MESSAGES.state}
        required={editable}
        disabled={!editable}
        readOnly={!editable}
        data-testid="state-field"
        isSearchable={true}
      />
      <ZipCode
        type="numeric"
        label="ZIP code"
        data-testid="zip-field"
        onChange={event => {
          setFormValues('zip', event.target.value);
        }}
        value={formValues.zip || ''}
        error={formErrors.zip}
        errorText={formErrors.zip?.message}
        required={editable}
        disabled={!editable}
        readOnly={!editable}
      />
    </form>
  );
};
