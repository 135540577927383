import clsx from 'clsx';
import React, { type ReactNode, useState } from 'react';

import { Typography } from 'src/components/Typography/Typography';
import { cn } from 'src/util/cn';

import ArrowIcon from '../assets/Arrow.svg';

export type AccordionItem = {
  title: string;
  content: string | ReactNode;
};

interface AccordionProps {
  items: AccordionItem[];
}

export const Accordion: React.FC<AccordionProps> = ({ items }) => {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  return (
    <div>
      {items.map((item, index) => (
        <div key={index} className="mb-2 flex flex-col overflow-hidden rounded-lg border-[1px] border-[#E8E8E8]">
          <button
            className={clsx(
              'flex w-full cursor-pointer flex-row items-center justify-between p-4 text-left transition duration-300 ease-in-out hover:bg-[#F9F9F9]',
              {
                'bg-[#F9F9F9]': activeIndex === index,
                '': activeIndex !== index,
              },
            )}
            onClick={() => setActiveIndex(activeIndex === index ? null : index)}
          >
            <Typography variant="body" className="!font-bold">
              {item.title}
            </Typography>

            <img
              src={ArrowIcon}
              alt="arrow icon"
              className={cn({
                'rotate-180 transform transition-transform duration-300 ease-in-out': activeIndex === index,
              })}
            />
          </button>
          <div
            className={clsx('transition-max-height overflow-hidden duration-300 ease-in-out', {
              'max-h-0': activeIndex !== index,
              'max-h-screen bg-[#F9F9F9]': activeIndex === index,
            })}
          >
            <Typography variant="body" className="flex flex-col gap-4 px-4 pb-4">
              {item.content}
            </Typography>
          </div>
        </div>
      ))}
    </div>
  );
};
