import { useState } from 'react';

import type {
  AssetType,
  Cut,
  MetalType,
  RingInput,
  SecondaryStoneInput,
} from '../../../../../../../__generated__/graphql/api';
import {
  GetItemCartDocument,
  RingType,
  StoneType,
  useAddRingCurrentUserMutation,
} from '../../../../../../../__generated__/graphql/api';
import { apolloClient } from '../../../../../../../api/Apollo';
import { CARAT_MULTIPLIER } from '../../../../../../../constants/application';
import { QUICK_APPRAISAL_LS_KEY } from '../../../../../../../constants/localStorageKeys';
import { useGoRoutes } from '../../../../../../../hooks/useGoRoutes';
import { useIsMobile } from '../../../../../../../hooks/useIsMobile';
import { useLocalStorage } from '../../../../../../../hooks/useLocalStorage';
import { useUser } from '../../../../../../../hooks/useUser';

interface RingPayload {
  ringInput: RingInput;
  photoLinks?: (string | undefined)[];
}

export const useRingFLow = (setAssetType: (assetType: AssetType | undefined) => void) => {
  const { user } = useUser();
  const [, setLocalStorage] = useLocalStorage<RingPayload | null>(QUICK_APPRAISAL_LS_KEY, {} as RingPayload);
  const { goToSignUp, gotoItemCart } = useGoRoutes();

  const [addRingForCurrentUser, { loading: isLoading }] = useAddRingCurrentUserMutation({
    refetchQueries: [{ query: GetItemCartDocument }],
    errorPolicy: 'all',
    client: apolloClient,
  });

  const [ringPayload, setRingPayload] = useState<RingPayload>({
    ringInput: {
      metalType: null,
      ringType: null,
    },
  });

  const [innerScreen, setInnerScreen] = useState(0);
  const isMobile = useIsMobile();

  const isRingStyleSolitaire = ringPayload.ringInput.ringType === RingType.Solitaire;
  const isRingStyleWithSecondaryStones = ringPayload.ringInput.ringType
    ? [RingType.EternityBand, RingType.WeddingBand, RingType.AwardOrSignet].includes(ringPayload.ringInput.ringType)
    : false;

  const isCenterStone =
    ringPayload.ringInput.ringType === RingType.Solitaire ||
    ringPayload.ringInput.ringType === RingType.BridalSet ||
    ringPayload.ringInput.ringType === RingType.Halo ||
    ringPayload.ringInput.ringType === RingType.ThreeStone;

  const isDiamond =
    ringPayload.ringInput.centerStoneInput?.stoneType === StoneType.DiamondUnknown ||
    ringPayload.ringInput.centerStoneInput?.stoneType === StoneType.DiamondNatural ||
    ringPayload.ringInput.centerStoneInput?.stoneType === StoneType.DiamondLabGrown;
  const clearStatePayload = () => {
    if (innerScreen === 4 || innerScreen === 5) {
      setRingPayload({
        ...ringPayload,
        ringInput: {
          ...ringPayload.ringInput,
          secondaryStoneInputs: null,
        },
      });
    }
    if (innerScreen === 3 || innerScreen === 2) {
      setRingPayload({
        ...ringPayload,
        ringInput: {
          ringType: ringPayload.ringInput.ringType,
          metalType: ringPayload.ringInput.metalType,
        },
      });
    }
    if (innerScreen === 1) {
      setRingPayload({
        ...ringPayload,
        ringInput: {
          ...ringPayload.ringInput,
          metalType: null,
        },
      });
    }
  };

  const handleBack = () => {
    if (innerScreen === 0) {
      return setAssetType(undefined);
    }
    clearStatePayload();
    if (innerScreen === 4 && isRingStyleWithSecondaryStones) return setInnerScreen(innerScreen - 3);
    if (innerScreen === 5 && isRingStyleSolitaire) return setInnerScreen(innerScreen - 2);
    return setInnerScreen(innerScreen - 1);
  };

  const handleRingStyle = (style: RingType) => {
    setRingPayload({
      ...ringPayload,
      ringInput: {
        ...ringPayload.ringInput,
        ringType: style,
      },
    });
    setInnerScreen(innerScreen + 1);
  };

  const handleRingMetal = (metal: MetalType) => {
    setRingPayload({
      ...ringPayload,
      ringInput: {
        ...ringPayload.ringInput,
        metalType: metal,
      },
    });
    if (isRingStyleWithSecondaryStones) return setInnerScreen(innerScreen + 3);
    return setInnerScreen(innerScreen + 1);
  };
  const handleRingCenterStone = (stone: StoneType) => {
    setRingPayload({
      ...ringPayload,
      ringInput: {
        ...ringPayload.ringInput,
        centerStoneInput: {
          ...ringPayload.ringInput.centerStoneInput,
          stoneType: stone,
        },
      },
    });
    setInnerScreen(innerScreen + 1);
  };

  const handleRingStoneDetails = (cut: Cut | undefined, carat: number, count: number) => {
    setRingPayload({
      ...ringPayload,
      ringInput: {
        ...ringPayload.ringInput,
        centerStoneInput: {
          ...ringPayload.ringInput.centerStoneInput,
          ...(cut && { cut: cut }),
          carat: Math.round((carat || 0) * CARAT_MULTIPLIER),
          count: count,
        },
      },
    });
    if (isRingStyleSolitaire) return setInnerScreen(innerScreen + 2);
    return setInnerScreen(innerScreen + 1);
  };

  const handleRingSecondaryStones = (secondaryStonesArray: SecondaryStoneInput[] | undefined) => {
    setRingPayload({
      ...ringPayload,
      ringInput: {
        ...ringPayload.ringInput,
        secondaryStoneInputs: secondaryStonesArray,
      },
    });

    setInnerScreen(innerScreen + 1);
  };

  const handleSubmitRingFlow = (photos: (string | undefined)[]) => {
    const mainPayload: RingPayload = {
      ringInput: {
        ...ringPayload.ringInput,
      },
      photoLinks: photos,
    };

    if (!user) {
      setLocalStorage(mainPayload);
      goToSignUp();
    } else {
      addRingForCurrentUser({
        variables: {
          creditApplicationId: user.creditApplication?.id || '',
          ringInput: mainPayload.ringInput,
          photoLinks: mainPayload.photoLinks as string[],
        },
      })
        .then(res => {
          if (res.errors) {
            console.error(res.errors);
          } else {
            gotoItemCart();
          }
        })
        .catch(error => {
          console.error(error);
        });
    }
  };
  return {
    handleBack,
    handleRingStyle,
    handleRingMetal,
    handleRingCenterStone,
    handleRingStoneDetails,
    handleRingSecondaryStones,
    handleSubmitRingFlow,
    innerScreen,
    isDiamond,
    isCenterStone,
    isMobile,
    isLoading,
  };
};
