import {
  applicationRoutes,
  cashSecuredRoutes,
  cashSecuredStatusRoutes,
  offerRoutes,
  routes,
  userStatusRoutes,
} from '../../../constants/routes';

export const topLevelRoutes = Object.values(routes).map(route => {
  if (route === 'root') return null;
  return {
    text: route,
    value: route,
  };
});

export const applicationLevelRoutes = Object.values(applicationRoutes)
  .filter(route => route !== applicationRoutes.root)
  .map(route => ({
    text: route,
    value: route,
  }));

export const cashSecuredLevelRoutes = Object.values(cashSecuredRoutes)
  .filter(route => route !== cashSecuredRoutes.root)
  .map(route => ({
    text: route,
    value: route,
  }));

export const statusLevelRoutes = Object.values(userStatusRoutes).map(route => ({
  text: route,
  value: route,
}));

export const cashSecuredStatus = Object.values(cashSecuredStatusRoutes)
  .filter(route => route !== cashSecuredStatusRoutes.root)
  .map(route => ({
    text: route,
    value: route,
  }));

export const offerLevelRoutes = Object.values(offerRoutes)
  .filter(route => route !== offerRoutes.root)
  .map(route => ({
    text: route,
    value: route,
  }));

export const appNavigationOptions = [
  ...topLevelRoutes,
  ...applicationLevelRoutes,
  ...offerLevelRoutes,
  ...statusLevelRoutes,
  ...cashSecuredLevelRoutes,
  ...cashSecuredStatus,
];
