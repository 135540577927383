import React from 'react';

import { Typography } from 'src/components/Typography/Typography';
import { useIsMobile } from 'src/hooks/useIsMobile';

interface StepProps {
  stepNumber: number;
  title: string;
  description: string | JSX.Element;
}

const Step: React.FC<StepProps> = ({ stepNumber, title, description }) => (
  <li>
    <div className="mb-2">
      <Typography
        variant="bodyLarge"
        className="texte-center mx-auto w-[100px] rounded-[80px] bg-darkShade px-2 text-center text-white"
      >
        Step {stepNumber}
      </Typography>
    </div>
    <div className="mb-4">
      <Typography variant="headerMedium" className="text-center">
        {title}
      </Typography>
    </div>
    <Typography variant="bodyLarge" className="text-center">
      {description}
    </Typography>
  </li>
);

interface StepsToGetCardProps {
  steps: Array<{
    title: string;
    description: string | JSX.Element;
  }>;
}

export const StepsToGetCard: React.FC<StepsToGetCardProps> = ({ steps }) => {
  const isMobile = useIsMobile();

  return (
    <div className="rounded-xl border-[1px] border-neutralStrong p-4">
      <Typography variant={isMobile ? 'subHeroSmall' : 'subHeroLarge'} className="text-center">
        How to get the Pesto Mastercard®
      </Typography>
      <Typography variant={isMobile ? 'headerSmall' : 'bodyLarge'} className="mb-8 text-center">
        Three simple steps to get your card.
      </Typography>
      <ul
        role="list"
        className="mx-auto mt-10 grid max-w-2xl grid-cols-1 gap-6 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-3 lg:gap-8"
      >
        {steps.map((step, index) => (
          <Step key={index} stepNumber={index + 1} title={step.title} description={step.description} />
        ))}
      </ul>
    </div>
  );
};
