import { datadogLogs } from '@datadog/browser-logs';
import type { ReactNode } from 'react';
import React, { createContext, useCallback, useContext, useMemo, useState } from 'react';

type SessionExpiredContextType = {
  isModalOpen: boolean;
  handleIsOpenModal: (value: boolean) => void;
  skipError: boolean;
  setSkipError: (value: boolean) => void;
  setErrorStatus: (value: 'Unauthorized' | 'None') => void;
  errorStatus: string;
};
type SessionExpiredProviderProps = {
  children: ReactNode;
};

const SessionExpiredContext = createContext<SessionExpiredContextType>({
  isModalOpen: false,
  skipError: false,
  setSkipError: () => {},
  handleIsOpenModal: () => {},
  setErrorStatus: () => {},
  errorStatus: 'None',
});

export const useSessionExpiredContext = () => useContext(SessionExpiredContext);

export const SessionExpiredProvider: React.FC<SessionExpiredProviderProps> = ({ children }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [skipError, setSkipError] = useState(false);
  const [errorStatus, setErrorStatus] = useState('None');

  const handleIsOpenModal = useCallback((value: boolean) => {
    datadogLogs.logger.log('Session Expired Modal shown');
    setIsModalOpen(value);
  }, []);

  const sessionValues: SessionExpiredContextType = useMemo(
    () => ({
      isModalOpen,
      skipError,
      setSkipError,
      handleIsOpenModal,
      setErrorStatus,
      errorStatus,
    }),

    [isModalOpen, skipError, handleIsOpenModal, errorStatus],
  );

  return <SessionExpiredContext.Provider value={sessionValues}>{children}</SessionExpiredContext.Provider>;
};
