import * as React from 'react';
import type { SVGProps } from 'react';

interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgMoissanite = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <circle cx={12.25} cy={12.5} r={9.5} fill="#C3C5C7" opacity={0.5} />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M12.25 3 4 7.5v9.75l8.25 4.5 8.25-4.5V7.5L12.25 3Z"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="m12.25 7.5-4.5 2.25V15l4.5 2.25 4.5-2.25V9.75l-4.5-2.25ZM12.25 7.5V3M12.25 17.25v4.5M16.75 15l3.75 2.25M16.75 9.75 20.5 7.5M7.75 9.75 4 7.5M7.75 15 4 17.25"
    />
  </svg>
);
export default SvgMoissanite;
