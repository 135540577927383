import { useCallback, useState } from 'react';

import { AutoCompleteAddress } from 'src/components/UI/Form/AutoCompleteAddress/AutoCompleteAddress';
import { Dropdown } from 'src/components/UI/Form/Dropdown/Dropdown';
import { TextField } from 'src/components/UI/Form/TextField/TextField';
import ZipCode from 'src/components/Zipcode/ZipCode';
import { states } from 'src/data/states';
import type { ReMappedGoogleAddress } from 'src/pages/ApplicationFlow/steps/AddressAndIncome/utils/addressHelper';
import { cn } from 'src/util/cn';
import { ERROR_MESSAGES } from 'src/util/sharedFieldValidations';

import { InfoTooltip } from '../../InfoTooltip/InfoTooltip';

import type { CreditBillingProps } from './CreditBillingTypes';

export const CreditBilling = (props: CreditBillingProps) => {
  const [hasUndefinedAddress, setHasUndefinedAddress] = useState<boolean>(false);
  const { className, formValues, setFormValues, formErrors, setFocus = false } = props;

  const rootStyle = cn(
    className,
    'text-left grid grid-cols-[repeat(auto-fill,_minmax(_max(_120px,_100%/3),_1fr))] sm:grid-cols-[repeat(auto-fill,_minmax(_200px,_1fr))] gap-x-2 gap-y-3 sm:gap-y-4 sm:gap-x-8',
  );

  const reAssignAddressValues = useCallback(
    (address: ReMappedGoogleAddress) => {
      setHasUndefinedAddress(!!address.street?.includes('undefined'));
      const formattedStreet = address.street?.replace('undefined', '') || '';
      setFormValues('address', formattedStreet);
      setFormValues('city', address.city);
      setFormValues('state', address.state);
      setFormValues('zip', address.zip);
      setFormValues('suite', address.suite);
    },
    [setFormValues, setHasUndefinedAddress],
  );

  return (
    <div className={rootStyle}>
      <AutoCompleteAddress
        label="Address"
        value={formValues.address || ''}
        onChange={event => setFormValues('address', event.target.value)}
        error={formErrors.address || hasUndefinedAddress}
        onPlaceSelected={address => {
          reAssignAddressValues(address);
          if (address.suite) {
            return null;
          }
          if (setFocus) return setFocus('suite');
        }}
        required
        errorText={
          formErrors?.address?.message ??
          (hasUndefinedAddress ? 'Address seems incomplete, is it missing a number?' : '')
        }
      />
      <TextField
        onChange={event => setFormValues('suite', event.target.value)}
        label="Address line 2"
        placeholder={'Unit/Apt/Suite etc. & no.'}
        value={formValues.suite || ''}
        info={
          <InfoTooltip toolTipText="Address Line 2 is for the apartment, suite, unit number, or other address designation that is not part of the physical address description on Address Line 1. Please include the designation and the number" />
        }
      />
      <TextField
        onChange={event => setFormValues('city', event.target.value)}
        label="City"
        value={formValues.city || ''}
        error={formErrors.city}
        errorText={ERROR_MESSAGES.city}
        required
      />
      <Dropdown
        label="State"
        placeholder={'Select state'}
        options={states}
        onChange={event => {
          setFormValues('state', event.value);
        }}
        selectedValue={formValues.state}
        error={formErrors.state}
        errorText={formErrors?.state?.message || ERROR_MESSAGES.state}
        isSearchable
        required
        nullMenuTarget
      />
      <ZipCode
        type="numeric"
        label="ZIP code"
        onChange={event => {
          setFormValues('zip', event.target.value);
        }}
        value={formValues.zip || ''}
        error={formErrors.zip}
        errorText={formErrors.zip?.message}
        required
      />
    </div>
  );
};
