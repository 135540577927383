import type { FC, ReactNode } from 'react';
import { Link } from 'react-router-dom';

import { cn } from 'src/util/cn';

export enum LinkType {
  Anchor = 'a',
  RouterLink = 'Link',
  Button = 'button',
}

type TextLinkProps = {
  as: LinkType;
  className?: string;
  children: ReactNode;
  onClick?: (arg: any) => void;
} & (
  | {
      as: LinkType.Anchor;
      href: string;
      target?: HTMLAnchorElement['target'];
    }
  | {
      as: LinkType.RouterLink;
      to: string;
    }
  | {
      as: LinkType.Button;
      href?: never;
      target?: HTMLAnchorElement['target'];
    }
);
const TextLink: FC<TextLinkProps> = props => {
  const { as, children, className, onClick } = props;
  const commonClasses = cn('text-action underline hover:no-underline', className);

  if (as === LinkType.Anchor && 'href' in props) {
    return (
      <a className={commonClasses} target={props.target} href={props.href} onClick={onClick}>
        {children}
      </a>
    );
  }

  if (as === LinkType.RouterLink && 'to' in props) {
    return (
      <Link className={commonClasses} to={props.to} onClick={onClick}>
        {children}
      </Link>
    );
  }

  if (as === LinkType.Button) {
    return (
      <button className={commonClasses} onClick={onClick}>
        {children}
      </button>
    );
  }

  return null;
};

export default TextLink;
