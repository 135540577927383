import clsx from 'clsx';

import { ButtonContainer } from 'src/components/ButtonContainer/ButtonContainer';
import { Divider } from 'src/components/Devider/Devider';
import { BackButton } from 'src/components/UI/Button/BackButton';

import { NecklaceMetalType } from '../../../../../../__generated__/graphql/api';
import { ListSelection } from '../../../../../../components/ListSelection/ListSelection';
import { Typography } from '../../../../../../components/Typography/Typography';
import { OtherStar } from '../../../../../../icons/components';
import SvgGoldPlatedIcon from '../../../../../../icons/components/metal/GoldPlatedIcon';
import SvgGoldSolidIcon from '../../../../../../icons/components/metal/GoldSolidIcon';
import SvgPlatinumPlatedIcon from '../../../../../../icons/components/metal/PlatinumPlatedIcon';
import SvgPlatinumSolidIcon from '../../../../../../icons/components/metal/PlatinumSolidIcon';
import SvgSilverPlatedIcon from '../../../../../../icons/components/metal/SilverPlatedIcon';
import SvgSilverSolidIcon from '../../../../../../icons/components/metal/SilverSolidIcon';

interface MetalTypeScreenProps {
  className?: string;
  onNext: (data: NecklaceMetalType) => void;
  handleBack: () => void;
}

export const solidTypes = [
  {
    value: NecklaceMetalType.SolidGold,
    text: 'Solid Gold',
    icon: <SvgGoldSolidIcon />,
  },
  {
    value: NecklaceMetalType.SolidSilver,
    text: 'Solid Silver',
    icon: <SvgSilverSolidIcon />,
  },
  {
    value: NecklaceMetalType.SolidPlatinum,
    text: 'Solid Platinum',
    icon: <SvgPlatinumSolidIcon />,
  },
];

export const platedTypes = [
  {
    value: NecklaceMetalType.PlatedGold,
    text: 'Plated Gold',
    icon: <SvgGoldPlatedIcon />,
  },
  {
    value: NecklaceMetalType.PlatedSilver,
    text: 'Plated Silver',
    icon: <SvgSilverPlatedIcon />,
  },
  {
    value: NecklaceMetalType.PlatedPlatinum,
    text: 'Plated Platinum',
    icon: <SvgPlatinumPlatedIcon />,
  },
];

export const otherType = [
  {
    value: NecklaceMetalType.Other,
    text: 'Other',
    icon: <OtherStar />,
  },
];

const MetalType = (props: MetalTypeScreenProps) => {
  const { className, onNext, handleBack } = props;
  const rootStyles = clsx('flex flex-col gap-4 max-w-[400px] w-full', className);

  return (
    <div className={rootStyles}>
      <Typography variant={'headerMedium'}>What's the metal type?</Typography>
      <Divider gap={2} className="w-[70%] self-center">
        <Typography variant={'bodySmall'} className={'whitespace-nowrap text-neutral'}>
          Solid Metal
        </Typography>
      </Divider>
      <div className="flex w-full flex-col justify-center gap-2 text-center">
        {solidTypes.map((type, index) => (
          <ListSelection key={index} text={type.text} leftIcon={type.icon} onClick={() => onNext(type.value)} />
        ))}
      </div>

      <Divider gap={2} className="w-[70%] self-center">
        <Typography variant={'bodySmall'} className={'whitespace-nowrap text-neutral'}>
          Plated Metal
        </Typography>
      </Divider>

      <div className="flex w-full flex-col justify-center gap-2 text-center">
        {platedTypes.map((type, index) => (
          <ListSelection key={index} text={type.text} leftIcon={type.icon} onClick={() => onNext(type.value)} />
        ))}
      </div>

      <div className="h-[1px] w-full bg-neutralSoft" />

      <div className="flex w-full flex-col justify-center gap-2 text-center">
        {otherType.map((type, index) => (
          <ListSelection key={index} text={type.text} leftIcon={type.icon} onClick={() => onNext(type.value)} />
        ))}
      </div>

      <ButtonContainer leftButton={<BackButton onClick={handleBack} rightButtonExist={false} />} />
    </div>
  );
};

export default MetalType;
