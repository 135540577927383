import { forwardRef } from 'react';
import Select from 'react-select';

import type { OptionsOrGroups } from 'react-select/dist/declarations/src/types';
import { cn } from 'src/util/cn';

import type { SelectProps } from '../FormTypes';
import { TextFieldWrapper } from '../TextFieldWrapper/TextFieldWrapper';
import { generateInputStyles } from '../inputStylesHelper';

export type DropdownReturnType = {
  value: string;
  text: string;
};

export const Dropdown = forwardRef((props: SelectProps, ref) => {
  const {
    className,
    options,
    onChange,
    selectedValue,
    label,
    required,
    caption,
    error,
    adminDebugger = false,
    placeholder = 'Select',
    errorText,
    isSearchable = false,
    readOnly = false,
    disabled = false,
    nullMenuTarget = false,
    inputSize,
    textAlign,
    ...otherProps
  } = props;
  const reMappedOptions: OptionsOrGroups<unknown, any> = options?.map((option: any) => {
    return { value: option.value, label: option.text };
  });

  const isErrorVisible = !!error;
  const inputStyle = generateInputStyles({
    className,
    isErrorVisible,
    readOnly,
    inputSize,
    textAlign,
  });

  const combineStyle = cn('!p-[2px] !rounded-b-none', inputStyle);
  const rootStyle = cn(className, 'w-full relative');
  return (
    <div className={rootStyle} id={'dropdown-select'}>
      <TextFieldWrapper
        errorText={errorText}
        error={isErrorVisible}
        label={label}
        caption={caption}
        required={required}
        textAlign={textAlign}
      >
        <Select
          {...otherProps}
          ref={ref as any}
          className={'w-full text-[14px] sm:text-base'}
          onChange={value => onChange(value)}
          value={reMappedOptions.find(option => option.value === selectedValue)}
          options={reMappedOptions}
          placeholder={placeholder}
          isSearchable={isSearchable}
          menuPortalTarget={nullMenuTarget || adminDebugger ? null : document.body}
          isDisabled={disabled}
          styles={{
            singleValue: provided => ({
              ...provided,
              color: 'black',
            }),
            indicatorSeparator: () => ({
              display: 'none',
            }),
            dropdownIndicator: (provided, state) => ({
              ...provided,
              transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : 'rotate(0deg)',
              transition: 'transform 0.2s',
            }),
            control: (provided, state) => ({
              ...provided,
              borderColor: readOnly ? 'transparent' : state.isFocused ? 'black' : '',
              backgroundColor: readOnly ? 'transparent' : 'white',
              boxShadow: 'none',
              '&:hover': {
                borderColor: 'none',
                cursor: 'pointer',
              },
            }),
            option: (provided, state) => ({
              ...provided,
              backgroundColor: state.isSelected ? '#000' : state.isFocused ? '#f3f3f3' : 'white',
              '&:hover': {
                backgroundColor: state.isSelected ? '#606060' : '#f3f3f3',
                cursor: 'pointer',
              },
            }),
            menuPortal: provided => ({
              ...provided,
              zIndex: 1000000,
            }),
            menu: provided => ({
              ...provided,
              zIndex: 9999,
            }),
            menuList: provided => ({
              ...provided,
              maxHeight: '200px',
            }),
            indicatorsContainer: provided => ({
              ...provided,
              display: readOnly ? 'none' : 'flex',
            }),
          }}
          classNames={{
            placeholder: () => (readOnly ? '!text-transparent' : '!text-neutralSoft'),
            control: () => combineStyle,
          }}
        />
      </TextFieldWrapper>
    </div>
  );
});
