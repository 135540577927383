import { FaqLanding } from 'src/pages/in-landing/components/FaqLanding';

import { ComparisonSection } from './components/ComparisonSection';
import EligibleItems from './components/EligibleItems';
import Footer from './components/Footer';
import Hero from './components/Hero';
import { HowItWorks } from './components/HowItWorks';
import PeopleLovePesto from './components/PeopleLovePest';
import PestoAppSection from './components/PestAppSection';
import { WhatYourItemsWorth } from './components/WhatYourItemsWorth';

export const EmailElle = () => {
  return (
    <>
      <Hero />
      <PeopleLovePesto />
      <EligibleItems />
      <WhatYourItemsWorth />
      <HowItWorks />
      <PestoAppSection />
      <ComparisonSection />
      <FaqLanding />
      <Footer />
    </>
  );
};
