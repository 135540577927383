import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import type { AssetType, MetalCategory, NewMetalInput } from '../../../../../../__generated__/graphql/api';
import {
  GetItemCartDocument,
  MetalType,
  useAddItemNewMetalForCurrentUserMutation,
} from '../../../../../../__generated__/graphql/api';
import { apolloClient } from '../../../../../../api/Apollo';
import { QUICK_APPRAISAL_LS_KEY } from '../../../../../../constants/localStorageKeys';
import { useGoRoutes } from '../../../../../../hooks/useGoRoutes';
import { useUser } from '../../../../../../hooks/useUser';
import { handleError } from '../../../../../../util/errorTracking';
import { cleanFilesArray } from '../../../../../../util/filesUploaderHelper';

import { MetalCategoryScreen } from './MetalCategoryScreen';
import { MetalTypeScreen } from './MetalTypeScreen';

export interface MetalItemPayload {
  metalCategory: MetalCategory;
  description: string;
  photoLinks: (string | undefined)[];
}

interface NewMetalPayload {
  newMetalInput: NewMetalInput;
  photoLinks: string[] | undefined;
}

interface MetalProps {
  setItemType: (itemType: AssetType | undefined) => void;
}

export const Metal = (props: MetalProps) => {
  const { setItemType } = props;
  const { user } = useUser();
  const navigate = useNavigate();
  const { goToSignUp, gotoItemCart } = useGoRoutes();
  const [innerScreen, setInnerScreen] = useState(0);
  const [metalType, setMetalType] = useState<MetalType | undefined>(undefined);
  const [addNewMetalForCurrentUser, { loading }] = useAddItemNewMetalForCurrentUserMutation({
    refetchQueries: [{ query: GetItemCartDocument }],
    errorPolicy: 'all',
    client: apolloClient,
  });

  const setInnerScreenStepBack = () => {
    setInnerScreen(innerScreen - 1);
  };

  const handleBack = () => {
    if (innerScreen === 0) {
      setItemType(undefined);
      navigate(-1);
    }
  };

  const handleMetalType = (metalType: MetalType) => {
    setMetalType(metalType);
    setInnerScreen(1);
  };

  const handleAddMetal = useCallback(
    (metalInputPayload: NewMetalPayload) => {
      if (!user) {
        setPayload(metalInputPayload);
        return goToSignUp();
      }
      return addNewMetalForCurrentUser({
        variables: {
          creditApplicationId: user.creditApplication?.id || '',
          ...metalInputPayload,
          photoLinks: cleanFilesArray(metalInputPayload.photoLinks as string[]),
        },
      })
        .then(res => {
          if (res.errors) {
            return handleError(res.errors[0]);
          } else {
            gotoItemCart();
          }
        })
        .catch(error => {
          handleError(error);
        });
    },
    [addNewMetalForCurrentUser, goToSignUp, gotoItemCart, user],
  );

  const handleMetalItem = useCallback(
    (metalItem: MetalItemPayload) => {
      const metalInputPayload: NewMetalPayload = {
        newMetalInput: {
          metalType: metalType || MetalType.None,
          metalCategory: metalItem.metalCategory,
          additionalDetails: metalItem.description,
        },
        photoLinks: cleanFilesArray(metalItem.photoLinks),
      };
      handleAddMetal(metalInputPayload);
    },
    [handleAddMetal, metalType],
  );

  const setPayload = (metalInputPayload: NewMetalPayload) => {
    localStorage.setItem(QUICK_APPRAISAL_LS_KEY, JSON.stringify({ ...metalInputPayload }));
  };

  const renderScreens = {
    0: <MetalTypeScreen onNext={handleMetalType} handleBack={handleBack} />,
    1: (
      <MetalCategoryScreen onNext={handleMetalItem} setInnerScreenStepBack={setInnerScreenStepBack} loading={loading} />
    ),
  };

  return <>{renderScreens[innerScreen as keyof typeof renderScreens]}</>;
};
