import auth0 from 'auth0-js';
import type { ReactNode } from 'react';
import * as React from 'react';
import { createContext, useCallback, useContext, useMemo, useState } from 'react';

import { ACCESS_TOKEN_LS_KEY, EXPIRES_AT_LS_KEY } from '../constants/localStorageKeys';
import { routes } from '../constants/routes';
import logger from '../util/logger';

export type AuthContextData = {
  sendCode: (email: string) => Promise<unknown>;
  signOut: (reload?: boolean) => void;
  verifyCodeAndLogin: (email: string, verificationCode: string, state?: string, nonce?: string) => Promise<unknown>;
  parseAuthToken: () => Promise<any>;
  isAuthenticated: () => boolean;
  error: any;
};

export type AuthData = {
  accessToken: string;
  expiresIn: number;
  idToken: string;
  refreshToken: string | null;
  scope: string;
  tokenType: string;
};

export function getAccessToken() {
  return localStorage.getItem(ACCESS_TOKEN_LS_KEY);
}

const auth0Client = new auth0.WebAuth({
  domain: process.env.REACT_APP_AUTH0_DOMAIN ?? '',
  clientID: process.env.REACT_APP_AUTH0_CLIENT_ID ?? '',
  audience: process.env.REACT_APP_AUTH0_AUDIENCE ?? '',
  responseType: 'token id_token',
  redirectUri: window.location.origin + routes.loginCallback,
});

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

export function AuthProvider({ children }: { children: ReactNode }) {
  const [error, setError] = useState<any>(null);

  const setAuthData = (authResult: AuthData) => {
    //set the time the access token will expire
    const expiresAt = JSON.stringify(authResult.expiresIn * 1000 + new Date().getTime());

    localStorage.setItem(ACCESS_TOKEN_LS_KEY, authResult.accessToken);
    localStorage.setItem(EXPIRES_AT_LS_KEY, expiresAt);
    logger.info('auth data saved in localstorage');
  };

  const clearAuthData = () => {
    localStorage.removeItem(ACCESS_TOKEN_LS_KEY);
    localStorage.removeItem(EXPIRES_AT_LS_KEY);
  };

  const isAuthenticated = useCallback(() => {
    try {
      const expiresAt = JSON.parse(localStorage.getItem(EXPIRES_AT_LS_KEY) ?? '0');
      return new Date().getTime() < expiresAt;
    } catch (e) {
      return false;
    }
  }, []);

  const signOut = useCallback(async (reload = false) => {
    clearAuthData();
    window.location.hash = '';
    auth0Client.logout({
      returnTo: `${window.location.origin}`,
      clientID: process.env.REACT_APP_CLIENT_ID,
    });

    reload && window.location.reload();
  }, []);

  const sendCode = useCallback(async (email: string) => {
    return new Promise((resolve, reject) => {
      auth0Client.passwordlessStart(
        {
          connection: 'email',
          send: 'code',
          email: email,
        },
        function (err, res) {
          if (err) {
            setError(err);
            reject(err);
            return;
          }
          if (res) {
            logger.info('Response: ' + JSON.stringify(res));
            logger.info('Response: ' + JSON.stringify(res.status));
            resolve(res);
          }
        },
      );
    });
  }, []);

  const parseAuthToken = useCallback(async () => {
    return new Promise(resolve => {
      if (window.location.hash) {
        auth0Client.parseHash((err, authResult) => {
          if (authResult?.accessToken) {
            setAuthData(authResult as AuthData);
            auth0Client.client.userInfo(authResult.accessToken, (err, res) => {
              logger.error('user info error', err);
              logger.info('user info success', res);
            });
            logger.info('done parsing token callback');
            resolve(authResult.state);
          }
          if (err) {
            logger.error('login callback error parsing hash', err);
            return null;
          }
        });
      }
    });
  }, []);

  const verifyCodeAndLogin = useCallback(
    async (email: string, verificationCode: string, state?: string, nonce?: string) => {
      return new Promise((resolve, reject) => {
        auth0Client.passwordlessLogin(
          {
            connection: 'email',
            email: email,
            verificationCode: verificationCode,
            state: state,
            nonce: nonce,
          },
          (err, res) => {
            if (err) {
              setError(err);
              reject(err);
              return;
            }
            if (res) {
              logger.warn('code response', res);
              resolve(res);
            }
          },
        );
      });
    },
    [],
  );

  const authState = useMemo(
    () => ({
      sendCode,
      signOut,
      error,
      verifyCodeAndLogin,
      parseAuthToken,
      isAuthenticated,
    }),
    [isAuthenticated, sendCode, verifyCodeAndLogin, signOut, error, parseAuthToken],
  );

  return <AuthContext.Provider value={authState}>{children}</AuthContext.Provider>;
}

export function useAuth() {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an auth provider');
  }
  return context;
}
