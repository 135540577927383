import clsx from 'clsx';
import React, { useState } from 'react';

import { Cut } from 'src/__generated__/graphql/api';
import { ButtonContainer } from 'src/components/ButtonContainer/ButtonContainer';
import { MaxCaratValue } from 'src/components/CaratSlider/CaratSlider';
import { BackButton } from 'src/components/UI/Button/BackButton';
import Button from 'src/components/UI/Button/Button';

import CaratDropdown from '../../../../../../components/CaratDropdown/CaratDropdown';
import CutDropdown from '../../../../../../components/CutDropdown/CutDropdown';
import { Typography } from '../../../../../../components/Typography/Typography';

interface TypeOfStonesScreenProps {
  className?: string;
  onNext: (cut: Cut, carat: number) => void;
  handleBack: () => void;
}

const StoneDetails = (props: TypeOfStonesScreenProps) => {
  const { className, onNext, handleBack } = props;
  const [cut, setCut] = useState<Cut>(Cut.Unknown);
  const [carat, setCarat] = useState<number>(0);

  const rootStyles = clsx('flex flex-col gap-4 max-w-[400px] w-full', className);

  return (
    <div className={rootStyles}>
      <Typography variant={'headerMedium'}>Stone details</Typography>
      <div className=" flex w-full flex-col justify-center gap-2 text-center">
        <CaratDropdown
          maxCaratValue={MaxCaratValue.THREE}
          onChange={setCarat}
          inputLabel="What is the average carat size of all secondary stones?"
          label="Carat size"
          alwaysOpen
        />
        <CutDropdown onChange={setCut} alwaysOpen />

        <ButtonContainer
          rightButton={
            <Button buttonType="primary" disabled={carat === 0} text={'Next'} onClick={() => onNext(cut, carat)} />
          }
          leftButton={<BackButton onClick={handleBack} rightButtonExist />}
        />
      </div>
    </div>
  );
};

export default StoneDetails;
