import * as React from 'react';
import type { SVGProps } from 'react';

interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgCufflinkIcon = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <g stroke="currentColor" strokeLinecap="round" strokeWidth={1.5}>
      <path
        strokeLinejoin="round"
        strokeMiterlimit={10}
        d="M15.875 11c2.25-.324 3.625-1.081 3.625-1.838C19.5 7.973 16.125 7 12 7s-7.5.973-7.5 2.162c0 .865 1.5 1.514 3.875 1.838"
      />
      <path
        strokeLinejoin="round"
        strokeMiterlimit={10}
        d="m4.5 9-.088-5.838C4.412 1.973 7.375 1 11.5 1s8 .973 8 2.162V9M12 9v14"
      />
      <path d="M7.5 18.5h9" />
    </g>
  </svg>
);
export default SvgCufflinkIcon;
