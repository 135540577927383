import { forwardRef, useEffect, useState } from 'react';

import { useIsMobile } from '../../hooks/useIsMobile';
import Checked from '../../images/white-check-icon.svg';
import { Typography } from '../Typography/Typography';

import type { CheckBoxProps } from './CheckBoxTypes';
import { CheckerIcon } from './CheckerIcon';
import { generateRootStyle } from './generateRootStyle';

const CheckBox = forwardRef<HTMLDivElement, CheckBoxProps>((props, ref) => {
  const {
    className,
    controlled = true,
    checked = false,
    size = { width: '20px', height: '20px' },
    error,
    onChange,
    value = false,
    dataTestId,
  } = props;

  const isMobile = useIsMobile();
  const [isChecked, setIsChecked] = useState<boolean>(controlled ? value : checked);

  useEffect(() => {
    if (controlled) {
      setIsChecked(value);
    } else {
      setIsChecked(checked);
    }
  }, [controlled, checked, value]);

  const handleCheckboxClick = () => {
    const newValue = !isChecked;
    if (!controlled) {
      setIsChecked(newValue);
    }
    if (typeof onChange === 'function') {
      onChange(newValue);
    }
  };

  const rootStyle = generateRootStyle({
    className,
    isChecked: isChecked,
    size,
  });

  if (!controlled) {
    return <CheckerIcon checked={isChecked} />;
  }

  return (
    <div className="flex gap-2" ref={ref} onClick={handleCheckboxClick}>
      <div
        onClick={handleCheckboxClick}
        onKeyDown={e => {
          if (e.key === 'Enter' || e.key === ' ') {
            handleCheckboxClick();
            e.preventDefault();
          }
        }}
        role="checkbox"
        aria-checked={isChecked}
        tabIndex={0}
        data-testid={dataTestId}
      >
        <div style={{ width: size.width, height: size.height }} className={rootStyle}>
          {isChecked ? <img src={Checked} alt={`Checker: ${checked}`} /> : null}
        </div>
      </div>

      <div className="text-md">
        <Typography className="cursor-pointer" variant={isMobile ? 'captionSmall' : 'bodySmall'}>
          {props.text}
        </Typography>
        {error && <div className="text-sm text-danger">{error}</div>}
      </div>
    </div>
  );
});

export default CheckBox;
