import { Typography } from 'src/components/Typography/Typography';
import { Wrapper1280 } from 'src/components/UI/Wrapper/Wrapper1280';

import JewelryImage from '../../../assets/J.webp';
import GoldImage from '../../../assets/RingsLuxuary.webp';
import CollectiblesImage from '../../../assets/collectibles.webp';
import WatchImage from '../../../assets/woman-watch-1.webp';

const EligibleItems = () => {
  return (
    <div className="bg-white pb-12 pt-12 font-[Playfair,serif] md:pb-[52px] md:pt-[52px]">
      <Wrapper1280>
        <Typography variant="heroSmall" className="text-center !font-light text-black">
          Eligible Items
        </Typography>

        <div className="mt-6 flex flex-wrap items-center justify-center gap-4 md:gap-10">
          <div className="w-full bg-[#E8E8E8] pb-7 pl-6 pr-6 pt-7 text-left md:w-auto">
            <p className="text-xl md:text-2xl">⌚️ Watches</p>
            <div className="mt-4 flex h-48 w-full items-center justify-center bg-white md:h-64 md:w-48">
              <img src={WatchImage} alt="watch" className="h-full w-full object-cover" />
            </div>
          </div>

          <div className="w-full bg-[#E8E8E8] pb-7 pl-6 pr-6 pt-7 text-left md:w-auto">
            <p className="text-xl md:text-2xl">💍 Rings</p>
            <div className="mt-4 flex h-48 w-full items-center justify-center bg-white md:h-64 md:w-48">
              <img src={GoldImage} alt="ring" className="h-full w-full object-cover" />
            </div>
          </div>

          <div className="w-full bg-[#E8E8E8] pb-7 pl-6 pr-6 pt-7 text-left md:w-auto">
            <p className="text-xl md:text-2xl">📿 Jewelry</p>
            <div className="mt-4 flex h-48 w-full items-center justify-center bg-white md:h-64 md:w-48">
              <img src={JewelryImage} alt="jewelry" className="h-full w-full object-cover" />
            </div>
          </div>

          <div className="w-full bg-[#E8E8E8] pb-7 pl-6 pr-6 pt-7 text-left md:w-auto">
            <p className="text-xl md:text-2xl">🃏 Collectibles</p>
            <div className="mt-4 flex h-48 w-full items-center justify-center bg-white md:h-64 md:w-48">
              <img src={CollectiblesImage} alt="baseball card" className="h-full w-full object-cover" />
            </div>
          </div>
        </div>
      </Wrapper1280>
    </div>
  );
};

export default EligibleItems;
