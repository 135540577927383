import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { BackButton } from 'src/components/UI/Button/BackButton';

import type { AssetType } from '../../../../../../__generated__/graphql/api';
import {
  GetItemCartDocument,
  useAddItemOtherForCurrentUserMutation,
} from '../../../../../../__generated__/graphql/api';
import { apolloClient } from '../../../../../../api/Apollo';
import { ButtonContainer } from '../../../../../../components/ButtonContainer/ButtonContainer';
import Button from '../../../../../../components/UI/Button/Button';
import type { DropFile } from '../../../../../../components/UI/ImageUploader/ImageUploader';
import { ImageUploader } from '../../../../../../components/UI/ImageUploader/ImageUploader';
import { QUICK_APPRAISAL_LS_KEY } from '../../../../../../constants/localStorageKeys';
import { useGoRoutes } from '../../../../../../hooks/useGoRoutes';
import { useLocalStorage } from '../../../../../../hooks/useLocalStorage';
import { useUser } from '../../../../../../hooks/useUser';
import { cleanFilesArray } from '../../../../../../util/filesUploaderHelper';
import type { QuickAppraisalLocalStorage } from '../../../../../Login/SignUp/SignUp';
import { ScreenQuickAppraisal } from '../../components/ScreenQuickAppraisal';

import { OtherForm } from './OtherForm';

interface OtherProps {
  setItemType: (type: AssetType | undefined) => void;
}

export const Other = (props: OtherProps) => {
  const { setItemType } = props;
  const { user } = useUser();
  const { gotoItemCart, goToSignUp, goBack } = useGoRoutes();
  const [isLoadingImages, setIsLoadingImages] = useState(false);
  const schemaOther = yup.object().shape({
    other: yup.object().shape({
      otherItem: yup.string().required('Category field is required'),
      otherDescription: yup.string().required('Item description field is required'),
    }),
    photos: yup
      .array()
      .required()
      .min(1, 'At least one photo required')
      .test('is-loading', 'Photos are loading', () => !isLoadingImages),
  });
  const [, setLocalStorage] = useLocalStorage<QuickAppraisalLocalStorage>(
    QUICK_APPRAISAL_LS_KEY,
    {} as QuickAppraisalLocalStorage,
  );

  const {
    handleSubmit,
    watch,
    setValue,
    trigger,
    formState: { errors, isSubmitted, isSubmitting },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schemaOther),
    defaultValues: {
      other: {
        otherItem: '',
        otherDescription: '',
      },
      photos: [] as DropFile[],
    },
  });

  const formValues = {
    otherItem: watch('other.otherItem'),
    otherDescription: watch('other.otherDescription'),
    photos: watch('photos'),
  };

  useEffect(() => {
    if (isSubmitted || isSubmitting) {
      trigger();
    }
  }, [isSubmitted, trigger, formValues.photos, formValues.otherDescription, formValues.otherItem, isSubmitting]);

  const imagesPayload = cleanFilesArray(formValues?.photos.map(photo => photo.responseKey));

  const [addItemOtherForCurrentUser] = useAddItemOtherForCurrentUserMutation({
    variables: {
      creditApplicationId: user?.creditApplication?.id || '',
      otherInput: {
        otherAssetType: formValues.otherItem,
        description: formValues.otherDescription,
      },
      photoLinks: imagesPayload,
    },
    refetchQueries: [{ query: GetItemCartDocument }],
    errorPolicy: 'all',
    client: apolloClient,
  });

  const handleOtherSubmit = () => {
    addItemOtherForCurrentUser()
      .then(() => {
        gotoItemCart();
      })
      .catch(error => {
        console.error(error);
      });
  };

  const setPayload = () => {
    setLocalStorage({
      otherInput: {
        otherAssetType: formValues.otherItem,
        description: formValues.otherDescription,
      },
      photoLinks: imagesPayload,
    });
  };
  const processOtherSubmit = () => {
    if (isLoadingImages || formValues.photos.length === 0) {
      return;
    }
    if (user) {
      handleOtherSubmit();
    } else {
      setPayload();
      goToSignUp();
    }
  };

  const handleBackButtonClick = () => {
    goBack();
    return setItemType(undefined);
  };

  const addPhotoLink = useCallback(
    (value: DropFile[]) => {
      setValue('photos', value);
    },
    [setValue],
  );

  return (
    <form onSubmit={handleSubmit(processOtherSubmit)}>
      <ScreenQuickAppraisal title={'Tell us more about your item'}>
        <>
          <ImageUploader addPhotoLink={addPhotoLink} isLoaded={setIsLoadingImages} />
          <div className="text-left text-sm text-danger">{errors.photos?.message}</div>
        </>
        <>
          <OtherForm
            item={formValues.otherItem}
            description={formValues.otherDescription}
            setOtherDescription={(otherDescription: string) => setValue('other.otherDescription', otherDescription)}
            setItem={(item: string) => setValue('other.otherItem', item)}
            errors={errors}
          />
        </>
      </ScreenQuickAppraisal>
      <ButtonContainer
        leftButton={<BackButton onClick={handleBackButtonClick} rightButtonExist />}
        rightButton={<Button text="SUBMIT FOR REVIEW" buttonType={'primary'} type="submit" />}
      />
    </form>
  );
};
