import * as React from 'react';
import type { SVGProps } from 'react';

interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgAwardSignetRingStyle = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <circle cx={12} cy={13.5} r={5.75} stroke="#101820" strokeLinecap="round" strokeWidth={1.5} />
    <path
      fill="#101820"
      d="M7.093 6.571A1 1 0 0 1 7.913 5h8.173a1 1 0 0 1 .821 1.571l-.696 1a1 1 0 0 1-.82.429H8.608a1 1 0 0 1-.82-.429l-.696-1Z"
    />
  </svg>
);
export default SvgAwardSignetRingStyle;
