import clsx from 'clsx';

import type { DiamondType } from 'src/__generated__/graphql/api';
import { ButtonContainer } from 'src/components/ButtonContainer/ButtonContainer';
import { BackButton } from 'src/components/UI/Button/BackButton';
import { diamondColorTypes } from 'src/data/stones';

import { ListSelection } from '../../../../../../components/ListSelection/ListSelection';
import { Typography } from '../../../../../../components/Typography/Typography';

interface TypeOfDiamondScreenProps {
  className?: string;
  onNext: (data: DiamondType) => void;
  handleBack: () => void;
}

const TypeOfDiamond = (props: TypeOfDiamondScreenProps) => {
  const { className, onNext, handleBack } = props;
  const rootStyles = clsx('flex flex-col gap-4 max-w-[400px] w-full', className);

  return (
    <div className={rootStyles}>
      <Typography variant={'headerMedium'}>What type of diamond?</Typography>
      <div className="flex w-full flex-col justify-center gap-2 text-center">
        {diamondColorTypes.map((type, index) => (
          <ListSelection key={index} text={type.text} leftIcon={type.icon} onClick={() => onNext(type.value)} />
        ))}
      </div>

      <ButtonContainer leftButton={<BackButton onClick={handleBack} rightButtonExist={false} />} />
    </div>
  );
};

export default TypeOfDiamond;
