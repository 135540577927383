import { useLocation, useNavigate } from 'react-router-dom';

import { DisclaimerCreditScore } from 'src/components/DisclamerCreditScore/DisclaimerCreditScore';
import { SwitchFlowBanner } from 'src/components/SwitchFlowBanner/SwitchFlowBanner';
import { BackButton } from 'src/components/UI/Button/BackButton';
import { FROM_LANDING_PAGE } from 'src/constants/application';
import { landingPageRoutes } from 'src/constants/routes';
import { useGetUserStatus } from 'src/hooks/useGetUserStatus';

import type { AssetType } from '../../../../../__generated__/graphql/api';
import { ButtonContainer } from '../../../../../components/ButtonContainer/ButtonContainer';
import { useGoRoutes } from '../../../../../hooks/useGoRoutes';
import { useUser } from '../../../../../hooks/useUser';
import { AnalyticsEvent } from '../../../../../providers/analytics/AnalyticsEvents';
import { useAnalytics } from '../../../../../providers/analytics/AnalyticsProvider';
import { ButtonSelect } from '../components/ButtonSelect';
import { ScreenQuickAppraisal } from '../components/ScreenQuickAppraisal';

interface SelectItemScreenProps {
  itemType: AssetType | null | undefined;
  selectedItemType: (type: AssetType) => void;
}

export const SelectItemScreen = (props: SelectItemScreenProps) => {
  const { itemType, selectedItemType } = props;
  const { user } = useUser();
  const { analytics } = useAnalytics();
  const { gotoItemCart, gotoHomepage } = useGoRoutes();
  const { showSwapFlowBanner } = useGetUserStatus();
  const location = useLocation();
  const navigate = useNavigate();

  const handleBackToCart = () => {
    gotoItemCart();
  };

  const handleBack = () => {
    if (user) {
      return handleBackToCart();
    }

    if (location.state === FROM_LANDING_PAGE) {
      return navigate(landingPageRoutes.root);
    }

    return gotoHomepage();
  };
  const backButtonText = user ? 'Back to cart' : 'Back';

  const handleSelect = (type: AssetType) => {
    selectedItemType(type);
    if (!user) {
      analytics.event(AnalyticsEvent.Item.QUICK_APPRAISAL_STARTED, { formVersion: 1.0 });
    }
  };

  return (
    <ScreenQuickAppraisal title={'What item do you have?'}>
      <ButtonSelect itemType={itemType} selectedAssetType={handleSelect} />

      {showSwapFlowBanner && <SwitchFlowBanner currentFlow="asset-backed" />}

      <DisclaimerCreditScore />

      <ButtonContainer
        leftButton={
          <BackButton
            dataTestId={'quick-appraisal-back-button'}
            text={backButtonText}
            onClick={handleBack}
            rightButtonExist={false}
            className="!w-[40px]"
          />
        }
      />
    </ScreenQuickAppraisal>
  );
};
