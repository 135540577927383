import * as React from 'react';
import type { SVGProps } from 'react';

interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgCoinCash = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <g fill="currentColor" clipPath="url(#CoinCash_svg__a)">
      <path d="M23.091 8.26a.594.594 0 0 0-.29.084l-14.087 8.42-7.532-4.286a.593.593 0 1 0-.587 1.032l7.833 4.456a.594.594 0 0 0 .598-.006L23.41 9.362a.593.593 0 0 0-.319-1.102Zm-.005 1.963a.595.595 0 0 0-.29.084l-14.088 8.42-7.531-4.285a.593.593 0 1 0-.587 1.032l7.832 4.456a.594.594 0 0 0 .598-.006l14.385-8.598a.593.593 0 0 0-.32-1.103Zm.003 1.964a.593.593 0 0 0-.29.084L8.71 20.69 1.18 16.406a.593.593 0 1 0-.587 1.031l7.833 4.457a.594.594 0 0 0 .598-.007l14.385-8.597a.594.594 0 0 0-.32-1.103Z" />
      <path d="M15.114 2.029a.594.594 0 0 0-.3.083L.577 10.586a.594.594 0 0 0 .01 1.026L8.39 16.04a.593.593 0 0 0 .596-.006l14.438-8.553a.594.594 0 0 0-.019-1.031L15.402 2.1a.594.594 0 0 0-.288-.072Zm.018 1.276 1.177.64c-.325.133-.603.187-1.077.158-.348-.021-.65-.11-1.004-.26l.904-.538Zm-2.116 1.259c.84.45 1.45.681 2.144.724.873.053 1.463-.121 2.425-.65l1.965 1.069c-.02.022-.04.036-.06.06-.226.282-.387.69-.371 1.136.016.437.14.829.356 1.133.072.101.156.18.238.265l-8.835 5.233c-.886-.566-1.583-.877-2.421-.879-.81-.002-1.423.284-2.213.803l-1.9-1.079c.234-.338.391-.778.372-1.316-.02-.536-.244-.94-.512-1.255l8.812-5.244Zm7.64 1.744 1.263.686-1.134.672c-.13-.113-.288-.24-.342-.317-.086-.122-.128-.218-.138-.49-.006-.182.025-.242.112-.351.043-.054.159-.134.239-.2ZM3.159 10.43c.207.165.358.338.37.675.015.398-.134.59-.232.68l-1.237-.701 1.099-.654Zm5.295 3.412c.435.001.804.143 1.253.386l-1.031.61-1.255-.712c.335-.183.62-.285 1.033-.284Z" />
      <path d="M9.52 7.34c-.322.593-.115 1.26.248 1.79s.92 1.01 1.612 1.386c.693.377 1.399.583 2.04.598.643.016 1.315-.173 1.637-.765.322-.593.115-1.26-.248-1.79s-.92-1.01-1.612-1.386c-.693-.377-1.399-.583-2.04-.598-.643-.016-1.315.173-1.637.765Zm1.043.567c.023-.042.167-.155.564-.146.398.01.955.157 1.503.455.548.298.975.685 1.2 1.013.224.328.207.51.184.553-.023.042-.167.155-.564.146-.397-.01-.955-.157-1.503-.455-.548-.298-.975-.685-1.199-1.013-.224-.328-.208-.51-.185-.553Z" />
    </g>
    <defs>
      <clipPath id="CoinCash_svg__a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgCoinCash;
