import FlexibleBorrowingIcon from '../../assets/FlexibleBorrowingTime.svg';
import ITINAcceptedIcon from '../../assets/IttinAccepted.svg';
import MobileAppIcon from '../../assets/MobileApp.svg';
import ProtectIcon from '../../assets/ProtectYourSecurity.svg';
import ShippingDepositIcon from '../../assets/ShippingDeposit.svg';
import SupportIcon from '../../assets/Support.svg';
import { Layout } from '../../components/Layout';
import { Sidebar } from '../../components/Sidebar';

import { PersonalInfoForm } from './components/PersonalInfoForm';

export const PersonalInfoDemo = () => {
  return (
    <div className="w-full">
      <Layout
        leftContent={
          <div className="h-full">
            <Sidebar
              title="Use Cash Pesto Mastercard®"
              subtitle="Pay for the initial refundable security deposit via debit card. The credit line will be equal to the deposit amount."
              listItems={[
                'Protect your credit score',
                'Mobile App card management',
                'Flexible Borrowing Time',
                'ITIN Accepted',
                '24/7 support',
                'Insured Shipping and Deposits',
              ]}
              iconImageSrc={[
                ProtectIcon,
                MobileAppIcon,
                FlexibleBorrowingIcon,
                ITINAcceptedIcon,
                SupportIcon,
                ShippingDepositIcon,
              ]}
            />
          </div>
        }
        rightContent={
          <div>
            <PersonalInfoForm />
          </div>
        }
      />
    </div>
  );
};
