import type { Ref } from 'react';
import { forwardRef } from 'react';
import { NumericFormat } from 'react-number-format';

import type { AmountProps } from '../FormTypes';
import { TextFieldWrapper } from '../TextFieldWrapper/TextFieldWrapper';
import { generateInputStyles } from '../inputStylesHelper';

export const Amount = forwardRef((props: AmountProps, ref: Ref<HTMLInputElement>) => {
  const {
    onChange,
    className,
    value,
    errorText,
    error,
    label,
    caption,
    required,
    rightHelperText,
    placeholder,
    textAlign,
    readOnly,
    inputSize,
    decimalScale = 0,
  } = props;

  const isErrorVisible = !!error;
  const inputStyle = generateInputStyles({
    isErrorVisible,
    readOnly,
    textAlign,
    inputSize,
    className,
  });

  return (
    <>
      <TextFieldWrapper
        errorText={errorText}
        error={!!error}
        label={label}
        caption={caption}
        required={required}
        textAlign={textAlign}
        rightHelperText={rightHelperText}
      >
        <NumericFormat
          className={inputStyle}
          getInputRef={ref}
          value={value}
          placeholder={placeholder}
          thousandSeparator={true}
          prefix={'$'}
          inputMode={'numeric'}
          decimalScale={decimalScale}
          allowNegative={false}
          onChange={onChange}
        />
      </TextFieldWrapper>
    </>
  );
});
