import type { ReactNode } from 'react';

import { DiamondType, StoneType } from '../__generated__/graphql/api';
import {
  BlackDiamond,
  CubicZirconium,
  Moissanite,
  Morganite,
  PinkDiamond,
  YellowDiamond,
} from '../icons/components/new-diamonds';
import SvgEmeraldIcon from '../icons/components/stones/EmeraldIcon';
import SvgLabIcon from '../icons/components/stones/LabIcon';
import SvgNaturalIcon from '../icons/components/stones/NaturalIcon';
import SvgRubyIcon from '../icons/components/stones/RubyIcon';
import SvgSapphireIcon from '../icons/components/stones/SapphireIcon';
import SvgUnknownIcon from '../icons/components/stones/UnknownIcon';

export const diamondColorTypes: { value: DiamondType; text: string; icon: ReactNode }[] = [
  {
    value: DiamondType.BlackDiamond,
    text: 'Black Diamond',
    icon: <BlackDiamond />,
  },
  {
    value: DiamondType.PinkDiamond,
    text: 'Pink Diamond',
    icon: <PinkDiamond />,
  },
  {
    value: DiamondType.YellowDiamond,
    text: 'Yellow Diamond',
    icon: <YellowDiamond />,
  },
  {
    value: DiamondType.Moissanite,
    text: 'Moissanite',
    icon: <Moissanite />,
  },
  {
    value: DiamondType.Morganite,
    text: 'Morganite',
    icon: <Morganite />,
  },
  {
    value: DiamondType.CubicZirconia,
    text: 'Cubic Zirconium',
    icon: <CubicZirconium />,
  },
];

export const diamondTypes: {
  fabric: StoneType;
  value: StoneType;
  text: string;
  icon: ReactNode;
}[] = [
  {
    fabric: StoneType.DiamondNatural,
    value: StoneType.DiamondNatural,
    text: 'Natural Diamond',
    icon: <SvgNaturalIcon />,
  },
  {
    fabric: StoneType.DiamondLabGrown,
    value: StoneType.DiamondLabGrown,
    text: 'Lab Grown Diamond',
    icon: <SvgLabIcon />,
  },
  {
    fabric: StoneType.DiamondUnknown,
    value: StoneType.DiamondUnknown,
    text: 'Other Diamond',
    icon: <SvgUnknownIcon />,
  },
];
export const gemstoneTypes: {
  fabric: StoneType;
  value: StoneType;
  text: string;
  icon: ReactNode;
}[] = [
  {
    fabric: StoneType.Ruby,
    value: StoneType.Ruby,
    text: 'Ruby',
    icon: <SvgRubyIcon />,
  },
  {
    fabric: StoneType.Sapphire,
    value: StoneType.Sapphire,
    text: 'Sapphire',
    icon: <SvgSapphireIcon />,
  },
  {
    fabric: StoneType.Emerald,
    value: StoneType.Emerald,
    text: 'Emerald',
    icon: <SvgEmeraldIcon />,
  },
];
