import { useState } from 'react';
import { useNavigate, useRoutes } from 'react-router-dom';

import { AssetType } from 'src/__generated__/graphql/api';
import { applicationRoutesExact, quickAppraisalRoutesExact } from 'src/constants/routes';
import { useScrollTopOnMount } from 'src/hooks/useScrollTopOnMount';
import { useUser } from 'src/hooks/useUser';

import { Earrings } from './screens/Earrings/Earrings';
import { Gemstone } from './screens/Gemstone/Gemstone';
import { Metal } from './screens/Metal/Metal';
import { Necklace } from './screens/Necklace/Necklace';
import { Other } from './screens/Other/Other';
import { Ring } from './screens/Ring/Ring';
import { SelectItemScreen } from './screens/SelectItemScreen';
import { Watch } from './screens/Watch/Watch';

export const QuickAppraisal = () => {
  useScrollTopOnMount();
  const navigate = useNavigate();
  const { user } = useUser();
  const [itemType, setItemType] = useState<AssetType>();

  const quickAppraisalRoute = user ? '/application/quick-appraisal' : '/quick-appraisal';

  const routeMap = {
    [AssetType.PreciousMetals]: quickAppraisalRoute + quickAppraisalRoutesExact.metal,
    [AssetType.Necklace]: quickAppraisalRoute + quickAppraisalRoutesExact.necklace,
    [AssetType.Watch]: quickAppraisalRoute + quickAppraisalRoutesExact.watch,
    [AssetType.Earrings]: quickAppraisalRoute + quickAppraisalRoutesExact.earrings,
    [AssetType.Gemstones]: quickAppraisalRoute + quickAppraisalRoutesExact.gemstone,
    [AssetType.Ring]: quickAppraisalRoute + quickAppraisalRoutesExact.ring,
    [AssetType.Other]: quickAppraisalRoute + quickAppraisalRoutesExact.other,
    default: '/',
  };

  const selectedAssetType = (type: AssetType) => {
    setItemType(type);
    const path = routeMap[type as keyof typeof routeMap] || routeMap['default'];
    navigate(path);
  };

  const routes = useRoutes([
    {
      path: applicationRoutesExact.root,
      children: [
        { path: quickAppraisalRoutesExact.metal, element: <Metal setItemType={setItemType} /> },
        { path: quickAppraisalRoutesExact.necklace, element: <Necklace setItemType={setItemType} /> },
        { path: quickAppraisalRoutesExact.watch, element: <Watch setItemType={setItemType} /> },
        { path: quickAppraisalRoutesExact.earrings, element: <Earrings setItemType={setItemType} /> },
        { path: quickAppraisalRoutesExact.gemstone, element: <Gemstone setItemType={setItemType} /> },
        { path: quickAppraisalRoutesExact.ring, element: <Ring setItemType={setItemType} /> },
        { path: quickAppraisalRoutesExact.other, element: <Other setItemType={setItemType} /> },
        { path: '/', element: <SelectItemScreen selectedItemType={selectedAssetType} itemType={itemType} /> },
      ],
    },
  ]);

  return <>{routes}</>;
};
