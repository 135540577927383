import { cn } from 'src/util/cn';

import type { SwitchState } from '../ItemCart';

interface SwitchButtonProps {
  switchState: SwitchState;
  setSwitchState: (state: SwitchState) => void;
  currentState: SwitchState;
  children: React.ReactNode;
  shouldDisplay?: boolean;
}

const SwitchButton: React.FC<SwitchButtonProps> = ({
  shouldDisplay,
  switchState,
  setSwitchState,
  currentState,
  children,
}) => {
  return (
    shouldDisplay && (
      <button
        className={cn(
          'flex items-center justify-center gap-1 rounded-full px-4 py-1 text-sm font-medium',
          switchState === currentState ? 'bg-basicSoft text-white' : 'bg-gray-200 text-gray-800',
        )}
        onClick={() => setSwitchState(currentState)}
      >
        {children}
      </button>
    )
  );
};

export default SwitchButton;
