import { CreditApplicationStatus } from 'src/__generated__/graphql/api';
import CheckBox from 'src/components/CheckBox/CheckBox';
import {
  LANDING_FINDER_LS_KEY,
  NEW_SIGN_UP_DEMO_LS_KEY,
  PERSONAL_INFO_DEMO_LS_KEY,
} from 'src/constants/localStorageKeys';

import { Typography } from '../../Typography/Typography';
import Button from '../../UI/Button/Button';
import { useAdminDebugger } from '../hooks/useAdminDebugger';

export const FeatureFlags = () => {
  const {
    getIsRouteEnabled,
    handleRouteChange,
    applyCreditAppStatusForUser,
    getIsSchedulePayment,
    handleShowSchedulePayment,
  } = useAdminDebugger();

  return (
    <div className="relative mt-6 px-4 sm:px-6">
      <div
        className="relative my-4 flex flex-col gap-4 rounded border border-green-500 bg-green-100 px-4 py-3"
        role="alert"
      >
        <div className="text-lg font-semibold text-green-800">Feature Flags</div>

        <Button
          buttonType={'primary'}
          text={'Change status to Verify Doc'}
          onClick={() => applyCreditAppStatusForUser(CreditApplicationStatus.VerifyDoc)}
        />

        <Button
          buttonType={'primary'}
          text={'Change status to Verify Payment'}
          onClick={() => applyCreditAppStatusForUser(CreditApplicationStatus.VerifyPayment)}
        />
      </div>

      <div
        className="relative my-4 flex flex-col gap-4 rounded border border-yellow-500 bg-yellow-100 px-4 py-3"
        role="alert"
      >
        <CheckBox
          value={getIsSchedulePayment()}
          onChange={value => handleShowSchedulePayment(value)}
          text={'Enable Schedule Payment'}
          label={''}
        />
      </div>
      <div
        className="relative my-4 flex flex-col gap-4 rounded border border-green-500 bg-green-100 px-4 py-3"
        role="alert"
      >
        <div className="text-lg font-semibold text-green-800">Experimental pages</div>
        <Typography variant={'body'}>or add the 'showthedemo=true' query param to the URL</Typography>
        <CheckBox
          value={getIsSchedulePayment()}
          onChange={value => handleShowSchedulePayment(value)}
          text={'Enable Schedule Payment'}
          label={''}
        />

        <CheckBox
          value={getIsRouteEnabled(PERSONAL_INFO_DEMO_LS_KEY)}
          onChange={value => handleRouteChange(PERSONAL_INFO_DEMO_LS_KEY, value)}
          text={'Enable /personal-info-demo route'}
          label={''}
        />
        <CheckBox
          value={getIsRouteEnabled(NEW_SIGN_UP_DEMO_LS_KEY)}
          onChange={value => handleRouteChange(NEW_SIGN_UP_DEMO_LS_KEY, value)}
          text={'Enable /new-sign-up-demo route'}
          label={''}
        />
        <CheckBox
          value={getIsRouteEnabled(LANDING_FINDER_LS_KEY)}
          onChange={value => handleRouteChange(LANDING_FINDER_LS_KEY, value)}
          text={'Enable /landing/finder route'}
          label={''}
        />
      </div>
    </div>
  );
};
