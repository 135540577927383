import React, { createContext, useContext, useState } from 'react';

import type { PaymentOptions } from '../types';

type CashSecuredActiveScreenContextType = {
  activeScreen: number;
  setActiveScreen: React.Dispatch<React.SetStateAction<number>>;
  handleClick: (option: PaymentOptions) => void;
  handleContinue: () => void;
};

type CashSecuredActiveScreenProviderProps = {
  children: React.ReactNode;
};

const CashSecuredActiveScreenContext = createContext<CashSecuredActiveScreenContextType | undefined>(undefined);

export const useActiveScreen = () => {
  const context = useContext(CashSecuredActiveScreenContext);
  if (context === undefined) {
    throw new Error('useActiveScreen must be used within an CashSecuredActiveScreenProvider');
  }
  return context;
};

export const CashSecuredActiveScreenProvider = ({ children }: CashSecuredActiveScreenProviderProps) => {
  const [activeScreen, setActiveScreen] = useState(0);

  const handleClick = () => {
    setActiveScreen(1);
  };

  const handleContinue = () => {
    setActiveScreen(2);
  };

  return (
    <CashSecuredActiveScreenContext.Provider value={{ activeScreen, setActiveScreen, handleClick, handleContinue }}>
      {children}
    </CashSecuredActiveScreenContext.Provider>
  );
};
