import type { ReactNode } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { ReactAppEnvIsNotProd } from '../constants/global';
import { routes } from '../constants/routes';
import { useUser } from '../hooks/useUser';
import { isProtectedRoutesDisabled } from '../util/adminDebuggerHelper';

interface ProtectedRouteProps {
  children: ReactNode;
}

export const ProtectedRoute = (props: ProtectedRouteProps) => {
  const { children } = props;
  const { isLoading, isLoggedIn } = useUser();
  const isNotLoadingAndNotLoggedIn = !isLoading && !isLoggedIn;
  const location = useLocation();
  if (isProtectedRoutesDisabled && ReactAppEnvIsNotProd) return <>{children}</>;
  if (isNotLoadingAndNotLoggedIn) return <Navigate to={routes.login} replace state={{ path: location.pathname }} />;
  return children;
};
