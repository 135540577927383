import * as React from 'react';
import type { SVGProps } from 'react';

interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgDavidYurman = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 64 9"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      d="M.785 8V.5h2.31c2.24 0 3.75 1.55 3.75 3.73 0 2.21-1.51 3.77-3.75 3.77H.785Zm2.31-6.47h-1.19v5.44h1.19c1.55 0 2.58-1.08 2.58-2.73 0-1.64-1.03-2.71-2.58-2.71ZM11.21 8l-.05-.82c-.39.57-1 .91-1.74.91-.92 0-1.59-.53-1.59-1.43 0-.99.84-1.39 1.95-1.65l1.34-.31c-.1-.61-.52-.88-1.11-.88-.64 0-1.07.27-1.22.86L7.8 4.5c.24-.95 1.03-1.56 2.22-1.56 1.28 0 2.12.69 2.12 2.02V8h-.93ZM8.89 6.59c0 .4.27.63.71.63.77 0 1.54-.54 1.54-1.6V5.5l-1.33.33c-.57.14-.92.33-.92.76ZM14.634 8l-1.9-4.97h1.15l1.37 3.76 1.37-3.76h1.09L15.794 8h-1.16ZM18.4 1.41c0-.38.28-.65.66-.65.39 0 .67.27.67.65s-.28.66-.67.66c-.38 0-.66-.28-.66-.66Zm.15 6.59V3.03h1.03V8h-1.03Zm7.318 0h-.97l-.04-.69c-.4.49-.99.78-1.67.78-1.39 0-2.44-1.09-2.44-2.58 0-1.48 1.05-2.57 2.44-2.57.67 0 1.24.28 1.64.74V.5h1.04V8Zm-2.54-4.11c-.89 0-1.5.65-1.5 1.62 0 .97.61 1.63 1.5 1.63.9 0 1.5-.66 1.5-1.63 0-.97-.6-1.62-1.5-1.62ZM31.676 8V4.73L28.956.5h1.3l2 3.16L34.266.5h1.25l-2.72 4.23V8h-1.12Zm4.817-4.97v2.98c0 .74.42 1.13 1.13 1.13.58 0 1.18-.34 1.18-1.27V3.03h1.04V8h-.95l-.04-.58c-.33.44-.81.67-1.5.67-1.06 0-1.89-.69-1.89-1.99V3.03h1.03Zm4.9 4.97V3.03h.98l.05.68c.29-.46.77-.76 1.43-.76.17 0 .3.02.49.07l-.14 1.01c-.17-.05-.34-.07-.51-.07-.69 0-1.27.51-1.27 1.45V8h-1.03Zm3.809 0V3.03h.96l.04.56c.3-.38.72-.65 1.31-.65.62 0 1.16.27 1.46.8.33-.47.82-.8 1.52-.8.98 0 1.76.65 1.76 1.91V8h-1.04V4.92c0-.68-.38-1.02-.93-1.02-.54 0-1.04.29-1.04 1.03V8h-1.04V4.92c0-.68-.37-1.02-.92-1.02-.54 0-1.05.3-1.05 1.06V8h-1.03Zm11.546 0-.05-.82c-.39.57-1 .91-1.74.91-.92 0-1.59-.53-1.59-1.43 0-.99.84-1.39 1.95-1.65l1.34-.31c-.1-.61-.52-.88-1.11-.88-.64 0-1.07.27-1.22.86l-.99-.18c.24-.95 1.03-1.56 2.22-1.56 1.28 0 2.12.69 2.12 2.02V8h-.93Zm-2.32-1.41c0 .4.27.63.71.63.77 0 1.54-.54 1.54-1.6V5.5l-1.33.33c-.57.14-.92.33-.92.76Zm4.7 1.41V3.03h.96l.04.62c.38-.45.91-.71 1.54-.71 1.06 0 1.95.7 1.95 2.07V8h-1.04V5.15c0-.83-.49-1.25-1.15-1.25-.63 0-1.27.36-1.27 1.32V8h-1.03Z"
    />
  </svg>
);
export default SvgDavidYurman;
