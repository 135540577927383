import type { ReactNode } from 'react';
import type { FieldError } from 'react-hook-form';
import { Controller } from 'react-hook-form';

import type { Control } from 'react-hook-form/dist/types/form';

import type { StoneSize, StoneType } from '../../../../../../../__generated__/graphql/api';
import { CheckDown } from '../../../../../../../components/CheckDown/CheckDown';

import { InnerStoneSizeAndCount } from './InnerStoneSizeAndCount';

interface ConnectedCheckDownProps {
  stone: { value: StoneType; text: string; icon: ReactNode };
  dataName?: string;
  control: Control<any>;
  caption?: ReactNode;
  error?: {
    stoneSize?: FieldError;
    stoneCount?: FieldError;
  };
}
export const ConnectedCheckDown = (props: ConnectedCheckDownProps) => {
  const { stone, control, dataName = 'stones', error, caption } = props;

  const innerDefaultValue = {
    isChecked: false,
    stoneSize: '',
    stoneCount: '',
  };

  return (
    <Controller
      name={`${dataName}[${stone.value}]`}
      control={control}
      defaultValue={{ stoneType: stone.value, ...innerDefaultValue }}
      render={({ field }) => {
        return (
          <CheckDown
            key={stone.text}
            checked={field.value.isChecked}
            onChange={(checked: boolean) => {
              if (!checked) {
                field.onChange({ ...innerDefaultValue, stoneType: stone.value });
                return;
              }
              field.onChange({ ...field.value, isChecked: checked });
            }}
            title={stone.text}
            leftIcon={stone.icon}
          >
            <InnerStoneSizeAndCount
              size={field.value.stoneSize}
              count={field.value.stoneCount}
              onSizeChange={(stoneSize: StoneSize) => field.onChange({ ...field.value, stoneSize })}
              onCountChange={(stoneCount: number) => field.onChange({ ...field.value, stoneCount })}
              sizeError={error?.stoneSize?.message}
              countError={error?.stoneCount?.message}
            />
            <div className={'mt-1 flex h-auto w-full flex-col items-center justify-center'}>{caption}</div>
          </CheckDown>
        );
      }}
    />
  );
};
