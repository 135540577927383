import clsx from 'clsx';
import { Navigate } from 'react-router-dom';

import { Header } from '../components/Header/Header';
import { LoaderSpinnerFull } from '../components/LoaderSpinnerFull';
import { Wrapper1280 } from '../components/UI/Wrapper/Wrapper1280';
import { routes } from '../constants/routes';
import { useUser } from '../hooks/useUser';

import { QuickAppraisal } from './ApplicationFlow/steps/QuickAppraisal/QuickAppraisal';

const QuickAppraisalPage = () => {
  const { isLoading, user } = useUser();

  if (isLoading) return <LoaderSpinnerFull />;
  if (user) return <Navigate to={routes.application} />;
  return (
    <>
      <Header />
      <Wrapper1280 className={clsx('flex min-h-[80vh] justify-center pb-[240px] pt-4 md:pb-[150px] md:pt-8')}>
        <QuickAppraisal />
      </Wrapper1280>
    </>
  );
};
export default QuickAppraisalPage;
