import { useCurrentUserQuery } from 'src/__generated__/graphql/api';
import { AddressOrShipping } from 'src/components/UserInfo/AddressOrShipping/AddressOrShipping';
import { PersonalInformation } from 'src/components/UserInfo/PersonalInformation/PersonalInformation';
import { useHasPersonalInfoData } from 'src/hooks/useHasPersonalInfoData';

import AppraisalStatusMessage from '../components/AppraisalStatusMessage';

const PersonalInfoSettings = () => {
  const hasPersonalInfoData = useHasPersonalInfoData();

  const { data } = useCurrentUserQuery();
  const user = data?.currentUser;

  const hasBillingAddressData = Object.values(user?.billingAddress || {}).some(value => value !== null && value !== '');

  return (
    <div className="flex w-full max-w-[550px] flex-col gap-4 text-left">
      <AppraisalStatusMessage status={user?.creditApplication?.status} />
      {hasPersonalInfoData && <PersonalInformation user={user} />}
      {hasBillingAddressData && <AddressOrShipping user={user} billingEditable={false} />}
    </div>
  );
};

export default PersonalInfoSettings;
