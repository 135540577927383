import TextLink, { LinkType } from 'src/components/TextLink/TextLink';
import { Typography } from 'src/components/Typography/Typography';
import { Wrapper1280 } from 'src/components/UI/Wrapper/Wrapper1280';
import { routes } from 'src/constants/routes';
import { accordionItems } from 'src/pages/in-landing/constants-landing';
import { Accordion } from 'src/pages/landing/components/Accordion';

export const FaqLanding = () => {
  return (
    <Wrapper1280 className="flex flex-col gap-4 py-16 text-left">
      <Typography variant="heroSmall" className="">
        FAQ
      </Typography>
      <Accordion items={accordionItems} />
      <Typography variant={'body'} className={'text-center'}>
        Still have questions?{' '}
        <TextLink as={LinkType.RouterLink} to={routes.faq}>
          Check out our FAQ page
        </TextLink>{' '}
        or{' '}
        <TextLink
          as={LinkType.Button}
          className={'w-auto'}
          onClick={() => {
            window.HubSpotConversations?.widget?.open();
          }}
        >
          talk to a specialist
        </TextLink>
      </Typography>
    </Wrapper1280>
  );
};
