import isEmpty from 'lodash.isempty';
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { SKIP_NAVIGATE } from 'src/constants/application';
import { applicationRoutes, cashSecuredRoutes } from 'src/constants/routes';
import { URLContext } from 'src/providers/URLProvider';

import { CurrentUserDocument, SecurityType, useSetSecurityTypeMutation } from '../../__generated__/graphql/api';
import { useUser } from '../../hooks/useUser';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import TextLink, { LinkType } from '../TextLink/TextLink';
import { Typography } from '../Typography/Typography';

interface SwitchFlowBannerProps {
  currentFlow: 'cash-secured' | 'asset-backed';
}

const data = {
  'cash-secured': {
    description: 'If you’d like to fund your card with an item instead?',
    button: 'Click here',
  },
  'asset-backed': {
    description: 'If you’d like to fund your card with cash instead?',
    button: 'Click here',
  },
};
export const SwitchFlowBanner = (props: SwitchFlowBannerProps) => {
  const { currentFlow } = props;
  const text = data[currentFlow];
  const { user } = useUser();
  const navigate = useNavigate();
  const { getUrlOnStatus } = useContext(URLContext);
  const [setSecurityTypeMutation, { loading }] = useSetSecurityTypeMutation({
    errorPolicy: 'all',
    refetchQueries: [{ query: CurrentUserDocument }],
    awaitRefetchQueries: true,
  });
  const handleSwitchFlow = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    setSecurityTypeMutation({
      variables: {
        creditApplicationId: user?.creditApplication?.id || '',
        securityType:
          user?.creditApplication?.securityType === SecurityType.Cash ? SecurityType.Item : SecurityType.Cash,
      },
    })
      .then(res => {
        if (res?.errors) {
          console.error('Error switching flow - ' + res.errors[0].message);
          return;
        }
        const status = res.data?.setSecurityTypeAndDetails?.creditApplication?.status;
        const securityType = res.data?.setSecurityTypeAndDetails?.creditApplication?.securityType;
        const items = res.data?.setSecurityTypeAndDetails?.creditApplication?.items;
        const hasNoItems = isEmpty(items?.filter(item => item?.active));
        const hasNoCashAmount = !items?.some(item => item?.securityType === SecurityType.Cash && item?.active);

        if (!status || !securityType) return null;

        if (securityType === SecurityType.Item && hasNoItems) {
          return navigate(applicationRoutes.quickAppraisal, { state: SKIP_NAVIGATE });
        }
        if (securityType === SecurityType.Cash && hasNoCashAmount) {
          return navigate(cashSecuredRoutes.creditLine, { state: SKIP_NAVIGATE });
        }

        return navigate(getUrlOnStatus(securityType, status));
      })
      .catch(err => {
        console.error('Error switching flow - ' + err.message);
      });
  };

  return (
    <div className="w-full max-w-[440px] rounded border border-bisque bg-[#FEDEC740] p-4 text-center">
      {loading ? (
        <div className="flex h-full w-full flex-col items-center justify-center">
          <LoadingSpinner />
          <div>Switching funding type</div>
        </div>
      ) : (
        <>
          {' '}
          <Typography variant="body" className="inline">
            {text.description}
          </Typography>{' '}
          <TextLink as={LinkType.Anchor} href="" onClick={handleSwitchFlow}>
            <Typography variant={'body'}>{text.button}</Typography>
          </TextLink>
        </>
      )}
    </div>
  );
};
