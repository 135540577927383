import * as React from 'react';
import type { SVGProps } from 'react';

interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgGoldBarsIcon = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <g stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5}>
      <path d="M8.945 12H3.481a1.533 1.533 0 0 0-1.516 1.217l-1.187 6A1.513 1.513 0 0 0 2.294 21h7.838a1.512 1.512 0 0 0 1.515-1.783l-1.187-6A1.532 1.532 0 0 0 8.945 12ZM20.519 12h-5.464a1.532 1.532 0 0 0-1.515 1.217l-1.187 6A1.512 1.512 0 0 0 13.868 21h7.838a1.514 1.514 0 0 0 1.516-1.783l-1.187-6A1.533 1.533 0 0 0 20.519 12Z" />
      <path d="M14.945 3H9.481a1.533 1.533 0 0 0-1.516 1.217l-1.187 6A1.513 1.513 0 0 0 8.294 12h7.838a1.512 1.512 0 0 0 1.515-1.783l-1.187-6A1.532 1.532 0 0 0 14.945 3Z" />
    </g>
  </svg>
);
export default SvgGoldBarsIcon;
