import type { ReactNode } from 'react';

import { Wrapper1280 } from 'src/components/UI/Wrapper/Wrapper1280';

interface HeroCardProps {
  children: ReactNode;
}
export const HeroCard = (props: HeroCardProps) => {
  const { children } = props;
  return (
    <div className="bg-[#F9F9F9] bg-[radial-gradient(50%_50%_at_50%_50%,#F9F9F9_0%,_#F9F9F9_100%)]">
      <Wrapper1280 className="flex h-full flex-col items-center gap-4 px-4 py-6 sm:w-auto sm:gap-12 sm:px-8 sm:py-20">
        {children}
      </Wrapper1280>
    </div>
  );
};
