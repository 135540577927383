import React from 'react';

import { Typography } from 'src/components/Typography/Typography';
import { useIsMobile } from 'src/hooks/useIsMobile';

interface BenefitItemProps {
  icon: JSX.Element;
  title: string;
}

const BenefitItem: React.FC<BenefitItemProps> = ({ icon, title }) => {
  const isMobile = useIsMobile();
  return (
    <div className="flex items-center space-x-2">
      <div className="text-[#1C4B6D]">{icon}</div>
      <Typography variant={isMobile ? 'bodySmall' : 'caption'} className="font-bold text-[#1C4B6D]">
        {title}
      </Typography>
    </div>
  );
};

export default BenefitItem;
