import * as React from 'react';
import type { SVGProps } from 'react';

interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgCardIcon = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 42 26"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <g clipPath="url(#CardIcon_svg__a)">
      <rect width={40.392} height={25.41} x={0.726} y={0.295} fill="#F93549" rx={1.918} />
      <circle cx={-1.132} cy={12.94} r={16.48} fill="#101820" />
      <rect
        width={5.034}
        height={3.596}
        x={3.782}
        y={8.266}
        fill="#878B8F"
        stroke="currentColor"
        strokeOpacity={0.2}
        strokeWidth={0.12}
        rx={0.599}
      />
      <path
        fill="url(#CardIcon_svg__b)"
        fillOpacity={0.5}
        fillRule="evenodd"
        d="M4.501 8.385a.6.6 0 0 0-.6.6V11.143a.6.6 0 0 0 .6.598h3.596a.6.6 0 0 0 .6-.599V8.985a.6.6 0 0 0-.6-.6H4.5Zm1.349 1.753H3.977a.075.075 0 0 1 0-.15H5.85V9.3H3.977a.075.075 0 1 1 0-.15H6.224v-.687a.075.075 0 0 1 .15 0v.686h.375v.002h1.833a.075.075 0 1 1 0 .15H6.748v.689h1.873a.075.075 0 0 1 0 .15H6.748v.689h1.873a.075.075 0 1 1 0 .15H6.374v.687a.075.075 0 1 1-.15 0v-.686H5.85v-.002H3.978a.075.075 0 1 1 0-.15H5.85v-.689Zm.15-.074v.764h.599V9.299h-.6v.764Z"
        clipRule="evenodd"
      />
      <path
        fill="url(#CardIcon_svg__c)"
        fillOpacity={0.2}
        fillRule="evenodd"
        d="M4.501 8.385a.6.6 0 0 0-.6.6V11.143a.6.6 0 0 0 .6.598h3.596a.6.6 0 0 0 .6-.599V8.985a.6.6 0 0 0-.6-.6H4.5Zm1.349 1.753H3.977a.075.075 0 0 1 0-.15H5.85V9.3H3.977a.075.075 0 1 1 0-.15H6.224v-.687a.075.075 0 0 1 .15 0v.686h.375v.002h1.833a.075.075 0 1 1 0 .15H6.748v.689h1.873a.075.075 0 0 1 0 .15H6.748v.689h1.873a.075.075 0 1 1 0 .15H6.374v.687a.075.075 0 1 1-.15 0v-.686H5.85v-.002H3.978a.075.075 0 1 1 0-.15H5.85v-.689Zm.15-.074v.764h.599V9.299h-.6v.764Z"
        clipRule="evenodd"
      />
      <circle cx={34.298} cy={21.714} r={1.822} fill="#EB001B" opacity={0.8} />
      <circle cx={37.213} cy={21.714} r={1.822} fill="#F79E1B" opacity={0.8} />
      <path
        fill="#FF5F00"
        fillRule="evenodd"
        d="M35.74 22.853c.229-.305.364-.683.364-1.094 0-.41-.135-.788-.364-1.093a1.814 1.814 0 0 0-.364 1.093c0 .41.135.79.364 1.094Z"
        clipRule="evenodd"
      />
      <g fill="#17232F" clipPath="url(#CardIcon_svg__d)">
        <path d="M18.412 9.93c-.007-.058-.007-.124-.007-.183 0-1.508.962-2.56 2.361-2.56 1.222 0 2.125.802 2.125 2.001 0 1.376-1.177 2.23-2.599 2.23-.17 0-.37-.015-.57-.045.133.456.311.927.51 1.405h-1.087c-.23-.551-.413-1.13-.563-1.684a7.148 7.148 0 0 1-.17-1.163Zm1.088.465c.252.066.496.095.733.095 1.03 0 1.614-.581 1.614-1.317 0-.625-.437-1.06-1.074-1.06-.762 0-1.325.626-1.325 1.634 0 .206.015.42.052.647ZM23 10.954c0-1.119.791-1.942 1.916-1.942s1.844.728 1.844 1.787c0 .133-.015.28-.045.42h-2.761c.096.573.474.83 1.007.83.488 0 .77-.213.896-.529l.866.177c-.222.728-.866 1.155-1.762 1.155-1.23 0-1.962-.802-1.962-1.899Zm.984-.449h1.791c-.081-.47-.385-.72-.859-.72-.429 0-.799.227-.932.72ZM27.081 11.748l.955-.191c.074.383.348.544.733.544s.607-.147.607-.412c0-.28-.4-.316-.844-.397-.6-.095-1.303-.257-1.303-1.103 0-.684.652-1.177 1.547-1.177.837 0 1.34.368 1.555 1.022l-.925.191c-.074-.316-.296-.463-.63-.463-.37 0-.592.162-.592.382 0 .273.34.332.762.398.622.103 1.392.228 1.392 1.088 0 .7-.518 1.222-1.57 1.222-.828 0-1.48-.316-1.687-1.104ZM31.983 8.254h.252v.838h1.177v.81h-1.177v1.567c0 .382.192.53.525.53.163 0 .319-.045.504-.126l.207.766c-.281.14-.6.213-.918.213-.785 0-1.251-.434-1.251-1.17v-1.78h-.704v-.81h.704v-.838h.681ZM37.579 10.931c0 1.111-.837 1.92-1.977 1.92-1.14 0-1.976-.809-1.976-1.92 0-1.11.836-1.92 1.976-1.92 1.14 0 1.977.817 1.977 1.92Zm-2.976 0c0 .648.393 1.074 1 1.074.606 0 .999-.426.999-1.074 0-.647-.4-1.066-1-1.066s-.999.426-.999 1.066Z" />
      </g>
    </g>
    <rect width={40.332} height={25.35} x={0.756} y={0.325} stroke="#fff" strokeWidth={0.06} rx={1.888} />
    <defs>
      <clipPath id="CardIcon_svg__a">
        <rect width={40.392} height={25.41} x={0.726} y={0.295} fill="#fff" rx={1.918} />
      </clipPath>
      <clipPath id="CardIcon_svg__d">
        <path fill="#fff" d="M18.405 7.187h19.177v5.753H18.405z" />
      </clipPath>
      <radialGradient
        id="CardIcon_svg__c"
        cx={0}
        cy={0}
        r={1}
        gradientTransform="matrix(0 3.95532 -5.65045 0 6.299 10.063)"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#fff" stopOpacity={0.5} />
        <stop offset={1} stopColor="#FDFDFC" stopOpacity={0.1} />
      </radialGradient>
      <linearGradient id="CardIcon_svg__b" x1={3.827} x2={9.877} y1={8.385} y2={11.95} gradientUnits="userSpaceOnUse">
        <stop stopColor="#D9D9D9" />
        <stop offset={0.379} stopColor="#AEAEAE" />
        <stop offset={0.533} stopColor="#CFCFCF" />
        <stop offset={0.669} stopColor="#AEAEAE" />
        <stop offset={0.774} stopColor="#B0B0B0" />
        <stop offset={0.925} stopColor="#fff" />
      </linearGradient>
    </defs>
  </svg>
);
export default SvgCardIcon;
