import * as React from 'react';
import type { SVGProps } from 'react';

interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgOtherLineIcon = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 64 64"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      stroke="currentColor"
      strokeWidth={1.5}
      d="m38.045 23.178-4.144-12.752c-.598-1.842-3.205-1.842-3.804 0l-4.144 12.752a.5.5 0 0 1-.475.346H12.07c-1.938 0-2.743 2.48-1.176 3.618l10.847 7.882a.5.5 0 0 1 .182.559L17.78 48.335c-.598 1.842 1.51 3.374 3.078 2.236l10.848-7.88a.5.5 0 0 1 .587 0l10.848 7.88c1.567 1.138 3.676-.394 3.078-2.236l-4.143-12.752a.5.5 0 0 1 .181-.56l10.848-7.88c1.567-1.14.761-3.619-1.176-3.619H38.52a.5.5 0 0 1-.476-.346Z"
    />
  </svg>
);
export default SvgOtherLineIcon;
