import * as React from 'react';
import type { SVGProps } from 'react';

interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgWhiteInfo = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <circle cx={12} cy={12} r={8.25} stroke="#FDFDFC" strokeWidth={1.5} />
    <path
      fill="#FDFDFC"
      d="M11.09 17v-6.546h1.815V17H11.09Zm.912-7.39a.986.986 0 0 1-.695-.268.87.87 0 0 1-.286-.652c0-.25.096-.464.286-.643a.978.978 0 0 1 .695-.273c.27 0 .5.09.69.273.193.179.29.393.29.643 0 .253-.097.47-.29.652a.971.971 0 0 1-.69.269Z"
    />
  </svg>
);
export default SvgWhiteInfo;
