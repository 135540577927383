import { useCallback } from 'react';
import type { FieldError } from 'react-hook-form';

import type { FieldValues } from 'react-hook-form/dist/types/fields';
import type { UseFormSetFocus } from 'react-hook-form/dist/types/form';
import CheckBox from 'src/components/CheckBox/CheckBox';
import CurrencyField from 'src/components/CurrencyField';
import { Dob } from 'src/components/Dob/Dob';
import { SSN } from 'src/components/SSN';
import { cn } from 'src/util/cn';
import { ERROR_MESSAGES } from 'src/util/sharedFieldValidations';
import { splitDOB } from 'src/util/splitDOB';

import type { FormUserField } from '../FormUserTypes';

interface IncomeInfoProps {
  className?: string;
  formValues: {
    annualIncome?: number;
    dob?: string;
    ssnOrTid?: string;
    confirmSsnOrTid?: string;
    agreement2?: boolean;
  };
  formErrors: {
    annualIncome?: FieldError;
    dob?: FieldError;
    ssnOrTid?: FieldError;
    confirmSsnOrTid?: FieldError;
    agreement2?: FieldError;
  };
  setFormValues: (field: FormUserField, value: any) => void;
  setFocus?: UseFormSetFocus<FieldValues>;
}

export const IncomeInfo = (props: IncomeInfoProps) => {
  const { className, formValues, setFormValues, formErrors, setFocus = false } = props;
  const [year, month, day] = splitDOB(formValues.dob);

  const rootStyle = cn(className, 'w-full grid grid-cols-1 gap-x-2 gap-y-3 sm:gap-y-4 sm:gap-x-8');

  const handleSetValue = useCallback(
    (field: keyof typeof props.formValues, value: any) => {
      setFormValues(field, value);
      if (setFocus && field === 'ssnOrTid') {
        setFocus('confirmSsnOrTid');
      }
    },
    [props, setFormValues, setFocus],
  );

  return (
    <div className={rootStyle}>
      <CurrencyField
        defaultValue={typeof formValues.annualIncome === 'number' ? formValues.annualIncome : 0}
        onChange={event => handleSetValue('annualIncome', event.target.value)}
        label="Before Tax Total Annual Income"
        error={formErrors.annualIncome?.message || ''}
        dollarsOnly
        required
      />
      <Dob
        year={year}
        month={month}
        day={day}
        onChange={dob => handleSetValue('dob', dob)}
        error={!!formErrors.dob}
        required
      />
      <SSN
        label="SSN/ITIN"
        onChange={value => handleSetValue('ssnOrTid', value)}
        defaultValue={formValues.ssnOrTid || ''}
        error={!!formErrors.ssnOrTid}
        errorText={formErrors.ssnOrTid?.message || ERROR_MESSAGES.ssnOrTid}
        required
      />
      <SSN
        label="Confirm SSN/ITIN"
        onChange={value => handleSetValue('confirmSsnOrTid', value)}
        defaultValue={formValues.confirmSsnOrTid || ''}
        compareToSsn={formValues.ssnOrTid}
        error={!!formErrors.confirmSsnOrTid}
        errorText={formErrors.confirmSsnOrTid?.message || ERROR_MESSAGES.confirmSsnOrTid}
        required
      />
      <div>
        <CheckBox
          text="I understand this is not a credit card application. This check will not impact your credit score. I acknowledge and authorize Pesto Mastercard&reg; to use information from my credit report to see if I am pre-qualified for a Pesto Mastercard&reg;."
          onChange={isChecked => handleSetValue('agreement2', isChecked)}
          value={formValues.agreement2}
          label="agreement2"
          error={formValues.agreement2 === false ? !!formErrors.agreement2 : undefined}
        />
        {formErrors && <div className="mt-[8px] text-sm text-danger">{formErrors.agreement2?.message}</div>}
      </div>
    </div>
  );
};
