import * as React from 'react';
import type { SVGProps } from 'react';

interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgMedium = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <g stroke="currentColor" strokeLinejoin="round" strokeWidth={1.6} clipPath="url(#medium_svg__a)">
      <path
        strokeLinecap="round"
        strokeMiterlimit={10}
        d="m23.024 1.073-4.097 4.098M5.17 18.927l-4.097 4.097M14.976 9.122l-5.854 5.854"
      />
      <path d="M19.58 4.517c.9.9.9 2.359 0 3.259l-2.536 2.537a2.304 2.304 0 0 1-3.259-3.26l2.537-2.536c.9-.9 2.359-.9 3.259 0ZM10.312 13.785c.9.9.9 2.36 0 3.26L7.776 19.58a2.304 2.304 0 0 1-3.26-3.26l2.538-2.536c.9-.9 2.359-.9 3.258 0Z" />
    </g>
    <defs>
      <clipPath id="medium_svg__a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgMedium;
