import clsx from 'clsx';
import React from 'react';

import { useIsMobile } from 'src/hooks/useIsMobile';

interface CardFlowProps {
  srcSVG?: string;
  children?: React.ReactNode;
}

export const CardFlow: React.FC<CardFlowProps> = (props: CardFlowProps) => {
  const { srcSVG, children } = props;
  const isMobile = useIsMobile();

  return (
    <div
      className={clsx('flex h-40 w-72 overflow-hidden rounded-lg border', {
        'w-full': isMobile,
      })}
    >
      <div className="w-25 flex-none bg-gray-200 ">
        <img className="h-full w-full object-cover" src={srcSVG} alt="choose pest flow" />
      </div>
      <div className="w-50 flex-grow bg-basicSoft">
        <div
          className={clsx('px-3', {
            '!px-1': isMobile,
          })}
        >
          {children}
        </div>
      </div>
    </div>
  );
};
