import clsx from 'clsx';
import type { ReactNode } from 'react';
import { useEffect, useState } from 'react';

import { capitalizeFirstLetter } from 'src/util/capitalize';
import { isCaratType } from 'src/util/isCaratType';

import { Typography } from '../../../../../components/Typography/Typography';
import SvgArrowIcon from '../../../../../icons/components/ArrowIcon';
import type { OptionType } from '../types';

interface DropDownSelectProps<T> {
  className?: string;
  label: string;
  onOpen?: (event: any) => void;
  icon?: ReactNode;
  children: (handleValueSelect: (value: T) => void) => ReactNode;
  isShowSelectedItem?: boolean;
  selectedItem?: T | undefined;
  options?: OptionType[] | undefined;
  liveUpdateValue?: boolean;
  isOpen?: boolean;
  alwaysOpen?: boolean;
}

interface ButtonProps {
  icon?: ReactNode;
  label: ReactNode | string;
  onClick?: () => void;
  isOpen: boolean;
  isShowSelectedItem?: boolean;
  selectedItem: OptionType | null;
}

const Button = ({
  icon,
  label,
  onClick,
  isOpen,
  isShowSelectedItem,
  selectedItem,
  alwaysOpen,
}: ButtonProps & { alwaysOpen?: boolean }) => {
  const arrowClassName = clsx(
    'transform transition-transform duration-200 ease-in-out',
    isOpen ? 'rotate-0' : 'rotate-180',
  );

  const selectedTextRender = (selectedItem: any) => {
    if (isCaratType(selectedItem)) {
      return `${selectedItem} ct`;
    } else if (typeof selectedItem === 'string') {
      return capitalizeFirstLetter(selectedItem);
    } else {
      return capitalizeFirstLetter(selectedItem.text);
    }
  };

  return (
    <button className="flex w-full max-w-full flex-row items-center justify-between gap-2" onClick={onClick}>
      <div className="flex flex-row">
        <div className="mr-[5px] flex flex-row items-center justify-center">
          {icon && <div className="mr-2">{icon}</div>}
          <Typography variant="headerMedium" className="whitespace-nowrap">
            {label}
          </Typography>
        </div>

        {isShowSelectedItem && selectedItem && (
          <div className="flex w-full flex-row items-center">
            <span className="ml-[5px] mr-[10px] h-[5px] w-[5px] rounded-full bg-neutral" />
            <Typography className="w-20 truncate text-left font-light text-neutral sm:w-full" variant="bodyLarge">
              {selectedTextRender(selectedItem)}
            </Typography>
          </div>
        )}
      </div>

      {!alwaysOpen && (
        <div>
          <SvgArrowIcon className={arrowClassName} />
        </div>
      )}
    </button>
  );
};

const DropdownSelect = <T,>(props: DropDownSelectProps<T>) => {
  const {
    className,
    label,
    onOpen,
    icon,
    children,
    isShowSelectedItem,
    options,
    selectedItem: externalSelectedItem,
    liveUpdateValue,
    isOpen: isOpenProps = false,
    alwaysOpen = false,
  } = props;
  const [selectedItem, setSelectedItem] = useState<OptionType | null>(null);
  const [allowClose, setAllowClose] = useState(true);

  const [isOpen, setIsOpen] = useState(isOpenProps);

  const rootStyle = clsx(
    className,
    'flex bg-white flex-col items-center justify-between w-full p-5 shadow-custom rounded-sm',
  );

  useEffect(() => {
    setIsOpen(alwaysOpen || isOpenProps);
  }, [alwaysOpen, isOpenProps]);

  useEffect(() => {
    if (liveUpdateValue && externalSelectedItem) {
      setSelectedItem(externalSelectedItem as OptionType);
    } else {
      if (liveUpdateValue) {
        setSelectedItem(null);
      }
    }
  }, [externalSelectedItem, liveUpdateValue]);

  const handleValueSelect = (value: T) => {
    if (typeof value === 'string') {
      setSelectedItem(value);
    } else {
      // TODO: FIX IT
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const selectedOption = options?.find((option: OptionType) => option.value === value.value) ?? null;
      setSelectedItem(selectedOption);
    }

    if (!alwaysOpen && allowClose) {
      setIsOpen(false);
    }
  };

  return (
    <div className={rootStyle}>
      <Button
        label={<div className="text-xl font-normal text-basicSoft">{label}</div>}
        icon={icon}
        onClick={() => {
          if (!alwaysOpen) {
            onOpen?.(label);
            setIsOpen(!isOpen);
          }
        }}
        isOpen={isOpen}
        isShowSelectedItem={alwaysOpen ? false : isShowSelectedItem}
        selectedItem={selectedItem}
        alwaysOpen={alwaysOpen}
      />
      {isOpen && (
        <div className="mt-3 w-full border-t border-neutralSoft pt-4">
          {children((value: T) => {
            setAllowClose(false);
            handleValueSelect(value);
            setAllowClose(true);
          })}
        </div>
      )}
    </div>
  );
};

export default DropdownSelect;
