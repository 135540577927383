import TextLink, { LinkType } from 'src/components/TextLink/TextLink';
import type { AccordionItem } from 'src/pages/landing/components/Accordion';

export const accordionItems: AccordionItem[] = [
  {
    title: 'Can you guarantee my valuables are safe and will they be insured?',
    content: (
      <>
        <div>
          Any item you send us is fully insured during shipping, both ways, to and from you. You can get notifications
          at every step of the shipping process to know where your item is at all times. If your item is accepted as
          security for a Pesto Mastercard®, it will be held at a central repository in secure storage.
        </div>
        <div>
          Your item will also always be insured while it’s held as security for your credit card. You can get your item
          back anytime. Simply close your account in good standing (fully paid off) and provide your return shipping
          address to arrange for its secure return delivery.
        </div>
      </>
    ),
  },

  {
    title: 'How do I ship my item to you and what are my shipping options?',
    content: (
      <>
        <div>
          Pesto Mastercard® offers multiple free, convenient, and fully insured shipping options for your valuables once
          an offer is accepted.
        </div>
        <div>
          The first option is a customer favorite where we send you a personalized shipping kit. This shipping kit will
          include all the shipping materials you need to securely package the item yourself and safely ship it directly
          to us.
        </div>
        <div>
          The second option is a “Do it Yourself” (DIY) option where you can receive a prepaid UPS shipping label
          immediately by email. This will have instructions on how to package and ship your item to Pesto Mastercard®.
          From there you can securely pack your item according to the provided instructions and drop your package off at
          any UPS Store.
        </div>
      </>
    ),
  },
  {
    title: 'Is Pesto Mastercard® a secured credit card?',
    content: (
      <div>
        Yes, Pesto Mastercard® is a secured credit card, backed by either an asset, like jewelry, gemstones or precious
        metals, or it can be backed by cash. Each customer chooses the option that works best for them.
      </div>
    ),
  },
  {
    title: 'How is Pesto Mastercard® different from other credit cards?',
    content: (
      <>
        <div>
          There are two main types of credit cards: unsecured and secured. An unsecured credit card is approved based on
          your credit history and credit score. If you don’t qualify for an unsecured credit card, you might qualify for
          a secured credit card.
        </div>
        <div>
          Secured credit cards require that you provide “security” for the credit, usually in the form of a cash
          deposit. If you cannot pay the outstanding balance, the creditor can keep the security.
        </div>
        <div>
          So, if you want to spend $500 on a secured credit card, you must first provide $500 to the bank. You are
          effectively using your own money, but secured credit cards are great tools to help you build your credit
          history, which, over time, can help you improve your credit score.
        </div>
        <div>
          Unfortunately, most people who need credit do not have cash for security. That’s why Pesto Mastercard® accepts
          valuables, such as jewelry, as security for credit.
        </div>
      </>
    ),
  },
  {
    title: 'Is this a pawn loan?',
    content: (
      <>
        <div>Pesto Mastercard® is a credit card. It is not a pawn loan, but it is an alternative to pawn loans.</div>
        <div>
          Instead of taking your valuables to a pawn shop to get a pawn loan, you can qualify for a Pesto Mastercard® by
          using your valuable items as security for your credit card.
        </div>
      </>
    ),
  },
  {
    title: 'How much credit can I get for my valuables?',
    content: (
      <>
        <div>The amount of credit you can get depends on the value of your item.</div>
        <div>
          You can get an initial estimate online using{' '}
          <TextLink as={LinkType.RouterLink} to={'/'}>
            our calculator
          </TextLink>
          . If your item qualifies for credit, the exact credit limit will be determined after qualified professionals
          have evaluated your item and you’ve completed the application.
        </div>
      </>
    ),
  },
];
