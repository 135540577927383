import * as React from 'react';
import type { SVGProps } from 'react';

interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgCloseWhite = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 14 14"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="#fff"
      d="M1.873.813a.75.75 0 1 0-1.06 1.06l4.773 4.774-5.127 5.126a.75.75 0 0 0 1.06 1.06l5.127-5.126 5.48 5.48a.75.75 0 0 0 1.061-1.06l-5.48-5.48 5.127-5.127A.75.75 0 0 0 11.773.46L6.646 5.585 1.873.813Z"
    />
  </svg>
);
export default SvgCloseWhite;
