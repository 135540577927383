import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import cardValidator from 'card-validator';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import * as yup from 'yup';

import noop from 'lodash/noop';

import { AnalyticsEvent } from '../../providers/analytics/AnalyticsEvents';
import { useAnalytics } from '../../providers/analytics/AnalyticsProvider';
import { replaceNonDigits } from '../CashSecuredFlow/screens/Payment/PaymentValidationSchema';
import { CardInputForm } from '../CashSecuredFlow/screens/Payment/components/CardInputForm';

import type { IFrameMessage, OutgoingMessage } from './PaymentFrame.types';
import { IframeFunc } from './PaymentFrame.types';

const PINLESS_DEBIT = 'PinlessDebit';

interface UsioGenerateTokenPayload {
  merchantKey: string;
  paymentType: string;
  emailAddress: string;
  cardNumber?: string;
  expDate?: string;
  cvv?: string;
  routingNumber?: string;
  accountNumber?: string;
  achAccountType?: string;
}

interface UsioGenerateTokenResponse {
  status: string;
  paymentType: string;
  token: string;
  message: string;
  last4: string;
  expDate: string;
  cardBrand: string;
  accountType: string;
  emailAddress: string;
}

enum PaymentStatus {
  Success = 'success',
  Failure = 'failure',
}

interface CreditCardForm {
  cardNumber: string;
  expirationDate: string;
  cvc: string;
}

const validateCardDate = (value: string) => {
  const month = value.slice(0, 2);
  const year = value.slice(2, 4);
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth() + 1;
  const currentYear = currentDate.getFullYear() % 100;
  if (Number(month) > 12) {
    return false;
  }
  if (Number(year) < currentYear) {
    return false;
  }
  return !(Number(year) === currentYear && Number(month) < currentMonth);
};
export const CreditCardValidation = yup.object().shape({
  cardNumber: yup
    .string()
    .required('Card number is required')
    .transform(replaceNonDigits)
    .test('validateCardNumber', 'Invalid card number', cardNumber => {
      const validation = cardValidator.number(cardNumber);
      const cardNumberLengths = validation.card?.lengths;
      if (cardNumberLengths) {
        return cardNumberLengths.includes(cardNumber.length);
      }
      return cardNumber.length === 16;
    }),
  expirationDate: yup
    .string()
    .required('Expiration date is required')
    .transform(replaceNonDigits)
    .min(4, 'Please enter a valid expiration date')
    .test('validateCardDate', 'Invalid expiration date', validateCardDate),
  cvc: yup
    .string()
    .required('CVC is required')
    .transform(replaceNonDigits)
    .test('validateCVC', 'Invalid CVC', (value, context) => {
      const cardNumber = context.parent.cardNumber;
      const cardType = cardValidator.number(cardNumber).card?.type;
      if (cardType === 'american-express') {
        return value.length === 4;
      }
      return value.length === 3;
    }),
});

export const PaymentFrame = () => {
  const [renderKey, setRenderKey] = useState(0);
  const cardWrapperRef = useRef<HTMLDivElement>(null);
  const {
    register,
    setValue,
    setFocus,
    watch,
    reset,
    trigger,
    handleSubmit,
    formState: { errors, isSubmitted, isSubmitting, isValidating, isLoading },
  } = useForm<CreditCardForm>({
    mode: 'all',
    resolver: yupResolver(CreditCardValidation),
    defaultValues: {
      cardNumber: '',
      expirationDate: '',
      cvc: '',
    },
  });
  const [params] = useSearchParams();
  const displayPaymentFrame = Boolean(params.get('paymentScreen')) || false;

  const formData = watch();
  useEffect(() => {
    // revalidate form on change after submit
    if (isSubmitted) {
      trigger();
    }
  }, [isSubmitted, trigger, formData.cvc, formData.cardNumber, formData.expirationDate]);

  useEffect(() => {
    // communicate the height of the card form to the parent
    const cardFormHeight = cardWrapperRef.current?.offsetHeight;
    handleSendMsg({
      message: {
        cardFormHeight: cardFormHeight,
      },
    });
  }, [formData, isValidating, isSubmitting, isLoading]);

  const clearFormData = useCallback(() => {
    reset({
      cardNumber: '',
      expirationDate: '',
      cvc: '',
    });
    setRenderKey(renderKey + 1);
  }, [renderKey, reset]);

  const { analytics } = useAnalytics();

  const handleModalState = useCallback((isOpen: boolean, status: PaymentStatus.Success | PaymentStatus.Failure) => {
    handleSendMsg({
      message: {
        modalState: {
          isOpen: true,
          status: status === PaymentStatus.Success ? 'success' : 'error',
        },
      },
    });
  }, []);
  const analyticEvent = useCallback(
    (status: PaymentStatus.Success | PaymentStatus.Failure) => {
      const rudderEvent =
        status === PaymentStatus.Success ? AnalyticsEvent.Cash.PAYMENT_SUCCESSFUL : AnalyticsEvent.Cash.PAYMENT_FAILED;
      analytics.event(rudderEvent, {
        payment_type: 'debit',
        payment_status: status,
      });
    },
    [analytics],
  );

  const generateToken = useCallback(
    (email: string, userId: string) => {
      const url = 'https://checkout.usiopay.com/2.0/GenerateToken';
      const expirationDate = formData.expirationDate.slice(0, 2) + '20' + formData.expirationDate.slice(3, 5);
      const data: UsioGenerateTokenPayload = {
        emailAddress: email || '',
        merchantKey: process.env.REACT_APP_USIO_MERCHANT_KEY || '',
        paymentType: PINLESS_DEBIT,
        cardNumber: replaceNonDigits(formData.cardNumber),
        expDate: expirationDate,
        cvv: formData.cvc,
      };

      const handleError = (message: OutgoingMessage) => {
        console.error('Error generating token CashFLow ' + userId, message);
        handleModalState(true, PaymentStatus.Failure);
        analyticEvent(PaymentStatus.Failure);
        return responseMsg({
          message: {
            ...message,
            modalState: {
              isOpen: true,
              status: 'error',
            },
          },
        });
      };

      const generateTokenUsio = axios
        .post<UsioGenerateTokenResponse>(url, data)
        .then(res => {
          return res.data;
        })
        .catch(err => {
          console.error(err.response?.data?.message || 'Something went wrong. Please try again later.');
          analyticEvent(PaymentStatus.Failure);
          handleModalState(true, PaymentStatus.Failure);
        });

      generateTokenUsio
        .then(res => {
          const token = res?.token ? res?.token : '';
          const cardBrand = res?.cardBrand ? res?.cardBrand : '';
          const expDate = formData.expirationDate.slice(0, 2) + '20' + formData.expirationDate.slice(3, 5);
          const firstSix = replaceNonDigits(formData.cardNumber).slice(0, 6);
          const lastFour = replaceNonDigits(formData.cardNumber).slice(-4);
          const message = res?.message ? res?.message : 'Error Generating token.';

          const messageResponse: OutgoingMessage = {
            token: token,
            cardBrand: cardBrand,
            expDate: expDate,
            firstSix: firstSix,
            lastFour: lastFour,
            errorMessage: message,
          };

          if (!token) {
            handleModalState(true, PaymentStatus.Failure);
            return handleError(messageResponse);
          }
          clearFormData();
          responseMsg({
            message: {
              token: token,
              cardBrand: cardBrand,
              expDate: expDate,
              firstSix: firstSix,
              lastFour: lastFour,
              errorMessage: message,
            },
          });
        })
        .catch(res => {
          handleError(res?.message || 'Error generating token.');
        });
    },
    [analyticEvent, clearFormData, formData.cardNumber, formData.cvc, formData.expirationDate, handleModalState],
  );

  const handleListener = useCallback(
    (event: MessageEvent) => {
      const data: IFrameMessage = event.data;
      const getToken = () => generateToken(data.message.email || '', data.message.userId || '');
      const setLoadingFalse = () =>
        responseMsg({
          message: {
            modalState: {
              isOpen: false,
              status: 'loading',
            },
          },
        });

      if (data.message?.func === IframeFunc.clear) {
        clearFormData();
        return;
      }
      if (data.message?.func === IframeFunc.getToken) {
        handleSubmit(getToken, setLoadingFalse)();
        return;
      }
    },
    [clearFormData, generateToken, handleSubmit],
  );
  const handleSendMsg = (data: IFrameMessage) => {
    return window.parent.postMessage(data, window.location.origin);
  };

  const responseMsg = (data: IFrameMessage) => {
    return window.parent.postMessage(
      {
        message: {
          ...data.message,
          response: true,
        },
      },
      window.location.origin,
    );
  };

  const handleDataChange = useCallback(
    (field: any, value: any) => {
      setValue(field, value);
    },
    [setValue],
  );
  useEffect(() => {
    window.addEventListener('message', handleListener);
    return () => {
      window.removeEventListener('message', handleListener);
    };
  }, [handleListener]);

  if (!displayPaymentFrame) return null;

  return (
    <div ref={cardWrapperRef}>
      <CardInputForm
        // this is a hack to make the cardNumber reset
        key={'cardInputFormKey' + renderKey}
        formData={formData}
        setFormData={handleDataChange}
        onCardTypeChange={noop}
        errors={errors}
        register={register}
        setFocus={setFocus}
      />
    </div>
  );
};
