import { useCallback, useMemo, useState, type SetStateAction } from 'react';

import {
  DiamondType,
  GetItemCartDocument,
  StoneType,
  useAddItemAndDetailsMutation,
  type Cut,
  type DiamondInput,
} from 'src/__generated__/graphql/api';

import { apolloClient } from '../../../../../../api/Apollo';
import { CARAT_MULTIPLIER } from '../../../../../../constants/application';
import { QUICK_APPRAISAL_LS_KEY } from '../../../../../../constants/localStorageKeys';
import { useGoRoutes } from '../../../../../../hooks/useGoRoutes';
import { useUser } from '../../../../../../hooks/useUser';
import ImageDetailsForm from '../../components/ImageDetailsForm/ImageDetailsForm';

import GemstoneDetails from './GemstoneDetails';
import NewDiamondUserBlocked from './NewDiamondUserBlocked';
import StoneDetails from './StoneDetails';
import TypeOfDiamond from './TypeOfDiamond';
import TypeOfStones from './TypeOfStones';
import type { CaratWeight, ImageFiles, SetItemType, StoneInfo } from './types';

interface GemstoneProps {
  setItemType: SetItemType;
}

interface GemstonePayload extends StoneInfo {
  diamondCarat?: CaratWeight;
  gemstoneCarat?: CaratWeight;
  photos?: ImageFiles;
}

const DIAMOND_SCREENS = {
  TYPE_OF_STONES: 0,
  STONE_DETAILS: 1,
  TYPE_OF_DIAMOND: 2,
  GEMSTONE_DETAILS: 3,
  MORE_DETAILS: 4,
  NEW_DIAMOND_USER_BLOCKED: 5,
};

export const Gemstone = ({ setItemType }: GemstoneProps) => {
  const [innerScreen, setInnerScreen] = useState<number>(0);
  const [, setPreviousScreen] = useState<number>(0);
  const [payload, setPayload] = useState<GemstonePayload>({
    fabric: undefined,
    cut: undefined,
    diamondCarat: undefined,
    gemstoneCarat: undefined,
    diamondType: undefined,
    gemstoneType: undefined,
    photos: undefined,
  });

  const { goToSignUp, gotoItemCart, goBack } = useGoRoutes();
  const { user } = useUser();

  const prepareDiamondInput = (payload: GemstonePayload) => {
    let diamondType: DiamondType | undefined;

    if (payload.fabric === StoneType.DiamondLabGrown) {
      diamondType = DiamondType.LabGrown;
    } else if (payload.fabric === StoneType.DiamondNatural) {
      diamondType = DiamondType.Natural;
    } else {
      diamondType = payload.diamondType;
    }

    const diamondInput: Partial<DiamondInput> = {
      cut: payload.cut,
      carat: Math.round((payload?.diamondCarat || 0) * CARAT_MULTIPLIER),
      diamondType,
    };

    return diamondInput;
  };

  const prepareGemstoneInput = (payload: GemstonePayload) => {
    const gemstoneInput = {
      gemstoneType: payload.fabric,
      carat: Math.round((payload?.gemstoneCarat || 0) * CARAT_MULTIPLIER),
    };

    return gemstoneInput;
  };

  const [addItemsAndDetailsMutation, { loading }] = useAddItemAndDetailsMutation({
    refetchQueries: [{ query: GetItemCartDocument }],
    errorPolicy: 'all',
    client: apolloClient,
  });

  const setScreen = useCallback(
    (newScreen: SetStateAction<number>) => {
      setPreviousScreen(innerScreen);
      setInnerScreen(newScreen);
    },
    [innerScreen],
  );

  const handleBack = useCallback(() => {
    const screenMap = {
      1: DIAMOND_SCREENS.TYPE_OF_STONES,
      2: DIAMOND_SCREENS.TYPE_OF_STONES,
      3: DIAMOND_SCREENS.TYPE_OF_STONES,
      4:
        payload.fabric === StoneType.DiamondUnknown
          ? DIAMOND_SCREENS.TYPE_OF_DIAMOND
          : payload.fabric === StoneType.Ruby ||
            payload.fabric === StoneType.Sapphire ||
            payload.fabric === StoneType.Emerald
          ? DIAMOND_SCREENS.GEMSTONE_DETAILS
          : DIAMOND_SCREENS.STONE_DETAILS,
      5: DIAMOND_SCREENS.TYPE_OF_STONES,
      default: DIAMOND_SCREENS.TYPE_OF_STONES,
    };

    setInnerScreen(prevInnerScreen => {
      const mappedValue = screenMap[prevInnerScreen as keyof typeof screenMap];

      if (mappedValue === undefined) {
        setTimeout(() => setItemType(undefined), 0);
        return screenMap['default'];
      }

      return mappedValue;
    });
  }, [payload.fabric, setItemType]);

  const handleTypeOfStone = useCallback(
    (fabric: StoneType) => {
      const screenMap = {
        [StoneType.DiamondNatural]: DIAMOND_SCREENS.STONE_DETAILS,
        [StoneType.DiamondLabGrown]: DIAMOND_SCREENS.STONE_DETAILS,
        [StoneType.DiamondUnknown]: DIAMOND_SCREENS.TYPE_OF_DIAMOND,
        [StoneType.Ruby]: DIAMOND_SCREENS.GEMSTONE_DETAILS,
        [StoneType.Sapphire]: DIAMOND_SCREENS.GEMSTONE_DETAILS,
        [StoneType.Emerald]: DIAMOND_SCREENS.GEMSTONE_DETAILS,
      };

      setPayload(prevPayload => ({ ...prevPayload, fabric }));
      setScreen(screenMap[fabric as keyof typeof screenMap] || DIAMOND_SCREENS.TYPE_OF_STONES);
    },
    [setScreen],
  );

  const handleStoneDetails = useCallback(
    (cut: Cut, diamondCarat: number) => {
      setScreen(4);
      setPayload(prevPayload => ({ ...prevPayload, cut, diamondCarat }));
    },
    [setScreen],
  );

  const handleTypeOfDiamond = useCallback(
    (diamondType: DiamondType) => {
      const diamondTypePayload = {
        ...payload,
        diamondType,
        skipMutation: true,
      };
      if (!user) {
        const addToLocalStorage = () => {
          localStorage.setItem(QUICK_APPRAISAL_LS_KEY, JSON.stringify({ ...diamondTypePayload }));
          goToSignUp();
        };
        return addToLocalStorage();
      }
      return setScreen(5);
    },

    [goToSignUp, payload, setScreen, user],
  );

  const handleGemstoneDetails = useCallback(
    (gemstoneCarat: number) => {
      setScreen(4);
      setPayload(prevPayload => ({ ...prevPayload, gemstoneCarat }));
    },
    [setScreen],
  );

  const handlePayload = useCallback(
    (images: string[] | undefined) => {
      const diamondInputPayload = prepareDiamondInput(payload);
      const gemstoneInputPayload = prepareGemstoneInput(payload);

      const mainPayload: any = {
        photoLinks: images,
      };

      if (diamondInputPayload?.carat && diamondInputPayload.carat > 0) {
        mainPayload.diamondInput = diamondInputPayload;
      } else if (gemstoneInputPayload?.carat && gemstoneInputPayload.carat > 0) {
        mainPayload.gemstoneInput = gemstoneInputPayload;
      }

      if (!diamondInputPayload?.carat && !diamondInputPayload?.cut) {
        delete mainPayload.diamondInput?.carat;
        delete mainPayload.diamondInput?.cut;
      }

      if (!user) {
        localStorage.setItem(QUICK_APPRAISAL_LS_KEY, JSON.stringify({ ...mainPayload }));
        goToSignUp();
      } else {
        addItemsAndDetailsMutation({
          variables: { ...mainPayload, creditApplicationId: user?.creditApplication?.id || '' },
        })
          .then(res => {
            if (res.errors) {
              console.error(res.errors);
            } else {
              gotoItemCart();
            }
          })
          .catch(error => {
            console.error(error);
          });
      }
    },
    [addItemsAndDetailsMutation, goToSignUp, gotoItemCart, payload, user],
  );

  const renderScreens = useMemo(
    () => ({
      [DIAMOND_SCREENS.TYPE_OF_STONES]: (
        <TypeOfStones
          onNext={handleTypeOfStone}
          handleBack={() => {
            goBack();
            setItemType(undefined);
          }}
        />
      ),
      [DIAMOND_SCREENS.STONE_DETAILS]: <StoneDetails onNext={handleStoneDetails} handleBack={handleBack} />,
      [DIAMOND_SCREENS.TYPE_OF_DIAMOND]: <TypeOfDiamond onNext={handleTypeOfDiamond} handleBack={handleBack} />,
      [DIAMOND_SCREENS.GEMSTONE_DETAILS]: <GemstoneDetails handleBack={handleBack} onNext={handleGemstoneDetails} />,
      [DIAMOND_SCREENS.MORE_DETAILS]: (
        <ImageDetailsForm
          listItems={['Your diamond/gemstones', 'Certification', 'Appraisal']}
          loadingMutation={loading}
          onNext={handlePayload}
          onBack={handleBack}
        />
      ),
      [DIAMOND_SCREENS.NEW_DIAMOND_USER_BLOCKED]: <NewDiamondUserBlocked onButtonClick={setInnerScreen} />,
    }),
    [
      handleTypeOfStone,
      handleStoneDetails,
      handleBack,
      handleTypeOfDiamond,
      handleGemstoneDetails,
      loading,
      handlePayload,
      goBack,
      setItemType,
    ],
  );

  return <>{renderScreens[innerScreen as keyof typeof renderScreens]}</>;
};
