import { useState } from 'react';

import { OTHER } from '../../../../../constants/application';
import DropdownSelect from '../components/DropDownSelect';
import DropdownContent from '../components/DropdownContent';

export const necklaceLengthList = [
  { value: 14, text: '14"' },
  { value: 16, text: '16"' },
  { value: 18, text: '18"' },
  { value: 20, text: '20"' },
  { value: 22, text: '22"' },
  { value: 24, text: '24"' },
  { value: 30, text: '30"' },
  { value: 36, text: '36"' },
  { value: 0, text: OTHER },
];

interface NecklaceLengthDropdownProps {
  onChange: (value: number) => void;
  isOpen?: boolean;
  alwaysOpen?: boolean;
}

const NecklaceLengthDropdown = ({ onChange, isOpen = false, alwaysOpen }: NecklaceLengthDropdownProps) => {
  const [selectedNecklaceLength, setSelectedNecklaceLength] = useState<number>();

  const handleChange = (value: number) => {
    setSelectedNecklaceLength(value);
    onChange(value);
  };

  return (
    <DropdownSelect
      icon={null}
      label={'Common lengths'}
      options={necklaceLengthList}
      isOpen={isOpen}
      alwaysOpen={alwaysOpen}
    >
      {(handleValueSelect: (value: number) => void) => (
        <div>
          <DropdownContent
            alwaysOpen
            selectedValue={selectedNecklaceLength}
            options={necklaceLengthList}
            onChange={value => {
              handleValueSelect(value);
              handleChange(value);
            }}
          />
        </div>
      )}
    </DropdownSelect>
  );
};

export default NecklaceLengthDropdown;
