import { useState } from 'react';

import logger from '../util/logger';

import { useOnMount } from './useOnMount';

export const useSocure = () => {
  const [deviceSessionId, setDeviceSessionId] = useState<string | null>(null);
  const getDeviceSessionId = () => {
    if ('devicer' in window) {
      try {
        const devicer = window.devicer as any;
        devicer.run(
          {
            publicKey: process.env.REACT_APP_SOCURE_SDK_KEY,
            userConsent: true,
            context: 'signup',
          },
          function (response: any) {
            logger.warn('response from socure', response);
            response?.sessionId && setDeviceSessionId(response.sessionId);
          },
        );
      } catch (error) {
        console.error('Error acquiring secure session id:', error);
      }
    }
  };
  useOnMount(() => {
    getDeviceSessionId();
  });

  return deviceSessionId;
};
