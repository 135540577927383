import React from 'react';

import { type CreditApplicationStatus, SecurityType } from 'src/__generated__/graphql/api';
import { loginRoutes } from 'src/constants/routes';
import { getURLPathForUserApplicationFlowStatus, getURLPathForUserCashFlowStatus } from 'src/util/navigationHelper';

interface URLContextState {
  getUrlOnStatus: (securityType: SecurityType, status: CreditApplicationStatus) => string;
}

export const URLContext = React.createContext<URLContextState>({} as URLContextState);

export const URLProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const getUrlOnStatus = (securityType: SecurityType, status: CreditApplicationStatus) => {
    const redirectUrl = () => {
      if (securityType === SecurityType.Cash) {
        return getURLPathForUserCashFlowStatus(status);
      }

      if (securityType === SecurityType.Item) {
        return getURLPathForUserApplicationFlowStatus(status);
      }

      return loginRoutes.chooseFlow;
    };
    return redirectUrl();
  };

  return <URLContext.Provider value={{ getUrlOnStatus }}>{children}</URLContext.Provider>;
};
