import { useEffect, useState } from 'react';
import useScript from 'react-script-hook';

export const useHubspotChat = () => {
  const [showChat, setShowChat] = useState(false);
  useEffect(() => {
    const isEmbeddedInIframe = window.self !== window.top;

    if (isEmbeddedInIframe) {
      setShowChat(false);
    } else {
      setShowChat(true);
    }
  }, []);
  useScript({
    src: showChat ? 'https://js.hs-scripts.com/23749842.js' : '',
    checkForExisting: true,
  });
};
