import * as React from 'react';
import type { SVGProps } from 'react';

interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgHighlightedSheet = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 121 120"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path fill="#E8F4FA" stroke="#DAEDF7" strokeWidth={0.96} d="M7.5 60a48 48 0 1 0 96 0 48 48 0 0 0-96 0Z" />
    <path
      fill="#CFDADE"
      d="M31.5 97c0 .263.427.523 1.256.765.83.243 2.045.463 3.577.65 1.532.185 3.35.332 5.353.433 2.002.1 4.147.152 6.314.152s4.312-.052 6.314-.152a78.03 78.03 0 0 0 5.353-.434c1.532-.186 2.748-.406 3.577-.649.83-.242 1.256-.502 1.256-.765s-.427-.523-1.256-.765c-.83-.243-2.045-.463-3.577-.65a77.967 77.967 0 0 0-5.353-.433C52.312 95.052 50.167 95 48 95s-4.312.052-6.314.152-3.821.248-5.353.434c-1.532.186-2.748.406-3.577.649-.83.242-1.256.502-1.256.765Z"
    />
    <path
      fill="#CFDADE"
      d="M51.5 96.5c0 .197.375.392 1.104.574.728.182 1.797.347 3.143.487 1.346.139 2.945.25 4.704.325 1.76.075 3.645.114 5.549.114 1.904 0 3.79-.039 5.549-.114a80.143 80.143 0 0 0 4.704-.325c1.347-.14 2.415-.305 3.143-.487.729-.182 1.104-.377 1.104-.574 0-.197-.375-.392-1.104-.574-.728-.182-1.796-.347-3.143-.487a80.057 80.057 0 0 0-4.704-.325C69.789 95.04 67.904 95 66 95c-1.904 0-3.79.039-5.549.114-1.76.076-3.358.186-4.704.325-1.346.14-2.415.305-3.143.487-.729.182-1.104.377-1.104.574Z"
    />
    <path
      fill="#fff"
      d="M22.028 25.604a1.757 1.757 0 0 1 1.752-1.758h35.15L72.99 37.904v47.687a1.755 1.755 0 0 1-1.757 1.758H23.78a1.756 1.756 0 0 1-1.757-1.758l.005-59.987Z"
    />
    <path
      fill="#FFF5EB"
      d="M71.231 83.358H23.78a1.756 1.756 0 0 1-1.752-1.758v3.99a1.757 1.757 0 0 0 1.752 1.76H71.23a1.756 1.756 0 0 0 1.757-1.759V81.6a1.755 1.755 0 0 1-1.757 1.758Z"
    />
    <path
      fill="#FFE500"
      d="M58.282 63.26a1.458 1.458 0 0 1-1.302.804H42.427a1.456 1.456 0 0 1-1.301-2.106l1.454-2.91a1.457 1.457 0 0 1 1.302-.805h14.554a1.455 1.455 0 0 1 1.3 2.106l-1.454 2.91ZM65.558 79.996a1.453 1.453 0 0 1-1.3.804H49.7a1.455 1.455 0 0 1-1.302-2.106l1.456-2.91a1.459 1.459 0 0 1 1.302-.806h14.556a1.456 1.456 0 0 1 1.302 2.106l-1.456 2.912Z"
    />
    <path
      stroke="#45413C"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.96}
      d="M22.028 25.604a1.757 1.757 0 0 1 1.752-1.758h35.15L72.99 37.904v47.687a1.755 1.755 0 0 1-1.757 1.758H23.78a1.756 1.756 0 0 1-1.757-1.758l.005-59.987Z"
    />
    <path
      fill="#E8F4FA"
      stroke="#45413C"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.96}
      d="M72.988 37.904 58.93 23.846v12.302a1.757 1.757 0 0 0 1.757 1.756h12.301Z"
    />
    <path
      stroke="#627B8C"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.96}
      d="M31.459 36.944h19.7M31.459 45.145h29.16M31.459 53.513h32.772M31.459 61.517h32.772M31.459 69.886h32.772M31.459 77.89h32.772"
    />
    <path
      fill="#FFE500"
      d="m68.268 69.9-4.33 4.328a1.398 1.398 0 0 0 .546 2.314l2.964.988a1.397 1.397 0 0 0 1.43-.338l3.341-3.34-3.951-3.952ZM90 69.781a1.429 1.429 0 0 1-1.007.474 1.409 1.409 0 0 1-1.027-.415l-.256-.256a1.455 1.455 0 0 1-.412-1.238c.225-1.56-1.875-5-5.2-8.326-3.326-3.325-6.77-5.424-8.326-5.2a1.46 1.46 0 0 1-1.237-.41l-.257-.258a1.385 1.385 0 0 1 .06-2.04l26.177-22.615a4.2 4.2 0 0 1 5.785.143l8.18 8.18a4.194 4.194 0 0 1 1.226 2.867 4.195 4.195 0 0 1-1.088 2.922L90.001 69.781Z"
    />
    <path
      fill="#FFF48C"
      d="m76.44 55.613 26.18-22.621a4.2 4.2 0 0 1 4.2-.84l-2.52-2.52a4.2 4.2 0 0 0-5.789-.138L72.338 52.118a1.44 1.44 0 0 0-.48 1.007 1.418 1.418 0 0 0 .415 1.027l.257.257a1.459 1.459 0 0 0 1.237.412c.946.007 1.87.283 2.666.797l.008-.005Z"
    />
    <path
      fill="#656769"
      d="M87.71 69.586a1.456 1.456 0 0 1-.41-1.24c.225-1.56-1.88-5.003-5.202-8.326-3.322-3.322-6.766-5.427-8.325-5.2a1.456 1.456 0 0 1-1.24-.412l-.314-.314-.989.987a1.4 1.4 0 0 0 0 1.977l13.832 13.83a1.395 1.395 0 0 0 1.975 0l.989-.988-.316-.314Z"
    />
    <path
      fill="#656769"
      d="m72.22 58.045-2.96 8.891-.988.988a1.395 1.395 0 0 0 0 1.976l3.952 3.952a1.396 1.396 0 0 0 1.975 0l.988-.988 8.892-2.964-11.86-11.855Z"
    />
    <path fill="#EBCB00" d="m72.22 73.852-3.346 3.34a1.379 1.379 0 0 1-1.422.336l-1.32-.438 4.649-4.706 1.438 1.468Z" />
    <path
      stroke="#45413C"
      strokeWidth={0.96}
      d="m68.268 69.9-4.33 4.328a1.398 1.398 0 0 0 .546 2.314l2.964.988a1.397 1.397 0 0 0 1.43-.338l3.341-3.34-3.951-3.952Z"
    />
    <path
      stroke="#45413C"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.96}
      d="M90 69.781a1.429 1.429 0 0 1-1.007.474 1.409 1.409 0 0 1-1.027-.415l-.256-.256a1.455 1.455 0 0 1-.412-1.238c.225-1.56-1.875-5-5.2-8.326-3.326-3.325-6.77-5.424-8.326-5.2a1.46 1.46 0 0 1-1.237-.41l-.257-.258a1.385 1.385 0 0 1 .06-2.04l26.177-22.615a4.2 4.2 0 0 1 5.785.143l8.18 8.18a4.194 4.194 0 0 1 1.226 2.867 4.195 4.195 0 0 1-1.088 2.922L90.001 69.781Z"
    />
    <path
      fill="#525252"
      d="m84.074 69.9-8.892 2.964-.987.988a1.397 1.397 0 0 1-1.976 0l-1.731-1.732a1.403 1.403 0 0 0 1.976 0l.988-.989 8.888-2.958 1.734 1.727Z"
    />
    <path
      stroke="#45413C"
      strokeWidth={0.96}
      d="m72.22 58.045-2.96 8.891-.988.988a1.395 1.395 0 0 0 0 1.976l3.952 3.952a1.396 1.396 0 0 0 1.975 0l.988-.988 8.892-2.964-11.86-11.855Z"
    />
    <path
      fill="#525252"
      d="m83.33 69.157 1.731 1.73a1.395 1.395 0 0 0 1.976 0l.988-.987-.315-.314a1.458 1.458 0 0 1-.41-1.24 2.678 2.678 0 0 0-.113-1.068l-1.882 1.882a1.402 1.402 0 0 1-1.976 0"
    />
    <path
      stroke="#45413C"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.96}
      d="M87.71 69.586a1.456 1.456 0 0 1-.41-1.24c.225-1.56-1.88-5.003-5.202-8.326-3.322-3.322-6.766-5.427-8.325-5.2a1.456 1.456 0 0 1-1.24-.412l-.314-.314-.989.987a1.4 1.4 0 0 0 0 1.977l13.832 13.83a1.395 1.395 0 0 0 1.975 0l.989-.988-.316-.314Z"
    />
  </svg>
);
export default SvgHighlightedSheet;
