import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { AuthProvider } from 'src/auth/AuthProvider';
import { AnalyticsProvider } from 'src/providers/analytics/AnalyticsProvider';

import './App.css';
import { ApolloProviderWrapper } from './api/Apollo';
import { AdminDebugger } from './components/AdminDebugger/AdminDebugger';
import { ReactAppEnvIsProd } from './constants/global';
import ErrorBoundary from './providers/ErrorBoundary';
import { ExternalScriptsLoadProvider } from './providers/ExternalScriptsLoadProvider';
import { ModalProvider } from './providers/ModalProvider';
import { SessionExpiredProvider } from './providers/SessionExpiredProvider';
import { URLProvider } from './providers/URLProvider';
import { RouterProvider } from './router/RouterProvider';
import logger, { LogLevel } from './util/logger';

const isProd = ReactAppEnvIsProd;

const App = () => {
  logger.setLevel(isProd ? LogLevel.ERROR : LogLevel.TRACE);

  return (
    <ExternalScriptsLoadProvider>
      <SessionExpiredProvider>
        <AuthProvider>
          <ApolloProviderWrapper>
            <AnalyticsProvider>
              <URLProvider>
                <BrowserRouter>
                  <ErrorBoundary>
                    <div className="App">
                      <ToastContainer />
                      <ModalProvider>
                        <AdminDebugger />
                        <RouterProvider />
                      </ModalProvider>
                    </div>
                  </ErrorBoundary>
                </BrowserRouter>
              </URLProvider>
            </AnalyticsProvider>
          </ApolloProviderWrapper>
        </AuthProvider>
      </SessionExpiredProvider>
    </ExternalScriptsLoadProvider>
  );
};

export default App;
