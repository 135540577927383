import * as React from 'react';
import type { SVGProps } from 'react';

interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgDiamondQaIcon = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 40 50"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeWidth={1.5}
      d="M11.784 25 17.76 8.514m4.108 0L27.47 25m-16.12.767 6.41 15.72m-6.41-15.72L4.69 25m6.66.767h16.12m0 0L34.938 25m-7.47.767-5.6 15.72M1.327 25.957l17.506 22.466a1.47 1.47 0 0 0 2.332 0l17.507-22.466a1.566 1.566 0 0 0 0-1.916L21.166 1.576a1.47 1.47 0 0 0-2.332 0L1.327 24.042a1.566 1.566 0 0 0 0 1.916Z"
    />
  </svg>
);
export default SvgDiamondQaIcon;
