import type { PaymentPreference } from 'src/__generated__/graphql/api';
import { MainSelectionButton } from 'src/components/HeroSelection/MainSelectionButton/MainSelectionButton';

import cardImage from '../../assets/cardLarge.png';
import fundOverTimeImage from '../../assets/fund-over-time.png';
import { PAYMENT_PREFERENCE } from '../../helpers';

interface ButtonOptions {
  id: number;
  image: string;
  title: string;
  description: string;
  paymentOption: PaymentPreference;
}

const buttonOptions: ButtonOptions[] = [
  {
    id: 1,
    image: cardImage,
    title: 'Pay for card now',
    description: 'Your refundable deposit of $200 or more gets you spending now',
    paymentOption: PAYMENT_PREFERENCE.fundNow,
  },
  {
    id: 2,
    image: fundOverTimeImage,
    title: 'Fund over time',
    description: 'With a $20 upfront payment* you can schedule your payments at your own pace',
    paymentOption: PAYMENT_PREFERENCE.fundOverTime,
  },
];

export const ButtonOptions = ({ onOptionClick }: { onOptionClick: (option: PaymentPreference) => void }) => {
  return (
    <div className={'flex flex-col gap-6 sm:flex-row sm:justify-center'}>
      {buttonOptions.map((option, index) => {
        return (
          <MainSelectionButton
            className={'max-w-[345px]'}
            key={index}
            onClick={() => onOptionClick(option.paymentOption)}
            image={option.image}
            title={option.title}
            description={option.description}
          />
        );
      })}
    </div>
  );
};
