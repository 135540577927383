import clsx from 'clsx';
import type { ReactNode } from 'react';

import { Typography } from '../../../../../components/Typography/Typography';

interface ScreenQuickAppraisalProps {
  className?: string;
  title: string;
  children: ReactNode;
}

export const ScreenQuickAppraisal = (props: ScreenQuickAppraisalProps) => {
  const { className, children, title } = props;
  const rootStyle = clsx(className, 'flex flex-col gap-4 mx-auto h-full w-full max-w-[400px]');

  return (
    <div data-testid="ScreenQuickAppraisal" className={rootStyle}>
      <Typography variant={'headerMedium'}>{title}</Typography>
      <div className={'flex flex-col gap-4'}>{children}</div>
    </div>
  );
};
