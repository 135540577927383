import clsx from 'clsx';

import { StoneType } from 'src/__generated__/graphql/api';
import { ButtonContainer } from 'src/components/ButtonContainer/ButtonContainer';
import { BackButton } from 'src/components/UI/Button/BackButton';

import { Divider } from '../../../../../../components/Devider/Devider';
import { ListSelection } from '../../../../../../components/ListSelection/ListSelection';
import { Typography } from '../../../../../../components/Typography/Typography';
import { Emerald, None } from '../../../../../../icons/components/earrings';
import SvgLabIcon from '../../../../../../icons/components/stones/LabIcon';
import SvgNaturalIcon from '../../../../../../icons/components/stones/NaturalIcon';
import SvgRubyIcon from '../../../../../../icons/components/stones/RubyIcon';
import SvgSapphireIcon from '../../../../../../icons/components/stones/SapphireIcon';
import SvgUnknownIcon from '../../../../../../icons/components/stones/UnknownIcon';

interface EarringsGemstoneTypeScreenProps {
  className?: string;
  onNext: (stoneType: StoneType) => void;
  handleBack: () => void;
}

export const diamondTypes = [
  {
    value: StoneType.DiamondNatural,
    text: 'Natural Diamond',
    icon: <SvgNaturalIcon />,
    dataTestId: 'earrings-stone--natural-diamond',
  },
  {
    value: StoneType.DiamondLabGrown,
    text: 'Lab Grown Diamond',
    icon: <SvgLabIcon />,
    dataTestId: 'earrings-stone--lab-grown-diamond',
  },
  {
    value: StoneType.DiamondUnknown,
    text: 'Other Diamond',
    icon: <SvgUnknownIcon />,
    dataTestId: 'earrings-stone--other-diamond',
  },
];

export const gemstoneTypes = [
  {
    value: StoneType.Ruby,
    text: 'Ruby',
    icon: <SvgRubyIcon />,
    dataTestId: 'earrings-stone--ruby',
  },
  {
    value: StoneType.Sapphire,
    text: 'Sapphire',
    icon: <SvgSapphireIcon />,
    dataTestId: 'earrings-stone--sapphire',
  },
  {
    value: StoneType.Emerald,
    text: 'Emerald',
    icon: <Emerald />,
    dataTestId: 'earrings-stone--emerald',
  },
];

export const otherType = [
  {
    value: StoneType.None,
    text: 'None',
    icon: <None />,
    dataTestId: 'earrings-stone--none',
  },
];

const EarringsGemstone = (props: EarringsGemstoneTypeScreenProps) => {
  const { className, onNext, handleBack } = props;
  const rootStyles = clsx('flex flex-col gap-4 max-w-[400px] w-full', className);

  return (
    <div className={rootStyles}>
      <Typography variant={'headerMedium'}>Do your earrings have diamonds/gemstones?</Typography>
      <Divider gap={2} className="w-[70%] self-center">
        <Typography variant={'bodySmall'} className={'text-neutral'}>
          Diamonds
        </Typography>
      </Divider>
      <div className="flex w-full flex-col justify-center gap-2 text-center">
        {diamondTypes.map((type, index) => (
          <ListSelection
            key={index}
            text={type.text}
            leftIcon={type.icon}
            onClick={() => onNext(type.value)}
            dataTestId={type.dataTestId}
          />
        ))}
      </div>

      <Divider gap={2} className="w-[70%] self-center">
        <Typography variant={'bodySmall'} className={'text-neutral'}>
          Gemstones
        </Typography>
      </Divider>

      <div className="flex w-full flex-col justify-center gap-2 text-center">
        {gemstoneTypes.map((type, index) => (
          <ListSelection
            key={index}
            text={type.text}
            leftIcon={type.icon}
            onClick={() => onNext(type.value)}
            dataTestId={type.dataTestId}
          />
        ))}
      </div>

      <div className="h-[1px] w-full bg-neutralSoft" />

      <div className="flex w-full flex-col justify-center gap-2 text-center">
        {otherType.map((type, index) => (
          <ListSelection
            key={index}
            text={type.text}
            leftIcon={type.icon}
            onClick={() => onNext(type.value)}
            dataTestId={type.dataTestId}
          />
        ))}
      </div>

      <ButtonContainer leftButton={<BackButton text={'BACK'} onClick={handleBack} rightButtonExist={false} />} />
    </div>
  );
};

export default EarringsGemstone;
