import * as React from 'react';
import type { SVGProps } from 'react';

interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgAmazonPinIcon = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <g stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10} strokeWidth={1.5}>
      <path d="M15.1 11.8C19.7 11.2 23 9.3 23 7c0-2.8-4.9-5-11-5S1 4.2 1 7c0 2.3 3.4 4.2 8 4.8" />
      <path d="M15.2 8.8c1.8-.3 2.9-1 2.9-1.7 0-1.1-2.7-2-6-2s-6 .9-6 2c0 .8 1.2 1.4 3.1 1.7M12.1 8v7.5M12 16.25a3.5 3.5 0 1 1 0 7 3.5 3.5 0 0 1 0-7Z" />
      <path d="M10.75 19.5c0 .8.7 1.5 1.5 1.5" />
    </g>
  </svg>
);
export default SvgAmazonPinIcon;
