import React from 'react';

import { cn } from 'src/util/cn';

import AmountSelector from '../../../../components/AmountSelector/AmountSelector';
import { Amount } from '../../../../components/UI/Form/Amount/Amount';

interface AmountEnterCardProps {
  inputRef: React.RefObject<HTMLInputElement>;
  className?: string;
  amount: string | number;
  errorAmount: any;
  onAmountChange: (amount: string) => void;
}
export const AmountEnterCard = (props: AmountEnterCardProps) => {
  const { className, amount, errorAmount, onAmountChange, inputRef } = props;
  return (
    <div
      className={cn(
        'flex flex-col gap-2 rounded-2xl border border-neutralStrong bg-[#F3F3F3] px-4 py-8 shadow-xl',
        className,
      )}
    >
      <Amount
        ref={inputRef}
        className="!border-1 !rounded-b-xl !rounded-t-xl !border-b !border-neutralStrong"
        key={amount}
        error={errorAmount}
        inputSize="big"
        textAlign="center"
        inputMode="numeric"
        onChange={event => {
          const newValue = event.target.value;
          onAmountChange(newValue);
        }}
        placeholder="$0.00"
        label="Your deposit"
        value={amount}
        required={true}
      />
      <div
        className={cn('text-center text-[10px] font-light', {
          'text-neutralStrong': !errorAmount,
          'text-danger': errorAmount,
        })}
      >
        {errorAmount ? errorAmount.message : 'Amount must be between $200 - $2,000'}
      </div>
      <AmountSelector
        buttonClassName={'!border-neutral'}
        useHighAmounts={true}
        onAmountSelected={amount => {
          if (amount !== null) {
            const newValue = amount.toString();
            onAmountChange(newValue);
          }
        }}
      />
    </div>
  );
};
