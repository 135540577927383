import clsx from 'clsx';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Typography } from 'src/components/Typography/Typography';
import { Wrapper1280 } from 'src/components/UI/Wrapper/Wrapper1280';
import { routes } from 'src/constants/routes';
import { useIsMobile } from 'src/hooks/useIsMobile';
import heroImageMobile from 'src/pages/in-landing/assets/diamond-ring3-elle.webp';
import heroImage from 'src/pages/in-landing/assets/diamond-ring4-elle.webp';

import PestoLogo from '../../../assets/Logo_pesto-black.svg';
import Logo from '../../../assets/PestoCaps.svg';
import VerifyIcon from '../../../assets/checkmark.svg';

const bulletPoints = [
  {
    title: 'Quick Estimate',
    content: 'Find out your credit line estimate in 2 minutes.',
  },
  {
    title: 'No Credit Score Check',
    content: 'There is no need for a credit score or history.',
  },
  {
    title: 'Asset-Backed',
    content: 'Leverage your jewelry while it is insured by our team.',
  },
];

const Hero: React.FC = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div className={'from-white from-50% to-[#0A0A0C] to-50% sm:bg-gradient-to-r'}>
      <div
        className={clsx(
          'sm:bg-top-center relative flex flex-col bg-contain  bg-top bg-no-repeat text-lg font-normal text-white ',
        )}
        style={{
          backgroundImage: `url(${isMobile ? heroImageMobile : heroImage})`,
          backgroundPositionY: isMobile ? '' : '',
        }}
      >
        <Wrapper1280 className="container mx-auto flex flex-col items-center justify-between px-4 py-4 sm:flex-row ">
          <div className="flex w-full items-center justify-between">
            <img src={PestoLogo} className="w-[100px]" />

            <div className="z-[23923884] sm:hidden">
              <button
                onClick={toggleMenu}
                aria-label="Toggle menu"
                className={clsx({
                  'fixed right-4 top-4 w-auto': isMenuOpen,
                })}
              >
                {isMenuOpen ? (
                  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 50 50" fill="white">
                    <path d="M 7.7070312 6.2929688 L 6.2929688 7.7070312 L 23.585938 25 L 6.2929688 42.292969 L 7.7070312 43.707031 L 25 26.414062 L 42.292969 43.707031 L 43.707031 42.292969 L 26.414062 25 L 43.707031 7.7070312 L 42.292969 6.2929688 L 25 23.585938 L 7.7070312 6.2929688 z" />
                  </svg>
                ) : (
                  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 50 50" fill="white">
                    <path d="M 5 9 L 5 11 L 45 11 L 45 9 L 5 9 z M 5 24 L 5 26 L 45 26 L 45 24 L 5 24 z M 5 39 L 5 41 L 45 41 L 45 39 L 5 39 z" />
                  </svg>
                )}
              </button>
            </div>
          </div>
          <div
            className={`flex-col gap-4 whitespace-nowrap sm:flex-row ${
              isMenuOpen && isMobile ? 'fixed top-0 flex h-[100vh] w-full justify-center bg-black' : 'hidden sm:flex'
            }`}
          >
            <a href={routes.login} className="text-transparentGreen text-lg hover:underline ">
              Check Application Status
            </a>
            <a
              href="https://cardservicing.getpesto.com/cholder/welcome"
              className="text-transparentGreen text-lg hover:underline"
            >
              Login
            </a>
          </div>
        </Wrapper1280>
        <Wrapper1280 className={'flex w-full flex-col sm:flex-row'}>
          <div className="flex w-full flex-col items-start gap-4 py-16 sm:gap-12">
            <img src={Logo} alt="logo 1st" className="mx-auto mt-[140px] w-[250px] sm:mt-0 sm:w-[350px]" />
            <div className="flex flex-col items-start gap-4 sm:text-left">
              <Typography variant={'heroLarge'} className=" text-3xl !font-bold text-black sm:text-5xl">
                Use Your Jewelry to Secure Your Credit Card
              </Typography>
              <h2 className="text-2xl font-extralight text-black sm:text-3xl">
                An efficient and secure way to build credit without selling your valuables
              </h2>
              <Typography variant={'body'} className={'text-black'}>
                Pesto Mastercard® is the first credit card that enables customers to use valuables as collateral to
                secure a credit card.{' '}
              </Typography>
            </div>
            <ul className="flex flex-col flex-wrap justify-center gap-4 text-lg font-normal text-black">
              {bulletPoints.map((point, index) => (
                <li key={index} className="flex items-start text-left">
                  <img className="mr-2 mt-1" src={VerifyIcon} alt={point.title} />
                  <div className={'flex flex-col items-start'}>
                    <Typography variant={'bodyLarge'} className={'!font-bold'}>
                      {point.title}
                    </Typography>
                    <Typography variant={'body'}>{point.content}</Typography>
                  </div>
                </li>
              ))}
            </ul>
            <div className="flex w-full flex-col gap-6 text-lg font-normal sm:flex-row">
              <button
                onClick={() => navigate(routes.quickAppraisal)}
                className="w-auto rounded-lg bg-black px-4 py-2 text-white shadow-lg hover:bg-slate-900 "
              >
                Get Your Card
              </button>
            </div>
          </div>
          <div className="w-full"></div>
        </Wrapper1280>
      </div>
    </div>
  );
};

export default Hero;
