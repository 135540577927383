import { yupResolver } from '@hookform/resolvers/yup';
import clsx from 'clsx';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import type { Cut } from 'src/__generated__/graphql/api';
import { ButtonContainer } from 'src/components/ButtonContainer/ButtonContainer';
import { BackButton } from 'src/components/UI/Button/BackButton';

import CaratDropdown from '../../../../../../components/CaratDropdown/CaratDropdown';
import { MaxCaratValue } from '../../../../../../components/CaratSlider/CaratSlider';
import CutDropdown from '../../../../../../components/CutDropdown/CutDropdown';
import { Typography } from '../../../../../../components/Typography/Typography';
import Button from '../../../../../../components/UI/Button/Button';
import { useOnMount } from '../../../../../../hooks/useOnMount';

export type CenterStoneDetailsParams = {
  cut: Cut | undefined;
  carat: number;
};

interface CenterStoneDetailsScreenProps {
  className?: string;
  onNext: (params: CenterStoneDetailsParams) => void;
  hasCentralDiamond: boolean;
  handleBack: () => void;
}

interface FormValues {
  carat: number;
  cut: Cut | undefined;
  hasCentralDiamond: boolean;
}

const schema = yup.object().shape({
  carat: yup.number().min(0.01, 'Carat is required'),
  cut: yup.string<Cut>().when('hasCentralDiamond', {
    is: true,
    then: schema => schema.test('cut', 'Cut is required', value => value !== undefined),
  }),
});

const CenterStoneDetails = (props: CenterStoneDetailsScreenProps) => {
  const { className, onNext, hasCentralDiamond, handleBack } = props;

  const {
    setValue,
    trigger,
    handleSubmit,
    watch,
    formState: { errors, isSubmitted },
  } = useForm<FormValues>({
    resolver: yupResolver(schema),
    defaultValues: {
      carat: 0,
      cut: undefined,
    },
  });

  const { cut, carat } = watch();

  useEffect(() => {
    if (isSubmitted) {
      trigger();
    }
  }, [cut, carat, isSubmitted, trigger]);
  useOnMount(() => {
    if (hasCentralDiamond) {
      setValue('hasCentralDiamond', true);
    }
  });

  const handleFormSubmit = (data: FormValues) => {
    onNext({ cut: data.cut, carat: data.carat });
  };

  const rootStyles = clsx('flex flex-col flex-colCenterStoneDetailsParams gap-4 max-w-[400px] w-full', className);
  return (
    <div className={rootStyles}>
      <Typography variant={'headerMedium'}>Center stone details</Typography>
      <form
        className=" flex w-full flex-col justify-center gap-2 text-center"
        onSubmit={handleSubmit(handleFormSubmit)}
      >
        <CaratDropdown
          maxCaratValue={hasCentralDiamond ? MaxCaratValue.THREE : MaxCaratValue.FIVE}
          onChange={(carat: number) => {
            setValue('carat', carat);
          }}
          inputLabel="What is the average carat size of your stone?"
          label="Carat size"
          alwaysOpen
        />
        {errors.carat && (
          <Typography variant={'caption'} className="text-danger">
            {errors.carat.message}
          </Typography>
        )}
        {hasCentralDiamond && (
          <CutDropdown
            onChange={(cut: Cut) => {
              setValue('cut', cut);
            }}
            alwaysOpen
          />
        )}
        {errors.cut && (
          <Typography variant={'caption'} className="text-danger">
            {errors.cut.message}
          </Typography>
        )}

        <ButtonContainer
          rightButton={
            <Button
              buttonType={'primary'}
              text={'Next'}
              onClick={handleSubmit(handleFormSubmit)}
              data-testid={'center-stone--next-button'}
            />
          }
          leftButton={<BackButton onClick={handleBack} rightButtonExist />}
        />
      </form>
    </div>
  );
};

export default CenterStoneDetails;
