import type { ForwardedRef } from 'react';
import { forwardRef } from 'react';
import { PatternFormat } from 'react-number-format';

import SvgCardBack from '../../../../icons/components/credit-card/CardBack';
import { InfoTooltip } from '../../../InfoTooltip/InfoTooltip';
import { Typography } from '../../../Typography/Typography';
import type { CardCvvProps } from '../FormTypes';
import { TextFieldWrapper } from '../TextFieldWrapper/TextFieldWrapper';
import { generateInputStyles } from '../inputStylesHelper';

export const CardCvv = forwardRef((props: CardCvvProps, ref: ForwardedRef<HTMLInputElement>) => {
  const {
    value,
    onChange,
    label,
    rightHelperText,
    errorText,
    error,
    caption,
    required,
    readOnly,
    cvvLength = 3,
    textAlign,
    inputSize,
    infoEnabled,
    ...otherProps
  } = props;

  const cvvMask = cvvLength === 4 ? '####' : '###';

  const info = (
    <>
      <Typography variant="body">Please enter the last {cvvLength} digits on the back of your card.</Typography>
      <SvgCardBack className="h-auto w-[200px]" />
    </>
  );

  const isErrorVisible = !!error;

  const inputStyle = generateInputStyles({
    isErrorVisible,
    readOnly,
    textAlign,
    inputSize,
  });

  return (
    <TextFieldWrapper
      errorText={errorText}
      error={!!error}
      label={label}
      caption={caption}
      required={required}
      rightHelperText={rightHelperText}
      info={infoEnabled && <InfoTooltip toolTipText={info} />}
    >
      <PatternFormat
        getInputRef={ref}
        className={inputStyle}
        value={value}
        onChange={e => {
          onChange(e.target.value);
        }}
        allowEmptyFormatting
        format={cvvMask}
        disabled={readOnly}
        mask="_"
        {...otherProps}
      />
    </TextFieldWrapper>
  );
});
