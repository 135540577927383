import type { ForwardedRef } from 'react';
import { forwardRef } from 'react';
import { PatternFormat } from 'react-number-format';

import type { CardNumberProps } from '../FormTypes';
import { TextFieldWrapper } from '../TextFieldWrapper/TextFieldWrapper';
import { generateInputStyles } from '../inputStylesHelper';

export const CardNumber = forwardRef((props: CardNumberProps, ref: ForwardedRef<HTMLInputElement>) => {
  const {
    value,
    onChange,
    label,
    rightHelperText,
    errorText,
    error,
    caption,
    cardTypeIcon,
    required,
    readOnly,
    placeholder,
    textAlign,
    inputSize,
    cardLengths = [],
    ...otherProps
  } = props;

  const isErrorVisible = !!error;
  const inputStyle = generateInputStyles({
    className: 'pl-14',
    isErrorVisible,
    readOnly,
    textAlign,
    inputSize,
  });

  const maxCardNumberLength = cardLengths[cardLengths.length - 1] || 16;
  const cardNumberMask = Array(maxCardNumberLength)
    .fill('#')
    .map((char, index) => ((index + 1) % 4 === 0 ? `${char} ` : char))
    .join('');

  return (
    <TextFieldWrapper
      errorText={errorText}
      error={!!error}
      label={label}
      caption={caption}
      required={required}
      rightHelperText={rightHelperText}
    >
      <div className={'absolute bottom-1.5 left-3 z-[1]'}>{cardTypeIcon}</div>
      <PatternFormat
        getInputRef={ref}
        className={inputStyle}
        value={value}
        onChange={e => {
          onChange(e.target.value);
        }}
        placeholder={'Enter card number' || placeholder}
        allowEmptyFormatting
        format={cardNumberMask || '#### #### #### ####'}
        disabled={readOnly}
        mask=" "
        {...otherProps}
      />
    </TextFieldWrapper>
  );
});
