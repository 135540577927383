import * as React from 'react';
import type { SVGProps } from 'react';

interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgBlackDiamond = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <circle cx={12} cy={10.5} r={9.5} fill="#17232F" opacity={0.4} />
    <path
      stroke="#101820"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M19.237 3.225a1.5 1.5 0 0 0-1.2-.6h-12a1.5 1.5 0 0 0-1.2.6L1.5 7.676a1.5 1.5 0 0 0 .048 1.86l9.338 11.3a1.5 1.5 0 0 0 2.3 0l9.338-11.3a1.5 1.5 0 0 0 .048-1.86l-3.335-4.451ZM5.349 2.792l6.688 18.583M18.724 2.792l-6.687 18.583M1.198 8.625h21.673"
    />
    <path
      stroke="#101820"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="m7.537 8.625 4.5-6 4.5 6"
    />
  </svg>
);
export default SvgBlackDiamond;
