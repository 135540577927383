import { useNavigate } from 'react-router-dom';

import { Typography } from 'src/components/Typography/Typography';
import { Wrapper1280 } from 'src/components/UI/Wrapper/Wrapper1280';
import { routes } from 'src/constants/routes';

import AppStore from '../../../assets/AppStore.svg';
import PestoApp from '../../../assets/PestApp.webp';
import GooglePlay from '../../../assets/PlayMarket.svg';
import Checkmark from '../../../assets/checkmark.svg';

const PestAppSection = () => {
  const navigate = useNavigate();

  return (
    <div className=" bg-[#E8E8E840]">
      <Wrapper1280>
        <div className="flex flex-col items-center justify-around px-4 pb-[93px] pt-[93px] md:flex-row">
          <div className="text-left">
            <Typography variant="bodyLarge" className="uppercase text-black">
              The pesto app
            </Typography>
            <Typography variant="heroSmall" className="text-black">
              Manage Your Spend
            </Typography>

            <div>
              <div className="mt-4 flex items-center gap-2">
                <img src={Checkmark} alt="checkmark" />
                <Typography variant="bodyLarge" className="text-basicSoft">
                  Track your spending
                </Typography>
              </div>
              <div className="mt-4 flex items-center gap-2">
                <img src={Checkmark} alt="checkmark" />
                <Typography variant="bodyLarge" className="text-basicSoft">
                  Pay your balance
                </Typography>
              </div>
              <div className="mt-4 flex items-center gap-2">
                <img src={Checkmark} alt="checkmark" />
                <Typography variant="bodyLarge" className="text-basicSoft">
                  Add additional users
                </Typography>
              </div>
            </div>

            <div className="mt-[44px] flex items-center gap-2">
              <a href="#">
                <img src={AppStore} alt="app store" className="h-12 md:h-auto" />
              </a>

              <a href="#">
                <img src={GooglePlay} alt="google play" className="h-12 md:h-auto" />
              </a>
            </div>

            <button
              onClick={() => navigate(routes.quickAppraisal)}
              className="mt-[25px] w-[190px] rounded bg-black px-6 py-2 text-white shadow-lg "
            >
              Get Your Card
            </button>
          </div>

          <div className="mt-10 md:mt-0">
            <img src={PestoApp} alt="pesto app" className="max-w-xs md:max-w-md lg:max-w-lg xl:max-w-xl" />
          </div>
        </div>
      </Wrapper1280>
    </div>
  );
};

export default PestAppSection;
