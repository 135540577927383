import { useState } from 'react';

import getDaysInMonth from 'date-fns/getDaysInMonth';
import noop from 'lodash/noop';

import { useIsMobile } from '../../hooks/useIsMobile';
import { Typography } from '../Typography/Typography';
import { Dropdown } from '../UI/Form/Dropdown/Dropdown';
import { TextField } from '../UI/Form/TextField/TextField';

const months = [
  { value: '01', text: 'Jan' },
  { value: '02', text: 'Feb' },
  { value: '03', text: 'Mar' },
  { value: '04', text: 'Apr' },
  { value: '05', text: 'May' },
  { value: '06', text: 'Jun' },
  { value: '07', text: 'Jul' },
  { value: '08', text: 'Aug' },
  { value: '09', text: 'Sep' },
  { value: '10', text: 'Oct' },
  { value: '11', text: 'Nov' },
  { value: '12', text: 'Dec' },
];

interface DobProps {
  year: string;
  month: string;
  day: string;
  onChange: (date: string) => void;
  readOnly?: boolean;
  required?: boolean;
  prefilled?: string;
  error: any;
}

export const Dob = ({ year, month, day, onChange, error, required, readOnly }: DobProps) => {
  const isMobile = useIsMobile();
  const [currentYear, setCurrentYear] = useState(year);
  const [days, setDays] = useState(
    Array.from({ length: 31 }, (_, i) => ({
      value: String(i + 1).padStart(2, '0'),
      text: String(i + 1),
    })),
  );

  const handleYearChange = (e: { target: { value: string } }) => {
    const selectedYear = e.target.value;
    setCurrentYear(selectedYear);
    onChange(`${selectedYear}-${month}-${day}`);

    const date = new Date(parseInt(selectedYear), parseInt(month) - 1);
    setDays(
      Array.from({ length: getDaysInMonth(date) }, (_, i) => ({
        value: String(i + 1).padStart(2, '0'),
        text: String(i + 1),
      })),
    );
  };

  const handleMonthChange = (selectedMonth: string) => {
    onChange(`${currentYear}-${selectedMonth}-${day}`);

    const date = new Date(parseInt(currentYear || new Date().getFullYear().toString()), parseInt(selectedMonth) - 1);
    setDays(
      Array.from({ length: getDaysInMonth(date) }, (_, i) => ({
        value: String(i + 1).padStart(2, '0'),
        text: String(i + 1),
      })),
    );
  };

  const handleDayChange = (selectedDay: string) => {
    const paddedDay = selectedDay.padStart(2, '');
    onChange(`${year}-${month}-${paddedDay}`);
  };

  const readOnlyValue = `${month}/${day}/${year}`;

  return (
    <div className="flex w-full flex-col">
      <Typography variant={isMobile ? 'bodySmall' : 'body'} className="text-slate-500">
        {required && <span className="text-danger">*</span>}
        Date of Birth
      </Typography>
      <div className="flex w-full flex-row gap-2 self-start">
        {readOnly ? (
          <TextField className="w-full" onChange={noop} value={readOnlyValue} disabled readOnly={readOnly} />
        ) : (
          <>
            <Dropdown
              options={months}
              onChange={e => handleMonthChange(e.value)}
              selectedValue={months.find(m => m.value === month)?.value}
              placeholder="Month"
              className="w-full"
              isSearchable
              error={error}
              readOnly={readOnly}
            />
            <Dropdown
              options={days}
              onChange={e => handleDayChange(e.value)}
              selectedValue={days.find(d => d.value === day)?.value}
              placeholder="Day"
              className="w-full"
              isSearchable
              error={error}
              readOnly={readOnly}
            />
            <TextField
              name="year"
              onChange={handleYearChange}
              type="tel"
              placeholder="Year"
              maxLength={4}
              className="w-full text-center"
              value={year}
              error={error}
              readOnly={readOnly}
            />
          </>
        )}
      </div>
      {error && <div className="mt-[8px] text-sm text-danger">You must be 18 years or older to apply for a loan.</div>}
    </div>
  );
};
