import { yupResolver } from '@hookform/resolvers/yup';
import clsx from 'clsx';
import { useCallback, useEffect, useState } from 'react';
import { type SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';

import { ButtonContainer } from 'src/components/ButtonContainer/ButtonContainer';
import { Typography } from 'src/components/Typography/Typography';
import { BackButton } from 'src/components/UI/Button/BackButton';
import Button from 'src/components/UI/Button/Button';
import { type DropFile, ImageUploader } from 'src/components/UI/ImageUploader/ImageUploader';
import { cleanFilesArray } from 'src/util/filesUploaderHelper';

interface ImageDetailsFormProps {
  className?: string;
  onNext: (data: any) => void;
  onBack: () => void;
  loadingMutation?: boolean;
  listItems: string[];
  imageDetailsFormTitle?: string;
}

interface FormValuesType {
  photoLinks: DropFile[];
}

const validationSchema = yup.object().shape({
  photoLinks: yup.array().required().min(1, 'At least one document is required'),
});

const ImageDetailsForm: React.FC<ImageDetailsFormProps> = props => {
  const { className, onNext, loadingMutation, onBack, listItems, imageDetailsFormTitle } = props;
  const [isLoadingImages, setIsLoadingImages] = useState(false);
  const rootStyles = clsx('flex flex-col gap-4 max-w-[400px] w-full', className);

  const list = listItems || ['Photo of earrings', 'Certification', 'Appraisal'];

  const {
    handleSubmit,
    watch,
    setValue,
    formState: { errors, isSubmitted, isSubmitting },
    trigger,
  } = useForm<FormValuesType>({
    mode: 'onSubmit',
    resolver: yupResolver(validationSchema),
    defaultValues: {
      photoLinks: [],
    },
  });

  const formValues = {
    photoLinks: watch('photoLinks'),
  };

  useEffect(() => {
    if (isSubmitted || isSubmitting) {
      trigger();
    }
  }, [isSubmitted, isSubmitting, trigger, formValues.photoLinks]);

  const onSubmit: SubmitHandler<FormValuesType> = data => {
    const image = cleanFilesArray(data?.photoLinks.map(image => image?.responseKey)) as string[];
    onNext(image);
  };

  const handleAddPhotoLink = useCallback(
    (value: DropFile[]) => {
      setValue('photoLinks', value);
    },
    [setValue],
  );

  const isLoading = isLoadingImages || loadingMutation;

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className={rootStyles}>
        <Typography variant={'headerMedium'}>{imageDetailsFormTitle || 'More details'}</Typography>
        <div>
          <Typography variant={'body'} className={'text-neutral'}>
            Upload at least one of the following:
          </Typography>
          <ul className="mx-auto w-[200px] list-disc self-center text-left">
            {list.map((item, index) => {
              return (
                <li key={item + index}>
                  <Typography variant={'body'} className={'text-neutral'}>
                    {item}
                  </Typography>
                </li>
              );
            })}
          </ul>
        </div>
        <ImageUploader addPhotoLink={handleAddPhotoLink} isLoaded={setIsLoadingImages} />
        {errors.photoLinks && (
          <Typography variant={'body'} className={'text-danger'}>
            {errors.photoLinks.message}
          </Typography>
        )}

        <ButtonContainer
          rightButton={
            <Button
              disabled={isLoading}
              isLoading={isLoading}
              text="NEXT"
              buttonType={'primary'}
              type="submit"
              data-testid="earrings-photo--submit"
            />
          }
          leftButton={<BackButton onClick={onBack} rightButtonExist />}
        />
      </form>
    </>
  );
};

export default ImageDetailsForm;
