import axios from 'axios';
import { useCallback, useState } from 'react';

import type { PreSignFormFields } from '../../../../__generated__/graphql/api';
import { useGenerateAssetUploadUrlMutation } from '../../../../__generated__/graphql/api';
import { MAX_IMAGE_UPLOAD_SIZE } from '../../../../constants/application';
import type { DropFile } from '../ImageUploader';

function splitCamelCase(s: string, delimiter = ' ') {
  return s.split(/(?=[A-Z])/).join(delimiter);
}
interface Uploading {
  imgId: string | undefined;
  isLoading: boolean;
}
export default function useImageUploader() {
  const [isUploading, setUploading] = useState<Uploading>({
    imgId: undefined,
    isLoading: false,
  });

  const [generateAssetUploadUrlMutation] = useGenerateAssetUploadUrlMutation();

  const createUploadForm = (uploadMetaData: PreSignFormFields, image: DropFile, fileType: string): FormData => {
    const form = new FormData();
    Object.entries(uploadMetaData)
      .filter(([key, value]) => key !== 'url' && key !== '__typename' && value)
      .forEach(([key]) => {
        form.append(splitCamelCase(key, '-').toLowerCase(), uploadMetaData[key as keyof PreSignFormFields] || '');
      });
    // to see what is in the form data, uncomment the line below
    // console.log('form', Object.fromEntries(form));
    form.append('acl', 'private');
    form.append('x-amz-meta-userid', 'anonymous');
    form.append('Content-Type', `${fileType}`);
    form.append('file', image);

    return form;
  };

  const uploadImage = useCallback(
    async (image: DropFile) => {
      try {
        setUploading({
          imgId: image.imgId,
          isLoading: true,
        });
        if (image.size > MAX_IMAGE_UPLOAD_SIZE) {
          return Promise.reject('File size is too big');
        }
        // 1. get upload url and params
        const result = await generateAssetUploadUrlMutation();
        const uploadMetaData = result?.data?.generateAssetUploadUrl;

        if (uploadMetaData) {
          // 2. get proper image uri depending on platform

          // 3. set form data
          const form = createUploadForm(uploadMetaData, image, image.type);

          // 4 create post and upload image
          if (uploadMetaData?.url) {
            const uploadResult = await axios({
              method: 'post',
              url: uploadMetaData?.url,
              data: form,
              headers: { 'Content-Type': 'multipart/form-data' },
            });

            if (!uploadResult.status) {
              return Promise.reject(uploadResult.statusText);
            }
            return uploadMetaData?.key;
          }
          return Promise.reject();
        } else {
          return Promise.reject(result?.errors?.[0] ? result?.errors?.[0] : 'Error generating upload url');
        }
      } catch (e) {
        console.error('Error uploading image', e);
        return Promise.reject(e);
      } finally {
        setUploading({
          imgId: image.imgId,
          isLoading: false,
        });
      }
    },
    [generateAssetUploadUrlMutation],
  );

  return { uploadImage, isUploading };
}
