import * as React from 'react';
import { useLayoutEffect } from 'react';

import { useAuth } from 'src/auth/AuthProvider';

import { ADD_ITEM_SIGN_UP, SKIP_ADD_ITEM_SIGN_UP } from '../constants/application';
import { loginRoutes } from '../constants/routes';

export const LoginCallback = () => {
  const { parseAuthToken } = useAuth();
  useLayoutEffect(() => {
    parseAuthToken().then(state => {
      if (state === SKIP_ADD_ITEM_SIGN_UP) {
        return window.location.replace(loginRoutes.skipAddItemSignUp);
      }
      if (state !== ADD_ITEM_SIGN_UP) {
        return window.location.replace(loginRoutes.getUser);
      }

      return window.location.replace(loginRoutes.addItemSignUp);
    });
  }, [parseAuthToken]);

  return (
    <div className={'flex h-screen flex-col items-center justify-center'}>
      <div className={'h-32 w-32 animate-spin rounded-full border-b-2 border-t-2 border-gray-900'}></div>
    </div>
  );
};
export default LoginCallback;
