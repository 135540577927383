import * as React from 'react';
import type { SVGProps } from 'react';

interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgI3Icon = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <circle cx={12} cy={12} r={9.25} stroke="#101820" strokeWidth={1.5} />
    <path
      stroke="#101820"
      strokeLinecap="round"
      d="M15 15.293 17.293 13M13 11.293 15.293 9M14 13.293 16.293 11M17.302 14.884l-4.418-6.187"
    />
  </svg>
);
export default SvgI3Icon;
