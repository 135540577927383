import React from 'react';

import { Modal2 } from 'src/components/UI/Modal/Modal2';

import type { UpdateIssues } from '../../__generated__/graphql/api';
import WarningIcon from '../../images/warning.svg';
import { updateIssueKey } from '../../util/updateIssuesHelper';
import { Typography } from '../Typography/Typography';
import Button from '../UI/Button/Button';

export interface UpdateIssuesModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSignOut: () => void;
  updateIssues: UpdateIssues;
}

export const UpdateIssuesModal: React.FC<UpdateIssuesModalProps> = ({ isOpen, onClose, updateIssues, onSignOut }) => {
  const emailOrPhone = updateIssueKey(updateIssues);
  return (
    <Modal2 className="w-full" isOpen={isOpen} onClose={onClose}>
      <div className="flex flex-col gap-4">
        <img src={WarningIcon} className="mx-auto h-[64px] w-[64px]" alt="warning icon" />
        <Typography variant="headerMedium">This {emailOrPhone} is already associated with another account.</Typography>
        <Typography variant="body">
          To continue, please log in to that account or update your {emailOrPhone}.
        </Typography>
        <Button buttonType="secondary" text="Sign Out" onClick={onSignOut} />
        <Button buttonType="primary" text="Ok" onClick={onClose} />
      </div>
    </Modal2>
  );
};
