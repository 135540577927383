import {
  AsscherIcon,
  ColorDIcon,
  ColorEIcon,
  ColorFIcon,
  ColorGIcon,
  ColorHIcon,
  ColorIIcon,
  ColorJIcon,
  ColorKIcon,
  CushionIcon,
  EmeraldIcon,
  HeartIcon,
  I1Icon,
  I2Icon,
  I3Icon,
  IfIcon,
  MarquiseIcon,
  OldmineIcon,
  OvalIcon,
  PearIcon,
  RadiantIcon,
  RoundIcon,
  Si1Icon,
  Si2Icon,
  Si3Icon,
  Vs1Icon,
  Vs2Icon,
  Vvs1Icon,
  Vvs2Icon,
} from 'src/icons/components/diamonds';

import { Clarity, Color, Cut, DiamondType } from '../__generated__/graphql/api';
import { I_DONT_KNOW, OTHER } from '../constants/application';

export const diamondCuts = [
  { value: Cut.Round, text: 'Round', icon: <RoundIcon /> },
  { value: Cut.Cushion, text: 'Cushion', icon: <CushionIcon /> },
  { value: Cut.Oval, text: 'Oval', icon: <OvalIcon /> },
  { value: Cut.Pear, text: 'Pear', icon: <PearIcon /> },
  { value: Cut.Emerald, text: 'Emerald', icon: <EmeraldIcon /> },
  { value: Cut.Marquise, text: 'Marquise', icon: <MarquiseIcon /> },
  { value: Cut.Asscher, text: 'Asscher', icon: <AsscherIcon /> },
  { value: Cut.Radiant, text: 'Radiant', icon: <RadiantIcon /> },
  { value: Cut.Heart, text: 'Heart', icon: <HeartIcon /> },
  { value: Cut.Oldmine, text: 'Oldmine', icon: <OldmineIcon /> },
];

export const centerStoneCuts = [
  { value: Cut.Round, text: 'Round', icon: <RoundIcon /> },
  { value: Cut.Cushion, text: 'Cushion', icon: <CushionIcon /> },
  { value: Cut.Oval, text: 'Oval', icon: <OvalIcon /> },
  { value: Cut.Pear, text: 'Pear', icon: <PearIcon /> },
  { value: Cut.Emerald, text: 'Emerald', icon: <EmeraldIcon /> },
  { value: Cut.Marquise, text: 'Marquise', icon: <MarquiseIcon /> },
  { value: Cut.Asscher, text: 'Asscher', icon: <AsscherIcon /> },
  { value: Cut.Radiant, text: 'Radiant', icon: <RadiantIcon /> },
  { value: Cut.Unknown, text: OTHER },
];

export const diamondClarity = [
  { value: Clarity.If, text: 'IF', icon: <IfIcon /> },
  { value: Clarity.Vvs1, text: 'VVS1', icon: <Vvs1Icon /> },
  { value: Clarity.Vvs2, text: 'VVS2', icon: <Vvs2Icon /> },
  { value: Clarity.Vs1, text: 'VS1', icon: <Vs1Icon /> },
  { value: Clarity.Vs2, text: 'VS2', icon: <Vs2Icon /> },
  { value: Clarity.Si1, text: 'SI1', icon: <Si1Icon /> },
  { value: Clarity.Si2, text: 'SI2', icon: <Si2Icon /> },
  { value: Clarity.Si3, text: 'SI3', icon: <Si3Icon /> },
  { value: Clarity.I1, text: 'I1', icon: <I1Icon /> },
  { value: Clarity.I2, text: 'I2', icon: <I2Icon /> },
  { value: Clarity.I3, text: 'I3', icon: <I3Icon /> },
  { value: Clarity.Unknown, text: I_DONT_KNOW },
];

export const diamondColor = [
  { value: Color.D, text: 'D', icon: <ColorDIcon /> },
  { value: Color.E, text: 'E', icon: <ColorEIcon /> },
  { value: Color.F, text: 'F', icon: <ColorFIcon /> },
  { value: Color.G, text: 'G', icon: <ColorGIcon /> },
  { value: Color.H, text: 'H', icon: <ColorHIcon /> },
  { value: Color.I, text: 'I', icon: <ColorIIcon /> },
  { value: Color.J, text: 'J', icon: <ColorJIcon /> },
  { value: Color.K, text: 'K', icon: <ColorKIcon /> },
  { value: Color.Unknown, text: I_DONT_KNOW },
];

export const diamondTypes = [
  { value: DiamondType.Natural, text: 'Natural' },
  { value: DiamondType.LabGrown, text: 'Lab grown' },
  { value: DiamondType.BlackDiamond, text: 'Black diamond' },
];

export const remappedDiamondTypes = diamondTypes.reduce((acc, curr) => {
  acc[curr.value] = curr.text;
  return acc;
}, {} as { [key: string]: string });
