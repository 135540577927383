import { cn } from 'src/util/cn';

import { Typography } from '../Typography/Typography';

interface ImageListSelectionProps {
  className?: string;
  onClick: () => void;
  image: string;
  text: string;
  dataTestId?: string;
}

export const ImageListSelection = (props: ImageListSelectionProps) => {
  const { className, onClick, text, image, dataTestId } = props;
  const rootStyles = cn(
    'flex w-full flex-col gap-2 items-center justify-between rounded-lg border border-neutralInactive bg-[#fff] p-2 text-basic hover:border-neutralSoft active:border-neutral',
    className,
  );

  return (
    <button
      data-testid={dataTestId}
      className={rootStyles}
      onClick={e => {
        e.preventDefault();
        onClick();
      }}
    >
      <img src={image} alt="image" className="w-[66px] sm:w-[100px]" />
      <Typography variant={'body'} className="text-center !font-bold">
        {text}
      </Typography>
    </button>
  );
};
