import * as React from 'react';
import type { SVGProps } from 'react';

interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgActiveIcon = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <g clipPath="url(#ActiveIcon_svg__a)">
      <g clipPath="url(#ActiveIcon_svg__b)">
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M12 .75C5.804.75.75 5.804.75 12S5.804 23.25 12 23.25 23.25 18.196 23.25 12 18.196.75 12 .75Zm0 20.125c-4.888 0-8.875-3.986-8.875-8.875 0-4.888 3.987-8.875 8.875-8.875 4.889 0 8.875 3.987 8.875 8.875S16.89 20.875 12 20.875Zm4.803-12.58c.47.47.471 1.24 0 1.712l-5.619 5.619a1.433 1.433 0 0 1-1.236.42 1.433 1.433 0 0 1-1.085-.726l-1.858-3.218a1.215 1.215 0 0 1 .443-1.654 1.214 1.214 0 0 1 1.655.443l1.24 2.15 4.747-4.747a1.215 1.215 0 0 1 1.713 0Z"
          clipRule="evenodd"
        />
      </g>
    </g>
    <defs>
      <clipPath id="ActiveIcon_svg__a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
      <clipPath id="ActiveIcon_svg__b">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgActiveIcon;
