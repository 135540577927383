import { lazy } from 'react';

import { RETRY_LAZY_LS_KEY } from '../constants/localStorageKeys';

// a function to retry loading a chunk to avoid chunk load error for out of date code
// found here: https://www.codemzy.com/blog/fix-chunkloaderror-react
export const lazyRetry = function (componentImport: () => Promise<any>): Promise<any> {
  return new Promise((resolve, reject) => {
    const hasRefreshed = JSON.parse(window.sessionStorage.getItem(RETRY_LAZY_LS_KEY) || 'false');
    componentImport()
      .then(component => {
        window.sessionStorage.setItem(RETRY_LAZY_LS_KEY, 'false');
        resolve(component);
      })
      .catch(error => {
        if (!hasRefreshed) {
          window.sessionStorage.setItem(RETRY_LAZY_LS_KEY, 'true'); // we are now going to refresh
          return window.location.reload(); // refresh the page
        }
        reject(error);
      });
  });
};

// helper function to wrap lazy imports with retries to avoid chunk load error for out of date code
// same as lazyRetry but with a function wrapper
export const lazyWithRetries: typeof lazy = importer => {
  return lazy(() => lazyRetry(importer));
};
