/**
 The formatLabel function takes a string containing two words, and returns a new
 formatted string where the first word is converted to lowercase and the second
 word is converted to uppercase. If the input string does not contain exactly two
 words, the function will throw an error.
 */
export const formatLabel = (label: string) => {
  const words = label.split(' ');

  if (words.length !== 2) {
    return label.toUpperCase();
  }

  const firstWord = words[0].toLowerCase();
  const secondWord = words[1].toUpperCase();

  return `${firstWord} ${secondWord}`;
};

export const formatToCurrency = (value: number, round?: number) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: round || 0,
  });

  return formatter.format(value);
};
