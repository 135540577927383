import React, { useState } from 'react';

import { Typography } from 'src/components/Typography/Typography';
import Button from 'src/components/UI/Button/Button';
import { Modal2 } from 'src/components/UI/Modal/Modal2';

import HelpSupportIcon from '../../images/help-support.svg';
import WarningIcon from '../../images/warning.svg';

export interface DuplicateSSNModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSignOut: () => void;
  blocking?: boolean;
}

const DuplicateSSNModal: React.FC<DuplicateSSNModalProps> = ({ isOpen, onClose, onSignOut, blocking }) => {
  const [showHelp, setShowHelp] = useState(false);

  const onNeedHelp = () => {
    setShowHelp(true);
  };

  return (
    <Modal2 className="w-full" isOpen={isOpen} onClose={onClose} blocking={blocking}>
      <div className="flex flex-col gap-4">
        {showHelp ? (
          <div className="flex flex-col gap-4">
            <img src={HelpSupportIcon} className="mx-auto h-[64px] w-[64px]" alt="warning icon" />

            <Typography variant="headerMedium">Help Center</Typography>
            <Typography variant="body">
              Toll-free number: <br />
              <a href="tel:+18882219804" className="text-action hover:underline" aria-label="Call (888) 221-9804">
                <b>(888) 221-9804</b>
              </a>
            </Typography>

            <Typography variant="body" className="mb-4">
              Email address: <br />
              <a
                href="mailto:support@getpesto.com"
                className="text-action hover:underline"
                aria-label="Email support@getpesto.com"
              >
                <b>support@getpesto.com</b>
              </a>
            </Typography>
            <Button buttonType="secondary" text="Back" onClick={() => setShowHelp(false)} />
          </div>
        ) : (
          <div className="flex flex-col gap-4">
            <img src={WarningIcon} className="mx-auto h-[64px] w-[64px]" alt="warning icon" />

            <Typography variant="headerMedium">Sign In to Your Existing Account</Typography>
            <Typography variant="body">
              We've detected that you've already used <br />
              this <b>Social Security Number (SSN)</b> in <br />
              another application with us. To maintain <br />
              data accuracy and security, please sign in <br />
              to your existing account.
            </Typography>
            <div className="flex flex-col gap-4">
              <Button buttonType="primary" text="Sign In" onClick={onSignOut} />
              <Button buttonType="secondary" text="Need Help?" onClick={onNeedHelp} />
            </div>
          </div>
        )}
      </div>
    </Modal2>
  );
};

export default DuplicateSSNModal;
