import * as React from 'react';
import type { SVGProps } from 'react';

interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgWatchQaIcon = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 38 50"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeWidth={1.5}
      d="M7.26 7.26V2.04A65.218 65.218 0 0 1 18.74 1c4.46 0 8.43.49 11.477 1.04v5.22M7.261 42.74v5.22c3.047.55 7.017 1.04 11.478 1.04 4.461 0 8.431-.49 11.478-1.04v-5.22M18.74 12.477V25l8.348 8.348m-8.348 9.391c-.949 0-1.875-.022-2.775-.062C6.893 42.27 1 34.08 1 25v0C1 15.92 6.893 7.729 15.964 7.323a62.159 62.159 0 0 1 5.55 0C30.586 7.73 36.478 15.92 36.478 25v0c0 9.08-5.892 17.271-14.964 17.677-.9.04-1.826.062-2.775.062Z"
    />
  </svg>
);
export default SvgWatchQaIcon;
