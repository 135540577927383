import LoadingSpinner from 'src/components/LoadingSpinner/LoadingSpinner';

import SvgCloseRoundIcon from '../../../../icons/components/CloseRoundIcon';
import PlaceholderImage from '../../../../images/image-error-placeholder.svg';
import type { DropFile } from '../ImageUploader';

interface FilePreviewProps {
  file: DropFile;
  onDelete: (file: File) => void;
  isLoading: boolean;
}
export const ImageUploaderPreview = (props: FilePreviewProps) => {
  const { file, onDelete, isLoading } = props;

  const handleDelete = () => {
    if (isLoading) return;
    return onDelete(file);
  };

  const handleError = (event: React.SyntheticEvent) => {
    const target = event.target as HTMLImageElement;
    target.onerror = null;
    target.src = PlaceholderImage;
  };

  return (
    <div key={file.name} className={'relative z-[99]'}>
      {isLoading && (
        <div className="absolute inset-0 flex h-[64px] w-[64px] items-center justify-center bg-black bg-opacity-50 ">
          <LoadingSpinner />
        </div>
      )}

      <div>
        <img
          className="h-[64px] w-[64px] rounded border border-neutralInactive object-cover"
          src={file.preview}
          onError={handleError}
          onLoad={() => {
            URL.revokeObjectURL(file.preview || '');
          }}
          alt={`Preview of ${file.name}`}
        />
      </div>

      {!isLoading && (
        <div
          className="absolute right-0 top-0 -translate-y-4 translate-x-4 p-2 hover:cursor-pointer"
          onClick={handleDelete}
        >
          <SvgCloseRoundIcon className={'h-5 w-5'} />
        </div>
      )}
    </div>
  );
};
