import React from 'react';

import { Typography } from 'src/components/Typography/Typography';
import Button from 'src/components/UI/Button/Button';
import { ActiveIcon } from 'src/icons/components';

interface Feature {
  text: string | React.ReactElement;
}

interface InfoCardProps {
  title: string | React.ReactElement;
  subTitle: string | React.ReactElement;
  description: string | React.ReactElement;
  featureTitle: string | React.ReactElement;
  subDescription: string | React.ReactElement;
  features: Feature[];
  buttonText: string;
  onApplyClick: () => void;
}

const FeatureItem: React.FC<Feature> = ({ text }) => (
  <div className="mb-4 flex text-left">
    <ActiveIcon className="mr-2 h-[24px] w-[24px] shrink-0 text-success" />
    <Typography variant="bodyLarge">{text}</Typography>
  </div>
);

const InfoCard: React.FC<InfoCardProps> = ({
  title,
  description,
  features,
  buttonText,
  subTitle,
  subDescription,
  featureTitle,
  onApplyClick,
}) => (
  <div className="flex w-full flex-col items-center justify-around rounded-2xl border border-[#E0E0E0] bg-[#F9F9F9] px-[50px] py-[30px] md:flex-row md:px-[100px] md:py-[80px]">
    <div className="order-2 text-left md:order-1">
      <Typography variant="headerMedium" className="mb-4">
        {title}
      </Typography>
      <Typography variant="headerMedium" className="mb-4 text-neutralStrong">
        {subTitle}
      </Typography>
      <Typography variant="bodyLarge" className="mb-6">
        {description}
      </Typography>
      <Typography variant="bodyLarge" className="text-[#1C7C4E]">
        {subDescription}
      </Typography>

      <Button onClick={onApplyClick} buttonType="primary" text={buttonText} className="mt-8 !w-[167px]" />
    </div>

    <div className="order-2 mb-6 mt-8 md:order-1 md:mb-0">
      <Typography variant="bodyLarge" className="mb-4 text-left text-neutralStrong">
        {featureTitle}
      </Typography>
      {features.map((feature, index) => (
        <FeatureItem key={index} text={feature.text} />
      ))}
    </div>
  </div>
);

export default InfoCard;
