import { useIsMobile } from 'src/hooks/useIsMobile';

import { Layout } from '../../components/Layout';
import { Sidebar } from '../../components/Sidebar';

import SignUpForm from './components/SignUpForm';

export const NewSignUpDemo = () => {
  const isMobile = useIsMobile();
  return (
    <div className="w-full">
      <Layout
        leftContent={
          isMobile ? null : (
            <div className="h-full">
              <Sidebar />
            </div>
          )
        }
        rightContent={<SignUpForm skipUseEffect />}
      />
    </div>
  );
};
