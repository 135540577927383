import {
  ADMIN_DEBUGGER_LS_KEY,
  FBCLID_LS_KEY,
  FLEX_REF_ID_LS_KEY,
  GCLID_LS_KEY,
  LG_LEAD_ID_LS_KEY,
  LG_SUB_ID_LS_KEY,
  MSCLKID_LS_KEY,
  NERD_WALLET_REF_LS_KEY,
  PESTOANONID_LS_KEY,
  REF_LS_KEY,
  REFERRAL_CODE_LS_KEY,
  TM_SUB_ID_1_KEY,
  TM_SUB_ID_2_KEY,
  TM_SUB_ID_3_KEY,
  UTM_CAMPAIGN_LS_KEY,
  UTM_CONTENT_LS_KEY,
  UTM_MEDIUM_LS_KEY,
  UTM_SOURCE_LS_KEY,
  UTM_STRING_LS_KEY,
} from 'src/constants/localStorageKeys';

export const REFERRAL_CODE_URL_PARAM = 'referralCode';

export interface UtmParams {
  utmSource: string | null;
  utmString: string | null;
  gclid: string | null;
  fbclid: string | null;
  msclkid: string | null;
  utmMedium: string | null;
  utmCampaign: string | null;
  utmContent: string | null;
  ref: string | null;
  pestoAnonId: string | null;
  referralCode: string | null;
  nerdwalletRef: string | null;
  lgSubId?: string | null;
  lgLeadId?: string | null;
  tmSubid1?: string | null;
  tmSubid2?: string | null;
  tmSubid3?: string | null;
  flexRefId?: string | null;
}

export type NonNullUtmParams = {
  [K in keyof UtmParams]: NonNullable<UtmParams[K]>;
};
export const parseURL = (url: string) => {
  return new URL(url);
};
export const getUtm = (): UtmParams => {
  const urlParams = parseURL(window.location.href).searchParams;

  // whole query string
  let utmString: string | null = JSON.stringify(Object.fromEntries(urlParams.entries()));

  // utm params
  let utmSource = urlParams.get('utm_source') || null;
  let utmMedium = urlParams.get('utm_medium');
  let utmCampaign = urlParams.get('utm_campaign');
  let utmContent = urlParams.get('utm_content');

  // other params
  let gclid = urlParams.get('gclid');
  let fbclid = urlParams.get('fbclid');
  let msclkid = urlParams.get('msclkid');

  // referral
  let referralCode = urlParams.get(REFERRAL_CODE_URL_PARAM);

  // affiliate params
  let ref = urlParams.get('ref');
  let pestoAnonId = urlParams.get('pestoAnonId');
  let nerdwalletRef = urlParams.get('subid');

  // lead group
  let lgSubId = urlParams.get('lgSubId');
  let lgLeadId = urlParams.get('lgLeadId');

  let tmSubid1 = urlParams.get('tm_subid1');
  let tmSubid2 = urlParams.get('tm_subid2');
  let tmSubid3 = urlParams.get('tm_subid3');

  let flexRefId = urlParams.get('refid');

  // admin debugger with a secret key
  const adminDebugger = urlParams.get('ISolemnlySwearThatIAmUpToNoGood');

  if (adminDebugger) {
    localStorage.setItem(ADMIN_DEBUGGER_LS_KEY, adminDebugger);
    window.history.replaceState({}, document.title, window.location.pathname);
    window.location.reload();
  }
  if (utmSource && utmString) {
    localStorage.setItem(UTM_STRING_LS_KEY, utmString);
    localStorage.setItem(UTM_SOURCE_LS_KEY, utmSource);
  }

  if (utmMedium) {
    localStorage.setItem(UTM_MEDIUM_LS_KEY, utmMedium);
  }

  if (utmCampaign) {
    localStorage.setItem(UTM_CAMPAIGN_LS_KEY, utmCampaign);
  }

  if (utmContent) {
    localStorage.setItem(UTM_CONTENT_LS_KEY, utmContent);
  }

  if (gclid) {
    localStorage.setItem(GCLID_LS_KEY, gclid);
  }

  if (fbclid) {
    localStorage.setItem(FBCLID_LS_KEY, fbclid);
  }

  if (msclkid) {
    localStorage.setItem(MSCLKID_LS_KEY, msclkid);
  }

  if (ref) {
    localStorage.setItem(REF_LS_KEY, ref);
  }

  if (pestoAnonId) {
    localStorage.setItem(PESTOANONID_LS_KEY, pestoAnonId);
  }

  if (referralCode) {
    localStorage.setItem(REFERRAL_CODE_LS_KEY, referralCode);
  }

  if (nerdwalletRef) {
    localStorage.setItem(NERD_WALLET_REF_LS_KEY, nerdwalletRef);
  }

  if (lgSubId) {
    localStorage.setItem(LG_SUB_ID_LS_KEY, lgSubId);
  }

  if (lgLeadId) {
    localStorage.setItem(LG_LEAD_ID_LS_KEY, lgLeadId);
  }

  if (tmSubid1) {
    localStorage.setItem(TM_SUB_ID_1_KEY, tmSubid1);
  }

  if (tmSubid2) {
    localStorage.setItem(TM_SUB_ID_2_KEY, tmSubid2);
  }

  if (tmSubid3) {
    localStorage.setItem(TM_SUB_ID_3_KEY, tmSubid3);
  }

  if (flexRefId) {
    localStorage.setItem(FLEX_REF_ID_LS_KEY, flexRefId);
  }

  utmString = localStorage.getItem(UTM_STRING_LS_KEY);
  utmSource = localStorage.getItem(UTM_SOURCE_LS_KEY);
  utmMedium = localStorage.getItem(UTM_MEDIUM_LS_KEY);
  utmCampaign = localStorage.getItem(UTM_CAMPAIGN_LS_KEY);
  utmContent = localStorage.getItem(UTM_CONTENT_LS_KEY);
  gclid = localStorage.getItem(GCLID_LS_KEY);
  fbclid = localStorage.getItem(FBCLID_LS_KEY);
  msclkid = localStorage.getItem(MSCLKID_LS_KEY);
  ref = localStorage.getItem(REF_LS_KEY);
  pestoAnonId = localStorage.getItem(PESTOANONID_LS_KEY);
  referralCode = localStorage.getItem(REFERRAL_CODE_LS_KEY);
  nerdwalletRef = localStorage.getItem(NERD_WALLET_REF_LS_KEY);
  lgLeadId = localStorage.getItem(LG_LEAD_ID_LS_KEY);
  lgSubId = localStorage.getItem(LG_SUB_ID_LS_KEY);
  tmSubid1 = localStorage.getItem(TM_SUB_ID_1_KEY);
  tmSubid2 = localStorage.getItem(TM_SUB_ID_2_KEY);
  tmSubid3 = localStorage.getItem(TM_SUB_ID_3_KEY);
  flexRefId = localStorage.getItem(FLEX_REF_ID_LS_KEY);

  return {
    utmSource,
    utmString,
    gclid,
    fbclid,
    msclkid,
    utmMedium,
    utmCampaign,
    utmContent,
    ref,
    pestoAnonId,
    referralCode,
    nerdwalletRef,
    lgLeadId,
    lgSubId,
    tmSubid1,
    tmSubid2,
    tmSubid3,
    flexRefId,
  };
};

export const getOldUtmParams = () => Object.fromEntries(Object.entries(getUtm()).filter(([value]) => !!value));

export const getOldNonNullUtmParamsForTrack = (): NonNullUtmParams => {
  const nonNullUtmParams = Object.entries(getOldUtmParams()).reduce((acc, [key, value]) => {
    if (value) {
      acc.push([key as keyof UtmParams, value]);
    }
    return acc;
  }, [] as [keyof UtmParams, NonNullable<UtmParams[keyof UtmParams]>][]);
  return Object.fromEntries(nonNullUtmParams) as NonNullUtmParams;
};
