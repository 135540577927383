import * as React from 'react';
import type { SVGProps } from 'react';

interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgVeryThick = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <g stroke="currentColor" strokeLinejoin="round" strokeWidth={2.4} clipPath="url(#very-thick_svg__a)">
      <path
        strokeLinecap="round"
        strokeMiterlimit={10}
        d="m22.78 1.22-4.097 4.097M5.317 18.683 1.219 22.78M14.927 9.073l-5.854 5.854"
      />
      <path d="M19.151 4.849c.744.743.744 1.949 0 2.693l-2.536 2.536a1.905 1.905 0 0 1-2.693-2.693l2.536-2.536a1.904 1.904 0 0 1 2.694 0ZM10.078 13.922c.744.743.744 1.95 0 2.693l-2.536 2.537a1.904 1.904 0 1 1-2.694-2.694l2.537-2.536a1.904 1.904 0 0 1 2.693 0Z" />
    </g>
    <defs>
      <clipPath id="very-thick_svg__a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgVeryThick;
