import { useCallback, useState, type ChangeEvent } from 'react';

import { Typography } from '../Typography/Typography';
import { TextField } from '../UI/Form/TextField/TextField';
import { PestoSlider } from '../UI/PestoSlider/PestoSlider';

export enum MaxCaratValue {
  THREE = 'three',
  FIVE = 'five',
}

interface CaratSliderProps {
  onChange: (value: any) => void;
  maxCaratValue: MaxCaratValue;
}

const caratMarksToThree = {
  '0': '0.0',
  0.5: '0.5',
  1: '1.0',
  1.5: '1.5',
  2: '2.0',
  2.5: '2.5',
  3: '3+',
};

const caratMarksToFive = {
  '0': '0.0',
  1: '1.0',
  2: '2.0',
  3: '3.0',
  4: '4.0',
  5: '5+',
};

export const CaratSlider = (props: CaratSliderProps) => {
  const { onChange, maxCaratValue = 'three' } = props;
  const [inputCaratValue, setInputCaratValue] = useState<number | ''>('');

  const caratMarks = maxCaratValue === 'three' ? caratMarksToThree : caratMarksToFive;
  const MAX_CARAT = maxCaratValue === 'three' ? 3 : 5;

  const handleCaratWeightChange = (event: ChangeEvent<HTMLInputElement>) => {
    const inputString = event.target.value;
    const decimalSeparatorCount = (inputString.match(/[,.]/g) || []).length;
    if (decimalSeparatorCount > 1) {
      event.target.value = inputString.replace(/[,.]/g, (match, offset, string) => {
        return string.lastIndexOf(match) === offset ? '.' : '';
      });
    }

    const value = parseFloat(event.target.value.replace(/,/g, '.'));
    if (value > MAX_CARAT) {
      setInputCaratValue(MAX_CARAT);
      onChange(MAX_CARAT);
      return;
    }
    if (value < 0) {
      setInputCaratValue(0);
      onChange(0);
      return;
    }
    setInputCaratValue(isNaN(value) ? '' : value);
    onChange(isNaN(value) ? 0 : value);
  };

  const handleCaratWeight = useCallback(
    (value: number) => {
      setInputCaratValue(value);
      onChange(value);
    },
    [onChange],
  );

  const textFieldStyles =
    'w-[64px] mx-auto text-center font-bold text-neutral [appearance:textfield] focus:text-basicSoft [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none';

  return (
    <>
      <div className="relative my-[16px]">
        <TextField
          className={textFieldStyles}
          name="caratWeight"
          onChange={handleCaratWeightChange}
          type="number"
          inputMode="decimal"
          step="0.01"
          placeholder={'0.00'}
          value={inputCaratValue}
          pattern="[0-9]*[.,]?[0-9]*"
          min="0.00"
          max={MAX_CARAT}
          data-testid="carat-slider--textField-input"
        />
        {inputCaratValue === MAX_CARAT && <span className="absolute top-[8px] ml-[5px] sm:top-[9px]">+</span>}
      </div>
      <PestoSlider value={Number(inputCaratValue)} onChange={handleCaratWeight} marks={caratMarks} max={MAX_CARAT} />
      <Typography variant={'captionSmall'} className="my-[16px] text-[10px] font-light text-neutralSoft">
        slide to adjust
      </Typography>
    </>
  );
};
