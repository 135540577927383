import { yupResolver } from '@hookform/resolvers/yup';
import clsx from 'clsx';
import React, { useCallback, useEffect, useState } from 'react';
import type { SubmitHandler } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { ButtonContainer } from 'src/components/ButtonContainer/ButtonContainer';
import { BackButton } from 'src/components/UI/Button/BackButton';

import { CheckDown } from '../../../../../../components/CheckDown/CheckDown';
import { Typography } from '../../../../../../components/Typography/Typography';
import Button from '../../../../../../components/UI/Button/Button';
import type { DropFile } from '../../../../../../components/UI/ImageUploader/ImageUploader';
import { ImageUploader } from '../../../../../../components/UI/ImageUploader/ImageUploader';

import type { WatchDetailsPayload } from './Watch';

interface WatchInnerScreenProps {
  className?: string;
  onNext: (data: WatchDetailsPayload) => void;
  isLoading?: boolean;
  handleBack: () => void;
}
const validationSchema = yup.object().shape({
  photoLinks: yup.array().required().min(1, 'At least one document is required'),
});
export const WatchDetailsScreen = (props: WatchInnerScreenProps) => {
  const { className, onNext, isLoading = false, handleBack } = props;
  const [isLoadingImages, setIsLoadingImages] = useState(false);

  const {
    handleSubmit,
    watch,
    setValue,
    formState: { errors, isSubmitted, isSubmitting },
    trigger,
  } = useForm<WatchDetailsPayload>({
    mode: 'onSubmit',
    resolver: yupResolver(validationSchema),
    defaultValues: {
      hasOriginalBox: false,
      hasOriginalPapers: false,
      photoLinks: [],
    },
  });

  const formValues = {
    hasOriginalBox: watch('hasOriginalBox'),
    hasOriginalPapers: watch('hasOriginalPapers'),
    photoLinks: watch('photoLinks'),
  };

  useEffect(() => {
    if (isSubmitted || isSubmitting) {
      trigger();
    }
  }, [
    isSubmitted,
    isSubmitting,
    trigger,
    formValues.hasOriginalBox,
    formValues.hasOriginalPapers,
    formValues.photoLinks,
  ]);

  const handleAddPhotoLink = useCallback(
    (value: DropFile[]) => {
      setValue(
        'photoLinks',
        value.map(photo => photo.responseKey),
      );
    },
    [setValue],
  );
  const onSubmit: SubmitHandler<WatchDetailsPayload> = data => {
    onNext({
      hasOriginalBox: data.hasOriginalBox,
      hasOriginalPapers: data.hasOriginalPapers,
      photoLinks: data.photoLinks,
    });
  };

  const rootStyles = clsx('flex flex-col gap-6 max-w-[400px] w-full', className);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={rootStyles}>
      <div className="flex flex-col gap-4">
        <div className="max-w-[300px] self-center">
          <Typography variant={'headerMedium'} className="mb-2">
            More details
          </Typography>
          <Typography variant={'bodySmall'} className="text-neutral">
            If you have the original packaging or any paperwork please check the following options
          </Typography>
        </div>
        <div className="flex w-full flex-col justify-center gap-4 bg-white px-8 py-4 text-center shadow-custom">
          <div className="pb-1 text-left text-xl font-normal text-basicSoft">Do you have any of these?</div>
          <div className="mb-1 h-[1px] w-full bg-neutralSoft" />
          <CheckDown
            onChange={value => setValue('hasOriginalBox', value)}
            checked={formValues.hasOriginalBox}
            title="Original box"
          />
          <CheckDown
            onChange={value => setValue('hasOriginalPapers', value)}
            checked={formValues.hasOriginalPapers}
            title="Original Paperwork"
          />
        </div>
      </div>
      <div className="flex flex-col gap-4">
        <Typography variant={'headerMedium'}>Please upload a photo</Typography>
        <ImageUploader addPhotoLink={handleAddPhotoLink} isLoaded={setIsLoadingImages} />
        {errors.photoLinks && (
          <Typography variant={'bodySmall'} className="text-danger">
            {errors.photoLinks.message}
          </Typography>
        )}
      </div>

      <ButtonContainer
        rightButton={
          <Button
            text={'Next'}
            type={'submit'}
            onClick={handleSubmit(onSubmit)}
            isLoading={isLoadingImages || isLoading}
            disabled={isLoadingImages}
            buttonType={'primary'}
          />
        }
        leftButton={<BackButton onClick={handleBack} rightButtonExist />}
      />
    </form>
  );
};
