import type { FC } from 'react';

import { Typography } from 'src/components/Typography/Typography';
import Button from 'src/components/UI/Button/Button';
import { Modal2 } from 'src/components/UI/Modal/Modal2';

import SecurityBg from '../assets/SecurityBg.svg';

interface SecureModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const SecureModal: FC<SecureModalProps> = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <Modal2 isOpen={isOpen} onClose={onClose}>
      <div className="flex flex-col gap-4 text-left">
        <Typography variant="headerSmall" className="font-bold">
          Security
        </Typography>

        <Typography variant="body" className="font-bold">
          Pesto uses 256-bit Secure Sockets Layer <br /> (SSL) technology. This means that when <br /> you are on our
          website, the data <br /> transferred between Pesto and you is <br /> encrypted.
        </Typography>

        <img src={SecurityBg} alt="pesto card" />

        <Button
          buttonType="secondary"
          text="Continue"
          onClick={() => {
            onClose();
          }}
        />
      </div>
    </Modal2>
  );
};
