import { PaymentPreference } from 'src/__generated__/graphql/api';

import type { CashSecuredApplicationForm } from './types';

export const PAYMENT_PREFERENCE = {
  fundNow: PaymentPreference.PayInFull,
  fundOverTime: PaymentPreference.PayOverTime,
};

export const SECURITY_TYPE_AND_DETAILS = 'CASH_SECURED_APPLICATION';

export const requiredFields = [
  'firstName',
  'middleName',
  'lastName',
  'email',
  'phoneNumber',
  'income',
  'dob',
  'address',
  'suite',
  'city',
  'state',
  'zip',
];

export const DEFAULT_FORM_DATA: CashSecuredApplicationForm = {
  firstName: '',
  middleName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
  income: 0,
  address: '',
  suite: '',
  city: '',
  state: '',
  zip: '',
  amount: '200',
  paymentPreference: PAYMENT_PREFERENCE.fundNow,
  type: '',
  lgSubId: '',
  lgLeadId: '',
  tmSubid1: '',
  tmSubid2: '',
  tmSubid3: '',
};
