import { useEffect, useState } from 'react';

import type { CurrentUserQuery } from '../../../__generated__/graphql/api';
import { PhoneField } from '../../UI/Form/PhoneField/PhoneField';
import { TextField } from '../../UI/Form/TextField/TextField';
import { EditableInfo } from '../EditableInfo/EditableInfo';

interface PersonalInformationProps {
  className?: string;
  user?: CurrentUserQuery['currentUser'];
  editable?: boolean;
}

export const PersonalInformation = (props: PersonalInformationProps) => {
  const { className, user, editable = false } = props;
  const [edit, setEdit] = useState(false);
  const [formValues, setFormValues] = useState({
    firstName: user?.firstName || '',
    middleName: user?.middleName || '',
    lastName: user?.lastName || '',
    phone: user?.phone || '',
  });

  useEffect(() => {
    if (user) {
      setFormValues({
        firstName: user.firstName || '',
        middleName: user.middleName || '',
        lastName: user.lastName || '',
        phone: user.phone || '',
      });
    }
  }, [user]);

  return (
    <EditableInfo
      title={'Personal information'}
      className={className}
      editable={editable}
      editState={edit}
      onEdit={(edit: boolean) => {
        setEdit(edit);
      }}
    >
      <form className="grid grid-cols-1 gap-x-2 gap-y-3 text-left sm:grid-cols-[repeat(auto-fill,_minmax(_200px,_1fr))] sm:gap-x-8 sm:gap-y-4 md:grid-cols-[repeat(auto-fill,_minmax(_max(_120px,_100%/3),_1fr))]">
        <TextField
          onChange={e => setFormValues({ ...formValues, firstName: e.target.value })}
          onBlur={e => setFormValues({ ...formValues, firstName: e.target.value.trim() })}
          label="First name"
          value={formValues.firstName}
          required={edit}
          disabled={!edit}
          readOnly={!edit}
        />
        <TextField
          onChange={e => setFormValues({ ...formValues, middleName: e.target.value })}
          onBlur={e => setFormValues({ ...formValues, middleName: e.target.value.trim() })}
          label="Middle name"
          value={formValues.middleName}
          disabled={!edit}
          readOnly={!edit}
        />

        <TextField
          onChange={e => setFormValues({ ...formValues, lastName: e.target.value })}
          onBlur={e => setFormValues({ ...formValues, lastName: e.target.value.trim() })}
          label="Last name"
          value={formValues.lastName}
          required={edit}
          disabled={!edit}
          readOnly={!edit}
        />
        <PhoneField
          onChange={e => setFormValues({ ...formValues, phone: e })}
          value={formValues.phone}
          label="Phone"
          required={edit}
          disabled={!edit}
          readOnly={!edit}
        />
      </form>
    </EditableInfo>
  );
};
