const ALWAYS_LOWERCASE = [
  'step',
  'items',
  'to',
  'a',
  'an',
  'and',
  'as',
  'at',
  'but',
  'by',
  'for',
  'from',
  'in',
  'nor',
  'of',
  'on',
  'or',
  'so',
  'the',
  'up',
  'with',
  'yet',
  'cart',
  'now',
];

export const toSentenceCase = (str: string) => {
  return str
    .split(' ')
    .map((word, index) => {
      if (index !== 0 && ALWAYS_LOWERCASE.includes(word.toLowerCase())) {
        return word.toLowerCase();
      } else {
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      }
    })
    .join(' ');
};
