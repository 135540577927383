import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';

import { ReactAppEnv } from './constants/global';

const env = ReactAppEnv;
const SITE = 'datadoghq.com';
const SERVICE = 'hubble';

datadogLogs.init({
  clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN || '',
  site: SITE,
  service: SERVICE,
  forwardErrorsToLogs: true,
  forwardConsoleLogs: ['error', 'warn'],
  sessionSampleRate: 100,
  env,
});

datadogRum.init({
  applicationId: process.env.REACT_APP_DATADOG_CLIENT_APPLICATION_ID || '',
  clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN || '',
  site: SITE,
  service: SERVICE,
  env,
  sessionSampleRate: 100,
  sessionReplaySampleRate: 20,
  trackUserInteractions: true,
  trackFrustrations: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: 'mask-user-input',
});

datadogRum.startSessionReplayRecording();
