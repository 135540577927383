import type { ReactNode } from 'react';
import React from 'react';

import { Brand } from '../__generated__/graphql/api';
import {
  AudemarsPiguetLogo,
  BreitlingLogo,
  BulgaryLogo,
  CartierLogo,
  Chanel,
  DavidYurman,
  Faberge,
  Gucci,
  HermesLogo,
  OmegaLogo,
  PatekPhilippeLogo,
  RolexLogo,
  Tiffany,
  VanCleef,
} from '../icons/components/brands';

const logoStyle = 'h-[40px] w-full';
export const jewelryBrands: { value: Brand; text: string; logo: ReactNode; dataTestId: string }[] = [
  {
    value: Brand.Unbranded,
    text: 'Unbranded',
    logo: null,
    dataTestId: 'jewelry-brand--unbranded',
  },
  {
    value: Brand.Bvlgari,
    text: 'Bvlgari',
    logo: <BulgaryLogo className={logoStyle} />,
    dataTestId: 'jewelry-brand--bulgary',
  },
  {
    value: Brand.Cartier,
    text: 'Cartier',
    logo: <CartierLogo className={logoStyle} />,
    dataTestId: 'jewelry-brand--cartier',
  },
  {
    value: Brand.Chanel,
    text: 'Chanel',
    logo: <Chanel className={logoStyle} />,
    dataTestId: 'jewelry-brand--chanel',
  },
  {
    value: Brand.DavidYurman,
    text: 'David Yurman',
    logo: <DavidYurman className={logoStyle} />,
    dataTestId: 'jewelry-brand--david-yurman',
  },
  {
    value: Brand.Faberge,
    text: 'Faberge',
    logo: <Faberge className={logoStyle} />,
    dataTestId: 'jewelry-brand--faberge',
  },
  {
    value: Brand.Gucci,
    text: 'Gucci',
    logo: <Gucci className={logoStyle} />,
    dataTestId: 'jewelry-brand--gucci',
  },
  {
    value: Brand.Hermes,
    text: 'Hermès',
    logo: <HermesLogo className={logoStyle} />,
    dataTestId: 'jewelry-brand--hermes',
  },
  {
    value: Brand.Tiffany,
    text: 'Tiffany & Co',
    logo: <Tiffany className={logoStyle} />,
    dataTestId: 'jewelry-brand--tiffany',
  },
  {
    value: Brand.Vca,
    text: 'Van Cleef & Arpels Philippe',
    logo: <VanCleef className={logoStyle} />,
    dataTestId: 'jewelry-brand--van-cleef',
  },
];

export const watchBrands: { value: Brand; text: string; logo: ReactNode }[] = [
  {
    value: Brand.AudemarsPiguet,
    text: 'Audemars Piguet',
    logo: <AudemarsPiguetLogo className={logoStyle} />,
  },
  {
    value: Brand.Breitling,
    text: 'Breitling',
    logo: <BreitlingLogo className={logoStyle} />,
  },
  {
    value: Brand.Bvlgari,
    text: 'Bvlgari',
    logo: <BulgaryLogo className={logoStyle} />,
  },
  {
    value: Brand.Cartier,
    text: 'Cartier',
    logo: <CartierLogo className={logoStyle} />,
  },
  {
    value: Brand.Hermes,
    text: 'Hermès',
    logo: <HermesLogo className={logoStyle} />,
  },
  {
    value: Brand.Omega,
    text: 'Omega',
    logo: <OmegaLogo className={logoStyle} />,
  },
  {
    value: Brand.PatekPhillipe,
    text: 'Patek Philippe',
    logo: <PatekPhilippeLogo className={logoStyle} />,
  },
  {
    value: Brand.Rolex,
    text: 'Rolex',
    logo: <RolexLogo className={logoStyle} />,
  },
  {
    value: Brand.Unbranded,
    text: 'Other',
    logo: null,
  },
  {
    value: Brand.Unbranded,
    text: "I don't know",
    logo: null,
  },
];
