import * as React from 'react';
import type { SVGProps } from 'react';

interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgCloseRoundIcon = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 19 18"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <rect width={17} height={17} x={1} y={0.5} fill="#fff" rx={8.5} />
    <path
      fill="#101820"
      d="M6.082 4.875a.5.5 0 0 0-.707.707l3.182 3.182-3.417 3.418a.5.5 0 1 0 .707.707l3.417-3.418 3.654 3.654a.5.5 0 1 0 .707-.707L9.97 8.764l3.418-3.417a.5.5 0 1 0-.707-.707L9.264 8.057 6.082 4.875Z"
    />
    <rect width={17} height={17} x={1} y={0.5} stroke="#C3C5C7" rx={8.5} />
  </svg>
);
export default SvgCloseRoundIcon;
