import clsx from 'clsx';
import type { ReactElement } from 'react';
import { Fragment } from 'react';

import { Typography } from 'src/components/Typography/Typography';

import { I_DONT_KNOW, OTHER } from '../../../../../constants/application';
import { goldKarats } from '../../../../../data/gold';
import { platinumTypes } from '../../../../../data/platinum';
import { silverTypes } from '../../../../../data/silver';

import { InnerDropButton } from './InnerDropButton';
import { necklaceLengthList } from './NecklaceLengthDropdown';

type OptionType<T> = {
  text: string;
  value: T;
  icon?: ReactElement;
};

interface DropdownContentProps<T> {
  options: Array<OptionType<T>>;
  onChange: (value: T) => void;
  isShowIcon?: boolean;
  alwaysOpen?: boolean;
  selectedValue?: T;
}
const DropdownContent = <T,>({ options, isShowIcon, onChange, alwaysOpen, selectedValue }: DropdownContentProps<T>) => {
  return (
    <div className="grid grid-cols-4 flex-wrap gap-2">
      {options.map(option => {
        const spreadLastRow = option.text === I_DONT_KNOW || option.text === OTHER;

        const goldKaratsTextArray = goldKarats.map(item => item.text);
        const silverKaratsTextArray = silverTypes.map(item => item.text);
        const platinumKaratsTextArray = platinumTypes.map(item => item.text);
        const necklaceLengthTextArray = necklaceLengthList.map(item => item.text);

        const isKaratText =
          goldKaratsTextArray.includes(option.text) ||
          silverKaratsTextArray.includes(option.text) ||
          platinumKaratsTextArray.includes(option.text) ||
          necklaceLengthTextArray.includes(option.text);

        const itemClassNames = clsx(
          'aspect-square w-full !items-center !justify-center',

          {
            'w-full h-16 flex col-span-4 !items-center !justify-center': spreadLastRow,
          },
        );

        return (
          <Fragment key={String(option.value)}>
            <InnerDropButton
              isSelected={alwaysOpen && selectedValue === option.value}
              onClick={() => onChange(option.value)}
              className={itemClassNames}
            >
              {isShowIcon ? (
                <>
                  {option.icon}
                  <Typography className="mt-2" variant="bodySmall">
                    {option.text}
                  </Typography>
                </>
              ) : (
                <Typography
                  variant={isKaratText && !spreadLastRow ? 'headerMedium' : 'body'}
                  className={necklaceLengthTextArray && 'font-light'}
                >
                  {option.text}
                </Typography>
              )}
            </InnerDropButton>
          </Fragment>
        );
      })}
    </div>
  );
};
export default DropdownContent;
