import { memo, useCallback, useEffect, useState } from 'react';

import CheckBox from 'src/components/CheckBox/CheckBox';
import { cn } from 'src/util/cn';

import type { CurrentUserQuery } from '../../../__generated__/graphql/api';
import { BillingAddress } from '../BillingAddress/BillingAddress';
import { useBillingAddress } from '../BillingAddress/hooks/useBillingAddress';
import { ShippingAddress } from '../ShippingAddress/ShippingAddress';
import { useShippingAddress } from '../ShippingAddress/hooks/useShippingAddress';

interface AddressOrShippingProps {
  className?: string;
  user: CurrentUserQuery['currentUser'];
  billingEditable?: boolean;
  billingCollapsed?: boolean;
  onCheckboxOrShippingChange?: () => void;
}
export const AddressOrShipping = memo((props: AddressOrShippingProps) => {
  const { className, user, billingEditable = false, billingCollapsed = false, onCheckboxOrShippingChange } = props;
  const wrapperStyle = cn(className, 'flex flex-col gap-4');
  const { setBillingAsShipping } = useBillingAddress(user);
  const { isShippingSet } = useShippingAddress(user);
  const [shippingCheckboxValue, setShippingCheckboxValue] = useState(true);

  useEffect(() => {
    setShippingCheckboxValue(!isShippingSet);
  }, [isShippingSet]);

  const handleShippingCheckboxChange = useCallback(() => {
    if (!shippingCheckboxValue) {
      setShippingCheckboxValue(true);
      setBillingAsShipping(true)
        .then(() => {
          onCheckboxOrShippingChange && onCheckboxOrShippingChange();
        })
        .catch(() => {
          setShippingCheckboxValue(false);
        });
    } else {
      setShippingCheckboxValue(false);
    }
  }, [shippingCheckboxValue, setBillingAsShipping, onCheckboxOrShippingChange]);

  return (
    <div className={wrapperStyle}>
      <BillingAddress
        title={'Billing Address'}
        user={user}
        editable={billingEditable}
        collapsed={billingCollapsed && billingCollapsed}
      />
      <CheckBox
        label="shipping-checkbox"
        value={shippingCheckboxValue}
        text="My billing address is also my shipping address"
        onChange={() => {
          handleShippingCheckboxChange();
        }}
      />
      {!shippingCheckboxValue && (
        <ShippingAddress
          title={'Shipping Address'}
          user={user}
          editable
          onCompleted={() => onCheckboxOrShippingChange && onCheckboxOrShippingChange()}
        />
      )}
    </div>
  );
});
