import type { Ref } from 'react';
import { forwardRef } from 'react';

import { cn } from 'src/util/cn';

import type { TextAreaProps } from '../FormTypes';
import { TextFieldWrapper } from '../TextFieldWrapper/TextFieldWrapper';
import { generateInputStyles } from '../inputStylesHelper';

export const TextArea = forwardRef((props: TextAreaProps, ref: Ref<HTMLTextAreaElement>) => {
  const {
    onChange,
    defaultValue,
    label,
    caption,
    error,
    errorText,
    rightHelperText,
    required,
    readOnly,
    textAlign,
    inputSize,
    ...textAreaProps
  } = props;

  const isErrorVisible = !!error;
  const inputStyle = generateInputStyles({
    isErrorVisible,
    readOnly,
    textAlign,
    inputSize,
  });

  const combinedStyles = cn(inputStyle, '-mb-1', '!w-full !min-h-[100px]');

  return (
    <TextFieldWrapper
      errorText={errorText}
      error={!!error}
      label={label}
      caption={caption}
      required={required}
      textAlign={'left'}
      rightHelperText={rightHelperText}
    >
      <textarea
        ref={ref}
        className={combinedStyles}
        onChange={onChange}
        defaultValue={defaultValue}
        placeholder={label}
        {...textAreaProps}
      />
    </TextFieldWrapper>
  );
});
