import * as React from 'react';
import type { SVGProps } from 'react';

interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgHaloRingStyle = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <circle cx={12} cy={15.5} r={6.5} stroke="#101820" strokeLinecap="round" strokeWidth={1.5} />
    <path stroke="#101820" strokeWidth={1.5} d="m9.815 5.998 2.23 1.62 2.231-1.62-.852-2.623h-2.757l-.852 2.623Z" />
    <path stroke="#101820" strokeLinecap="round" strokeWidth={1.5} d="M5.955 8h12" />
  </svg>
);
export default SvgHaloRingStyle;
