import { memo } from 'react';

import type { CurrentUserQuery } from 'src/__generated__/graphql/api';
import { Typography } from 'src/components/Typography/Typography';

import LoadingSpinner from '../../LoadingSpinner/LoadingSpinner';
import { AddressInnerForm } from '../AddressInnerForm/AddressInnerForm';
import { EditableInfo } from '../EditableInfo/EditableInfo';

import { useShippingAddress } from './hooks/useShippingAddress';

interface ShippingAddressProps {
  className?: string;
  title?: string;
  user?: CurrentUserQuery['currentUser'];
  editable?: boolean;
  onSave?: () => void;
  onCompleted?: () => void;
}

export const ShippingAddress = memo((props: ShippingAddressProps) => {
  const { className, title = 'Address information', user, editable = false, onSave, onCompleted } = props;

  const {
    edit,
    setEdit,
    isFetching,
    formValues,
    responseError,
    formErrors,
    isSaving,
    setValue,
    handleSubmitForm,
    handleReset,
    setFocus,
  } = useShippingAddress(user, onCompleted);

  return (
    <EditableInfo
      title={title}
      className={className}
      editable={editable}
      editState={edit}
      onEdit={(edit: boolean) => {
        setEdit(edit);
      }}
      onClose={handleReset}
      onSave={() => {
        onSave && onSave();
        handleSubmitForm();
      }}
      isSaving={isSaving}
    >
      {isFetching ? (
        <LoadingSpinner />
      ) : (
        <>
          {responseError && (
            <Typography variant="body" className="text-danger">
              Something went wrong. Please try again.
            </Typography>
          )}
          <AddressInnerForm
            formValues={formValues}
            formErrors={formErrors}
            setFormValues={setValue}
            editable={edit}
            setFocus={setFocus}
            onSubmit={() => {
              onSave && onSave();
              handleSubmitForm();
            }}
          />
        </>
      )}
    </EditableInfo>
  );
});
