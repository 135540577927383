import * as yup from 'yup';

import {
  addressFirstLineValidation,
  cityValidation,
  emailValidation,
  firstNameValidation,
  lastNameValidation,
  middleNameValidation,
  phoneNumberValidation,
  stateValidation,
  zipValidation,
} from 'src/util/sharedFieldValidations';

import { replaceDollarSignAndComas } from '../../util/currencyHelper';

export const validationSchema = yup.object().shape({
  firstName: firstNameValidation,
  middleName: middleNameValidation,
  lastName: lastNameValidation,
  email: emailValidation,
  phoneNumber: phoneNumberValidation,
  address: addressFirstLineValidation,
  suite: yup.string().optional(),
  city: cityValidation,
  state: stateValidation,
  zip: zipValidation,
  income: yup.number().min(5000, 'Please enter a valid income').required('Total Annual Income is required'),

  agreement2: yup.boolean().oneOf([true], 'Agreement is required'),
  amount: yup
    .string()
    .required('Amount is required')
    .transform(replaceDollarSignAndComas)
    .test('min', 'Amount cannot be less than 200', value => {
      return Number(value) >= 200;
    }),
});
