import { Typography } from '../../../../../../components/Typography/Typography';
import { Modal2 } from '../../../../../../components/UI/Modal/Modal2';

interface OtherDiamondModalProps {
  isModalOpen: boolean;
  closeModal: () => void;
}
export const OtherDiamondModal = (props: OtherDiamondModalProps) => {
  const { isModalOpen, closeModal } = props;
  return (
    <Modal2 theme="dark" isOpen={isModalOpen} onClose={closeModal}>
      <div className="flex flex-col items-center justify-center gap-4">
        <Typography variant="body" className="text-base text-altWhite">
          Select this option for colored diamonds—black, yellow, and pink.
        </Typography>
        <Typography variant="body" className="text-base text-altWhite">
          Also select this option for diamond alternatives—moissanite, morganite, and cubic zirconium.
        </Typography>
      </div>
    </Modal2>
  );
};
