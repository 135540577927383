import type { IconProps } from './IconHelper';

export const IconJewelry = (props: IconProps) => {
  const { className, stroke } = props;
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12" cy="8" r="6" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeDasharray="2 4" />
      <path
        d="M10.5849 18.1081L12 16.9644L13.4151 18.1081V20.8919L12 22.0356L10.5849 20.8919V18.1081Z"
        stroke={stroke}
        strokeWidth="1.5"
      />
    </svg>
  );
};
