import clsx from 'clsx';

import { ButtonContainer } from 'src/components/ButtonContainer/ButtonContainer';
import { BackButton } from 'src/components/UI/Button/BackButton';

import { Typography } from '../../../../../../components/Typography/Typography';
import NecklaceLengthDropdown from '../../components/NecklaceLengthDropdown';

interface LengthScreenProps {
  className?: string;
  onNext: (necklaceLength: number) => void;
  handleBack: () => void;
}

const Length = (props: LengthScreenProps) => {
  const { className, onNext, handleBack } = props;

  const rootStyles = clsx('flex flex-col gap-4 max-w-[400px] w-full', className);

  return (
    <div className={rootStyles}>
      <Typography variant={'headerMedium'}>What is the length?</Typography>
      <div className="flex w-full flex-col justify-center gap-2 text-center">
        <NecklaceLengthDropdown onChange={onNext} alwaysOpen />
      </div>

      <ButtonContainer leftButton={<BackButton rightButtonExist={false} onClick={handleBack} />} />
    </div>
  );
};

export default Length;
