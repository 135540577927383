import * as React from 'react';
import type { SVGProps } from 'react';

interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgThin = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <g stroke="currentColor" strokeLinejoin="round" strokeWidth={1.2} clipPath="url(#thin_svg__a)">
      <path
        strokeLinecap="round"
        strokeMiterlimit={10}
        d="m23.024 1.073-4.097 4.098M5.17 18.927l-4.097 4.097M14.976 9.122l-5.854 5.854"
      />
      <path d="M19.722 4.376a2.504 2.504 0 0 1 0 3.541l-2.537 2.537a2.504 2.504 0 0 1-3.541-3.542l2.536-2.536a2.504 2.504 0 0 1 3.542 0ZM10.454 13.644a2.505 2.505 0 0 1 0 3.542l-2.537 2.536a2.504 2.504 0 0 1-3.542-3.542l2.537-2.536a2.504 2.504 0 0 1 3.542 0Z" />
    </g>
    <defs>
      <clipPath id="thin_svg__a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgThin;
