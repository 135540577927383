import { format } from 'date-fns';

export enum LogLevel {
  TRACE = 0,
  INFO = 1,
  WARN = 2,
  ERROR = 3,
}

export function requestParser(request: any) {
  function setKeyAndValue(key: string) {
    if (key === 'params') {
      return `${key}: ${JSON.stringify(request[key] || [])}`;
    }
    return `${key}: ${request[key]}`;
  }

  return Object.keys(request).reduce((acc, key) => {
    return acc ? `${acc}\n${setKeyAndValue(key)}` : setKeyAndValue(key);
  }, ``);
}

const style = (color: string, bold = true) => {
  return `color:${color};font-weight:${bold ? '600' : '300'};font-size:11px`;
};

const now = () => format(new Date(), 'HH:mm:ss.SS');

export function formatAndLog(
  message: string,
  value: any,
  config?: {
    color?: string;
  },
) {
  console.groupCollapsed('%c%s  %s', style(config?.color ?? '#cccccc'), now(), message);
  value && console.log(value?.data ? requestParser(value.data) : value);
  console.groupEnd();
}

class Logger {
  private level: LogLevel = LogLevel.ERROR;

  setLevel = (level: LogLevel) => {
    this.level = level;
  };

  shouldLog = (level: LogLevel) => {
    return level >= this.level;
  };

  trace = (message: string, value?: any) => {
    if (this.shouldLog(LogLevel.TRACE)) {
      formatAndLog(message, value, { color: 'gray' });
    }
  };

  info = (message: string, value?: any) => {
    if (this.shouldLog(LogLevel.INFO)) {
      formatAndLog(message, value, { color: 'purple' });
    }
  };

  warn = (message: string, value?: any) => {
    if (this.shouldLog(LogLevel.WARN)) {
      formatAndLog(message, value, { color: 'orange' });
    }
  };

  error = (message: string, value?: any) => {
    if (this.shouldLog(LogLevel.ERROR)) {
      formatAndLog(message, value, { color: 'red' });
    }
  };
}

export default new Logger();
