import { useContext } from 'react';

import { useCurrentUserQuery } from 'src/__generated__/graphql/api';
import { useGoRoutes } from 'src/hooks/useGoRoutes';
import { URLContext } from 'src/providers/URLProvider';

export const useNavigateOnStatus = () => {
  const { getUrlOnStatus } = useContext(URLContext);
  const { navigate } = useGoRoutes();
  const { data } = useCurrentUserQuery();
  const user = data?.currentUser;

  const securityType = user?.creditApplication?.securityType;
  const userStatus = user?.creditApplication?.status;

  return () => {
    if (securityType && userStatus) {
      navigate(getUrlOnStatus(securityType, userStatus));
    }
  };
};
