import * as React from 'react';
import type { SVGProps } from 'react';

interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgCoins = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 40 40"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <g fill="currentColor" clipPath="url(#coins_svg__a)">
      <path d="M24.8 0c-5.17 0-9.936 2.595-12.752 6.942a.8.8 0 0 0 1.344.87A13.543 13.543 0 0 1 24.8 1.6c7.5 0 13.6 6.1 13.6 13.6 0 4.626-2.322 8.89-6.211 11.408a.799.799 0 0 0-.235 1.106.794.794 0 0 0 1.105.238A15.146 15.146 0 0 0 40 15.2C40 6.82 33.18 0 24.8 0Z" />
      <path d="M15.2 9.6C6.82 9.6 0 16.42 0 24.8 0 33.18 6.82 40 15.2 40c8.38 0 15.2-6.82 15.2-15.2 0-8.38-6.82-15.2-15.2-15.2Zm0 28.8c-7.5 0-13.6-6.1-13.6-13.6s6.1-13.6 13.6-13.6 13.6 6.1 13.6 13.6-6.1 13.6-13.6 13.6Z" />
      <path d="M19.2 21.6a.8.8 0 0 0 1.6 0c0-1.709-.893-3.7-4.8-3.95v-.85a.8.8 0 0 0-1.6 0v.85c-3.907.25-4.8 2.241-4.8 3.95 0 2.523 2.869 3.302 4.8 3.814v4.956c-2.199-.138-3.2-.882-3.2-2.37a.8.8 0 0 0-1.6 0c0 1.709.893 3.7 4.8 3.95v.85a.8.8 0 0 0 1.6 0v-.85c3.907-.25 4.8-2.241 4.8-3.95 0-2.523-2.869-3.302-4.8-3.814V19.23c2.198.138 3.2.882 3.2 2.37Zm-4.8 2.155c-1.734-.48-3.2-1.04-3.2-2.155 0-1.488 1.002-2.232 3.2-2.37v4.525ZM19.2 28c0 1.488-1.002 2.232-3.2 2.37v-4.525c1.734.48 3.2 1.04 3.2 2.155Z" />
    </g>
    <defs>
      <clipPath id="coins_svg__a">
        <path fill="#fff" d="M0 0h40v40H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgCoins;
