import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import {
  addressFirstLineValidation as address,
  cityValidation as cityName,
  stateValidation as cityState,
  zipValidation as zipCode,
} from 'src/util/sharedFieldValidations';

import type { CurrentUserQuery } from '../../../../__generated__/graphql/api';
import { useGetShippingAddressQuery, useUpdateAddressMutation } from '../../../../__generated__/graphql/api';
import { apolloClient } from '../../../../api/Apollo';
import logger from '../../../../util/logger';

export const ERROR_MESSAGES = {
  address: 'Please enter your address',
  city: 'Please enter your city',
  state: 'Please enter your state',
  zip: 'Please enter your zip code',
};

export const useShippingAddress = (user: CurrentUserQuery['currentUser'], onCompleted?: () => void) => {
  const [edit, setEdit] = useState(false);
  const [isShippingSet, setIsShippingSet] = useState(false);
  const [initialFormValues, setInitialFormValues] = useState({});
  const [responseError, setResponseError] = useState('');

  const addressInnerFormSchema = yup.object().shape({
    addressFirstLine: address,
    city: cityName,
    state: cityState,
    zip: zipCode,
  });

  const {
    handleSubmit,
    setValue,
    reset,
    watch,
    formState: { errors, isSubmitted },
    trigger,
    setFocus,
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(addressInnerFormSchema),
    defaultValues: {
      addressFirstLine: '',
      suiteNumber: '',
      city: '',
      state: '',
      zip: '',
    },
  });

  const formValues = {
    addressFirstLine: watch('addressFirstLine'),
    suiteNumber: watch('suiteNumber'),
    city: watch('city'),
    state: watch('state'),
    zip: watch('zip'),
  };

  const formErrors = {
    addressFirstLine: errors.addressFirstLine,
    city: errors.city,
    state: errors.state,
    zip: errors.zip,
  };

  const { addressFirstLine, suiteNumber, city, state, zip } = formValues;

  useEffect(() => {
    if (isSubmitted) {
      trigger();
    }
  }, [isSubmitted, trigger, addressFirstLine, suiteNumber, city, state, zip]);

  const { loading: isFetching } = useGetShippingAddressQuery({
    errorPolicy: 'all',
    client: apolloClient,
    onCompleted: data => {
      if (data?.currentUser?.shippingAddress) {
        const { shippingAddress } = data.currentUser;

        setValue('addressFirstLine', shippingAddress.addressFirstLine || '');
        setValue('suiteNumber', shippingAddress.suiteNumber || '');
        setValue('city', shippingAddress.city || '');
        setValue('state', shippingAddress.state || '');
        setValue('zip', shippingAddress.zip || '');

        setInitialFormValues({
          addressFirstLine: shippingAddress.addressFirstLine || '',
          suiteNumber: shippingAddress.suiteNumber || '',
          city: shippingAddress.city || '',
          state: shippingAddress.state || '',
          zip: shippingAddress.zip || '',
        });

        if (shippingAddress) {
          setIsShippingSet(!shippingAddress?.isBilling);
        }
      }
    },
    onError: error => {
      logger.warn('error useGetShippingAddressQuery', error);
    },
  });

  const [updateAddressMutation, { loading: isSaving }] = useUpdateAddressMutation({
    variables: {
      creditApplicationId: user?.creditApplication?.id || '',
      addressInput: {
        addressFirstLine: formValues.addressFirstLine,
        suiteNumber: formValues.suiteNumber,
        city: formValues.city,
        state: formValues.state,
        zip: formValues.zip,
        isPoBox: false,
        isShipping: true,
        isBilling: false,
      },
    },
    errorPolicy: 'all',
    client: apolloClient,
  });

  const handleSubmitForm = handleSubmit(() => {
    updateAddressMutation()
      .then(() => {
        setResponseError('');
        setEdit(false);
        onCompleted && onCompleted();
      })
      .catch(err => {
        setResponseError(err.message);
      });
  });

  const handleReset = () => {
    const hasErrors = Object.keys(errors).length > 0;

    if (hasErrors) {
      setResponseError('Please correct the errors in the form before resetting.');
    }

    reset(initialFormValues);
    setResponseError('');
  };

  return {
    edit,
    setEdit,
    isFetching,
    responseError,
    formValues,
    formErrors,
    isSaving,
    setValue,
    handleSubmitForm,
    handleReset,
    isShippingSet,
    setFocus,
  };
};
