import { Typography } from '../../../../components/Typography/Typography';
import Button from '../../../../components/UI/Button/Button';

import { AmountEnterCard } from './AmountEnterCard';

interface AmountHeroInputProps {
  inputRef: React.RefObject<HTMLInputElement>;
  amount: string | null | undefined;
  errorAmount: any;
  onAmountChange: (amount: string) => void;
  onContinue: () => void;
}
export const AmountHeroInput = (props: AmountHeroInputProps) => {
  const { onAmountChange, onContinue, errorAmount, amount, inputRef } = props;

  return (
    <div className="flex w-full max-w-[750px] flex-col items-center gap-8 rounded-2xl bg-[#2A2A2A] p-8">
      <Typography variant={'headerLarge'} className="text-white">
        What’s your deposit?
      </Typography>
      <AmountEnterCard
        inputRef={inputRef}
        className={'max-w-[355px]'}
        amount={amount || '200'}
        errorAmount={errorAmount}
        onAmountChange={onAmountChange}
      />
      <Button
        className={'w-auto max-w-fit self-center invert'}
        buttonType={'primary'}
        text={'Continue'}
        onClick={onContinue}
      />
    </div>
  );
};
