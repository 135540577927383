import { useEffect, useState } from 'react';
import type { FileError } from 'react-dropzone';
import { useDropzone } from 'react-dropzone';

import { cn } from 'src/util/cn';

import { MAX_IMAGE_FILES_UPLOAD, MAX_IMAGE_UPLOAD_SIZE, MEGA_BYTE } from '../../../constants/application';
import { useIsMobile } from '../../../hooks/useIsMobile';
import SvgDownloadIcon from '../../../icons/components/DownloadIcon';
import { cleanFilesArray } from '../../../util/filesUploaderHelper';
import { Typography } from '../../Typography/Typography';

import { ImageUploaderPreview } from './components/ImageUploaderPreview';
import useImageUploader from './hooks/useImageUploader';

interface FileUploaderProps {
  addPhotoLink: (files: File[]) => void;
  label?: string;
  responseKeys?: (keys: string[] | undefined) => void;
  isLoaded?: (bool: boolean) => void;
  maxSize?: number;
  maxFiles?: number;
  condensed?: boolean;
  required?: boolean;
}

export interface DropFile extends File {
  preview?: string;
  path?: string;
  responseKey?: string;
  imgId?: string;
  isLoading?: boolean;
}

type FileValidationFunction = (file: File) => FileError | Array<FileError> | null;
export const ImageUploader = (props: FileUploaderProps) => {
  const {
    addPhotoLink,
    isLoaded,
    responseKeys,
    maxSize = MAX_IMAGE_UPLOAD_SIZE,
    maxFiles = MAX_IMAGE_FILES_UPLOAD,
    condensed = false,
    required = false,
    label,
  } = props;

  const isMobile = useIsMobile();

  const [files, setFiles] = useState<DropFile[]>([]);
  const { uploadImage, isUploading } = useImageUploader();
  const condensedDesign = condensed || files.length > 0;
  const bodySize = condensedDesign ? 'bodySmall' : 'body';
  const maxFilesReached = files.length === maxFiles;
  const validatePhoto: FileValidationFunction = file => {
    if (file.size < maxSize) {
      return null;
    }
    return {
      code: 'file-too-large',
      message: `File is too large. Max file size is ${maxSize / MEGA_BYTE}MB`,
    };
  };

  const handleUploadImage = (file: DropFile) => {
    uploadImage(file)
      .then(res => {
        Object.assign(file, { responseKey: res });
      })
      .catch(err => {
        console.error('Error uploading image ', err);
        Object.assign(file, { responseKey: undefined });
        // delete file from files array if upload fails
        setFiles(files.filter(f => f.imgId !== file.imgId));
      })
      .finally(() => {
        Object.assign(file, { isLoading: false });
      });
  };

  const handleAcceptedFiles = (acceptedFiles: DropFile[]) => {
    const generateId = Math.random().toString(36).substr(2, 9);
    const limitedAcceptedFiles = acceptedFiles.slice(0, maxFiles - files.length);

    const loadedFiles: DropFile[] = limitedAcceptedFiles.map((file: DropFile) => {
      Object.assign(file, { preview: URL.createObjectURL(file), imgId: file.name + generateId, isLoading: true });
      handleUploadImage(file);
      return file;
    });

    return setFiles([...files, ...loadedFiles]);
  };

  const { getRootProps, getInputProps, open, fileRejections, isDragActive } = useDropzone({
    noClick: true,
    noKeyboard: true,
    accept: {
      'image/*': ['.png', '.jpg', '.jpeg', '.svg'],
    },
    disabled: maxFilesReached,
    validator: validatePhoto,
    maxFiles: maxFiles,
    onError: err => {
      console.error('OnError image uploader ', err);
    },
    onDrop: handleAcceptedFiles,
  });

  const deleteFile = (file: DropFile) => {
    const newFiles = files.filter(f => f.imgId !== file.imgId);
    setFiles(newFiles);
  };

  useEffect(() => {
    addPhotoLink(files);
    responseKeys && responseKeys(cleanFilesArray(files.map(file => file.responseKey)));
    isLoaded && isLoaded(isUploading.isLoading);

    return () => {
      files.forEach(file => URL.revokeObjectURL(file.preview || ''));
    };
  }, [addPhotoLink, files, isLoaded, isUploading, responseKeys]);

  return (
    <div>
      <div className="text-slate-500">
        <label className="mb-[8px]">
          <Typography variant={isMobile ? 'bodySmall' : 'body'}>
            {required && <span className="text-danger">*</span>}
            {label}
          </Typography>
        </label>
      </div>
      <div className="container relative flex flex-col gap-4 rounded p-1">
        <div
          {...getRootProps()}
          className={cn(
            'flex h-auto w-full cursor-pointer flex-col items-center rounded-lg bg-[#AFE2E366] px-6 shadow-[0px_0px_14px_0px_#AFE2E3_inset] outline-dashed outline-2 outline-basicSoft hover:shadow-[0px_0px_34px_0px_#AFE2E3_inset]',
            {
              'gap-2 py-2': condensedDesign,
              'gap-4 py-4': !condensedDesign,
              '!bg-[#FAFAFC] shadow-none hover:shadow-none': maxFilesReached,
              '!shadow-[0px_0px_44px_0px_#AFE2E3]': isDragActive,
            },
          )}
          onClick={open}
        >
          <input {...getInputProps()} />
          <div className="flex flex-col items-center gap-2 text-neutralStrong">
            <Typography variant={bodySize}>Drag and drop image here</Typography>
            <Typography variant={bodySize}>-or-</Typography>
            <a
              href=""
              onClick={e => {
                e.preventDefault();
              }}
              className="flex items-center gap-2 text-neutralStrong hover:underline"
            >
              <Typography variant={bodySize}>click to upload</Typography>

              <SvgDownloadIcon className="h-auto w-5" />
            </a>
          </div>
          <Typography variant="captionSmall" className="font-light text-neutral">
            Supported files: PNG, SVG, JPEG, JPG
          </Typography>
        </div>
        {fileRejections && fileRejections.length > 0 && (
          <div className="flex flex-col flex-wrap gap-2 text-center">
            {fileRejections.map((file, index) => (
              <Typography key={file.file.name + index} variant="caption" className="text-danger">
                {file.file.name} - {file.errors[0].message}
              </Typography>
            ))}
          </div>
        )}
        {files && files.length > 0 && (
          <div className="flex flex-col gap-4 text-left">
            {maxFilesReached && (
              <Typography variant="bodySmall" className="text-center">
                File/image upload limit ({maxFiles}) has been reached
              </Typography>
            )}
            <div className="flex flex-row flex-wrap justify-center gap-2">
              {files.map(file => {
                return (
                  <ImageUploaderPreview
                    key={file.imgId}
                    file={file}
                    onDelete={deleteFile}
                    isLoading={file.isLoading || false}
                  />
                );
              })}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
