import React, { useCallback } from 'react';

import { cn } from 'src/util/cn';

import SvgCurrentStep from '../../icons/components/CurrentStep';
import SvgNextStep from '../../icons/components/NextStep';
import SvgStepDone from '../../icons/components/StepDone';

export type Step = {
  mainStep: number;
  subStep?: number;
  isVisible?: boolean;
  title?: string;
};

enum StepStatus {
  DONE,
  CURRENT,
  NEXT,
}

interface StepperProps {
  steps: Step[];
  currentStep: Step;
  onStepChange?: (step: Step) => void;
}

const determineStepStatus = (step: Step, currentStep: Step): StepStatus => {
  if (
    step.mainStep < currentStep.mainStep ||
    (step.mainStep === currentStep.mainStep && (step.subStep || 0) < (currentStep.subStep || 0))
  ) {
    return StepStatus.DONE;
  }
  if (step.mainStep === currentStep.mainStep && step.subStep === currentStep.subStep) {
    return StepStatus.CURRENT;
  }
  return StepStatus.NEXT;
};

const Stepper: React.FC<StepperProps> = ({ steps = [], currentStep, onStepChange }) => {
  const handleStepClick = useCallback(
    (step: Step) => {
      if (step.mainStep <= currentStep.mainStep) {
        if (onStepChange) {
          onStepChange(step);
        }
      }
    },
    [currentStep, onStepChange],
  );

  const visibleSteps = steps.filter(({ isVisible }) => isVisible !== false);

  return (
    <div className="flex">
      {visibleSteps.map(step => {
        const status = determineStepStatus(step, currentStep);
        const icon = {
          [StepStatus.DONE]: <SvgStepDone />,
          [StepStatus.CURRENT]: <SvgCurrentStep />,
          [StepStatus.NEXT]: <SvgNextStep />,
        };
        const iconStatus = icon[status];

        return (
          <div className="flex items-center" key={step.mainStep}>
            <div
              className="step-item ml-[10px] mr-[10px] flex items-center"
              role="button"
              onClick={() => handleStepClick(step)}
            >
              <div className="mr-[5px]">{iconStatus}</div>

              <span
                className={cn(
                  'text-[10px] sm:text-[16px]',
                  status === StepStatus.CURRENT ||
                    (step.mainStep === currentStep.mainStep && !step.subStep && !currentStep.subStep)
                    ? 'font-bold'
                    : 'font-normal',
                )}
              >
                {step.title}
              </span>
            </div>
            {step.mainStep !== visibleSteps.length && (
              <div className="separator h-[1px] w-[24px] bg-neutralStrong"></div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default Stepper;
