import { useIsMobile } from 'src/hooks/useIsMobile';
import { BackIcon } from 'src/icons/components';
import { cn } from 'src/util/cn';

import Button from './Button';

interface BackButtonProps {
  onClick: () => void;
  className?: string;
  rightButtonExist: boolean;
  text?: string;
  dataTestId?: string;
}

const isTextForPositioningStyle = (text: string) => {
  const texts = ['Back', 'PREVIOUS: YOUR OFFER', 'I’m sure, reject offer and send item back'];
  return !texts.includes(text);
};

export const BackButton = (props: BackButtonProps) => {
  const { onClick, className, rightButtonExist, text, dataTestId = 'back-button', ...otherProps } = props;
  const isMobile = useIsMobile();

  const isTextHandle = () => {
    if (text !== undefined) {
      return text;
    }

    if (isMobile) {
      if (!rightButtonExist) {
        return 'Back';
      }
      return '';
    }

    return 'Back';
  };

  return (
    <Button
      onClick={onClick}
      text={isTextHandle()}
      type={'button'}
      data-testid={dataTestId}
      className={cn('!h-[40px] whitespace-nowrap', {
        className,
        '!w-[40px]': isMobile && rightButtonExist,
        '!w-full': !isTextForPositioningStyle(text || ''),
      })}
      buttonType={'secondary'}
      iconLeft={<BackIcon />}
      {...otherProps}
    />
  );
};
