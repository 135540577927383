import * as React from 'react';
import type { SVGProps } from 'react';

interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgLogoIcon = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 106 32"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <g fill="#101820" clipPath="url(#logoIcon_svg__a)">
      <path d="M.041 15.263C0 14.935 0 14.567 0 14.239 0 5.851 5.32 0 13.054 0c6.752 0 11.743 4.46 11.743 11.13 0 7.652-6.507 12.4-14.364 12.4a22.1 22.1 0 0 1-3.151-.245C8.019 25.822 9 28.441 10.105 31.1H4.092c-1.268-3.068-2.285-6.283-3.11-9.37-.414-1.555-.847-4.64-.94-6.467Zm6.015 2.578c1.392.368 2.741.532 4.051.532 5.687 0 8.92-3.233 8.92-7.325 0-3.478-2.415-5.893-5.934-5.893-4.215 0-7.325 3.478-7.325 9.084 0 1.146.081 2.332.287 3.6l.001.002ZM25.395 20.952c0-6.22 4.38-10.803 10.598-10.803 6.218 0 10.188 4.05 10.188 9.943 0 .737-.08 1.555-.245 2.332H30.673c.532 3.191 2.619 4.624 5.565 4.624 2.701 0 4.256-1.188 4.952-2.946l4.788.982c-1.227 4.05-4.788 6.424-9.74 6.424-6.794 0-10.844-4.46-10.844-10.558l.001.002Zm5.442-2.496h9.902c-.45-2.618-2.128-4.01-4.746-4.01-2.373 0-4.419 1.27-5.156 4.01ZM47.958 25.37l5.28-1.063c.409 2.128 1.923 3.027 4.05 3.027 2.129 0 3.356-.818 3.356-2.29 0-1.555-2.21-1.76-4.665-2.21-3.314-.53-7.203-1.432-7.203-6.137 0-3.806 3.6-6.546 8.552-6.546 4.624 0 7.407 2.045 8.594 5.687L60.806 16.9c-.409-1.759-1.636-2.579-3.478-2.579-2.045 0-3.274.901-3.274 2.128 0 1.514 1.883 1.842 4.215 2.21 3.436.573 7.693 1.268 7.693 6.056 0 3.887-2.865 6.793-8.675 6.793-4.582 0-8.185-1.758-9.33-6.137l.001-.002ZM75.053 5.934h1.39v4.665h6.51v4.502h-6.51v8.716c0 2.128 1.064 2.946 2.906 2.946.9 0 1.76-.245 2.782-.696l1.146 4.257a11.425 11.425 0 0 1-5.074 1.187c-4.338 0-6.916-2.414-6.916-6.507v-9.902H67.4v-4.5h3.886V5.935h3.765l.002-.002ZM105.984 20.827c0 6.18-4.624 10.68-10.926 10.68-6.301 0-10.925-4.5-10.925-10.68 0-6.179 4.624-10.68 10.925-10.68 6.302 0 10.926 4.543 10.926 10.68Zm-16.449 0c0 3.6 2.17 5.974 5.523 5.974 3.354 0 5.525-2.373 5.525-5.974 0-3.6-2.21-5.934-5.525-5.934-3.315 0-5.523 2.374-5.523 5.934Z" />
    </g>
    <defs>
      <clipPath id="logoIcon_svg__a">
        <path fill="#fff" d="M0 0h106v32H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgLogoIcon;
