export const getAllUrlParams = (): Record<string, string> => {
  const params = new URLSearchParams(window.location.search);
  const paramMap: Record<string, string> = {};
  params.forEach((value, key) => {
    paramMap[key] = value;
  });
  return paramMap;
};

// hack to get around typescript error
interface URLObject {
  constructor?: any;
  toString?: any;
  toJSON?: any;
  [key: string]: any;
}
export const urlObject = (): Record<string, any> => {
  const url = new URL(window.location.href);
  const keys = Object.getOwnPropertyNames(Object.getPrototypeOf(url));
  const plainObject: URLObject = {};

  for (const key of keys) {
    const descriptor = Object.getOwnPropertyDescriptor(Object.getPrototypeOf(url), key);
    if (descriptor && descriptor.get) {
      plainObject[key] = descriptor.get.call(url);
    } else {
      plainObject[key] = url[key as keyof URL];
    }
  }

  delete plainObject.constructor;
  delete plainObject.toString;
  delete plainObject.toJSON;

  return plainObject;
};
