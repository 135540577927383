import { useNavigate } from 'react-router-dom';

import type { CreditApplicationStatus } from 'src/__generated__/graphql/api';
import { CurrentUserDocument, type CurrentUserQuery } from 'src/__generated__/graphql/api';
import { apolloClient } from 'src/api/Apollo';
import {
  DISABLE_PROTECTED_ROUTES_SS_KEY,
  IGNORE_SEGMENT_AND_PESTO_TRACKING_DEV_MODE_LS_KEY,
  IS_INCREASE_CREDIT_FLOW_LS_KEY,
  LANDING_FINDER_LS_KEY,
  NEW_SIGN_UP_DEMO_LS_KEY,
  PERSONAL_INFO_DEMO_LS_KEY,
  SHOW_SCHEDULED_PAYMENTS_FLAG,
} from 'src/constants/localStorageKeys';
import { landingPageRoutes } from 'src/constants/routes';

export const useAdminDebugger = () => {
  const navigate = useNavigate();

  const applyCreditAppStatusForUser = (status: CreditApplicationStatus) => {
    const userCache = apolloClient?.cache?.readQuery<CurrentUserQuery>({ query: CurrentUserDocument });
    console.log(status);
    console.log(userCache);
    if (userCache) {
      apolloClient?.cache?.writeQuery<CurrentUserQuery>({
        query: CurrentUserDocument,
        data: {
          currentUser: {
            ...userCache.currentUser,
            creditApplication: {
              ...userCache.currentUser?.creditApplication,
              status: status,
            },
          },
        },
      });
    }
  };

  const handleSegmentAndPestoTrackingIgnoreStatusForDevMode = (value: boolean) => {
    localStorage.setItem(IGNORE_SEGMENT_AND_PESTO_TRACKING_DEV_MODE_LS_KEY, String(value));
    window.location.reload();
  };

  const handleShowSchedulePayment = (value: boolean) => {
    localStorage.setItem(SHOW_SCHEDULED_PAYMENTS_FLAG, String(value));
    window.location.reload();
  };

  const getIsSegmentAndPestoTrackingIgnoredForDevMode = (): boolean => {
    const value = localStorage.getItem(IGNORE_SEGMENT_AND_PESTO_TRACKING_DEV_MODE_LS_KEY);
    return value === 'true';
  };

  const getIsSchedulePayment = (): boolean => {
    const value = localStorage.getItem(SHOW_SCHEDULED_PAYMENTS_FLAG);
    return value === 'true';
  };

  const getIsRouteEnabled = (key: string): boolean => {
    const value = localStorage.getItem(key);
    return value === 'true';
  };
  const getIsIncreaseCreditFlow = (): boolean => {
    const value = localStorage.getItem(IS_INCREASE_CREDIT_FLOW_LS_KEY);
    return value === 'true';
  };

  const handleIsIncreaseCreditFlowChange = (value: boolean) => {
    localStorage.setItem(IS_INCREASE_CREDIT_FLOW_LS_KEY, String(value));
    window.location.reload();
  };

  const handleDisableProtectedRoutes = (value: boolean) => {
    sessionStorage.setItem(DISABLE_PROTECTED_ROUTES_SS_KEY, String(!value));
    window.location.reload();
  };

  const handleRouteChange = (key: string, value: boolean) => {
    if (value) {
      localStorage.removeItem(PERSONAL_INFO_DEMO_LS_KEY);
      localStorage.removeItem(NEW_SIGN_UP_DEMO_LS_KEY);
      localStorage.removeItem(LANDING_FINDER_LS_KEY);
    }

    localStorage.setItem(key, String(value));

    if (value) {
      switch (key) {
        case PERSONAL_INFO_DEMO_LS_KEY:
          navigate(landingPageRoutes.personalInfoDemo);
          break;
        case NEW_SIGN_UP_DEMO_LS_KEY:
          navigate(landingPageRoutes.newSignUpDemo);
          break;
        case LANDING_FINDER_LS_KEY:
          navigate(landingPageRoutes.root);
          break;
        default:
          break;
      }
    }
  };

  return {
    getIsIncreaseCreditFlow,
    handleIsIncreaseCreditFlowChange,
    handleDisableProtectedRoutes,
    getIsRouteEnabled,
    handleRouteChange,
    applyCreditAppStatusForUser,
    handleSegmentAndPestoTrackingIgnoreStatusForDevMode,
    getIsSegmentAndPestoTrackingIgnoredForDevMode,
    handleShowSchedulePayment,
    getIsSchedulePayment,
  };
};
