import type { ReactNode } from 'react';

import type { CardType } from '../../../../components/screens/Payment/Payment';
import SvgAmericanExpress from '../../../../icons/components/credit-card/AmericanExpress';
import SvgDiners from '../../../../icons/components/credit-card/Diners';
import SvgDiscover from '../../../../icons/components/credit-card/Discover';
import SvgElo from '../../../../icons/components/credit-card/Elo';
import SvgGenericCard from '../../../../icons/components/credit-card/GenericCard';
import SvgHiper from '../../../../icons/components/credit-card/Hiper';
import SvgHipercard from '../../../../icons/components/credit-card/Hipercard';
import SvgJcb from '../../../../icons/components/credit-card/Jcb';
import SvgMaestro from '../../../../icons/components/credit-card/Maestro';
import SvgMastercard from '../../../../icons/components/credit-card/Mastercard';
import SvgMir from '../../../../icons/components/credit-card/Mir';
import SvgUnionpay from '../../../../icons/components/credit-card/Unionpay';
import SvgVisa from '../../../../icons/components/credit-card/Visa';
import americanExpressSvg from '../../../../icons/icon/credit-card/american-express.svg';
import dinersSvg from '../../../../icons/icon/credit-card/diners.svg';
import discoverSvg from '../../../../icons/icon/credit-card/discover.svg';
import eloSvg from '../../../../icons/icon/credit-card/elo.svg';
import hiperSvg from '../../../../icons/icon/credit-card/hiper.svg';
import hipercardSvg from '../../../../icons/icon/credit-card/hipercard.svg';
import jcbSvg from '../../../../icons/icon/credit-card/jcb.svg';
import maestroSvg from '../../../../icons/icon/credit-card/maestro.svg';
import mastercardSvg from '../../../../icons/icon/credit-card/mastercard.svg';
import mirSvg from '../../../../icons/icon/credit-card/mir.svg';
import unionpaySvg from '../../../../icons/icon/credit-card/unionpay.svg';
import visaSvg from '../../../../icons/icon/credit-card/visa.svg';

const cardTypeStye = 'w-8 h-8';

export const CARD_TYPE_LOGOS: Record<CardType, ReactNode> = {
  visa: <SvgVisa className={cardTypeStye} />,
  mastercard: <SvgMastercard className={cardTypeStye} />,
  'american-express': <SvgAmericanExpress className={cardTypeStye} />,
  discover: <SvgDiscover className={cardTypeStye} />,
  'diners-club': <SvgDiners className={cardTypeStye} />,
  jcb: <SvgJcb className={cardTypeStye} />,
  unionpay: <SvgUnionpay className={cardTypeStye} />,
  maestro: <SvgMaestro className={cardTypeStye} />,
  elo: <SvgElo className={cardTypeStye} />,
  mir: <SvgMir className={cardTypeStye} />,
  hiper: <SvgHiper className={cardTypeStye} />,
  hipercard: <SvgHipercard className={cardTypeStye} />,
  default: <SvgGenericCard className={cardTypeStye} />,
};

export const CARD_TYPE_BG_COLORS: Record<CardType, string> = {
  visa: '#1a1f71',
  mastercard: '#e30613',
  'american-express': '#0e6eb8',
  discover: '#ff6000',
  'diners-club': '#006e6e',
  jcb: '#1a1f71',
  maestro: '#1a1f71',
  elo: '#1a1f71',
  mir: '#1a1f71',
  hiper: '#1a1f71',
  hipercard: '#1a1f71',
  unionpay: '#1a1f71',
  default: '#fafafa',
};
export const CARD_TYPE_BG_SVG_IMAGE: Record<CardType, string | ReactNode> = {
  visa: visaSvg,
  mastercard: mastercardSvg,
  'american-express': americanExpressSvg,
  discover: discoverSvg,
  'diners-club': dinersSvg,
  jcb: jcbSvg,
  maestro: maestroSvg,
  elo: eloSvg,
  mir: mirSvg,
  hiper: hiperSvg,
  hipercard: hipercardSvg,
  unionpay: unionpaySvg,
  default: '#fafafa',
};
