import React from 'react';

import { Typography } from 'src/components/Typography/Typography';
import { Wrapper1280 } from 'src/components/UI/Wrapper/Wrapper1280';

import ForbesLogo from '../../../assets/Forbes.svg';
import FortuneLogo from '../../../assets/Fortune.svg';
import NerdWalletLogo from '../../../assets/Nerdwallet.svg';
import TechCrunchLogo from '../../../assets/TechCrunch.svg';
import StarsSvg from '../../../assets/stars.svg';

interface TestimonialProps {
  title: string;
  quote: string;
  customerName: string;
}

const testimonials: TestimonialProps[] = [
  {
    title: 'Life-Saving Service',
    quote:
      "Pesto is a great new way to get a line of credit when you need it, especially during an emergency, you don't have to have the best credit in the world.",
    customerName: 'Nicole',
  },
  {
    title: 'Quick Help When Needed',
    quote:
      'My family found themselves in a situation where we needed money to repair a car and we just did not have the extra money... So I did some more research and I found Pesto.',
    customerName: 'Aldan',
  },
  {
    title: 'Simple and Effective',
    quote:
      'During some financial troubles I did some Googling and luckily came across Pesto - it was the most amazing find for me this year. It’s such a simple process.',
    customerName: 'Jasmine',
  },
];

const TestimonialCard: React.FC<TestimonialProps> = ({ title, quote, customerName }) => (
  <div className="min-w-[250px] max-w-full rounded border-[1px] border-[#E8E8E8] bg-white p-4 shadow-md md:w-[370px] md:p-6">
    <h3 className="text-md font-[Playfair,serif] font-bold sm:text-lg">{title}</h3>
    <p className="mt-2 text-sm text-gray-600 md:text-base">{quote}</p>
    <div className="mt-4 flex items-center">
      <div className="flex items-center gap-2">
        <div className="text-left">
          <p className="text-xs font-semibold md:text-sm">{customerName}</p>
          <p className="text-xs text-gray-500 md:text-sm">Pesto Customer</p>
        </div>
      </div>
    </div>
  </div>
);

const PeopleLovePesto: React.FC = () => {
  return (
    <div className="bg-[#E8E8E840] pb-8 pt-8 md:pb-[62px] md:pt-[62px]">
      <div className="flex flex-col items-center justify-center gap-4 text-center md:flex-row">
        <Typography variant="heroSmall" className="!font-light text-black">
          People Love Pesto
        </Typography>
        <div>
          <Typography variant="headerLarge">5.00 out of 5</Typography>
          <img src={StarsSvg} alt="rating pesto" className="mx-auto" />
          <Typography variant="body">App Store Rating</Typography>
        </div>
      </div>
      <div className="mt-8 flex  flex-col items-center justify-center gap-4 p-4 sm:flex-row">
        {testimonials.map((testimonial, index) => (
          <TestimonialCard key={index} {...testimonial} />
        ))}
      </div>

      <Wrapper1280>
        <div className="flex flex-col items-center justify-center gap-4 pt-4 md:flex-row md:gap-8 md:pt-[42px]">
          <Typography variant="headerMedium" className="border-b-[2px] border-salsa">
            As seen in:
          </Typography>
          <div className="flex flex-wrap items-center justify-center gap-4 md:space-x-20">
            <img src={NerdWalletLogo} alt="nerd wallet logo" />
            <img src={ForbesLogo} alt="forbes logo" />
            <img src={FortuneLogo} alt="fortune logo" />
            <img src={TechCrunchLogo} alt="tech crunch logo" />
          </div>
        </div>
      </Wrapper1280>
    </div>
  );
};

export default PeopleLovePesto;
