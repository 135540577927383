import { useUser } from '../../../hooks/useUser';
import { CardDetailsSmall } from '../../CardDetails/CardDetailsSmall';

import LogoContainer from './LogoContainer';
import Menu from './Menu';

const Navigation = () => {
  const { user } = useUser();
  const isCardholder = !!user?.cards?.length;
  return (
    <div className="flex w-full flex-row items-center justify-between py-4">
      <LogoContainer />
      <div className="ml-[10px] flex flex-row items-center">
        {isCardholder && <CardDetailsSmall />}
        <Menu />
      </div>
    </div>
  );
};

export default Navigation;
