import type { ReactNode } from 'react';

interface Wrapper1280Props {
  children: ReactNode;
  className?: string;
}
export const Wrapper1280 = (props: Wrapper1280Props) => {
  const { children, className } = props;
  const wrapperStyle = `max-w-[1280px] mx-auto px-6 md:px-8 ${className}`;
  return <div className={wrapperStyle}>{children}</div>;
};
