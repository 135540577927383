import * as React from 'react';
import type { SVGProps } from 'react';

interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgOmegaLogo = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 60 30"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="#101820"
      fillRule="evenodd"
      d="m19.657 26.29-3.681-8.478-3.38 11.935h1.621l2.103-7.456 3.337 7.705 3.346-7.705 2.094 7.456h1.621l-3.372-11.935-3.69 8.477ZM6.135 17.811h-.026c-1.759.009-3.226.626-4.41 1.862C.566 20.867 0 22.274 0 23.904c0 1.604.549 2.994 1.647 4.178 1.185 1.27 2.669 1.905 4.462 1.914h.026c1.716 0 3.174-.618 4.367-1.862 1.15-1.193 1.725-2.609 1.725-4.23 0-1.639-.575-3.055-1.725-4.247-1.184-1.227-2.634-1.845-4.367-1.845Zm3.14 9.395c-.824.875-1.87 1.313-3.158 1.313H6.11c-1.287 0-2.342-.446-3.166-1.33-.815-.892-1.227-1.982-1.227-3.268 0-1.288.412-2.386 1.227-3.287.824-.9 1.879-1.347 3.166-1.347h.008c1.287 0 2.334.447 3.158 1.339.815.9 1.227 1.999 1.227 3.295 0 1.312-.412 2.402-1.227 3.285ZM28.022 15.05v-.737c-1.158-.738-2.17-1.665-2.977-2.866-1.33-2.549-1.682-5.088.103-7.637 3.329-3.758 5.654-2.3 7.833-.103 1.433 2.051 1.502 4.102.961 6.152-.6 2.042-1.63 3.535-3.071 4.453v.73h6.152l1.004-2.3c-.095-.523-.327-.455-.79-.438l-.755 1.047H34.68c.534-.774 1.063-1.552 1.588-2.334.729-1.887 1.012-3.86.317-6.04C35.118.223 29.129-1.047 25.31.832 22.97 1.973 21.27 4.247 21.33 7.31c.069 2.3 1.184 4.393 2.797 6.04h-1.381l-1.27-1.03c-.361-.12-.627.044-.695.447l1.312 2.283h5.93Zm26.392 2.78-.008-.017-5.577 11.935h1.75l1.468-3.14h4.727l1.467 3.14H60l-5.586-11.918Zm0 7.302h-1.801l1.793-3.578.008.017 1.794 3.561h-1.794Zm-26.452 4.616h6.967V28.27h-5.345V24.17h5.345v-1.476h-5.345v-3.183h5.345v-1.45h-6.967v11.686Zm14.363-4.702h4.179c-.095 1.004-.515 1.836-1.262 2.497-.738.652-1.647.978-2.728.978-1.296 0-2.351-.446-3.166-1.33-.824-.892-1.236-1.982-1.236-3.268 0-1.288.412-2.386 1.236-3.287.815-.9 1.87-1.347 3.166-1.347.678 0 1.261.112 1.759.343.497.223.969.567 1.415 1.021l1.227-.978c-.627-.609-1.287-1.072-1.99-1.39-.712-.317-1.502-.472-2.394-.472-1.768 0-3.252.618-4.436 1.862-1.133 1.193-1.699 2.6-1.699 4.23 0 1.441.446 2.737 1.347 3.895 1.133 1.468 2.737 2.197 4.788 2.197 2.016 0 3.534-.746 4.555-2.248.798-1.158 1.193-2.548 1.193-4.178h-5.37l-.584 1.475Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgOmegaLogo;
