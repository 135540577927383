import * as React from 'react';
import type { SVGProps } from 'react';

interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgJacket = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path stroke="#101820" strokeLinecap="round" strokeWidth={1.5} d="M8 11.5v7M10 13.5v3" />
    <path
      stroke="#101820"
      strokeWidth={1.5}
      d="M14.124 3c6.48 0 6.837 9.597 4.713 11.707-2.357 2.342-6.088.976-8.837 0v-7.61C10 6.513 9.91 3 14.124 3ZM9.5 15l-1.125.65-2.25 1.299a1.25 1.25 0 0 1-1.875-1.083v-1.732a1.25 1.25 0 0 1 1.875-1.083M9.5 15l-3.375-1.949M9.5 15l-1.125-.65M9.5 15l-1.125-.65m-2.25-1.299 2.25 1.3m-2.25-1.3 2.25 1.3"
    />
  </svg>
);
export default SvgJacket;
