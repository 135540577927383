import type { FC, MouseEvent, ReactNode } from 'react';
import { useRef } from 'react';

import { CloseWhite } from 'src/icons/components';

import SvgInfo from '../../../icons/components/Info';

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: ReactNode;
}

const Modal: FC<ModalProps> = props => {
  const { isOpen, onClose, children } = props;
  const modalWrapperRef = useRef(null);

  if (!isOpen) return null;

  const handleClickOutside = (e: MouseEvent) => {
    if (modalWrapperRef.current === e.target) {
      onClose();
    }
  };

  if (!isOpen) return null;

  return (
    <div
      ref={modalWrapperRef}
      onClick={handleClickOutside}
      className="fixed inset-0 z-[99999] flex flex-col items-center justify-center bg-darkShade md:bg-black md:bg-opacity-50"
    >
      <div className="relative h-full w-full bg-darkShade sm:h-[600px] sm:w-[600px] sm:max-w-[900px]">
        <div className="flex h-full flex-col">
          <SvgInfo className="mx-auto mt-6 h-8 w-6" />
          {children && <div className="flex-1 overflow-y-auto p-6">{children}</div>}
          <div>
            <button onClick={onClose} className="flex w-full justify-center bg-basicSoft py-[16px] text-center">
              <CloseWhite className="cursor-pointer" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
