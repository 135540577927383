import type { IconProps } from './IconHelper';

export const IconStar = (props: IconProps) => {
  const { className, stroke } = props;
  return (
    <svg
      className={className}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.79 7.15983L11.1888 2.23176C10.8146 1.08012 9.18537 1.08012 8.81118 2.23176L7.20995 7.15983H2.02828C0.817373 7.15983 0.313903 8.70935 1.29355 9.4211L5.48561 12.4668L3.88438 17.3949C3.51019 18.5465 4.82829 19.5042 5.80794 18.7924L10 15.7467L14.1921 18.7924C15.1717 19.5042 16.4898 18.5465 16.1156 17.3949L14.5144 12.4668L18.7065 9.4211C19.6861 8.70935 19.1826 7.15983 17.9717 7.15983H12.79Z"
        stroke={stroke}
        strokeWidth="1.5"
      />
    </svg>
  );
};
