import type { IconProps } from './IconHelper';

export const IconMetal = (props: IconProps) => {
  const { className, stroke, scale } = props;
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      scale={scale}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.2054 3.75H13.382C13.4767 3.75 13.5632 3.8035 13.6056 3.8882L14.2654 3.55828L13.6056 3.8882L16.6056 9.8882C16.6887 10.0544 16.5678 10.25 16.382 10.25H7.53876C7.35785 10.25 7.23683 10.0638 7.31031 9.89847L9.97698 3.89846C10.0171 3.80818 10.1066 3.75 10.2054 3.75Z"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M4.20543 13.75H7.38197C7.47666 13.75 7.56322 13.8035 7.60557 13.8882L8.2654 13.5583L7.60557 13.8882L10.6056 19.8882C10.6887 20.0544 10.5678 20.25 10.382 20.25H1.53876C1.35785 20.25 1.23683 20.0638 1.31031 19.8985L3.97698 13.8985C4.0171 13.8082 4.10663 13.75 4.20543 13.75Z"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M16.2054 13.75H19.382C19.4767 13.75 19.5632 13.8035 19.6056 13.8882L20.2654 13.5583L19.6056 13.8882L22.6056 19.8882C22.6887 20.0544 22.5678 20.25 22.382 20.25H13.5388C13.3578 20.25 13.2368 20.0638 13.3103 19.8985L15.977 13.8985C16.0171 13.8082 16.1066 13.75 16.2054 13.75Z"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};
