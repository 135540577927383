import clsx from 'clsx';
import { useEffect, useState } from 'react';

import { isDebuggerEnabled, isProtectedRoutesDisabled } from 'src/util/adminDebuggerHelper';

import SvgSettingsIcon from '../../icons/components/SettingsIcon';

import { DebuggerDrawer } from './components/DebuggerDrawer';

export const AdminDebugger = () => {
  const [showDebugger, setShowDebugger] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  useEffect(() => {
    const isEmbeddedInIframe = window.self !== window.top;

    if (isEmbeddedInIframe) {
      setShowDebugger(false);
    } else {
      setShowDebugger(true);
    }
  }, []);

  if (!isDebuggerEnabled || !showDebugger) {
    return null;
  }

  return (
    <div className={''}>
      <DebuggerDrawer className={'z-[999999999]'} open={open} onClose={setOpen} />

      <div className={'fixed right-0 top-1/2 -translate-y-1/2'}>
        <button
          className={clsx(
            'flex h-10 w-10 flex-col items-center justify-center rounded-l-lg bg-basic hover:bg-basicSoft',
            {
              'border-2 border-salsa': isProtectedRoutesDisabled,
            },
          )}
          onClick={() => setOpen(!open)}
        >
          <SvgSettingsIcon
            className={clsx('text-xl', {
              'text-salsa': isProtectedRoutesDisabled,
              'text-white': !isProtectedRoutesDisabled,
            })}
          />
        </button>
      </div>
    </div>
  );
};
