import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { ButtonContainer } from 'src/components/ButtonContainer/ButtonContainer';
import { BackButton } from 'src/components/UI/Button/BackButton';

import type { SecondaryStoneInput, StoneSize } from '../../../../../../__generated__/graphql/api';
import { StoneType } from '../../../../../../__generated__/graphql/api';
import { Divider } from '../../../../../../components/Devider/Devider';
import { ListSelection } from '../../../../../../components/ListSelection/ListSelection';
import { Typography } from '../../../../../../components/Typography/Typography';
import Button from '../../../../../../components/UI/Button/Button';
import { diamondTypes, gemstoneTypes } from '../../../../../../data/stones';
import SvgNoneIcon from '../../../../../../icons/components/stones/NoneIcon';
import { OtherDiamondModal } from '../../modals/OtherDiamondModal/OtherDiamondModal';
import { ScreenQuickAppraisal } from '../ScreenQuickAppraisal';

import { ConnectedCheckDown } from './components/ConnectedCheckDown';

interface SecondaryStonesDetailsFormProps {
  title: string;
  className?: string;
  onNext: (secondaryStonesArray: SecondaryStoneInput[] | undefined) => void;
  handleBack: () => void;
}

const stoneTypeValues = [...diamondTypes, ...gemstoneTypes].map(stone => stone.value);
interface FormValues {
  stones: {
    [key in StoneType]: {
      isChecked: boolean;
      stoneType: StoneType;
      stoneSize: StoneSize;
      stoneCount: number;
    };
  };
}

const validation = yup.object().shape({
  stones: yup
    .object()
    .shape(
      stoneTypeValues.reduce((acc: any, stoneType) => {
        acc[stoneType] = yup.object().shape({
          isChecked: yup.boolean(),
          stoneType: yup.string().oneOf([stoneType]).required(),
          stoneSize: yup.string().when('isChecked', {
            is: true,
            then: schema => schema.required('Please select stone size'),
          }),
          stoneCount: yup
            .number()
            .typeError('Please enter a valid number')
            .nullable()
            // we need to transform empty input to null, because yup will error with typeError if we pass empty string
            .transform((_, val) => (val !== '' ? Number(val) : null))
            .when('isChecked', {
              is: true,
              then: schema =>
                schema
                  .min(1, 'Please select stone count')
                  .positive('Please select stone count')
                  .required('Please select stone count'),
            }),
        });
        return acc;
      }, {}),
    )
    .test('stones', 'Please select at least one stone', value => {
      const selectedStones = Object.values(value).filter(stone => stone.isChecked);
      return selectedStones.length > 0;
    }),
});

export const SecondaryStonesDetailsForm = (props: SecondaryStonesDetailsFormProps) => {
  const { className, onNext, title, handleBack } = props;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<FormValues>({
    resolver: yupResolver(validation),
    reValidateMode: 'onChange',
  });
  const onSubmit = (data: FormValues) => {
    const checkedSecondaryStonesArray = Object.values(data.stones)
      .filter(stone => stone.isChecked)
      .map(
        (stone): SecondaryStoneInput => ({
          stoneType: stone.stoneType,
          size: stone.stoneSize,
          count: stone.stoneCount,
        }),
      );

    onNext(checkedSecondaryStonesArray);
  };

  const moreInfoCaption = (
    <button type="button" onClick={() => setIsModalOpen(true)} className={'w-auto underline hover:no-underline'}>
      <Typography variant={'caption'} className={'text-primary'}>
        More info
      </Typography>
    </button>
  );

  return (
    <ScreenQuickAppraisal className={className} title={title}>
      <form name={'stones'} onSubmit={handleSubmit(onSubmit)}>
        <div className=" flex w-full flex-col justify-center gap-2 text-center">
          <Divider gap={2} className="w-[70%] self-center">
            <Typography variant={'bodySmall'} className={'text-neutral'}>
              Diamonds
            </Typography>
          </Divider>
          {diamondTypes.map((diamond, index) => {
            const stoneError = errors.stones?.[diamond.value];
            const isUnknownDiamond = diamond.value === StoneType.DiamondUnknown;
            return (
              <ConnectedCheckDown
                key={diamond.text + index}
                stone={diamond}
                control={control}
                error={stoneError}
                caption={isUnknownDiamond && moreInfoCaption}
              />
            );
          })}
          <Divider gap={2} className="w-[70%] self-center">
            <Typography variant={'bodySmall'} className={'text-neutral'}>
              Gemstones
            </Typography>
          </Divider>
          {gemstoneTypes.map((gemstone, index) => {
            const stoneError = errors.stones?.[gemstone.value];
            return (
              <ConnectedCheckDown key={gemstone.text + index} stone={gemstone} control={control} error={stoneError} />
            );
          })}
          <Divider gap={0} className="w-[70%] self-center" />
          <ListSelection onClick={() => onNext([])} leftIcon={<SvgNoneIcon />} text={'None'} />
          {errors.stones?.message && (
            <Typography variant={'caption'} className={'text-danger'}>
              {errors.stones?.message}
            </Typography>
          )}
          <OtherDiamondModal isModalOpen={isModalOpen} closeModal={() => setIsModalOpen(false)} />

          <ButtonContainer
            rightButton={
              <Button buttonType={'primary'} type={'submit'} text={'Next'} onClick={() => handleSubmit(onSubmit)} />
            }
            leftButton={<BackButton onClick={handleBack} rightButtonExist />}
          />
        </div>
      </form>
    </ScreenQuickAppraisal>
  );
};
