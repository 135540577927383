import { heapCookieKey } from '../constants/cookieStorageKeys';

export interface HeapCookie {
  identity: string;
  pageviewId: string;
  sessionId: string;
  userId: string;
  trackerVersion: string;
}
export const getCookies = (cookieKey: string) => {
  const cookies = document.cookie.split(';');
  const cookie = cookies.find(cookie => cookie.includes(cookieKey));
  if (cookie) {
    return cookie.split('=')[1];
  }
  return null;
};
export const getHeapCookie = (): Partial<HeapCookie> => {
  const heapCookie = getCookies(heapCookieKey);
  return heapCookie ? JSON.parse(decodeURIComponent(heapCookie)) : null;
};
