import * as React from 'react';
import type { SVGProps } from 'react';

interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgLabIcon = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      stroke="#101820"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M18.146 9.1a6.134 6.134 0 0 1 .315 9.07 6.4 6.4 0 0 1-8.941 0 6.17 6.17 0 0 1-1.558-2.52"
    />
    <path
      stroke="#101820"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M16.151 11.054a.913.913 0 0 1-1.277 0L13.6 9.8a.875.875 0 0 1 0-1.254l3.832-3.764a.915.915 0 0 1 1.277 0l1.277 1.255a.875.875 0 0 1 0 1.254l-3.835 3.763Z"
    />
    <path
      stroke="#101820"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="m19.168 5.236 2.59-2.592-1.445-1.445M23.203 4.089l-1.445-1.445M5.797 15.65h5.058M6.585 22.875h13.728"
    />
    <path stroke="#101820" strokeLinecap="round" strokeLinejoin="round" d="M13.81 19.985v2.89" />
    <g stroke="#101820" strokeLinecap="round" strokeLinejoin="round" clipPath="url(#Lab-icon_svg__a)">
      <path d="M10.055 4.317a.612.612 0 0 0-.49-.245h-4.9a.613.613 0 0 0-.49.245L2.813 6.134a.613.613 0 0 0 .02.76l3.812 4.614a.612.612 0 0 0 .94 0l3.812-4.614a.613.613 0 0 0 .02-.76l-1.362-1.817ZM4.384 4.14l2.731 7.588M9.846 4.14l-2.73 7.588M2.69 6.522h8.849" />
      <path strokeWidth={0.8} d="m5.278 6.522 1.837-2.45 1.838 2.45" />
    </g>
    <defs>
      <clipPath id="Lab-icon_svg__a">
        <path fill="#fff" d="M2.2 3H12v9.8H2.2z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgLabIcon;
