import { useCallback, useMemo, useState, type SetStateAction } from 'react';

import type { EarringsInput, EarringsType } from 'src/__generated__/graphql/api';
import {
  Cut,
  GetItemCartDocument,
  StoneType,
  useAddEarringsForCurrentUserMutation,
  type AssetType,
  type Brand,
} from 'src/__generated__/graphql/api';

import { apolloClient } from '../../../../../../api/Apollo';
import { CARAT_MULTIPLIER } from '../../../../../../constants/application';
import { QUICK_APPRAISAL_LS_KEY } from '../../../../../../constants/localStorageKeys';
import { useGoRoutes } from '../../../../../../hooks/useGoRoutes';
import { useLocalStorage } from '../../../../../../hooks/useLocalStorage';
import { useUser } from '../../../../../../hooks/useUser';
import type { QuickAppraisalLocalStorage } from '../../../../../Login/SignUp/SignUp';
import ImageDetailsForm from '../../components/ImageDetailsForm/ImageDetailsForm';
import JewelryBrandsForm from '../../components/JewelryBrandsForm/JewelryBrandsForm';

import CenterStoneDetails, { type CenterStoneDetailsParams } from './CenterStoneDetails';
import EarringsGemstone from './EarringsGemstone';
import EarringsTypeScreen from './EarringsTypeScreen';
import MetalTypes, { type MetalType } from './MetalTypes';

export type SetItemType = (itemType: AssetType | undefined) => void;

interface EarringsProps {
  setItemType: SetItemType;
}

interface EarringsPayload {
  earringsInput: EarringsInput;
  photoLinks?: string[];
}

const EARRINGS_SCREEN = {
  EARRINGS_TYPE: 0,
  METAL_TYPES: 1,
  EARRINGS_GEMSTONE: 2,
  CENTER_STONE_DETAILS: 3,
  EARRINGS_BRAND: 4,
  MORE_DETAILS: 5,
};

export const Earrings = ({ setItemType }: EarringsProps) => {
  const [innerScreen, setInnerScreen] = useState<number>(0);
  const [, setPreviousScreen] = useState<number>(0);
  const { goToSignUp, gotoItemCart, goBack } = useGoRoutes();
  const { user } = useUser();
  const [, setLocalStorage] = useLocalStorage<QuickAppraisalLocalStorage>(
    QUICK_APPRAISAL_LS_KEY,
    {} as QuickAppraisalLocalStorage,
  );
  const [payload, setPayload] = useState<EarringsInput>({
    earringsType: undefined,
    centerStoneInput: {
      cut: Cut.Unknown,
      carat: 0,
      stoneType: StoneType.DiamondUnknown,
    },
    brand: undefined,
    metalType: undefined,
  });

  const setScreen = useCallback(
    (newScreen: SetStateAction<number>) => {
      setPreviousScreen(innerScreen);
      setInnerScreen(newScreen);
    },
    [innerScreen],
  );

  const [addEarringsForCurrentUserMutation, { loading }] = useAddEarringsForCurrentUserMutation({
    refetchQueries: [{ query: GetItemCartDocument }],
    errorPolicy: 'all',
    client: apolloClient,
  });

  const hasDiamond = useMemo(() => {
    return (
      payload.centerStoneInput?.stoneType === StoneType.DiamondUnknown ||
      payload.centerStoneInput?.stoneType === StoneType.DiamondNatural ||
      payload.centerStoneInput?.stoneType === StoneType.DiamondLabGrown
    );
  }, [payload.centerStoneInput?.stoneType]);

  const handleBack = useCallback(() => {
    const isStoneIsNone = payload.centerStoneInput?.stoneType === StoneType.None;
    const screenMap = {
      1: EARRINGS_SCREEN.EARRINGS_TYPE,
      2: EARRINGS_SCREEN.METAL_TYPES,
      3: EARRINGS_SCREEN.EARRINGS_GEMSTONE,
      4: isStoneIsNone ? EARRINGS_SCREEN.EARRINGS_GEMSTONE : EARRINGS_SCREEN.CENTER_STONE_DETAILS,
      5: EARRINGS_SCREEN.EARRINGS_BRAND,
      default: EARRINGS_SCREEN.EARRINGS_TYPE,
    };

    setInnerScreen(prevInnerScreen => {
      const mappedValue = screenMap[prevInnerScreen as keyof typeof screenMap];

      if (mappedValue === undefined) {
        setTimeout(() => setItemType(undefined), 0);
        return screenMap['default'];
      }

      return mappedValue;
    });
  }, [payload.centerStoneInput?.stoneType, setItemType]);

  const handleEarringsType = useCallback(
    (earringsType: EarringsType) => {
      setScreen(EARRINGS_SCREEN.METAL_TYPES);
      setPayload(prevPayload => ({
        ...prevPayload,
        earringsType: earringsType,
      }));
    },
    [setScreen],
  );

  const handleMetalType = useCallback(
    (metalType: MetalType) => {
      setScreen(EARRINGS_SCREEN.EARRINGS_GEMSTONE);
      setPayload(prevPayload => ({
        ...prevPayload,
        metalType: metalType,
      }));
    },
    [setScreen],
  );

  const handleStoneType = useCallback(
    (stoneType: StoneType) => {
      setPayload(prevPayload => ({
        ...prevPayload,
        centerStoneInput: {
          ...prevPayload.centerStoneInput,
          stoneType: stoneType,
        },
      }));
      if (stoneType === StoneType.None) {
        return setScreen(EARRINGS_SCREEN.EARRINGS_BRAND);
      }

      return setScreen(EARRINGS_SCREEN.CENTER_STONE_DETAILS);
    },
    [setScreen],
  );

  const handleCenterStoneDetails = useCallback(
    (params: CenterStoneDetailsParams) => {
      setScreen(EARRINGS_SCREEN.EARRINGS_BRAND);
      setPayload(prevPayload => ({
        ...prevPayload,
        centerStoneInput: {
          cut: params.cut,
          carat: params.carat || 0,
          stoneType: prevPayload.centerStoneInput?.stoneType || StoneType.DiamondUnknown,
        },
      }));
    },
    [setScreen],
  );

  const handleEarringsBrand = useCallback(
    (brand: Brand) => {
      setScreen(EARRINGS_SCREEN.MORE_DETAILS);
      setPayload(prevPayload => ({
        ...prevPayload,
        brand: brand,
      }));
    },
    [setScreen],
  );

  const handlePayload = useCallback(
    (images: string[] | undefined) => {
      const earringsPayload: EarringsInput = {
        ...payload,
        centerStoneInput: {
          ...payload.centerStoneInput,
          carat: payload.centerStoneInput?.carat && Math.round(payload.centerStoneInput.carat * CARAT_MULTIPLIER || 0),
        },
      };

      const mainPayload: EarringsPayload = {
        earringsInput: earringsPayload,
        photoLinks: images,
      };

      if (!user) {
        setLocalStorage(mainPayload);
        goToSignUp();
      } else {
        addEarringsForCurrentUserMutation({
          variables: { creditApplicationId: user?.creditApplication?.id || '', ...mainPayload },
        })
          .then(res => {
            if (res.errors) {
              console.error(res.errors);
            } else {
              gotoItemCart();
            }
          })
          .catch(error => {
            console.error(error);
          });
      }
    },
    [addEarringsForCurrentUserMutation, goToSignUp, gotoItemCart, payload, setLocalStorage, user],
  );

  const renderScreens = useMemo(
    () => ({
      [EARRINGS_SCREEN.EARRINGS_TYPE]: (
        <EarringsTypeScreen
          handleBack={() => {
            goBack();
            setItemType(undefined);
          }}
          onNext={handleEarringsType}
        />
      ),
      [EARRINGS_SCREEN.METAL_TYPES]: <MetalTypes handleBack={handleBack} onNext={handleMetalType} />,
      [EARRINGS_SCREEN.EARRINGS_GEMSTONE]: <EarringsGemstone handleBack={handleBack} onNext={handleStoneType} />,
      [EARRINGS_SCREEN.CENTER_STONE_DETAILS]: (
        <CenterStoneDetails handleBack={handleBack} onNext={handleCenterStoneDetails} hasCentralDiamond={hasDiamond} />
      ),
      [EARRINGS_SCREEN.EARRINGS_BRAND]: (
        <JewelryBrandsForm
          title={'What brand are your earrings?'}
          handleBack={handleBack}
          onNext={handleEarringsBrand}
        />
      ),
      [EARRINGS_SCREEN.MORE_DETAILS]: (
        <ImageDetailsForm
          loadingMutation={loading}
          onNext={handlePayload}
          onBack={handleBack}
          listItems={['Photo of earrings', 'Certification', 'Appraisal']}
        />
      ),
    }),
    [
      handleEarringsType,
      handleBack,
      handleMetalType,
      handleStoneType,
      handleCenterStoneDetails,
      hasDiamond,
      handleEarringsBrand,
      loading,
      handlePayload,
      goBack,
      setItemType,
    ],
  );

  return <>{renderScreens[innerScreen as keyof typeof renderScreens]}</>;
};
