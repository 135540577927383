const CLOUDFLARE_LEGAL_URL = 'https://files.getpesto.com/legal';
export const routes = {
  home: '/',
  application: '/application',
  quickAppraisal: '/quick-appraisal',
  cashSecured: '/cash-secured',
  login: '/login',
  loginCallback: '/login-callback',
  status: '/status',
  applicationStatus: '/application/status',
  cashSecuredStatus: '/cash-secured/status',
  applicationOffer: '/application/offer',
  preQualified: '/application/pre-qualified',
  landingFinder: '/landing',
  socureVerification: '/socure-verification',
  settings: '/settings',
  faq: '/faq',
};

export const links = {
  talkToTheTeam: 'https://calendly.com/jamespestocustomercalls/30min',
  marketingWebsite: 'https://www.getpesto.com',
  privacyPolicy: `${CLOUDFLARE_LEGAL_URL}/Pesto-Privacy-Policy.pdf`,
  privacyNotice: `${CLOUDFLARE_LEGAL_URL}/Pesto-Privacy-Notice.pdf`,
  termsOfService: `${CLOUDFLARE_LEGAL_URL}/Pesto-Terms-of-Service.pdf`,
  rewardsTerms: `${CLOUDFLARE_LEGAL_URL}/Pesto-Mastercard-Rewards-Program-Terms-and-Conditions.pdf`,
  eSignDisclosure: `${CLOUDFLARE_LEGAL_URL}/Pesto-E-Sign-Disclosure-and-Consent-to-Use-Electronic-Records-and-Signatures.pdf`,
  tcpa: `${CLOUDFLARE_LEGAL_URL}/Pesto-Telephone-Consumer-Protection-Act-Notice.pdf`,
  cardholderAgreement: `${CLOUDFLARE_LEGAL_URL}/Pesto-Consumer-Cardholder-Agreement.pdf`,
  packingAndShippingInstructions: `${CLOUDFLARE_LEGAL_URL}/Pesto-Preparing-your-Valuables-for-Shipment.pdf`,
  securityAgreementItem: `${CLOUDFLARE_LEGAL_URL}/Security-Agreement-Item.pdf`,
  securityAgreementCash: `${CLOUDFLARE_LEGAL_URL}/Security-Agreement-Cash.pdf`,
  patriotAct: `${CLOUDFLARE_LEGAL_URL}/Pesto-Patriot-Act-Notice.pdf`,
  rateDisclosureItem: `${CLOUDFLARE_LEGAL_URL}/Pesto-Disclosure-of-Rates-Fees-and-Costs-plus-Security-Agreement-Item.pdf`,
  rateDisclosureCash: `${CLOUDFLARE_LEGAL_URL}/Pesto-Disclosure-of-Rates-Fees-and-Costs-plus-Security-Agreement-Cash.pdf`,
  accountOpeningDisclosures: `${CLOUDFLARE_LEGAL_URL}/TILA-Disclosures-Account-Opening.pdf`,
  appStoreUrl: 'https://apps.apple.com/us/app/pesto-credit/id1669664225',
  playStoreUrl: 'https://play.google.com/store/apps/details?id=com.i2c.mcpcc.pinglendv32',
};

export const quickAppraisalRoutes = {
  metal: routes.quickAppraisal + '/metal',
  gemstone: routes.quickAppraisal + '/gemstone',
  ring: routes.quickAppraisal + '/ring',
  earrings: routes.quickAppraisal + '/earrings',
  necklace: routes.quickAppraisal + '/necklace',
  watch: routes.quickAppraisal + '/watch',
  other: routes.quickAppraisal + '/other',
};

export const quickAppraisalRoutesExact = Object.keys(quickAppraisalRoutes).reduce(
  (acc, key) => ({
    ...acc,
    [key]: quickAppraisalRoutes[key as keyof typeof quickAppraisalRoutes].replace(routes.quickAppraisal, ''),
  }),
  {} as typeof quickAppraisalRoutes,
);

export const loginRoutes = {
  root: routes.login + '/',
  addItemSignUp: routes.login + '/add-item-sign-up',
  skipAddItemSignUp: routes.login + '/skip-add-item-sign-up',
  signUp: routes.login + '/sign-up',
  getUser: routes.login + '/get-user',
  createAccount: routes.login + '/create-account',
  personalInfo: routes.login + '/personal-info',
  chooseFlow: routes.login + '/choose-flow',
};

export const loginRoutesExact = Object.keys(loginRoutes).reduce(
  (acc, key) => ({
    ...acc,
    [key]: loginRoutes[key as keyof typeof loginRoutes].replace(routes.login, ''),
  }),
  {} as typeof loginRoutes,
);

export const applicationRoutes = {
  root: routes.application + '/',
  quickAppraisal: routes.application + '/quick-appraisal',
  signUp: routes.application + '/sign-up',
  itemsCart: routes.application + '/asset-cart',
  addressAndIncome: routes.application + '/address-and-income',
  preQualified: routes.application + '/pre-qualified',
  itemsShipped: routes.application + '/items-shipped',
  itemsDelivered: routes.application + '/items-delivered',
  offer: routes.application + '/offer',
  completion: routes.application + '/completion',
};
export const applicationRoutesExact = Object.keys(applicationRoutes).reduce(
  (acc, key) => ({
    ...acc,
    [key]: applicationRoutes[key as keyof typeof applicationRoutes].replace(routes.application, ''),
  }),
  {} as typeof applicationRoutes,
);
export const userStatusRoutes = {
  inReview: routes.applicationStatus + '/in-review',
  manualReview: routes.applicationStatus + '/manual-review',
  creditFreeze: routes.applicationStatus + '/credit-freeze',
  offerRejected: routes.applicationStatus + '/offer-rejected',
  notPrequalified: routes.applicationStatus + '/not-prequalified',
  creditDenied: routes.applicationStatus + '/credit-denied',
  invalidState: routes.applicationStatus + '/invalid-state',
  dontOperateInState: routes.applicationStatus + '/dont-operate-in-state',
  loginToYourAccount: routes.applicationStatus + '/login-to-your-account',
};
export const userStatusRoutesExact = Object.keys(userStatusRoutes).reduce(
  (acc, key) => ({
    ...acc,
    [key]: userStatusRoutes[key as keyof typeof userStatusRoutes].replace(routes.applicationStatus, ''),
  }),
  {} as typeof userStatusRoutes,
);

export const offerRoutes = {
  root: routes.applicationOffer + '/',
  rejectOffer: routes.applicationOffer + '/reject-offer',
  verifyInfo: routes.applicationOffer + '/verify-personal-info',
  verifyIncome: routes.applicationOffer + '/verify-income-info',
  finalAgreements: routes.applicationOffer + '/final-agreements',
};

export const offerRoutesExact = Object.keys(offerRoutes).reduce(
  (acc, key) => ({
    ...acc,
    [key]: offerRoutes[key as keyof typeof offerRoutes].replace(routes.applicationOffer, ''),
  }),
  {} as typeof offerRoutes,
);

export const preQualifiedRoute = {
  shipping: routes.preQualified + '/',
  downloadLabel: routes.preQualified + '/download-label',
  shippingKit: routes.preQualified + '/shipping-kit',
  shippingCode: routes.preQualified + '/shipping-code',
};

export const preQualifiedRoutesExact = Object.keys(preQualifiedRoute).reduce(
  (acc, key) => ({
    ...acc,
    [key]: preQualifiedRoute[key as keyof typeof preQualifiedRoute].replace(routes.preQualified, ''),
  }),
  {} as typeof preQualifiedRoute,
);

export const settingsRoutes = {
  root: routes.settings + '/',
  personalInfo: routes.settings + '/personal-info',
  myItems: routes.settings + '/my-items',
};

export const settingsRoutesExact = Object.keys(settingsRoutes).reduce(
  (acc, key) => ({
    ...acc,
    [key]: settingsRoutes[key as keyof typeof settingsRoutes].replace(routes.settings, ''),
  }),
  {} as typeof settingsRoutes,
);

export const cashSecuredRoutes = {
  root: routes.cashSecured + '/',
  creditLine: routes.cashSecured + '/credit-line',
  personalInfo: routes.cashSecured + '/personal-info',
  paymentSelection: routes.cashSecured + '/payment-selection',
  fundOverTime: routes.cashSecured + '/fund-over-time',
  payment: routes.cashSecured + '/payment',
  verifyPayment: routes.cashSecured + '/verify-payment',
  agreements: routes.cashSecured + '/agreements',
  congratulations: routes.cashSecured + '/congratulations',
  status: routes.cashSecured + '/status',
};

export const cashSecuredRoutesExact = Object.keys(cashSecuredRoutes).reduce(
  (acc, key) => ({
    ...acc,
    [key]: cashSecuredRoutes[key as keyof typeof cashSecuredRoutes].replace(routes.cashSecured, ''),
  }),
  {} as typeof cashSecuredRoutes,
);

export const landingPageRoutes = {
  root: routes.landingFinder + '/finder',
  newSignUpDemo: routes.landingFinder + '/new-sign-up-demo',
  personalInfoDemo: routes.landingFinder + '/personal-info-demo',
};

export const landingPageRoutesExact = Object.keys(landingPageRoutes).reduce(
  (acc, key) => ({
    ...acc,
    [key]: landingPageRoutes[key as keyof typeof landingPageRoutes].replace(routes.landingFinder, ''),
  }),
  {} as typeof landingPageRoutes,
);

export const cashPaymentRoutes = {
  root: cashSecuredRoutes.payment + '/',
  fundNow: cashSecuredRoutes.payment + '/fund-now',
  fundOverTime: cashSecuredRoutes.payment + '/fund-over-time',
};

export const cashPaymentRoutesExact = Object.keys(cashPaymentRoutes).reduce(
  (acc, key) => ({
    ...acc,
    [key]: cashPaymentRoutes[key as keyof typeof cashPaymentRoutes].replace(cashSecuredRoutes.payment, ''),
  }),
  {} as typeof cashPaymentRoutes,
);
export const cashSecuredStatusRoutes = {
  root: routes.cashSecuredStatus + '/',
  inReview: routes.cashSecuredStatus + '/in-review',
  offerRejected: routes.cashSecuredStatus + '/offer-rejected',
  invalidState: routes.cashSecuredStatus + '/invalid-state',
  notPrequalified: routes.cashSecuredStatus + '/not-prequalified',
  manualReview: routes.cashSecuredStatus + '/manual-review',
  creditFreeze: routes.cashSecuredStatus + '/credit-freeze',
  loginToYourAccount: routes.cashSecuredStatus + '/login-to-your-account',
};

export const cashSecuredStatusRoutesExact = Object.keys(cashSecuredStatusRoutes).reduce(
  (acc, key) => ({
    ...acc,
    [key]: cashSecuredStatusRoutes[key as keyof typeof cashSecuredStatusRoutes].replace(routes.cashSecuredStatus, ''),
  }),
  {} as typeof cashSecuredStatusRoutes,
);
