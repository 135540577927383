import clsx from 'clsx';
import { useNavigate, useRoutes } from 'react-router-dom';

import { SecurityType } from 'src/__generated__/graphql/api';
import { Header } from 'src/components/Header/Header';
import { Wrapper1280 } from 'src/components/UI/Wrapper/Wrapper1280';
import { FROM_LANDING_PAGE } from 'src/constants/application';
import { QUICK_APPRAISAL_LS_KEY } from 'src/constants/localStorageKeys';
import { landingPageRoutesExact, loginRoutes, routes } from 'src/constants/routes';
import { useLocalStorage } from 'src/hooks/useLocalStorage';
import { ApplicationProvider } from 'src/providers/ApplicationProvider';
import { parseURL } from 'src/util/URL/utmParamsHelper';
import {
  isLandingFinderEnabled,
  isNewSignUpDemoEnabled,
  isPersonalInfoDemoEnabled,
} from 'src/util/adminDebuggerHelper';

import type { QuickAppraisalLocalStorage } from '../../Login/SignUp/SignUp';

import { FinderLandingPage } from './finderLandingPage/FinderLandingPage';
import { NewSignUpDemo } from './newSignUpDemo/NewSignUpDemo';
import { PersonalInfoDemo } from './personalInfoDemo/PersonalInfoDemo';

const LandingPages = () => {
  const navigate = useNavigate();
  const urlParams = parseURL(window.location.href).searchParams;
  const displayDemo = urlParams.get('showthedemo') === 'true';

  const [, setLocalStorageSecurityType] = useLocalStorage<QuickAppraisalLocalStorage>(
    QUICK_APPRAISAL_LS_KEY,
    {} as QuickAppraisalLocalStorage,
  );

  const handleCashSecuredFlow = () => {
    setLocalStorageSecurityType({ securityType: SecurityType.Cash });
    navigate(loginRoutes.signUp, { state: FROM_LANDING_PAGE });
  };

  const handleJewelryFlow = () => {
    navigate(routes.quickAppraisal, { state: FROM_LANDING_PAGE });
  };

  const landingPagesRoutes = useRoutes([
    {
      path: landingPageRoutesExact.root,
      element: (isLandingFinderEnabled() || displayDemo) && (
        <FinderLandingPage onCashClick={handleCashSecuredFlow} onJewelryClick={handleJewelryFlow} />
      ),
    },
    {
      path: landingPageRoutesExact.newSignUpDemo,
      element: (isNewSignUpDemoEnabled() || displayDemo) && <NewSignUpDemo />,
    },
    {
      path: landingPageRoutesExact.personalInfoDemo,
      element: (isPersonalInfoDemoEnabled() || displayDemo) && <PersonalInfoDemo />,
    },
  ]);

  return (
    <ApplicationProvider>
      <div className="mx-auto">
        <Header />
        <Wrapper1280
          className={clsx('flex min-h-[80vh] flex-col items-center gap-4 pb-[240px] pt-4 md:pb-[150px] md:pt-8')}
        >
          {landingPagesRoutes}
        </Wrapper1280>
      </div>
    </ApplicationProvider>
  );
};

export default LandingPages;
