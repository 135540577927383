import { WatchCondition } from '../__generated__/graphql/api';

export const watchConditions: { value: WatchCondition; text: string }[] = [
  {
    value: WatchCondition.New,
    text: 'New',
  },
  {
    value: WatchCondition.Excellent,
    text: 'Excellent',
  },
  {
    value: WatchCondition.VeryGood,
    text: 'Very Good',
  },
  {
    value: WatchCondition.Good,
    text: 'Good',
  },
  {
    value: WatchCondition.Fair,
    text: 'Fair',
  },
  {
    value: WatchCondition.NotWorking,
    text: 'Not Working',
  },
];
