import * as React from 'react';
import type { SVGProps } from 'react';

interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgCopyIcon = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M3 19.88v1.546c0 .24.071.469.198.638.127.169.3.264.479.264h1.16M3 15.758v-3.013m4.928 9.583h2.26m3.055 0h1.08c.18 0 .352-.095.479-.264.127-.17.198-.399.198-.638v-1.547M3 8.67V7.23c0-.24.071-.469.198-.638.127-.169.3-.264.479-.264h1.16M20.285 16.044c0 .253-.076.497-.21.676-.135.18-.317.28-.507.28H9.002c-.19 0-.372-.1-.507-.28a1.138 1.138 0 0 1-.21-.676V1.956c0-.253.076-.497.21-.676.135-.18.317-.28.507-.28h10.566c.19 0 .372.1.507.28.134.18.21.423.21.676v14.088Z"
    />
  </svg>
);
export default SvgCopyIcon;
