import dayjs from 'dayjs';
import { useEffect, useState } from 'react';

import duration from 'dayjs/plugin/duration';

import { Modal2 } from '../UI/Modal/Modal2';

import PromobBg from './bg.png';

dayjs.extend(duration);

const PromotionalBanner = () => {
  const [showDisclosure, setShowDisclosure] = useState<boolean>(false);
  const [timeLeft, setTimeLeft] = useState<string>(() => localStorage.getItem('timeLeft') || '');

  const handleToggleDisclosure = () => {
    setShowDisclosure(!showDisclosure);
  };

  useEffect(() => {
    const calculateTimeLeft = () => {
      const now = dayjs();
      const dateEnds = dayjs('2024-03-31T23:59:59');

      const difference = dateEnds.diff(now);

      if (difference <= 0) {
        setTimeLeft('');
        localStorage.removeItem('timeLeft');
        return '';
      }

      const duration = dayjs.duration(difference);
      const days = duration.days();
      const hours = duration.hours();
      const minutes = duration.minutes();
      const seconds = duration.seconds();
      const timeString = `${days} day${days !== 1 ? 's' : ''} ${hours}h ${minutes}m ${seconds}s`;

      localStorage.setItem('timeLeft', timeString);
      return timeString;
    };

    const interval = setInterval(() => {
      const newTimeLeft = calculateTimeLeft();
      setTimeLeft(newTimeLeft);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  if (!timeLeft) return null;

  return (
    <div className="inset-x-0 top-0 z-[15000] bg-[#FFF9E8]" id="promotional-banner">
      <div>
        <div className="mx-auto max-w-7xl px-3 py-3 sm:px-6 lg:px-8">
          <div className="flex flex-col items-center justify-between lg:justify-center">
            <div className="flex h-[159px] items-center lg:h-auto">
              <div>
                <div className="flex h-[159px] flex-1 flex-col items-center lg:mr-3 lg:h-auto">
                  <p className="sm:text-md h-[100%] justify-center bg-white p-2 text-center text-sm font-medium text-basicSoft">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="2"
                      stroke="currentColor"
                      aria-hidden="true"
                      className="mr-2 hidden h-6 w-6 lg:inline"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M11 5.882V19.24a1.76 1.76 0 01-3.417.592l-2.147-6.15M18 13a3 3 0 100-6M5.436 13.683A4.001 4.001 0 017 6h1.832c4.1 0 7.625-1.234 9.168-3v14c-1.543-1.766-5.067-3-9.168-3H7a3.988 3.988 0 01-1.564-.317z"
                      ></path>
                    </svg>
                    Dont Miss Out: Get a free $10. Reach the minimum of $200 in deposits today and get a Pesto
                    Mastercard® cash secured card to take advantage of this offer. Hurry, this offer expires in{' '}
                    <b>{timeLeft}</b>
                  </p>
                </div>
              </div>

              <div className="bg-red h-[159px] lg:hidden">
                <img className="h-[100%] w-[550px] object-cover" src={PromobBg} />
              </div>
            </div>
            <button
              onClick={handleToggleDisclosure}
              className="mt-[5px] flex items-center justify-center text-basicSoft underline"
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                <path
                  fill="currentColor"
                  d="M12 24c6.627 0 12-5.373 12-12s-5.373-12-12-12-12 5.373-12 12 5.373 12 12 12zm1-6h-2v-8h2v8zm-1-12.25c.69 0 1.25.56 1.25 1.25s-.56 1.25-1.25 1.25-1.25-.56-1.25-1.25.56-1.25 1.25-1.25z"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
      {showDisclosure && (
        <Modal2 isOpen={showDisclosure} onClose={handleToggleDisclosure}>
          <div className="flex w-full items-center justify-between">
            <span className="text-lg font-semibold">Legal Disclosure</span>
            <button onClick={handleToggleDisclosure} className="w-[10px] text-xl font-bold text-black">
              &times;
            </button>
          </div>
          <div className="mt-4">
            <p className="text-justify text-sm text-basicSoft">
              You must fund the minimum of $200 in total security deposits by 11:59 pm EST on 03/31/2024 to be eligible
              for this offer. The $10 will be posted to your Account as a statement credit on your third billing
              statement. You are only eligible to receive this statement credit if your Account is up to date and not in
              default as defined in the Cardholder Agreement during your second billing cycle. This statement credit
              will reduce your Account balance but will not be counted as a payment toward your minimum balance. You
              will still accrue interest and/or late fees if you do not submit your minimum payment, even if you have a
              statement credit during that billing cycle.
            </p>
          </div>
        </Modal2>
      )}
    </div>
  );
};

export default PromotionalBanner;
