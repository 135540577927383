import clsx from 'clsx';
import type { FC, ReactNode } from 'react';
import React from 'react';

import type { Brand } from 'src/__generated__/graphql/api';
import { ButtonContainer } from 'src/components/ButtonContainer/ButtonContainer';
import { BackButton } from 'src/components/UI/Button/BackButton';

import { Typography } from '../../../../../../components/Typography/Typography';
import { jewelryBrands } from '../../../../../../data/brands';

interface JewelryBrandsScreenProps {
  className?: string;
  title: string;
  onNext: (data: Brand) => void;
  handleBack: () => void;
}

interface BrandButtonContentProps {
  logo?: ReactNode | string;
  text: string;
}

const BrandButtonContent: FC<BrandButtonContentProps> = ({ logo, text }) => {
  if (typeof logo === 'string') {
    return <img src={logo} alt={text || ''} />;
  }

  if (React.isValidElement(logo)) {
    return logo;
  }

  return <span className="text-center text-[12px] font-medium">{text}</span>;
};

const JewelryBrandsForm = (props: JewelryBrandsScreenProps) => {
  const { className, title, onNext, handleBack } = props;
  const rootStyles = clsx('flex flex-col gap-4', className);

  return (
    <div className={rootStyles}>
      <Typography variant={'headerMedium'}>{title}</Typography>
      <div className="grid w-full max-w-[400px] grid-cols-2 justify-center gap-4 text-center">
        {jewelryBrands.map((brand, index) => (
          <button
            key={index}
            className={clsx('h-[76px] w-auto rounded-sm border-[1px] bg-[#FAFAFC] px-8 py-3 hover:bg-gray-100')}
            value={brand.value}
            data-testid={brand.dataTestId}
            onClick={() => onNext(brand.value)}
          >
            <BrandButtonContent logo={brand.logo} text={brand.text} />
          </button>
        ))}
      </div>
      <ButtonContainer
        leftButton={<BackButton onClick={handleBack} rightButtonExist={false} className="!w-[40px]" />}
      />
    </div>
  );
};

export default JewelryBrandsForm;
