import clsx from 'clsx';

import { RadioBox } from 'src/components/RadioBox/RadioBox';

interface RadioSelectProps {
  onChange: (checked: boolean) => void;
  checked: boolean;
  className?: string;
  title?: string;
  labelText?: string;
  type: 'fast' | 'fee';
  disabled?: boolean;
}

export const RadioSelect = (props: RadioSelectProps) => {
  const { className, onChange, checked, title, labelText, type, disabled = false } = props;
  const rootStyle = clsx(
    className,
    'flex w-full flex-col items-center justify-between rounded-lg border border-neutralInactive bg-[#FAFAFC] text-lg text-left font-normal text-basic hover:bg-[#F2F2F7] active:bg-[#EDEDF2]',
    {
      'shadow-lg !border-neutral': checked,
      'opacity-50 cursor-not-allowed pointer-events-none': disabled,
    },
  );

  const labelClass = clsx('flex items-center gap-4 rounded-lg p-2 text-sm', {
    'bg-[#D8E9F6] text-[#116AAA]': type === 'fast',
    'bg-[#87D5AF33] text-[#1C7C4E]': type === 'fee',
  });

  const toggleView = () => {
    if (disabled) return;
    onChange(!checked);
  };

  const handleRadioChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (disabled) return;
    onChange(e.target.checked);
  };

  return (
    <div className={rootStyle}>
      <button
        className="flex w-full items-center gap-4 px-4 py-4"
        onClick={e => {
          e.preventDefault();
          toggleView();
        }}
      >
        <RadioBox value={type} checked={checked} onChange={handleRadioChange} />
        <div className="flex w-full items-center justify-between gap-4">
          <span className="text-lg">{title}</span>
          <div className={labelClass}>{labelText}</div>
        </div>
      </button>
    </div>
  );
};
