import { ButtonContainer } from 'src/components/ButtonContainer/ButtonContainer';
import { BackButton } from 'src/components/UI/Button/BackButton';

import type { RingType } from '../../../../../../__generated__/graphql/api';
import { ImageListSelection } from '../../../../../../components/ImageListSelection/ImageListSelection';
import { ringStyles } from '../../../../../../data/ring';
import { ScreenQuickAppraisal } from '../../components/ScreenQuickAppraisal';

interface RingStyleScreenProps {
  className?: string;
  onNext: (data: RingType) => void;
  handleBack: () => void;
}
export const RingStyleScreen = (props: RingStyleScreenProps) => {
  const { className, onNext, handleBack } = props;

  return (
    <ScreenQuickAppraisal className={className} title="What style is your ring?">
      <div className={'grid grid-cols-2 gap-4'}>
        {ringStyles.map((style, index) => {
          return (
            <ImageListSelection onClick={() => onNext(style.value)} image={style.image} text={style.text} key={index} />
          );
        })}
      </div>
      <ButtonContainer leftButton={<BackButton onClick={handleBack} rightButtonExist={false} />} />
    </ScreenQuickAppraisal>
  );
};
