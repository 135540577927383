import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, type ChangeEvent } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { ButtonContainer } from 'src/components/ButtonContainer/ButtonContainer';
import CheckBox from 'src/components/CheckBox/CheckBox';
import { BackButton } from 'src/components/UI/Button/BackButton';
import { TextField } from 'src/components/UI/Form/TextField/TextField';

import type { Cut } from '../../../../../../__generated__/graphql/api';
import CaratDropdown from '../../../../../../components/CaratDropdown/CaratDropdown';
import { MaxCaratValue } from '../../../../../../components/CaratSlider/CaratSlider';
import CutDropdown from '../../../../../../components/CutDropdown/CutDropdown';
import { Typography } from '../../../../../../components/Typography/Typography';
import Button from '../../../../../../components/UI/Button/Button';
import { useOnMount } from '../../../../../../hooks/useOnMount';
import { ScreenQuickAppraisal } from '../../components/ScreenQuickAppraisal';

interface RingStoneDetailsScreenProps {
  className?: string;
  onNext: (cut: Cut | undefined, carat: number, count: number) => void;
  hasCentralDiamond: boolean;
  handleBack: () => void;
}

interface FormValues {
  carat: number;
  cut: Cut | undefined;
  hasCentralDiamond: boolean;
  multiStone?: number | string;
  isMultiStone: boolean;
}

const schema = yup.object().shape({
  carat: yup.number().min(0.01, 'Carat is required'),
  multiStone: yup.number().when('isMultiStone', {
    is: true,
    then: schema => schema.required('Amount of stones is required').min(1, 'Amount of stones should be more than 0'),
    otherwise: schema => schema.notRequired(),
  }),
  cut: yup.mixed().when(['hasCentralDiamond', 'isMultiStone'], {
    is: (hasCentralDiamond: boolean, isMultiStone: boolean) => hasCentralDiamond && !isMultiStone,
    then: schema => schema.required('Cut is required'),
    otherwise: schema => schema.notRequired(),
  }),
});

export const RingCenterStoneDetailsScreen = (props: RingStoneDetailsScreenProps) => {
  const { className, onNext, hasCentralDiamond = false, handleBack } = props;
  const screenTitle = hasCentralDiamond ? 'Diamond details' : 'Stone details';

  const {
    setValue,
    trigger,
    handleSubmit,
    watch,
    formState: { errors, isSubmitted },
  } = useForm<FormValues>({
    resolver: yupResolver(schema),
    defaultValues: {
      carat: 0,
      cut: undefined,
      multiStone: 1,
      isMultiStone: false,
    },
  });

  const { cut, carat, multiStone, isMultiStone } = watch();

  useEffect(() => {
    if (isSubmitted) {
      trigger();
    }
  }, [cut, carat, isSubmitted, trigger]);
  useOnMount(() => {
    if (hasCentralDiamond) {
      setValue('hasCentralDiamond', true);
    }
  });

  const handleFormSubmit = (data: FormValues) => {
    onNext(data.cut, data.carat, Number(data.multiStone));
  };

  return (
    <ScreenQuickAppraisal className={className} title={screenTitle}>
      <CheckBox
        value={isMultiStone}
        onChange={bool => {
          setValue('isMultiStone', bool);
          if (!bool) {
            setValue('multiStone', 1);
          }
        }}
        label=""
        text={
          <Typography variant={'body'} className="text-left">
            My center stone is composed of multiple stones
          </Typography>
        }
      />

      <form className={'flex flex-col gap-4'} onSubmit={handleSubmit(handleFormSubmit)}>
        {isMultiStone && (
          <TextField
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              const value = Number(event.target.value);
              if (!isNaN(value)) {
                setValue('multiStone', value);
              }
            }}
            label="Amount of stones"
            value={multiStone}
            inputMode="numeric"
            required
          />
        )}

        <CaratDropdown
          label={isMultiStone ? 'Total carat size' : 'Carat size'}
          inputLabel={isMultiStone ? 'What is the average carat size of your stones?' : ''}
          maxCaratValue={hasCentralDiamond ? MaxCaratValue.THREE : MaxCaratValue.FIVE}
          onChange={(carat: number) => {
            setValue('carat', carat);
          }}
          alwaysOpen
        />

        {errors.carat && (
          <Typography variant={'caption'} className="text-danger">
            {errors.carat.message}
          </Typography>
        )}
        {!isMultiStone && hasCentralDiamond && (
          <CutDropdown
            onChange={(cut: Cut) => {
              setValue('cut', cut);
            }}
            alwaysOpen
          />
        )}
        {errors.cut && (
          <Typography variant={'caption'} className="text-danger">
            {errors.cut.message}
          </Typography>
        )}

        {errors.multiStone && (
          <Typography variant={'caption'} className="text-danger">
            {errors.multiStone.message}
          </Typography>
        )}

        <ButtonContainer
          rightButton={<Button buttonType={'primary'} text={'Next'} onClick={handleSubmit(handleFormSubmit)} />}
          leftButton={<BackButton rightButtonExist onClick={handleBack} />}
        />
      </form>
    </ScreenQuickAppraisal>
  );
};
