import { useState } from 'react';

import type { Cut } from 'src/__generated__/graphql/api';

import { centerStoneCuts } from '../../data/diamond';
import DropdownSelect from '../../pages/ApplicationFlow/steps/QuickAppraisal/components/DropDownSelect';
import DropdownContent from '../../pages/ApplicationFlow/steps/QuickAppraisal/components/DropdownContent';

interface CutDropdownProps {
  onChange: (value: Cut) => void;
  isOpen?: boolean;
  alwaysOpen?: boolean;
}

const CutDropdown = ({ onChange, isOpen = false, alwaysOpen }: CutDropdownProps) => {
  const [selectedCut, setSelectedCut] = useState<Cut>();

  const handleChange = (value: Cut) => {
    setSelectedCut(value);
    onChange(value);
  };

  return (
    <DropdownSelect
      isShowSelectedItem
      icon={null}
      label="Cut"
      selectedItem={selectedCut}
      options={centerStoneCuts}
      isOpen={isOpen}
      alwaysOpen={alwaysOpen}
    >
      {(handleValueSelect: (value: Cut) => void) => (
        <div>
          <DropdownContent
            alwaysOpen
            isShowIcon
            selectedValue={selectedCut}
            options={centerStoneCuts}
            onChange={value => {
              handleValueSelect(value);
              handleChange(value);
            }}
          />
        </div>
      )}
    </DropdownSelect>
  );
};

export default CutDropdown;
