import { links } from 'src/constants/routes';
import { cn } from 'src/util/cn';

import { Typography } from '../Typography/Typography';

interface MeetWithButtonProps {
  className?: string;
}

export const MeetWithComponent = (props: MeetWithButtonProps) => {
  const { className } = props;
  const rootStyle = cn(className);
  return (
    <div className="h-auto w-full bg-powder p-2 sm:p-4">
      <Typography variant="bodySmall" className={rootStyle}>
        Questions about Pesto?{' '}
        <b>
          Schedule a call{' '}
          <a href={links.talkToTheTeam} target="_blank" className="text-action underline hover:no-underline">
            here
          </a>
        </b>
        .
      </Typography>
    </div>
  );
};
