import * as React from 'react';
import type { SVGProps } from 'react';

interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgEarringQaIcon = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 42 50"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <g
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      clipPath="url(#Earring-qa-icon_svg__a)"
    >
      <path d="M17.982 44.728c0 2.35-1.91 4.272-4.246 4.272-2.335 0-4.245-1.922-4.245-4.272 0-2.35 4.245-7.476 4.245-7.476s4.246 5.127 4.246 7.476ZM14.16 11.46l1.274-3.845c.85-2.35-.212-4.912-2.334-5.98-6.63-3.336-7.855 7.383-7.855 11.107 0 2.35-1.91 4.272-4.245 4.272M13.736 19.577a1.065 1.065 0 0 1-1.061-1.068c0-.59.475-1.068 1.061-1.068M13.736 19.577c.586 0 1.062-.478 1.062-1.068 0-.59-.476-1.068-1.062-1.068M13.736 25.696a1.065 1.065 0 0 1-1.061-1.068c0-.59.475-1.068 1.061-1.068M13.736 25.696c.586 0 1.062-.479 1.062-1.068 0-.59-.476-1.068-1.062-1.068M13.736 32.393a1.065 1.065 0 0 1-1.061-1.068c0-.59.475-1.068 1.061-1.068M13.736 32.393c.586 0 1.062-.478 1.062-1.068 0-.59-.476-1.068-1.062-1.068M24.018 44.728c0 2.35 1.91 4.272 4.246 4.272 2.335 0 4.245-1.922 4.245-4.272 0-2.35-4.245-7.476-4.245-7.476s-4.246 5.127-4.246 7.476ZM27.84 11.46l-1.274-3.845c-.85-2.35.212-4.912 2.335-5.98 6.629-3.336 7.854 7.383 7.854 11.107 0 2.35 1.91 4.272 4.245 4.272M28.264 19.577c.586 0 1.061-.478 1.061-1.068 0-.59-.475-1.068-1.061-1.068M28.264 19.577a1.065 1.065 0 0 1-1.062-1.068c0-.59.475-1.068 1.062-1.068M28.264 25.696c.586 0 1.061-.479 1.061-1.068 0-.59-.475-1.068-1.061-1.068M28.264 25.696a1.065 1.065 0 0 1-1.062-1.068c0-.59.475-1.068 1.062-1.068M28.264 32.393c.586 0 1.061-.478 1.061-1.068 0-.59-.475-1.068-1.061-1.068M28.264 32.393a1.065 1.065 0 0 1-1.062-1.068c0-.59.475-1.068 1.062-1.068" />
    </g>
    <defs>
      <clipPath id="Earring-qa-icon_svg__a">
        <path fill="#fff" d="M0 0h42v50H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgEarringQaIcon;
