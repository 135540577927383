import clsx from 'clsx';
import type { FC, ReactNode } from 'react';

import { AssetType } from '../../../../../__generated__/graphql/api';
import { Typography } from '../../../../../components/Typography/Typography';
import SvgCoinQaIcon from '../../../../../icons/components/quick-appraisal/CoinQaIcon';
import SvgDiamondQaIcon from '../../../../../icons/components/quick-appraisal/DiamondQaIcon';
import SvgEarringQaIcon from '../../../../../icons/components/quick-appraisal/EarringQaIcon';
import SvgNecklaceQaIcon from '../../../../../icons/components/quick-appraisal/NecklaceQaIcon';
import SvgOtherLineIcon from '../../../../../icons/components/quick-appraisal/OtherLineIcon';
import SvgRingQaIcon from '../../../../../icons/components/quick-appraisal/RingQaIcon';
import SvgWatchIcon from '../../../../../icons/components/quick-appraisal/WatchQaIcon';

interface IconButtonSelectProps {
  onSelectType: (type: AssetType) => void;
  activate: boolean;
  type: AssetType;
  text: string;
  extraText?: string;
  flowStarted: boolean;
  dataTestId?: string;
}

export const IconButtonSelect: FC<IconButtonSelectProps> = ({
  onSelectType,
  activate,
  type,
  text,
  flowStarted,
  dataTestId,
}) => {
  const colorStateClass = clsx('h-[48px] w-full', {
    'stroke-red-600': activate,
    'stroke-slate-300': !activate && flowStarted,
  });

  const iconSizeClass = '!h-12 !w-12';

  const renderIcon: Record<AssetType, ReactNode | null> = {
    [AssetType.PreciousMetals]: <SvgCoinQaIcon className={clsx(colorStateClass, iconSizeClass)} />,
    [AssetType.Gemstones]: <SvgDiamondQaIcon className={clsx(colorStateClass, iconSizeClass)} />,
    [AssetType.Jewelry]: <SvgNecklaceQaIcon className={clsx(colorStateClass, iconSizeClass)} />,
    [AssetType.Watch]: <SvgWatchIcon className={clsx(colorStateClass, iconSizeClass)} />,
    [AssetType.Diamond]: <SvgDiamondQaIcon className={clsx(colorStateClass, iconSizeClass)} />,
    [AssetType.Ring]: <SvgRingQaIcon className={clsx(colorStateClass, iconSizeClass)} />,
    [AssetType.Other]: <SvgOtherLineIcon className={clsx(colorStateClass, iconSizeClass)} />,
    [AssetType.Earrings]: <SvgEarringQaIcon className={clsx(colorStateClass, iconSizeClass)} />,
    [AssetType.Necklace]: <SvgNecklaceQaIcon className={clsx(colorStateClass, iconSizeClass)} />,
    [AssetType.Cash]: <SvgOtherLineIcon className={clsx(colorStateClass, iconSizeClass)} />,
  };

  const handleAssetTypeSelection = () => {
    onSelectType(type);
  };

  return (
    <>
      <button
        className={clsx(
          'h-[150px] w-full p-4',
          'rounded-sm border border-neutralInactive bg-[#FAFAFC] shadow hover:bg-[#F2F2F7] active:bg-[#EDEDF2]',
          {
            'border-red-600': activate,
            'border-slate-300': !activate && flowStarted,
          },
        )}
        value={type}
        onClick={handleAssetTypeSelection}
        data-testid={dataTestId}
      >
        <div className="flex min-w-[50px] items-center justify-center">
          <div className="flex flex-col items-center gap-2">
            <div>{renderIcon[type]}</div>
            <div
              className={clsx('align-left col-span-5', {
                'text-red-600': activate,
                'text-slate-300': !activate && flowStarted,
              })}
            >
              <Typography className="whitespace-nowrap" variant={'body'}>
                {text}
              </Typography>
            </div>
          </div>
        </div>
      </button>
    </>
  );
};
