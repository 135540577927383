import * as React from 'react';
import type { SVGProps } from 'react';

interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgPencil = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 12 11"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      stroke="#4B5157"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M10.529.971A1.618 1.618 0 0 0 8.23.987L1.787 7.43 1 10.5l3.069-.787 6.444-6.445A1.616 1.616 0 0 0 10.53.971ZM8.047 1.171l2.282 2.282M1.787 7.43l2.284 2.28"
    />
  </svg>
);
export default SvgPencil;
