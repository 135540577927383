import * as React from 'react';
import type { SVGProps } from 'react';

interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgRadiantIcon = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 16 20"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      stroke="#101820"
      strokeWidth={1.5}
      d="M.75 4.384a.25.25 0 0 1 .064-.168L3.86.833A.25.25 0 0 1 4.045.75h8.274a.25.25 0 0 1 .196.094l2.68 3.35a.25.25 0 0 1 .055.157v11.798a.25.25 0 0 1-.055.156l-2.28 2.851a.25.25 0 0 1-.196.094H4.006a.25.25 0 0 1-.174-.07L.826 16.256a.25.25 0 0 1-.076-.18V4.385Z"
    />
  </svg>
);
export default SvgRadiantIcon;
