import * as React from 'react';
import type { SVGProps } from 'react';

interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgCheckSecure = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <g clipPath="url(#check-Secure_svg__a)">
      <path
        fill="currentColor"
        d="M21.547 3.886 12.359.072a.938.938 0 0 0-.719 0L2.454 3.886a.938.938 0 0 0-.578.866v4.637a15.7 15.7 0 0 0 9.77 14.541.938.938 0 0 0 .71 0 15.7 15.7 0 0 0 9.77-14.54V4.751a.937.937 0 0 0-.578-.866ZM20.25 9.39A13.828 13.828 0 0 1 12 22.044 13.829 13.829 0 0 1 3.75 9.39V5.378L12 1.953l8.25 3.425v4.011Zm-9.286 3.16 4.03-4.03a.938.938 0 0 1 1.325 1.326l-4.692 4.693a.937.937 0 0 1-1.326 0l-2.62-2.62a.937.937 0 1 1 1.326-1.326l1.957 1.957Z"
      />
    </g>
    <defs>
      <clipPath id="check-Secure_svg__a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgCheckSecure;
