import * as React from 'react';
import type { SVGProps } from 'react';

interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgSi2Icon = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <circle cx={12} cy={12} r={9.25} stroke="#101820" strokeWidth={1.5} />
    <path stroke="#101820" strokeLinecap="round" d="M15 15.293 16.293 14M13 11.293l.293-.293M14 13.293 16.293 11" />
  </svg>
);
export default SvgSi2Icon;
