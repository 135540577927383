import { Combobox, Transition } from '@headlessui/react';
import type { Ref } from 'react';
import { Fragment, forwardRef, useEffect, useState } from 'react';

import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService';
import LoadingSpinner from 'src/components/LoadingSpinner/LoadingSpinner';
import { cn } from 'src/util/cn';

import { createAddressObject } from '../../../../pages/ApplicationFlow/steps/AddressAndIncome/utils/addressHelper';
import type { AutoCompleteAddressProps } from '../FormTypes';
import { TextFieldWrapper } from '../TextFieldWrapper/TextFieldWrapper';
import { generateInputStyles } from '../inputStylesHelper';

type OptionType = {
  value: string;
  text: string;
};

const googleMapsApiKey = process.env.REACT_APP_GOOGLE_AUTO_COMPLETE_KEY || '';

export const AutoCompleteAddress = forwardRef((props: AutoCompleteAddressProps, ref: Ref<HTMLInputElement>) => {
  const {
    value,
    onPlaceSelected,
    readOnly,
    disabled,
    onChange,
    error,
    errorText,
    label,
    required,
    textAlign,
    inputSize,
  } = props;
  const [isOpened, setIsOpened] = useState(false);
  const [options, setOptions] = useState<OptionType[]>([]);
  const { placesService, placePredictions, getPlacePredictions, isPlacePredictionsLoading } = usePlacesService({
    apiKey: googleMapsApiKey,
    debounce: 300,
    options: {
      fields: ['address_components', 'geometry'],
      types: ['address'],
      componentRestrictions: { country: 'us' },
    },
  });

  const isErrorVisible = !!error;
  const inputStyle = generateInputStyles({
    isErrorVisible,
    readOnly,
    textAlign,
    inputSize,
  });

  const remapAddressComponents = (place: any) => {
    return createAddressObject(place['address_components']);
  };

  const handlePlaceSelect = (placeId: string) => {
    placesService?.getDetails(
      {
        placeId: placeId,
        fields: ['address_components', 'geometry'],
        types: ['address'],
        componentRestrictions: { country: 'us' },
      },
      (placeDetails: any) => {
        const addressObj = remapAddressComponents(placeDetails);
        onPlaceSelected(addressObj);
        setIsOpened(false);
      },
    );
  };
  useEffect(() => {
    if (placePredictions.length > 0) {
      const getOptions = () => {
        return placePredictions.map(
          (prediction: any): OptionType => ({
            value: prediction['place_id'],
            text: prediction.description,
          }),
        );
      };
      setOptions(getOptions());
      setIsOpened(true);
    }
  }, [placePredictions, placesService]);
  const loadOptions = (inputValue: string) => {
    getPlacePredictions({ input: inputValue });
  };

  return (
    <div className="relative col-span-2 w-full flex-auto" data-testid="address-autocomplete">
      <TextFieldWrapper errorText={errorText} error={!!error} label={label} required={required} textAlign={textAlign}>
        <Combobox
          value={value}
          disabled={disabled}
          onChange={value => {
            handlePlaceSelect(value as string);
          }}
        >
          <Combobox.Input
            as="input"
            ref={ref}
            className={inputStyle}
            onChange={event => {
              onChange(event);
              loadOptions(event.currentTarget.value);
            }}
            placeholder={readOnly ? '' : 'Enter an address'}
          />
          {isPlacePredictionsLoading ? (
            <span className="absolute right-0 top-0 p-3">
              <LoadingSpinner />
            </span>
          ) : null}
          <Transition
            enter="transition duration-100 ease-out"
            enterFrom="transform scale-95 opacity-0"
            enterTo="transform scale-100 opacity-100"
            leave="transition duration-75 ease-out"
            leaveFrom="transform scale-100 opacity-100"
            leaveTo="transform scale-95 opacity-0"
            className="absolute z-[9999] w-full"
          >
            {isOpened && (
              <Combobox.Options
                data-testid="address-autocomplete-options"
                className="mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
              >
                {options.length === 0 && <div>No results</div>}
                {options.length > 0 &&
                  options.map(option => (
                    <Combobox.Option key={option.value} value={option.value} as={Fragment}>
                      {({ active, selected }) => (
                        <div
                          className={cn(
                            'cursor-pointer p-[8px] hover:bg-powder hover:text-basic',
                            active && 'bg-powder text-basic',
                            selected && 'bg-basicSoft text-white',
                          )}
                        >
                          {option.text}
                        </div>
                      )}
                    </Combobox.Option>
                  ))}
              </Combobox.Options>
            )}
          </Transition>
        </Combobox>
      </TextFieldWrapper>
    </div>
  );
});
