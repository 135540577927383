/**
 * handleSuccess function
 *
 * This function is used to handle successful cases by logging a success message along with the response.
 *
 * @param {string} message - The success message to be logged.
 * @param {void} response - The response to be logged along with the success message.
 *
 * Usage:
 * 1. Import the function: import { handleSuccess } from './handleSuccess';
 * 2. Use the function in your component:
 *    handleSuccess('Success message', response);
 */
export const handleSuccess = (message: string, response: void) => {
  console.log(message, response);
};

/**
 * handleError function
 *
 * This function is used to handle error cases by logging the error.
 *
 * @param {unknown} error - The error to be logged.
 *
 * Usage:
 * 1. Import the function: import { handleError } from './handleError';
 * 2. Use the function in your component:
 *    handleError(error);
 */
export const handleError = (error: unknown) => {
  console.error(error);
};
