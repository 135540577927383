import * as React from 'react';
import type { SVGProps } from 'react';

interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgCubicZirconium = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <circle cx={12} cy={12} r={9} fill="#61A7DA" opacity={0.3} />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M9 3 5.25 6.75v10.5L9 21h6l3.75-3.75V6.75L15 3H9Z"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M10.5 6.75 9 8.25v7.5l1.5 1.5h3l1.5-1.5v-7.5l-1.5-1.5h-3ZM10.5 17.25 9 21M13.5 17.25 15 21M15 15.75l3.75 1.5M15 8.25l3.75-1.5M15 3l-1.5 3.75M9 3l1.5 3.75M5.25 6.75 9 8.25M9 15.75l-3.75 1.5"
    />
  </svg>
);
export default SvgCubicZirconium;
