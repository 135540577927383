import { type ReactNode, useContext } from 'react';
import { useLocation } from 'react-router-dom';

import type { CreditApplicationStatus, CurrentUserQuery, SecurityType } from '../__generated__/graphql/api';
import { LoaderSpinnerFull } from '../components/LoaderSpinnerFull';
import {
  GO_TO_ADDRESS_AND_INCOME,
  PERSONAL_INFO_SUBMITTED,
  SKIP_ADD_ITEM_SIGN_UP,
  SKIP_NAVIGATE,
} from '../constants/application';
import { useGoRoutes } from '../hooks/useGoRoutes';
import { useOnMount } from '../hooks/useOnMount';
import { useUser } from '../hooks/useUser';

import { URLContext } from './URLProvider';

interface ApplicationProviderProps {
  children: ReactNode;
}

export const ApplicationProvider = (props: ApplicationProviderProps) => {
  const { children } = props;

  const { isLoading, getUserData } = useUser();
  const { state } = useLocation();
  const { navigate, gotoPersonalInfo } = useGoRoutes();
  const { getUrlOnStatus } = useContext(URLContext);

  const navigateOnStatus = (securityType: SecurityType, status: CreditApplicationStatus) => {
    return navigate(getUrlOnStatus(securityType, status), { state: state });
  };

  useOnMount(() => {
    getUserData()
      .then((user: CurrentUserQuery['currentUser'] | null) => {
        const userHasPersonalInfo = user?.firstName && user?.lastName && user?.phone;
        const creditFlow = user?.creditApplication?.securityType;
        const userStatus = user?.creditApplication?.status;

        if (state === SKIP_NAVIGATE) {
          return null;
        }

        if (!userHasPersonalInfo) {
          // to skip the personal info page if the user has already submitted it
          if (state === PERSONAL_INFO_SUBMITTED || state === SKIP_ADD_ITEM_SIGN_UP) {
            return null;
          }
          return gotoPersonalInfo();
        }
        if (creditFlow && userStatus) {
          return navigateOnStatus(creditFlow, userStatus);
        }
        if (state !== GO_TO_ADDRESS_AND_INCOME && creditFlow && userStatus) {
          return navigateOnStatus(creditFlow, userStatus);
        }
      })
      .catch(err => {
        console.error('Failed to get the user ApplicationFlow ', err);
        return null;
      });
  });

  if (isLoading) return <LoaderSpinnerFull />;

  return <div>{children}</div>;
};
