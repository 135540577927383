import { useNavigate } from 'react-router-dom';

import { Typography } from 'src/components/Typography/Typography';
import Button from 'src/components/UI/Button/Button';
import { Wrapper1280 } from 'src/components/UI/Wrapper/Wrapper1280';
import { routes } from 'src/constants/routes';
import { useIsMobile } from 'src/hooks/useIsMobile';
import CheckRoundIcon from 'src/icons/components/CheckRoundIcon';
import RejectRoundIcon from 'src/icons/components/RejectRoundIcon';
import { cn } from 'src/util/cn';

import cardDiamond from '../../../assets/card-diamond.webp';
import lightLeak from '../../../assets/light_leak.png';
import lightLeakRotated from '../../../assets/light_leak_rotated.png';

const features = [
  'Secured By Valuables',
  'No Credit Score Checks',
  'Builds Credit',
  'Earn Rewards',
  'Low Interest Rate',
];
const cards = [
  { name: 'Pawn Shop', features: [true, true, false, false, false] },
  { name: 'Pesto Mastercard', features: [true, true, true, true, true] },
];

const renderIcon = (isFeaturePresent: boolean) => {
  const SVG = isFeaturePresent ? CheckRoundIcon : RejectRoundIcon;
  return (
    <SVG
      className={cn('h-4 w-4 sm:h-6 sm:w-6', {
        'text-green-500': isFeaturePresent,
        'text-red-400': !isFeaturePresent,
      })}
    />
  );
};

export const ComparisonSection = () => {
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  return (
    <>
      <Wrapper1280 className={'flex flex-col gap-4 py-16'}>
        <div className={'flex flex-col'}>
          <Typography variant={'heroSmall'} className="text-center text-black">
            See how Pesto Compares
          </Typography>
          <Typography variant={'bodyLarge'} className="mx-auto max-w-[500px] text-center text-black">
            Enjoy the flexibility of a credit card backed by your jewelry, compared to the rigid terms and high-interest
            rates of pawnshop loans.{' '}
          </Typography>
        </div>
        <div className={'flex flex-col justify-center gap-4 sm:flex-row'}>
          <div className="flex flex-col gap-6 rounded-md border px-8 py-12">
            <Typography variant={'headerMedium'} className="text-center text-black sm:text-left">
              {cards[0].name}
            </Typography>
            <div className={'flex flex-col gap-2'}>
              {cards[0].features.map((isFeaturePresent, featureIndex) => (
                <div key={featureIndex} className="flex items-center gap-2  sm:text-left">
                  {renderIcon(isFeaturePresent)}
                  <Typography variant={'bodyLarge'}>{features[featureIndex]}</Typography>
                </div>
              ))}
            </div>
          </div>
          <div
            className="flex w-full flex-col gap-12 rounded-md border bg-black bg-gradient-to-l from-[#E8E8E840] to-[#E8E8E800] px-8 py-12 text-white drop-shadow-2xl sm:w-2/3 sm:flex-row"
            style={{
              background: `url(${isMobile ? lightLeakRotated : lightLeak}) black 50% / cover no-repeat`,
              backgroundPosition: 'center',
            }}
          >
            <div className="flex flex-col gap-6 sm:w-1/2">
              <Typography variant={'headerMedium'} className="text-center text-white sm:text-left">
                {cards[1].name}
              </Typography>
              <div className={'flex flex-col gap-2'}>
                {cards[1].features.map((isFeaturePresent, featureIndex) => (
                  <div key={featureIndex} className="flex items-center gap-2  sm:text-left">
                    {renderIcon(isFeaturePresent)}
                    <Typography variant={'bodyLarge'}>{features[featureIndex]}</Typography>
                  </div>
                ))}
              </div>
              <Button
                buttonType={'primary'}
                onClick={() => navigate(routes.quickAppraisal)}
                className={'w-full invert'}
                text={'Get Your Card'}
              />
            </div>
            <div className={'-mb-8 flex items-center sm:w-full'}>
              <img src={cardDiamond} alt="card with diamond" className={'max-w-full'} />
            </div>
          </div>
        </div>
      </Wrapper1280>
    </>
  );
};
