import clsx from 'clsx';

import { EarringsType } from 'src/__generated__/graphql/api';
import { ButtonContainer } from 'src/components/ButtonContainer/ButtonContainer';
import { BackButton } from 'src/components/UI/Button/BackButton';
import { Dangle, Hoop, Jacket, Stud, Teardrop } from 'src/icons/components/earrings';

import { ListSelection } from '../../../../../../components/ListSelection/ListSelection';
import { Typography } from '../../../../../../components/Typography/Typography';
import OtherStar from '../../../../../../icons/components/OtherStar';

interface EarringsTypeScreenProps {
  className?: string;
  onNext: (data: EarringsType) => void;
  handleBack: () => void;
}

export const earringTypes = [
  {
    value: EarringsType.Stud,
    text: 'Stud',
    icon: <Stud />,
    dataTestId: 'earrings-type--stud',
  },

  {
    value: EarringsType.Jacket,
    text: 'Jacket',
    icon: <Jacket />,
    dataTestId: 'earrings-type--jacket',
  },
  {
    value: EarringsType.Teardrop,
    text: 'Teardrop',
    icon: <Teardrop />,
    dataTestId: 'earrings-type--teardrop',
  },
  {
    value: EarringsType.Dangle,
    text: 'Dangle',
    icon: <Dangle />,
    dataTestId: 'earrings-type--dangle',
  },
  {
    value: EarringsType.Hoop,
    text: 'Hoop',
    icon: <Hoop />,
    dataTestId: 'earrings-type--hoop',
  },
  {
    value: EarringsType.Other,
    text: 'Other',
    icon: <OtherStar />,
    dataTestId: 'earrings-type--other',
  },
];

const EarringsTypeScreen = (props: EarringsTypeScreenProps) => {
  const { className, onNext, handleBack } = props;
  const rootStyles = clsx('flex flex-col gap-4 max-w-[400px] w-full', className);

  return (
    <div className={rootStyles}>
      <Typography variant={'headerMedium'}>What type are your earrings?</Typography>

      <div className="flex w-full flex-col justify-center gap-2 text-center">
        {earringTypes.map((type, index) => (
          <ListSelection
            key={index}
            text={type.text}
            leftIcon={type.icon}
            onClick={() => onNext(type.value)}
            dataTestId={type.dataTestId}
          />
        ))}
      </div>

      <ButtonContainer leftButton={<BackButton text={'BACK'} onClick={handleBack} rightButtonExist={false} />} />
    </div>
  );
};

export default EarringsTypeScreen;
