import clsx from 'clsx';
import React from 'react';

import { ButtonContainer } from 'src/components/ButtonContainer/ButtonContainer';
import { BackButton } from 'src/components/UI/Button/BackButton';

import type { WatchCondition } from '../../../../../../__generated__/graphql/api';
import { ListSelection } from '../../../../../../components/ListSelection/ListSelection';
import { Typography } from '../../../../../../components/Typography/Typography';
import { watchConditions } from '../../../../../../data/watch';

interface WatchInnerScreenProps {
  className?: string;
  onNext: (data: WatchCondition) => void;
  handleBack: () => void;
}
export const WatchConditionScreen = (props: WatchInnerScreenProps) => {
  const { className, onNext, handleBack } = props;
  const rootStyles = clsx('flex flex-col gap-4 max-w-[400px] w-full', className);

  return (
    <div className={rootStyles}>
      <Typography variant={'headerMedium'}>What condition is it in?</Typography>
      <div className=" flex w-full flex-col justify-center gap-2 text-center">
        {watchConditions.map((condition, index) => (
          <ListSelection key={index} text={condition.text} onClick={() => onNext(condition.value)} />
        ))}
      </div>

      <ButtonContainer leftButton={<BackButton onClick={handleBack} rightButtonExist={false} />} />
    </div>
  );
};
