import type { ReactNode } from 'react';

import { AssetType } from '../../../__generated__/graphql/api';
import { IconDiamond } from '../../../icons/iconsComponents/IconDiamond';
import { IconJewelry } from '../../../icons/iconsComponents/IconJewelry';
import { IconMetal } from '../../../icons/iconsComponents/IconMetal';
import { IconStar } from '../../../icons/iconsComponents/IconStart';

interface IconWrapperProps {
  itemType: AssetType | undefined;
}

const IconWrapper = (props: { children: ReactNode; bg: string }) => {
  return (
    <div className={`${props.bg} flex flex-auto flex-col items-center justify-center rounded-md`}>
      <div className={'rounded-full border border-white bg-basic p-2'}>{props.children}</div>
    </div>
  );
};
export const IconDrawer = (props: IconWrapperProps) => {
  const { itemType = AssetType.Other } = props;
  const nullIcon = (
    <IconWrapper bg={'bg-bisque'}>
      <IconStar stroke={'white'} />
    </IconWrapper>
  );
  const RENDER_ICONS: Record<AssetType, ReactNode> = {
    [AssetType.PreciousMetals]: (
      <IconWrapper bg={'bg-bisque'}>
        <IconMetal stroke={'white'} />
      </IconWrapper>
    ),
    [AssetType.Gemstones]: (
      <IconWrapper bg={'bg-lace'}>
        <IconDiamond stroke={'white'} />
      </IconWrapper>
    ),
    [AssetType.Jewelry]: (
      <IconWrapper bg={'bg-powder'}>
        <IconJewelry stroke={'white'} />
      </IconWrapper>
    ),
    [AssetType.Other]: (
      <IconWrapper bg={'bg-actionSoft'}>
        <IconStar stroke={'white'} />
      </IconWrapper>
    ),

    [AssetType.Watch]: nullIcon,
    [AssetType.Diamond]: nullIcon,
    [AssetType.Earrings]: nullIcon,
    [AssetType.Necklace]: nullIcon,
    [AssetType.Ring]: nullIcon,
    [AssetType.Cash]: nullIcon,
  };
  return <>{RENDER_ICONS[itemType]}</>;
};
