import type { ApolloCache } from '@apollo/client';

import startCase from 'lodash/startCase';

import type { GetItemCartQuery, Item, RemoveItemMutation } from '../../../../../__generated__/graphql/api';
import { AssetType, GetItemCartDocument, useRemoveItemMutation } from '../../../../../__generated__/graphql/api';
import { apolloClient } from '../../../../../api/Apollo';
import ItemCard from '../../../../../components/ItemCard/ItemCard';
import type { SwitchState } from '../ItemCart';
import { useParseItemDescription } from '../hooks/useParseItemDescription';

interface ConnectedAssetCardProps {
  item: Item;
  switchState?: SwitchState;
}

const optimisticRemoveItem = (data: RemoveItemMutation | null | undefined, cache: ApolloCache<any>) => {
  if (!data) return;

  const removedItemId = data.removeItemFromApplication?.id;
  const existingData = cache.readQuery<GetItemCartQuery>({ query: GetItemCartDocument });

  const updatedItems = existingData?.currentUser?.creditApplication?.items?.map(item => {
    if (item?.id === removedItemId) {
      return {
        ...item,
        isActive: false,
      };
    }
    return item;
  });

  if (existingData && removedItemId) {
    cache.writeQuery<GetItemCartQuery>({
      query: GetItemCartDocument,
      data: {
        currentUser: {
          ...existingData.currentUser,
          creditApplication: {
            ...existingData.currentUser?.creditApplication,
            items: updatedItems,
          },
        },
      },
    });
  }
};

export const ConnectedItemCard = (props: ConnectedAssetCardProps) => {
  const { item, switchState } = props;
  const [removeAssetMutation, { loading }] = useRemoveItemMutation({
    variables: {
      itemId: item.id || '',
    },
    update: (cache, { data }) => {
      optimisticRemoveItem(data, cache);
    },
    errorPolicy: 'all',
    client: apolloClient,
    refetchQueries: [{ query: GetItemCartDocument }],
  });
  const description = useParseItemDescription(item.qaDetails || '');
  const imageString = (item.presignedPhotoLinks && item.presignedPhotoLinks[0]) || '';
  const roundedLowCreditEstimate = item.lowCreditEstimate && item.lowCreditEstimate.slice(0, -3);
  const roundedHighCreditEstimate = item.highCreditEstimate && item.highCreditEstimate.slice(0, -3);
  const priceRange = `${roundedLowCreditEstimate} - ${roundedHighCreditEstimate}`;
  const isInReview = item.qaNeedsReview;
  const itemType = item.type === AssetType.Other ? 'Other' : startCase(item.type?.toLowerCase() || '');
  const rejectedReason = item?.qaRejectionReason || '';

  return (
    <>
      <ItemCard
        title={itemType}
        description={description}
        image={imageString}
        price={priceRange}
        isInReview={isInReview || false}
        onDelete={removeAssetMutation}
        loadingDelete={loading}
        switchState={switchState}
        rejectedReason={rejectedReason}
      />
    </>
  );
};
