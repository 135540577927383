import type { ForwardedRef } from 'react';
import { forwardRef } from 'react';

import { MONEY_MULTIPLIER } from '../constants/application';

import { InfoTooltip } from './InfoTooltip/InfoTooltip';
import { TextField } from './UI/Form/TextField/TextField';

const incomeToolTipText = `
Before Tax Total Annual Income: Include your full taxable annual income. If you are under age 21
include only your own income. Alimony, child support or separate maintenance income
need not be revealed if you do not wish to have it considered as a basis for repaying
this obligation.
`;

interface CurrencyFieldProps {
  onChange: (event: { target: { value: any } }) => void;
  defaultValue: number;
  label: string;
  required?: boolean;
  // TODO: change this to a string after ReactHook form is implemented
  error?: string | boolean;
  dollarsOnly?: boolean;
  toolTipText?: string;
  disabled?: boolean;
  readOnly?: boolean;
  showInfoTooltip?: boolean;
}

const CurrencyField = forwardRef((props: CurrencyFieldProps, ref: ForwardedRef<HTMLInputElement>) => {
  const { error, readOnly } = props;
  const formatCurrency = (value: number) => {
    if (!value) return '';

    const formatted = (value / MONEY_MULTIPLIER).toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
    });

    return props.dollarsOnly ? formatted.slice(0, -3) : formatted;
  };

  const onChange = (event: any) => {
    const numberValue = parseInt(event.target.value.replace(/[^\d]/g, ''));
    const newValue = props.dollarsOnly ? numberValue * MONEY_MULTIPLIER : numberValue;
    // TODO: the requirement to pass an event up to props.onChange should be removed.
    // Instead we should either update a form context, or pass back only the correctly formatted value on change
    props.onChange({ target: { value: newValue } });
  };

  return (
    <TextField
      ref={ref}
      label={props.label}
      onChange={onChange}
      maxLength={13}
      inputMode="numeric"
      value={formatCurrency(props.defaultValue)}
      placeholder={'Enter ' + props.label.toLowerCase()}
      data-testid={props.label}
      required={props.required}
      error={Boolean(error)}
      readOnly={readOnly}
      errorText={error as string}
      info={props.showInfoTooltip ? <InfoTooltip toolTipText={incomeToolTipText} /> : undefined}
      disabled={readOnly || props.disabled}
    />
  );
});

export default CurrencyField;
