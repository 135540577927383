import type { IconProps } from './IconHelper';

export const IconEdit = (props: IconProps) => {
  const { className, stroke } = props;
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.8358 13.7096L9.86621 14.1344L10.2902 11.164L17.927 3.52721C18.63 2.82426 19.7697 2.82426 20.4726 3.52721C21.1756 4.23016 21.1756 5.36986 20.4726 6.07281L12.8358 13.7096Z"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.0781 4.37604L19.6237 6.92164"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.4 13.8008V19.8008C17.4 20.4636 16.8627 21.0008 16.2 21.0008H4.2C3.53726 21.0008 3 20.4636 3 19.8008V7.80083C3 7.13809 3.53726 6.60083 4.2 6.60083H10.2"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
