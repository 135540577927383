import startCase from 'lodash/startCase';

import { CARAT_MULTIPLIER } from '../../../../../constants/application';

interface JsonObject {
  [key: string]: string | number;
}

export const useParseItemDescription = (itemDetails: string) => {
  const formatKey = (key: string, value: string | number | null): string => {
    const stringValue = startCase(value?.toString());
    const numberValue = Number(value);
    const formattedValue: {
      [key: string]: string;
    } = {
      brand: stringValue.replace(/_/g, ' '),
      color: `${stringValue} color`,
      clarity: `${stringValue} clarity`,
      length_in_inches: `${stringValue}“`,
      carat: `${numberValue / CARAT_MULTIPLIER} ct`,
      default: `${stringValue}`,
    };
    return formattedValue[key] || formattedValue.default;
  };
  const recursivelyRenderDetails = (detail: JsonObject): string[] => {
    return Object.entries(detail).reduce((acc: string[], [key, value]) => {
      if (value !== null && typeof value === 'object') {
        return [...acc, ...recursivelyRenderDetails(value as JsonObject)];
      } else {
        acc.push(`${formatKey(key, value)} |`);
        return acc;
      }
    }, []);
  };

  const parseDetails = (detailsJSON: string): string => {
    const details: JsonObject = JSON.parse(detailsJSON);
    const detailsArray = recursivelyRenderDetails(details);
    return detailsArray.join(' ').slice(0, -2);
  };

  if (!itemDetails) {
    return '';
  }

  return parseDetails(itemDetails);
};
