import type { FC, SyntheticEvent } from 'react';
import { useEffect, useState } from 'react';

import { cn } from 'src/util/cn';

import PlaceholderImage from '../../images/image-error-placeholder.svg';

type ProgressiveImageProps = {
  highQualitySrc: string;
  alt?: string;
  className?: string;
};

const ProgressiveImage: FC<ProgressiveImageProps> = ({ highQualitySrc, alt, className }) => {
  const [isHighQualityImageLoaded, setIsHighQualityImageLoaded] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);

  useEffect(() => {
    const highQualityImageLoader = new Image();
    highQualityImageLoader.src = highQualitySrc;
    highQualityImageLoader.onload = () => {
      setIsHighQualityImageLoaded(true);
    };
    highQualityImageLoader.onerror = () => {
      setHasError(true);
    };
  }, [highQualitySrc]);

  const rootStyle = cn(className, 'flex h-full w-full');

  const imageClasses = cn('transition-all duration-500 ease-in-out max-w-full w-full', {
    'object-contain': hasError,
    'object-cover': !hasError,
    hidden: !isHighQualityImageLoaded,
  });

  const handleError = (event: SyntheticEvent) => {
    const target = event.target as HTMLImageElement;
    target.onerror = null;
    target.src = PlaceholderImage;
    setHasError(true);
  };

  return (
    <div className={rootStyle}>
      {!isHighQualityImageLoaded ? (
        <div className="h-full w-full animate-pulse bg-gray-300" />
      ) : (
        <img
          className={imageClasses}
          onError={handleError}
          src={isHighQualityImageLoaded ? highQualitySrc : undefined}
          alt={alt}
        />
      )}
    </div>
  );
};

export default ProgressiveImage;
