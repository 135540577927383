export interface IncomingMessage {
  func?: IframeFunc;
  email?: string;
  userId?: string;
}

export interface OutgoingMessage {
  cardFormHeight?: number;
  token?: string;
  cardBrand?: string;
  expDate?: string;
  firstSix?: string;
  lastFour?: string;
  modalState?: PaymentStatusModal;
  errorMessage?: string;
}

interface PaymentStatusModal {
  isOpen: boolean;
  status: 'success' | 'error' | 'loading' | 'addressError';
  errorMessage?: string | null;
}

export interface IFrameMessage {
  message: PaymentFrameMessage;
  func?: IframeFunc;
}

export const enum IframeFunc {
  clear = 'clear',
  getToken = 'getToken',
}

export interface PaymentFrameMessage extends IncomingMessage, OutgoingMessage {
  response?: boolean;
}
