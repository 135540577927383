import * as React from 'react';
import type { SVGProps } from 'react';

interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgFaberge = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 60 14"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <g clipPath="url(#Faberge_svg__a)">
      <path
        fill="currentColor"
        d="M2.32 10.77c-.005.714-.132 1.875.664 2.187v.041H0v-.041c.797-.297.703-1.473.705-2.187v-6C.703 4.044.797 2.885 0 2.584V2.54h5.055c.264-.008.533.023.745-.083h.042V4.29H5.8c-.496-.548-1.144-.612-1.864-.603-.562-.009-1.115.007-1.616.105V6.75h1.886c.243-.002.498-.002.683-.103h.042v1.688h-.042c-.25-.474-.913-.442-1.409-.44H2.32v2.876ZM8.1 9.062l-.827 2.25c-.328.889-.44 1.35.31 1.646v.041h-2.9v-.041c.918-.471 1.186-1.36 1.533-2.27l2.652-6.625c.21-.575.512-1.243-.25-1.48V2.54h2.507l3.274 8.145c.372.91.657 1.801 1.554 2.27v.042h-3.232v-.041c.77-.36.643-.535.207-1.646l-.85-2.25H8.102v.001Z"
      />
      <path fill="#fff" d="m10.07 3.875-1.554 4.02h3.107l-1.553-4.02Z" />
      <path
        fill="currentColor"
        d="M17.175 4.75c0-.707.11-1.867-.705-2.167V2.54h3.75c2.092-.008 3.31.883 3.316 2.292-.005 1.071-.685 1.675-1.596 2.062 1.432.313 2.587 1.266 2.59 2.833-.003 1.216-.842 2.265-1.907 2.771-1.101.543-2.35.496-3.543.5h-2.61v-.042c.797-.312.703-1.473.704-2.208l.001-5.999Z"
      />
      <path
        fill="#fff"
        d="M20.159 11.854c1.3.124 2.614-.48 2.61-2 .004-1.532-1.483-2.2-2.88-2.125l-1.098.063v3.937l1.368.125Zm-.85-5.105c1.028-.002 2.499-.352 2.507-1.667-.01-1.086-1.02-1.659-1.97-1.562l-1.055.105V6.75h.518Z"
      />
      <path
        fill="currentColor"
        d="m27.72 11.666 2.01.188c1.045.092 2.137-.241 2.921-.937h.063l-.726 2.082H25.4v-.041c.805-.296.71-1.473.705-2.187v-6c.006-.727.101-1.887-.706-2.187v-.043h5.056c.272-.008.54.023.746-.083h.041v1.854h-.041c-.489-.572-1.137-.634-1.865-.625-.553-.009-1.107.007-1.615.105V6.75h1.886c.252-.002.505-.002.705-.104h.041v1.688h-.041c-.262-.458-.927-.441-1.41-.438h-1.18v3.771h-.001Zm8.993-9.125c2.11-.008 3.263 1.105 3.273 2.541-.01 1.41-1.226 2.507-2.527 2.812l2.278 2.875c.666.826 1.663 1.652 2.57 2.228h-1.451c-.848-.003-1.37-.21-1.844-.79l-1.926-2.418-1.45-2.229c1.256-.195 2.727-.783 2.734-2.27-.007-1.152-.987-1.82-2.05-1.77a8.4 8.4 0 0 0-1.078.125v7.124c.007.73-.088 1.891.726 2.187v.041h-3.024v-.041c.804-.297.693-1.473.683-2.187V4.77c.01-.727.121-1.887-.683-2.187v-.043h3.77Z"
      />
      <path
        fill="currentColor"
        d="M50.075 4.375h-.041c-.654-.729-1.855-.95-2.818-.958-2.468.007-4.35 1.628-4.35 4.186 0 2.561 2.023 4.502 4.515 4.5a8.094 8.094 0 0 0 2.197-.292v-2.29c.009-.738-.008-1.518-.726-1.813v-.041h2.424v.04c-.078.201-.078.454-.083.688v3.666c.006.235-.01.489.083.71-1.264.24-2.497.48-3.77.479-4.056 0-6.444-2.226-6.444-5.417 0-3.423 2.705-5.554 6.526-5.56.843.007 1.665.102 2.486.27l.002 1.832Zm4.953 7.291 1.988.188c1.053.092 2.145-.242 2.943-.937H60l-.705 2.082h-6.609v-.041c.814-.297.72-1.473.726-2.187v-6c-.007-.727.088-1.888-.726-2.187V2.54h5.076c.26-.008.528.023.724-.083h.063v1.854h-.063c-.48-.572-1.128-.634-1.864-.625-.545-.01-1.099.007-1.594.105v2.957h1.864c.26-.002.514-.002.705-.104h.041v1.688h-.041c-.253-.458-.918-.442-1.409-.438h-1.159l-.001 3.771Zm-.373-9.687h.849L58.176.75h-1.512l-2.01 1.23Z"
      />
    </g>
    <defs>
      <clipPath id="Faberge_svg__a">
        <path fill="#fff" d="M0 .75h60v12.5H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgFaberge;
