import type { ReactNode } from 'react';

import { MetalType, RingType } from '../__generated__/graphql/api';
import SvgOtherIcon from '../icons/components/OtherIcon';
import SvgGoldPlatedIcon from '../icons/components/metal/GoldPlatedIcon';
import SvgPlatinumPlatedIcon from '../icons/components/metal/PlatinumPlatedIcon';
import SvgSilverPlatedIcon from '../icons/components/metal/SilverPlatedIcon';
import {
  AwardSignetRingStyle,
  BridalSetRingStyle,
  EternityRingStyle,
  HaloRingStyle,
  SolitareRingStyle,
  ThreeStoneRingStyle,
  WeddingBandRingStyle,
} from '../icons/components/ring';
import award from '../pages/ApplicationFlow/steps/QuickAppraisal/screens/Ring/assets/award.jpg';
import bridal from '../pages/ApplicationFlow/steps/QuickAppraisal/screens/Ring/assets/bridal.jpg';
import cluster from '../pages/ApplicationFlow/steps/QuickAppraisal/screens/Ring/assets/cluster.jpg';
import eternity from '../pages/ApplicationFlow/steps/QuickAppraisal/screens/Ring/assets/eternity.jpg';
import halo from '../pages/ApplicationFlow/steps/QuickAppraisal/screens/Ring/assets/halo.jpg';
import solitare from '../pages/ApplicationFlow/steps/QuickAppraisal/screens/Ring/assets/solitare.jpg';
import threeStone from '../pages/ApplicationFlow/steps/QuickAppraisal/screens/Ring/assets/threeStone.jpg';
import wedding from '../pages/ApplicationFlow/steps/QuickAppraisal/screens/Ring/assets/wedding.jpg';

const ringIconSizeStyle = 'h-[26px] w-[26px]';

export const ringStyles: { value: RingType; image: string; text: string; icon: ReactNode }[] = [
  {
    value: RingType.Solitaire,
    image: solitare,
    text: 'Solitaire',
    icon: <SolitareRingStyle className={ringIconSizeStyle} />,
  },
  {
    value: RingType.BridalSet,
    image: bridal,
    text: 'Bridal Set',
    icon: <BridalSetRingStyle className={ringIconSizeStyle} />,
  },
  {
    value: RingType.ThreeStone,
    image: threeStone,
    text: 'Three Stone',
    icon: <ThreeStoneRingStyle className={ringIconSizeStyle} />,
  },
  {
    value: RingType.Halo,
    image: halo,
    text: 'Halo',
    icon: <HaloRingStyle className={ringIconSizeStyle} />,
  },
  {
    value: RingType.Halo,
    image: cluster,
    text: 'Cluster',
    icon: <HaloRingStyle className={ringIconSizeStyle} />,
  },
  {
    value: RingType.WeddingBand,
    image: wedding,
    text: 'Wedding Band',
    icon: <WeddingBandRingStyle className={ringIconSizeStyle} />,
  },
  {
    value: RingType.EternityBand,
    image: eternity,
    text: 'Eternity Band',
    icon: <EternityRingStyle className={ringIconSizeStyle} />,
  },
  {
    value: RingType.AwardOrSignet,
    image: award,
    text: 'Award/Signet',
    icon: <AwardSignetRingStyle className={ringIconSizeStyle} />,
  },
];

export const ringMetals: { value: MetalType; text: string; icon: ReactNode }[] = [
  {
    value: MetalType.Gold,
    text: 'Gold',
    icon: <SvgGoldPlatedIcon />,
  },
  {
    value: MetalType.Silver,
    text: 'Silver',
    icon: <SvgSilverPlatedIcon />,
  },
  {
    value: MetalType.Platinum,
    text: 'Platinum',
    icon: <SvgPlatinumPlatedIcon />,
  },
  {
    value: MetalType.None,
    text: 'Other',
    icon: <SvgOtherIcon />,
  },
];
