import CryptoJS from 'crypto-js';

export const decryptData = (ciphertext: string) => {
  const bytes = CryptoJS.AES.decrypt(ciphertext, 'secret key 123');
  return bytes.toString(CryptoJS.enc.Utf8);
};

export const decryptEmail = (encryptedData: string | null) => {
  if (!encryptedData) return null;
  const decryptedData = decryptData(encryptedData);
  const deserializedData = JSON.parse(decryptedData);
  return {
    email: deserializedData.email,
  };
};

export const decryptSignUpUserData = (encryptedData: string | null) => {
  if (!encryptedData) return null;
  const decryptedData = decryptData(encryptedData);
  const deserializedData = JSON.parse(decryptedData);
  return {
    firstName: deserializedData.firstName,
    middleName: deserializedData.middleName,
    lastName: deserializedData.lastName,
    phone: deserializedData.phoneNumber,
    email: deserializedData.email,
  };
};
