import type { FC } from 'react';

import { PaymentPreference } from 'src/__generated__/graphql/api';
import { Typography } from 'src/components/Typography/Typography';
import { phoneFormat } from 'src/util/phonehelper';

import type { CashSecuredApplicationForm } from '../types';

type ApplicationPersonalInfoProps = {
  formData: Pick<
    CashSecuredApplicationForm,
    | 'firstName'
    | 'middleName'
    | 'lastName'
    | 'email'
    | 'phoneNumber'
    | 'zip'
    | 'address'
    | 'city'
    | 'suite'
    | 'state'
    | 'income'
    | 'dob'
    | 'paymentPreference'
    | 'amount'
  >;
};

export const ApplicationPersonalInfo: FC<ApplicationPersonalInfoProps> = ({ formData }) => {
  return (
    <div className="flex flex-col gap-4 text-left">
      <Typography variant="body">
        {formData.firstName} {formData.middleName} {formData.lastName}
      </Typography>

      <div className="flex flex-wrap justify-between gap-4">
        <Typography variant="body">{formData.email}</Typography>
        <Typography variant="body">{phoneFormat(formData.phoneNumber)}</Typography>
      </div>

      <Typography variant="body">
        {[formData.zip, formData.address, formData.city, formData.suite, formData.state].filter(Boolean).join(', ')}
      </Typography>

      <div className="flex flex-wrap justify-between gap-4">
        <Typography variant="body">
          ${(formData.income ?? 0).toLocaleString()}/year <br /> income
        </Typography>

        <Typography variant="body">{formData.dob}</Typography>
      </div>
      <div className="flex flex-wrap justify-between gap-4">
        <Typography variant="body">
          {formData.paymentPreference === PaymentPreference.PayInFull
            ? 'Bank deposit'
            : formData.paymentPreference === PaymentPreference.PayOverTime
            ? 'Fund over time'
            : ''}
        </Typography>
        <Typography variant="body">${formData.amount}</Typography>
      </div>
    </div>
  );
};
