import React, { useState } from 'react';

import referredTable from 'src/components/ReferralBanner/assets/ReferredTable.png';
import TextLink, { LinkType } from 'src/components/TextLink/TextLink';
import { Modal2 } from 'src/components/UI/Modal/Modal2';

import { Typography } from '../Typography/Typography';

interface ReferralHeaderProps {
  name?: string;
}

export const ReferralHeader = (props: ReferralHeaderProps) => {
  const { name = '' } = props;

  const endsWithApostropheS = name.endsWith("'s") || (name.includes("'") && name.endsWith('s'));
  const formattedName = name.charAt(0).toUpperCase() + name.slice(1) + (endsWithApostropheS ? '' : "'s");
  const [isExpanded, setIsExpanded] = useState(false);
  const handleToggleExpanded = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <>
      <div className="h-auto w-full bg-[#FFECE6] p-2 sm:flex sm:items-center sm:justify-center sm:gap-2 sm:p-4">
        <Typography variant="bodySmall" className="!font-bold">
          Get up to $40
        </Typography>
        <Typography variant="body">{'For using NAME referral link'.replace('NAME', formattedName)}</Typography>
        <TextLink
          as={LinkType.Button}
          onClick={handleToggleExpanded}
          className="inline w-auto self-center text-xs !font-bold"
        >
          Show referral rates
        </TextLink>
      </div>
      <Modal2 isOpen={isExpanded} onClose={handleToggleExpanded}>
        <div className="flex flex-col gap-4">
          <Typography variant="headerSmall">Referral rates</Typography>
          <img alt="referral rates table" src={referredTable} />
        </div>
      </Modal2>
    </>
  );
};
