import * as React from 'react';
import type { SVGProps } from 'react';

interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgHoop = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <circle cx={12} cy={12} r={7.25} stroke="#101820" strokeWidth={1.5} />
    <path stroke="#101820" strokeLinecap="round" strokeWidth={1.5} d="M8 4c6.5 1 8.5-.5 8.5-.5" />
  </svg>
);
export default SvgHoop;
