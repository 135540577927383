import * as React from 'react';
import type { SVGProps } from 'react';

interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgMarquiseIcon = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <mask id="marquise-icon_svg__a" fill="#fff">
      <path
        fillRule="evenodd"
        d="M17.776 12.211a1 1 0 0 0 0-.894l-4.881-9.764a1 1 0 0 0-1.79 0l-4.881 9.764a1 1 0 0 0 0 .894l4.882 9.764a1 1 0 0 0 1.789 0l4.881-9.764Z"
        clipRule="evenodd"
      />
    </mask>
    <path
      fill="#101820"
      d="m6.224 12.211-1.342.671 1.342-.67Zm11.552-.894-1.341.67 1.341-.67Zm-6.67-9.764L9.764.882l1.342.67Zm.447.67 4.882 9.764 2.683-1.341L14.236.882l-2.683 1.342Zm-3.988 9.764 4.882-9.763L9.764.882l-4.882 9.764 2.683 1.341Zm-2.683.895 4.882 9.764 2.683-1.342-4.882-9.764-2.683 1.342Zm9.354 9.764 4.882-9.764-2.683-1.342-4.882 9.764 2.683 1.342Zm-4.472 0c.921 1.842 3.55 1.842 4.472 0l-2.683-1.342a.5.5 0 0 1 .894 0l-2.683 1.342Zm-4.882-12a2.5 2.5 0 0 0 0 2.236l2.683-1.342a.5.5 0 0 1 0 .448l-2.683-1.342Zm11.553 1.341a.5.5 0 0 1 0-.447l2.683 1.342a2.5 2.5 0 0 0 0-2.236l-2.683 1.341ZM14.236.883c-.921-1.843-3.55-1.843-4.472 0l2.683 1.342a.5.5 0 0 1-.894 0L14.236.882Z"
      mask="url(#marquise-icon_svg__a)"
    />
  </svg>
);
export default SvgMarquiseIcon;
