import { useContext, useState } from 'react';

import { useCurrentUserQuery, useSubmitDocumentIdMutation } from 'src/__generated__/graphql/api';
import { Header } from 'src/components/Header/Header';
import { LoaderSpinnerFull } from 'src/components/LoaderSpinnerFull';
import { Socure } from 'src/components/Socure/Socure';
import { URLContext } from 'src/providers/URLProvider';

export const SocureDocVerification = () => {
  const user = useCurrentUserQuery();
  const [isLoaded, setIsLoaded] = useState(false);
  const { getUrlOnStatus } = useContext(URLContext);
  const [submitDocumentId] = useSubmitDocumentIdMutation({
    errorPolicy: 'all',
  });

  const inputData = {
    customerUserId: process.env.REACT_APP_SOCURE_CUSTOMER_USER_ID,
  };

  const processConfig = {
    sendMessage: 'true',
    flow: {
      name: 'socure_default',
    },
    language: 'en',
    documentType: 'license',
  };

  const sdkKey = process.env.REACT_APP_SOCURE_SDK_KEY || '';

  const handleSuccess = async (response: any) => {
    console.log('Parent received success:', response);

    if (response.documentUuid) {
      const mutation = await submitDocumentId({
        variables: {
          creditApplicationId: user?.data?.currentUser?.creditApplication?.id || '',
          documentId: response.documentUuid || '',
        },
      });

      const status = mutation.data?.submitDocumentId?.status;
      const securityType = mutation.data?.submitDocumentId?.securityType;

      if (mutation.errors) {
        console.error('Error submit documentId' + mutation.errors);
      }

      if (!status || !securityType) return null;
      // We use window.location.replace in this context for Socure redirection
      // and there is no need to change this implementation.
      return window.location.replace(getUrlOnStatus(securityType, status));
    }
  };

  const handleError = (error: any) => {
    console.log('Parent received error:', error);
  };

  const handleProgress = (progress: any) => {
    console.log(progress);
  };

  return (
    <>
      <Header />
      {!isLoaded && <LoaderSpinnerFull />}
      <Socure
        className="px-10"
        step={1}
        inputData={inputData}
        processConfig={processConfig}
        sdkKey={sdkKey}
        onSuccessCallback={handleSuccess}
        onProgressCallback={handleProgress}
        onErrorCallback={handleError}
        onLoaded={isLoaded => setIsLoaded(isLoaded)}
      />
    </>
  );
};
