import * as React from 'react';
import type { SVGProps } from 'react';

interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgEternityRingStyle = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <circle cx={11.919} cy={12.318} r={6.5} stroke="#101820" strokeLinecap="round" strokeWidth={1.5} />
    <path
      stroke="#101820"
      strokeWidth={1.5}
      d="M11.455 4.323 12 3.927l.545.396-.208.64h-.674l-.208-.64ZM11.455 20.404 12 20.8l.545-.396-.208-.64h-.674l-.208.64ZM16.81 6.327l.484.468.595-.317-.117-.663-.667-.094-.296.606ZM16.829 18.323l.544-.396.545.396-.208.64h-.673l-.208-.64ZM19.471 12.43l.648.186.376-.559-.415-.53-.632.23.023.673ZM3.35 12.084l.377.558.647-.186.024-.673-.633-.23-.415.53ZM6.829 6.404l.545.396.544-.396-.208-.64h-.673l-.208.64ZM6.829 18.323l.545-.396.544.396-.208.64h-.673l-.208-.64Z"
    />
  </svg>
);
export default SvgEternityRingStyle;
