import type { ReactNode } from 'react';

import { cn } from 'src/util/cn';

import SvgArrowIcon from '../../icons/components/ArrowIcon';

interface ListSelectionProps {
  className?: string;
  onClick: () => void;
  text: string;
  leftIcon?: ReactNode;
  rightIcon?: ReactNode;
  noArrow?: boolean;
  dataTestId?: string;
}

export const ListSelection = (props: ListSelectionProps) => {
  const { className, onClick, text, leftIcon, rightIcon, noArrow = false, dataTestId } = props;
  const rootStyles = cn(
    'flex w-full items-center shadow justify-between rounded-sm border border-neutralInactive bg-[#FAFAFC] p-4 text-lg text-left font-normal text-basic hover:bg-[#F2F2F7] active:bg-[#EDEDF2]',
    className,
  );

  return (
    <button
      data-testid={dataTestId}
      className={rootStyles}
      onClick={e => {
        e.preventDefault();
        onClick();
      }}
    >
      <div className="flex items-center gap-4">
        {leftIcon}
        {text}
      </div>
      {noArrow ? rightIcon : <SvgArrowIcon className="h-[20px] w-[20px] rotate-90 text-salsa" />}
    </button>
  );
};
