import { useNavigate } from 'react-router-dom';

import { Typography } from 'src/components/Typography/Typography';
import { routes } from 'src/constants/routes';

import pestoIcons from '../../../assets/bg-pesto-icons.png';

export const WhatYourItemsWorth = () => {
  const navigate = useNavigate();
  return (
    <div>
      <div
        className="flex h-auto w-full bg-[length:1300px] bg-center bg-no-repeat"
        style={{ backgroundImage: `url(${pestoIcons})` }}
      >
        <div
          className={
            'flex w-full flex-col gap-6 bg-[radial-gradient(50%_50%_at_50%_50%,_#ffffff50_0%,_#ffffff_80%)] p-4 py-12 sm:py-[120px]'
          }
        >
          <Typography variant="heroSmall" className="text-center !font-light text-basicSoft">
            What are your items worth?
          </Typography>
          <button
            onClick={() => navigate(routes.quickAppraisal)}
            className="mx-auto w-[220px] rounded bg-black px-6 py-2 uppercase text-white shadow-lg "
          >
            Get your estimate
          </button>
        </div>
      </div>
    </div>
  );
};
