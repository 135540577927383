import { useNavigate } from 'react-router-dom';

import { Typography } from 'src/components/Typography/Typography';
import { Wrapper1280 } from 'src/components/UI/Wrapper/Wrapper1280';
import { links, routes } from 'src/constants/routes';
import SvgPestoLogo from 'src/icons/components/PestoLogo';

import FacebookIcon from '../../../assets/fb-icon.svg';
import InstagramIcon from '../../../assets/inst-icon.svg';
import TiktokIcon from '../../../assets/tt-icon.svg';

const Footer = () => {
  const navigate = useNavigate();
  return (
    <footer className="flex flex-col gap-4 bg-black py-8 sm:py-16">
      <Wrapper1280 className="flex w-full flex-col items-center gap-4 sm:flex-row sm:items-center sm:justify-between">
        <SvgPestoLogo className="flex h-8 w-full text-white sm:max-w-[150px]" />
        <div className="flex flex-col items-center gap-4 sm:flex-row">
          <button
            onClick={() => navigate(routes.quickAppraisal)}
            className="w-full max-w-xs rounded bg-white px-4 py-2 font-bold text-black transition-colors hover:bg-opacity-90 sm:w-auto sm:max-w-none"
          >
            Get Started
          </button>
        </div>
        <p className="max-w-md text-center text-xs leading-relaxed text-white sm:max-w-lg sm:text-left sm:text-sm">
          Deposit accounts and cards issued by Continental Bank, Member FDIC.
        </p>
        <div className="flex flex-col items-center gap-4 sm:flex-row sm:items-center">
          <span className="text-sm font-semibold text-white">Stay in Touch:</span>
          <div className="flex gap-4">
            <a href="https://www.facebook.com/pestomastercard" className="">
              <img src={FacebookIcon} alt="Facebook" />
            </a>
            <a href="https://www.instagram.com/getpesto" className="">
              <img src={InstagramIcon} alt="Instagram" />
            </a>
            <a href="https://www.tiktok.com/@getpesto" className="">
              <img src={TiktokIcon} alt="TikTok" />
            </a>
          </div>
        </div>
      </Wrapper1280>
      <Wrapper1280 className="w-full border-t border-gray-600">
        <></>
      </Wrapper1280>
      <Wrapper1280>
        <Typography variant={'bodySmall'} className={'text-gray-400'}>
          Use the Pesto Mastercard® responsibly and over time it could help you improve your credit score
        </Typography>
      </Wrapper1280>
      <Wrapper1280 className="flex w-full flex-col flex-wrap items-center justify-between gap-4 sm:flex-row">
        <div className="flex flex-col flex-wrap gap-4 text-xs text-gray-400 sm:flex-row sm:text-sm">
          <a href={links.privacyPolicy} className="hover:text-gray-300">
            Privacy Policy
          </a>
          <a href={links.privacyPolicy} className="hover:text-gray-300">
            Privacy Notice
          </a>
          <a href={links.termsOfService} className="hover:text-gray-300">
            Terms of Service
          </a>
          <a href={links.cardholderAgreement} className="hover:text-gray-300">
            Cardholder Agreement
          </a>
        </div>
        <div className="text-xs text-gray-400 sm:text-base">© 2024 Pesto - Made with ❤️ in San Francisco</div>
      </Wrapper1280>
    </footer>
  );
};

export default Footer;
