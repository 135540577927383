import CheckBox from 'src/components/CheckBox/CheckBox';

import { useAdminDebugger } from '../hooks/useAdminDebugger';

export const DebuggerHelper = () => {
  const { getIsSegmentAndPestoTrackingIgnoredForDevMode, handleSegmentAndPestoTrackingIgnoreStatusForDevMode } =
    useAdminDebugger();

  return (
    <div className="relative mt-6 px-4 sm:px-6">
      <div
        className="relative my-4 flex flex-col gap-4 rounded border border-green-500 bg-green-100 px-4 py-3"
        role="alert"
      >
        <div className="text-lg font-semibold text-green-800">Debugger Helpers</div>
        <CheckBox
          value={getIsSegmentAndPestoTrackingIgnoredForDevMode()}
          onChange={value => handleSegmentAndPestoTrackingIgnoreStatusForDevMode(value)}
          text={'Toggle Segment and PestoAnalytics Tracking in Dev Mode'}
          label={''}
        />
      </div>
    </div>
  );
};
