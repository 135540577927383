import * as React from 'react';
import type { SVGProps } from 'react';

interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgThick = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <g stroke="currentColor" strokeLinejoin="round" strokeWidth={2} clipPath="url(#thick_svg__a)">
      <path
        strokeLinecap="round"
        strokeMiterlimit={10}
        d="M22.878 1.122 18.78 5.22M5.22 18.78l-4.098 4.098M14.927 9.073l-5.854 5.854"
      />
      <path d="M19.293 4.707a2.104 2.104 0 0 1 0 2.976l-2.537 2.537a2.104 2.104 0 0 1-2.976-2.976l2.537-2.537a2.104 2.104 0 0 1 2.976 0ZM10.22 13.78a2.104 2.104 0 0 1 0 2.976l-2.537 2.537a2.104 2.104 0 0 1-2.976-2.976l2.537-2.537a2.104 2.104 0 0 1 2.976 0Z" />
    </g>
    <defs>
      <clipPath id="thick_svg__a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgThick;
