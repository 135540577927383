import { useIsMobile } from 'src/hooks/useIsMobile';
import { cn } from 'src/util/cn';

import { Typography } from '../../Typography/Typography';

interface AsSeenOnBrandsProps {
  fontStyle?: 'sans' | 'display';
  color?: string;
}

const brands = [
  {
    name: 'TechCrunch',
  },
  {
    name: 'Fortune',
  },
  {
    name: 'Nerdwallet',
  },
  {
    name: 'Forbes',
  },
];
export const AsSeenOnBrands = (props: AsSeenOnBrandsProps) => {
  const { fontStyle = 'sans', color = '#525252' } = props;

  const isMobile = useIsMobile();
  return (
    <div className="flex flex-col gap-2">
      <Typography variant="body" className={`font-${fontStyle}`}>
        As seen on:
      </Typography>
      <div className="flex flex-wrap justify-around gap-8">
        {brands.map(brand => {
          return (
            <Typography
              variant="heroSmall"
              className={cn(`font-${fontStyle} text-[${color}]`, { '!text-[6vw]': isMobile, '!text-5xl': !isMobile })}
              key={brand.name}
            >
              {brand.name}
            </Typography>
          );
        })}
      </div>
    </div>
  );
};
