import * as yup from 'yup';

import {
  addressFirstLineValidation,
  cityValidation,
  dob,
  stateValidation,
  zipValidation,
} from 'src/util/sharedFieldValidations';

import { MONEY_MULTIPLIER } from '../../../../constants/application';
import { validateSSN } from '../../../../util/ssnHelper';

export const addressSchema = yup.object().shape({
  address: addressFirstLineValidation,
  city: cityValidation,
  state: stateValidation,
  zip: zipValidation,
});

export const incomeInfoSchema = yup.object().shape({
  annualIncome: yup
    .number()
    .min(5000 * MONEY_MULTIPLIER, 'Please enter a valid income')
    .required('Total Annual Income is required'),
  ssnOrTid: yup
    .string()
    .required('SSN/ITIN is required')
    .test('ssn-length', 'SSN/ITIN must be 9 digits long', function (value) {
      return value ? value.length >= 9 : true;
    })
    .test('ssn', 'SSN or ITIN is invalid', function (value) {
      return value ? validateSSN(value) : true;
    }),
  confirmSsnOrTid: yup
    .string()
    .required('Confirm SSN/ITIN is required')
    .test('ssn-match', 'SSN or ITIN does not match', function (value) {
      return value === this.resolve(yup.ref('ssnOrTid'));
    }),
  dob: dob,
});
