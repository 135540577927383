import type { Dispatch, SetStateAction } from 'react';
import { createContext, useState, type ReactNode } from 'react';

type SkipIntroContextType = {
  skipIntroScreen: boolean;
  setSkipIntroScreen: Dispatch<SetStateAction<boolean>>;
};

export const SkipIntroContext = createContext<SkipIntroContextType>({
  skipIntroScreen: false,
  setSkipIntroScreen: () => {},
});

export const SkipIntroProvider = ({ children }: { children: ReactNode }) => {
  const [skipIntroScreen, setSkipIntroScreen] = useState(false);

  return (
    <SkipIntroContext.Provider value={{ skipIntroScreen, setSkipIntroScreen }}>{children}</SkipIntroContext.Provider>
  );
};
