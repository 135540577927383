import * as React from 'react';
import type { SVGProps } from 'react';

interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgMorganite = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <circle cx={12} cy={12} r={9} fill="#FF671D" opacity={0.3} />
    <path
      stroke="#101820"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="m11.387 1.315-7.093 9.777c-.19.256-.294.576-.294.907 0 .33.104.651.294.907l7.093 9.777a.813.813 0 0 0 .275.233.74.74 0 0 0 .676 0 .814.814 0 0 0 .275-.233l7.093-9.777c.19-.256.294-.576.294-.907 0-.33-.104-.651-.294-.907l-7.093-9.777a.815.815 0 0 0-.274-.232.74.74 0 0 0-.677 0 .814.814 0 0 0-.275.232Z"
    />
    <path
      stroke="#101820"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="m4 12.001 4.667.998h6.666L20 12.001"
    />
    <path
      stroke="#101820"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="m8.444 13 3.334-11.995L15.11 13 11.778 23 8.444 13Z"
    />
  </svg>
);
export default SvgMorganite;
