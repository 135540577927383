import type { FC } from 'react';

import TextLink, { LinkType } from '../../../components/TextLink/TextLink';

interface SignInPromptProps {
  onClick: () => void;
}

export const SignInPrompt: FC<SignInPromptProps> = ({ onClick }) => (
  <div className="my-4 flex flex-col items-center justify-center">
    <div className="flex w-full items-center justify-center">
      <div className="flex-grow border-t border-gray-300"></div>
      <span className="px-4 text-sm text-gray-600">or</span>
      <div className="flex-grow border-t border-gray-300"></div>
    </div>
    <div className="mt-4 text-center text-sm">
      <span className="text-basic">Already have an account?</span>{' '}
      <TextLink as={LinkType.Button} onClick={onClick} className={'inline w-auto'}>
        Sign In
      </TextLink>
    </div>
  </div>
);
