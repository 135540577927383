import React from 'react';

import { cn } from 'src/util/cn';

interface AmountButtonProps {
  amount: number;
  onClick: (event: React.MouseEvent<HTMLButtonElement>, amount: number) => void;
  buttonClassName?: string;
}

interface AmountSelectorProps {
  useHighAmounts?: boolean;
  onAmountSelected?: (amount: number | null) => void;
  containerClassName?: string;
  buttonClassName?: string;
}

const AmountButton: React.FC<AmountButtonProps> = ({ amount, onClick, buttonClassName }) => {
  return (
    <button
      onClick={event => onClick(event, amount)}
      className={cn(
        'rounded-lg border px-2 py-1 text-[15px] text-neutralStrong',
        'hover:border-neutralInactive hover:bg-neutralInactive hover:text-black',
        buttonClassName,
      )}
    >
      ${amount}
    </button>
  );
};

const AmountSelector: React.FC<AmountSelectorProps> = ({
  useHighAmounts = false,
  onAmountSelected,
  containerClassName,
  buttonClassName,
}) => {
  const defaultAmounts = [20, 50, 100, 250];
  const highAmounts = [200, 500, 1000, 1500];

  const amounts = useHighAmounts ? highAmounts : defaultAmounts;

  const handleAmountClick = (event: { preventDefault: () => void }, amount: number) => {
    event.preventDefault();

    onAmountSelected && onAmountSelected(amount);
  };

  return (
    <div className={cn('flex flex-row gap-2', containerClassName)}>
      {amounts.map(amount => (
        <AmountButton key={amount} buttonClassName={buttonClassName} amount={amount} onClick={handleAmountClick} />
      ))}
    </div>
  );
};

export default AmountSelector;
