import type { FieldError } from 'react-hook-form';

import { Typography } from '../Typography/Typography';

import { CreditBilling } from './CreditBilling/CreditBilling';
import type { AddressFormValues } from './CreditBilling/CreditBillingTypes';
import type { FormUserField } from './FormUserTypes';
import { IncomeInfo } from './IncomeInfo/IncomeInfo';
import type { IncomeFormValues } from './IncomeInfo/IncomeInfoTypes';

interface FormUserProps {
  formCreditBilling: AddressFormValues;
  formIncomeInfo: IncomeFormValues;
  formErrorsCreditBilling: {
    address?: FieldError;
    city?: FieldError;
    state?: FieldError;
    zipCode?: FieldError;
    addressTooLong?: FieldError;
  };
  formErrorsIncomeInfo: {
    annualIncome?: FieldError;
    dob?: FieldError;
    ssnOrTid?: FieldError;
    confirmSsnOrTid?: FieldError;
    agreement2?: FieldError;
  };
  setValue: (field: FormUserField, value: any) => void;
  setFocus: (value: any) => void;
}

const FormUser = (props: FormUserProps) => {
  const { formCreditBilling, formIncomeInfo, formErrorsCreditBilling, formErrorsIncomeInfo, setValue, setFocus } =
    props;

  return (
    <div className="flex w-full max-w-[550px] flex-col gap-6 text-left">
      <div className="flex flex-col gap-4 sm:gap-6">
        <div className="flex flex-col gap-4">
          <Typography variant="headerMedium">Credit billing information</Typography>
          <CreditBilling
            formValues={formCreditBilling}
            formErrors={formErrorsCreditBilling}
            setFormValues={setValue}
            setFocus={setFocus}
          />

          <Typography variant="headerMedium">Income information</Typography>
          <IncomeInfo
            formValues={formIncomeInfo}
            formErrors={formErrorsIncomeInfo}
            setFormValues={setValue}
            setFocus={setFocus}
          />
        </div>
      </div>
    </div>
  );
};

export default FormUser;
