import clsx from 'clsx';
import { useState } from 'react';

interface UserTreeProps {
  className?: string;
  data?: any;
}
export const UserTree = (props: UserTreeProps) => {
  const { className, data } = props;
  const [hide, setHide] = useState(true);
  const rootStyle = clsx(className);
  const userStyle = clsx('flex flex-col', {
    hidden: hide,
  });
  const renderObject = (obj: any) => {
    return Object.keys(obj).map(key => {
      if (typeof obj[key] === 'object' && obj[key] !== null) {
        return (
          <div key={key} className={'flex flex-col'}>
            <div className={'font-bold'}>{key}</div>
            <div className={'ml-4'}>{renderObject(obj[key])}</div>
          </div>
        );
      }
      return (
        <div key={key} className={'flex flex-col'}>
          <div className={'font-bold'}>{key}</div>
          <div className={'ml-4'}>{obj[key]}</div>
        </div>
      );
    });
  };

  return (
    <div className={rootStyle}>
      <div
        className={'hover:bg-accent-action w-full rounded bg-gray-200 px-2 py-2 font-bold hover:cursor-pointer'}
        onClick={() => setHide(!hide)}
      >
        User Info Tree
      </div>
      <div className={userStyle}>{renderObject(data)}</div>
    </div>
  );
};
