export const VERIFICATION_CODE_LENGTH = 6;
export const CARAT_MULTIPLIER = 100;
export const MONEY_MULTIPLIER = 100;
export const GRAM_MULTIPLIER = 10;
export const I_DONT_KNOW = "I don't know";
export const OTHER = 'Other';
export const OTHER_ITEM_DESCRIPTION_MAX_LENGTH = 255;

export const MIN_METAL_WEIGHT = 1;
export const MAX_GOLD_WEIGHT = 1000;
export const MAX_SILVER_WEIGHT = 10000;
export const MAX_PLATINUM_WEIGHT = 10000;
export const ADD_ITEM_SIGN_UP = 'add_item_sign_up';
export const SKIP_ADD_ITEM_SIGN_UP = 'skipAddItemSignUp';
export const SKIP_NAVIGATE = 'skipNavigate';

export const PERSONAL_INFO_SUBMITTED = 'personalInfoSubmitted';
export const CASH_SECURED_FLOW = 'cashSecuredFlow';
export const FROM_LANDING_PAGE = 'fromLandingPage';

export const GO_TO_ADDRESS_AND_INCOME = 'gotoAddressAndIncome';

export const MEGA_BYTE = 1000000; // 1MB
export const MAX_IMAGE_UPLOAD_SIZE = 6 * MEGA_BYTE; // 6 000 000 bytes
export const MAX_IMAGE_FILES_UPLOAD = 5;
export const SPLIT_PAYMENT_FEE = 20;
export const MINIMUM_PAYMENT_AMOUNT = SPLIT_PAYMENT_FEE;

export const MIN_AMOUNT_NON_CARDHOLDER = 200;
export const MAX_AMOUNT = 2000;
export const MAX_CREDIT_LINE = 2000;
export const MAX_CREDIT_LIMIT_FOR_INCREASE = MAX_CREDIT_LINE - 1;
