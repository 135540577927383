import { useGoRoutes } from 'src/hooks/useGoRoutes';

import type { AssetType } from '../../../../../../__generated__/graphql/api';
import ImageDetailsForm from '../../components/ImageDetailsForm/ImageDetailsForm';
import { SecondaryStonesDetailsForm } from '../../components/SecondaryStonesDetailsForm/SecondaryStonesDetailsForm';

import { RingCenterStoneDetailsScreen } from './RingCenterStoneDetailsScreen';
import { RingCenterStoneScreen } from './RingCenterStoneScreen';
import { RingMetalScreen } from './RingMetalScreen';
import { RingStyleScreen } from './RingStyleScreen';
import { useRingFLow } from './hooks/useRingFlow';

interface RingProps {
  setItemType: (itemType: AssetType | undefined) => void;
}
export const Ring = (props: RingProps) => {
  const { setItemType } = props;
  const {
    handleBack,
    handleRingStyle,
    handleRingMetal,
    handleRingCenterStone,
    handleRingStoneDetails,
    handleRingSecondaryStones,
    handleSubmitRingFlow,
    innerScreen,
    isDiamond,
    isCenterStone,
    isLoading,
  } = useRingFLow(setItemType);
  const { goBack } = useGoRoutes();

  const renderScreens = {
    0: (
      <RingStyleScreen
        handleBack={() => {
          goBack();
          setItemType(undefined);
        }}
        onNext={handleRingStyle}
      />
    ),
    1: <RingMetalScreen handleBack={handleBack} onNext={handleRingMetal} />,
    2: <RingCenterStoneScreen handleBack={handleBack} onNext={handleRingCenterStone} hasCenterStone={isCenterStone} />,
    3: (
      <RingCenterStoneDetailsScreen
        handleBack={handleBack}
        onNext={(cut, carat, count) => handleRingStoneDetails(cut, carat, count)}
        hasCentralDiamond={isDiamond}
      />
    ),
    4: (
      <SecondaryStonesDetailsForm
        title="Does your ring have any accent stones?"
        onNext={handleRingSecondaryStones}
        handleBack={handleBack}
      />
    ),

    5: (
      <ImageDetailsForm
        listItems={['Your ring', 'Certification', 'Appraisal']}
        onNext={handleSubmitRingFlow}
        onBack={handleBack}
        loadingMutation={isLoading}
      />
    ),
  };

  return <>{renderScreens[innerScreen as keyof typeof renderScreens]}</>;
};
