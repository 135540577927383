import { ButtonContainer } from 'src/components/ButtonContainer/ButtonContainer';
import { BackButton } from 'src/components/UI/Button/BackButton';

import type { MetalType } from '../../../../../../__generated__/graphql/api';
import { ListSelection } from '../../../../../../components/ListSelection/ListSelection';
import { ringMetals } from '../../../../../../data/ring';
import { ScreenQuickAppraisal } from '../../components/ScreenQuickAppraisal';

interface RingMetalScreenProps {
  className?: string;
  onNext: (data: MetalType) => void;
  handleBack: () => void;
}
export const RingMetalScreen = (props: RingMetalScreenProps) => {
  const { className, onNext, handleBack } = props;

  return (
    <ScreenQuickAppraisal className={className} title="What metal is your ring?">
      {ringMetals.map((ringMetal, index) => {
        return (
          <ListSelection
            onClick={() => onNext(ringMetal.value)}
            leftIcon={ringMetal.icon}
            text={ringMetal.text}
            key={index}
          />
        );
      })}

      <ButtonContainer leftButton={<BackButton rightButtonExist={false} onClick={handleBack} />} />
    </ScreenQuickAppraisal>
  );
};
