import * as React from 'react';
import type { SVGProps } from 'react';

interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgMyItemsIcon = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 18 16"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      stroke="#101820"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="m1 2.599.613.869a.262.262 0 0 0 .426.013L4 1"
    />
    <path stroke="#101820" strokeLinecap="round" strokeWidth={1.5} d="M6.75 2.25h10.5" />
    <path
      stroke="#101820"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="m1 8.183.613.87a.262.262 0 0 0 .426.012L4 6.585"
    />
    <path stroke="#101820" strokeLinecap="round" strokeWidth={1.5} d="M6.75 7.834h10.5" />
    <path
      stroke="#101820"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="m1 13.768.613.869a.263.263 0 0 0 .426.012L4 12.17"
    />
    <path stroke="#101820" strokeLinecap="round" strokeWidth={1.5} d="M6.75 13.419h10.5" />
  </svg>
);
export default SvgMyItemsIcon;
