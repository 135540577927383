/**
 * Mask the provided SSN string by replacing all digits with '*'
 * and format the masked string in the format XXX-XX-XXXX or XXX-XX-1234 based on the length of the masked string
 * and the optional showLast parameter.
 *
 * @param ssn - The SSN string to be masked.
 * @param showLast - Optional number of digits to show at the end of the masked string.
 * @returns The masked SSN string in the format XXX-XX-XXXX or XXX-XX-1234.
 */
export const maskSSN = (ssn: string, showLast: number) => {
  const valueHidden = ssn.replace(/[\d]/g, '*');
  if (valueHidden.length <= 3) {
    return valueHidden;
  }
  if (valueHidden.length <= 5) {
    return valueHidden.slice(0, 3) + '-' + valueHidden.slice(3, 5);
  }
  if (showLast) {
    return valueHidden.slice(0, 3) + '-' + valueHidden.slice(3, 5) + '-' + ssn.slice(showLast + 1);
  } else {
    return valueHidden.slice(0, 3) + '-' + valueHidden.slice(3, 5) + '-' + valueHidden.slice(5, 9);
  }
};

/**
 * Validate if the provided string is a valid SSN.
 *
 * @param ssn - The SSN string to be validated.
 * @returns A boolean value indicating if the provided string is a valid SSN.
 */
export const validateSSN = (ssn: string) => {
  const ssnRegex = /^\d{3}[- ]{0,1}(?!00)\d{2}[- ]{0,1}(?!0{4})\d{4}$/;
  return ssnRegex.test(ssn);
};
