import clsx from 'clsx';

import { ButtonContainer } from 'src/components/ButtonContainer/ButtonContainer';
import { BackButton } from 'src/components/UI/Button/BackButton';

import { ListSelection } from '../../../../../../components/ListSelection/ListSelection';
import { Typography } from '../../../../../../components/Typography/Typography';
import { OtherStar } from '../../../../../../icons/components';
import SvgGoldPlatedIcon from '../../../../../../icons/components/metal/GoldPlatedIcon';
import SvgPlatinumPlatedIcon from '../../../../../../icons/components/metal/PlatinumPlatedIcon';
import SvgSilverPlatedIcon from '../../../../../../icons/components/metal/SilverPlatedIcon';

interface MetalTypeScreenProps {
  className?: string;
  onNext: (data: MetalType) => void;
  handleBack: () => void;
}

export enum MetalType {
  Gold = 'GOLD',
  Silver = 'SILVER',
  Platinum = 'PLATINUM',
  Other = 'OTHER',
}

export const solidTypes = [
  {
    value: MetalType.Gold,
    text: 'Gold',
    icon: <SvgGoldPlatedIcon />,
    dataTestId: 'earrings-metal--gold',
  },
  {
    value: MetalType.Silver,
    text: 'Silver',
    icon: <SvgSilverPlatedIcon />,
    dataTestId: 'earrings-metal--silver',
  },
  {
    value: MetalType.Platinum,
    text: 'Platinum',
    icon: <SvgPlatinumPlatedIcon />,
    dataTestId: 'earrings-metal--platinum',
  },
  {
    value: MetalType.Other,
    text: 'Other',
    icon: <OtherStar />,
    dataTestId: 'earrings-metal--other',
  },
];

const MetalTypes = (props: MetalTypeScreenProps) => {
  const { className, onNext, handleBack } = props;
  const rootStyles = clsx('flex flex-col gap-4 max-w-[400px] w-full', className);

  return (
    <div className={rootStyles}>
      <Typography variant={'headerMedium'}>What type of metal?</Typography>

      <div className="flex w-full flex-col justify-center gap-2 text-center">
        {solidTypes.map((type, index) => (
          <ListSelection
            key={index}
            text={type.text}
            leftIcon={type.icon}
            onClick={() => onNext(type.value)}
            dataTestId={type.dataTestId}
          />
        ))}
      </div>

      <ButtonContainer leftButton={<BackButton text={'BACK'} onClick={handleBack} rightButtonExist={false} />} />
    </div>
  );
};

export default MetalTypes;
