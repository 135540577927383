import * as React from 'react';
import type { SVGProps } from 'react';

interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgStarSolid = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      d="M11.524 3.464a.5.5 0 0 1 .952 0l1.657 5.1a.5.5 0 0 0 .475.346h5.364a.5.5 0 0 1 .294.904l-4.34 3.153a.5.5 0 0 0-.181.559l1.657 5.1a.5.5 0 0 1-.77.56l-4.338-3.153a.5.5 0 0 0-.588 0l-4.339 3.153a.5.5 0 0 1-.77-.56l1.658-5.1a.5.5 0 0 0-.182-.56L3.734 9.815a.5.5 0 0 1 .294-.904h5.364a.5.5 0 0 0 .475-.346l1.657-5.1Z"
    />
  </svg>
);
export default SvgStarSolid;
