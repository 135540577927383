import * as React from 'react';
import type { SVGProps } from 'react';

interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgSilverSolidIcon = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <g clipPath="url(#Silver-solid-icon_svg__a)">
      <path fill="#fff" d="M0 0h24v24H0z" />
      <rect width={23} height={23} x={0.5} y={0.5} fill="#101820" stroke="#101820" rx={3.5} />
      <path
        fill="#FDFDFC"
        d="M3.195 16.347a.25.25 0 0 0 .22.367h1.377a.25.25 0 0 0 .223-.136l1.427-2.785a.25.25 0 0 1 .223-.136h3.446a.25.25 0 0 1 .246.208l.455 2.642a.25.25 0 0 0 .247.207h1.326a.25.25 0 0 0 .245-.295L10.737 6.204A.25.25 0 0 0 10.493 6H8.837a.25.25 0 0 0-.22.133L3.194 16.347Zm4.658-4.49a.25.25 0 0 1-.222-.364l1.401-2.736a.25.25 0 0 1 .469.072l.46 2.737a.25.25 0 0 1-.246.291H7.853ZM16.272 16.7c.483 0 .945-.112 1.359-.355.218-.128.574.063.532.312-.189 1.172-.918 1.757-1.958 1.757-.78 0-1.269-.36-1.424-.935-.04-.15-.187-.259-.337-.22l-1.137.285a.238.238 0 0 0-.178.282C13.442 19.178 14.512 20 16.178 20c1.985 0 3.296-1.2 3.62-3.186l1.154-6.966a.25.25 0 0 0-.247-.29h-1.05a.25.25 0 0 0-.242.189l-.026.105c-.053.215-.356.269-.519.119-.441-.406-1.01-.571-1.596-.571-2.27 0-3.823 2.029-3.823 4.2 0 1.814 1.094 3.1 2.823 3.1Zm.419-1.614c-.973 0-1.5-.657-1.5-1.643 0-1.3.892-2.414 2.135-2.414.959 0 1.459.657 1.459 1.657 0 1.257-.838 2.4-2.094 2.4Z"
      />
    </g>
    <defs>
      <clipPath id="Silver-solid-icon_svg__a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgSilverSolidIcon;
