import type { IconProps } from './IconHelper';

export const IconTrash = (props: IconProps) => {
  const { className, stroke } = props;
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.25 21H6.75C5.92157 21 5.25 20.3284 5.25 19.5V6H18.75V19.5C18.75 20.3284 18.0784 21 17.25 21Z"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M9.75 16.5V10.5" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M14.25 16.5V10.5" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M2.25 6H21.75" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.25 3H9.75C8.92157 3 8.25 3.67157 8.25 4.5V6H15.75V4.5C15.75 3.67157 15.0784 3 14.25 3Z"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
