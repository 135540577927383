import type { ReactNode } from 'react';

import { useIsMobile } from 'src/hooks/useIsMobile';
import { cn } from 'src/util/cn';

import { Typography } from '../Typography/Typography';
import { Wrapper1280 } from '../UI/Wrapper/Wrapper1280';

export interface ButtonContainerProps {
  className: string;
  leftButton: ReactNode;
  rightButton: ReactNode;
  helperText: string;
  error: string;
  isError: boolean;
  column: boolean;
  buttonCombo: boolean;
}

export const ButtonContainer = ({
  className,
  leftButton,
  rightButton,
  helperText,
  error,
  isError,
  column,
  buttonCombo,
}: Partial<ButtonContainerProps>) => {
  const isMobile = useIsMobile();
  if (!leftButton && !rightButton) return null;

  const buttonComboMobile = isMobile && buttonCombo;

  const flexLayout =
    helperText && !buttonCombo ? 'flex-col-reverse items-center lg:items-start md:flex-row' : 'flex-row items-center';

  const renderButtonSection = (button: ReactNode) => (
    <div
      className={cn({
        'w-full justify-center': helperText,
      })}
    >
      <div className="mb-2">{button}</div>
    </div>
  );

  const renderHelperText = () => (
    <Typography variant="bodySmall" className="max-w-sm text-center">
      {helperText}
    </Typography>
  );

  return (
    <div
      className={cn(
        className,
        'fixed bottom-0 right-0 z-[100] w-full border-t-[1px] border-neutralLight bg-white py-4',
      )}
    >
      <Wrapper1280>
        <div
          className={cn('flex w-full md:justify-between', flexLayout, {
            '': !leftButton,
            'gap-4': leftButton,
            'w-full flex-col-reverse': isMobile && column,
          })}
        >
          <div
            className={cn({
              'w-auto': isMobile && column,
              'w-full': isMobile && column,
            })}
          >
            {renderButtonSection(leftButton)}
          </div>
          {rightButton && (
            <div className={cn({ 'w-full': isMobile })}>
              {renderButtonSection(rightButton)}
              {helperText && !buttonComboMobile && renderHelperText()}

              {isError && (
                <Typography variant="bodySmall" className="max-w-sm text-danger">
                  {error}
                </Typography>
              )}
            </div>
          )}
        </div>
        {helperText && buttonComboMobile && renderHelperText()}
      </Wrapper1280>
    </div>
  );
};
