import * as React from 'react';
import type { SVGProps } from 'react';

interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgGoldSolidIcon = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <g clipPath="url(#Gold-solid-icon_svg__a)">
      <rect width={23} height={23} x={0.5} y={0.5} fill="#101820" stroke="#101820" rx={3.5} />
      <path
        fill="#FDFDFC"
        d="M3.178 17.482a.25.25 0 0 0 .224.36h1.44a.25.25 0 0 0 .225-.142l1.475-3.094a.25.25 0 0 1 .225-.143h3.544a.25.25 0 0 1 .247.21l.474 2.959a.25.25 0 0 0 .247.21h1.378a.25.25 0 0 0 .247-.292L10.949 6.208A.25.25 0 0 0 10.703 6H9a.25.25 0 0 0-.224.14L3.178 17.48Zm4.785-5.008a.25.25 0 0 1-.225-.358l1.467-3.08a.25.25 0 0 1 .473.068l.482 3.081a.25.25 0 0 1-.247.289h-1.95ZM16.415 10.22a.25.25 0 0 0-.247-.288h-1.257a.25.25 0 0 0-.247.211l-.703 4.494c-.319 2.116.625 3.363 2.263 3.363.616 0 1.198-.166 1.687-.547.154-.12.417-.017.413.179a.207.207 0 0 0 .207.21h1.02a.25.25 0 0 0 .246-.211l1.158-7.41a.25.25 0 0 0-.247-.29h-1.27a.25.25 0 0 0-.248.213l-.661 4.288c-.18 1.168-.847 1.768-1.652 1.768-.86 0-1.305-.632-1.125-1.721l.663-4.259Z"
      />
    </g>
    <defs>
      <clipPath id="Gold-solid-icon_svg__a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgGoldSolidIcon;
