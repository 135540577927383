import { yupResolver } from '@hookform/resolvers/yup';
import clsx from 'clsx';
import { useCallback, useEffect, useState } from 'react';
import type { SubmitHandler } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { ButtonContainer } from 'src/components/ButtonContainer/ButtonContainer';
import { BackButton } from 'src/components/UI/Button/BackButton';

import type { MetalCategory } from '../../../../../../__generated__/graphql/api';
import { CheckDown } from '../../../../../../components/CheckDown/CheckDown';
import { Typography } from '../../../../../../components/Typography/Typography';
import Button from '../../../../../../components/UI/Button/Button';
import { TextArea } from '../../../../../../components/UI/Form/TextArea/TextArea';
import type { DropFile } from '../../../../../../components/UI/ImageUploader/ImageUploader';
import { ImageUploader } from '../../../../../../components/UI/ImageUploader/ImageUploader';
import { metalCategories } from '../../../../../../data/metal';
import { cleanFilesArray } from '../../../../../../util/filesUploaderHelper';

import type { MetalItemPayload } from './Metal';

interface MetalItemScreenProps {
  className?: string;
  loading: boolean;
  onNext: (data: MetalItemPayload) => void;
  setInnerScreenStepBack: () => void;
}

interface FormValuesType {
  metalCategory: MetalCategory;
  description: string;
  photoLinks: DropFile[];
}

const validationSchema = yup.object().shape({
  metalCategory: yup.string().required('Category field is required'),
  description: yup.string().required('Item description field is required'),
  photoLinks: yup.array().required().min(1, 'At least one document is required'),
});

export const MetalCategoryScreen = (props: MetalItemScreenProps) => {
  const { className, loading, onNext, setInnerScreenStepBack } = props;
  const rootStyles = clsx('flex text-left flex-col gap-4 max-w-[400px] w-full', className);

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors, isSubmitted, isSubmitting },
    trigger,
  } = useForm<FormValuesType>({
    mode: 'onSubmit',
    resolver: yupResolver(validationSchema),
    defaultValues: {
      metalCategory: undefined,
      photoLinks: [],
      description: '',
    },
  });

  const formValues = {
    metalCategory: watch('metalCategory'),
    photoLinks: watch('photoLinks'),
    description: watch('description'),
  };

  useEffect(() => {
    if (isSubmitted || isSubmitting) {
      trigger();
    }
  }, [isSubmitted, isSubmitting, trigger, formValues.metalCategory, formValues.photoLinks, formValues.description]);

  const [isLoadingImages, setIsLoadingImages] = useState(false);
  const imagesPayload = cleanFilesArray(formValues.photoLinks.map(photo => photo.responseKey));

  const handleCategoryChange = useCallback(
    (metalCategory: MetalCategory) => {
      setValue('metalCategory', metalCategory);
    },
    [setValue],
  );

  const handleAddPhotoLink = useCallback(
    (value: DropFile[]) => {
      setValue('photoLinks', value);
    },
    [setValue],
  );
  const onSubmit: SubmitHandler<FormValuesType> = data => {
    onNext({
      metalCategory: data.metalCategory,
      photoLinks: imagesPayload as string[],
      description: data.description,
    });
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)} className={rootStyles}>
      <Typography variant={'headerMedium'} className={'self-center'}>
        What’s your item?
      </Typography>
      <div className=" flex w-full flex-col justify-center gap-2 text-center">
        {metalCategories.map((metalCategory, index) => (
          <CheckDown
            key={index}
            dataTestId={metalCategory.value}
            onChange={() => handleCategoryChange(metalCategory.value)}
            checked={formValues.metalCategory === metalCategory.value}
            leftIcon={metalCategory.icon}
            title={metalCategory.text}
          />
        ))}
      </div>
      {errors.metalCategory && (
        <Typography variant={'body'} className={'text-danger'}>
          {errors.metalCategory.message}
        </Typography>
      )}
      <ImageUploader
        label="Photo upload"
        addPhotoLink={handleAddPhotoLink}
        isLoaded={setIsLoadingImages}
        required
        condensed
      />
      {errors.photoLinks && (
        <Typography variant={'body'} className={'text-danger'}>
          {errors.photoLinks.message}
        </Typography>
      )}
      <TextArea
        data-testid="additional-details-textarea"
        {...register('description')}
        onChange={e => setValue('description', e.target.value)}
        label="Additional details"
        // it's important to have this weird placeholder because we need to show 3 lines of text
        placeholder="&#x2022; exact metal weight in grams
&#x2022; type of metal (14k, 18k, 24k gold)
&#x2022; other details that may help during our review"
        error={!!errors.description}
        errorText={errors.description?.message}
        required
      />

      <ButtonContainer
        rightButton={
          <Button
            data-testid="submit-metal-flow"
            buttonType="primary"
            text={'Next'}
            type={'submit'}
            onClick={handleSubmit(onSubmit)}
            isLoading={isLoadingImages || loading}
            disabled={isLoadingImages}
          />
        }
        leftButton={<BackButton onClick={() => setInnerScreenStepBack()} rightButtonExist />}
      />
    </form>
  );
};
