import { cn } from 'src/util/cn';

interface LoaderSpinnerFullProps {
  className?: string;
  variant?: 'full' | 'screen';
}
export const LoaderSpinnerFull = (props: LoaderSpinnerFullProps) => {
  const { className, variant = 'screen' } = props;
  const loaderClass = cn(className, ' flex flex-col items-center justify-center', {
    'h-full': variant === 'full',
    'h-screen': variant === 'screen',
  });

  return (
    <div className={loaderClass}>
      <div className="border-t-5 h-32 w-32 animate-spin rounded-full border-b-2 border-gray-900"></div>
    </div>
  );
};
