import CheckBox from 'src/components/CheckBox/CheckBox';

import { useGoRoutes } from '../../../hooks/useGoRoutes';
import { isProtectedRoutesDisabled } from '../../../util/adminDebuggerHelper';
import { Dropdown } from '../../UI/Form/Dropdown/Dropdown';
import { appNavigationOptions } from '../constants/navigationOptions';
import { useAdminDebugger } from '../hooks/useAdminDebugger';

export const RoutingController = () => {
  const { navigate } = useGoRoutes();
  const { handleDisableProtectedRoutes } = useAdminDebugger();

  const currentPage = window.location.pathname;
  const selectedAppStep = appNavigationOptions.find(option => option?.value === currentPage)?.value;
  const handleAppStepChange = (option: any) => {
    navigate(option.value);
  };

  return (
    <div className="relative mt-6 px-4 sm:px-6">
      <div className="relative my-4 rounded border border-red-500 bg-red-100 px-4 py-3" role="alert">
        <CheckBox
          value={isProtectedRoutesDisabled}
          onChange={value => handleDisableProtectedRoutes(!value)}
          text={'Disable ProtectedRoute'}
          className={'mb-4'}
          label={''}
        />
        <strong className="font-bold">Use this only for debugging purposes.</strong>
        <span className="block sm:inline">
          <br />
          <br />
          This will disable the ProtectedRoute component and allow you to navigate to any route without being logged in.
        </span>
      </div>
      <Dropdown
        adminDebugger={true}
        options={appNavigationOptions}
        label={'Go to route:'}
        isSearchable
        onChange={handleAppStepChange}
        selectedValue={selectedAppStep}
        nullMenuTarget
      />
    </div>
  );
};
