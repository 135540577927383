import clsx from 'clsx';
import { useEffect, useState } from 'react';

import { Typography } from 'src/components/Typography/Typography';
import { useIsMobile } from 'src/hooks/useIsMobile';

import UseCashImageBg from '../assets/UseCash.svg';
import UseItemsImageBg from '../assets/UseItems.svg';

import { CardDetailsModal } from './CardDetailsModal';
import { CardFlow } from './CardFlow';
import { FAQModal } from './FAQModal';
import { SecureModal } from './SecureModal';

interface ContentProps {
  onCashClick: () => void;
  onJewelryClick: () => void;
}

export const Content = (props: ContentProps) => {
  const { onCashClick, onJewelryClick } = props;

  const [isCardDetailsModalOpen, setIsModalCardDetailsOpen] = useState(false);
  const [isSecurityModalOpen, setIsModalSecurityOpen] = useState(false);
  const [isFAQModalOpen, setIsFAQOpen] = useState(false);

  const isMobile = useIsMobile();

  useEffect(() => {
    if (window.location.search.includes('?card-details')) {
      setIsModalCardDetailsOpen(true);
    }
  }, []);

  return (
    <div className="flex flex-col justify-center gap-2 pl-0 md:pl-6">
      <Typography className="text-left" variant="headerLarge">
        Apply for the Pesto Mastercard®
      </Typography>
      {!isMobile && (
        <Typography className="text-left" variant="headerMedium">
          No credit score required. <br />
          Use your card anywhere Mastercard® is accepted.
        </Typography>
      )}

      <div
        className={clsx('mt-4 flex flex-row gap-6', {
          'overflow-y-scroll whitespace-nowrap': isMobile,
        })}
      >
        <div
          className="cursor-pointer"
          onClick={() => {
            setIsModalCardDetailsOpen(true);
          }}
        >
          💳 Card Details
        </div>
        <div
          className="cursor-pointer"
          onClick={() => {
            setIsModalSecurityOpen(true);
          }}
        >
          🔒 Secure
        </div>
        <div
          className="cursor-pointer"
          onClick={() => {
            setIsFAQOpen(true);
          }}
        >
          ℹ️ FAQ
        </div>
      </div>

      <div
        className={clsx('mt-4 flex flex-row gap-6', {
          'flex-col !gap-4': isMobile,
        })}
      >
        <CardFlow srcSVG={UseCashImageBg}>
          <div className="mt-4 flex flex-col justify-center gap-2 text-left">
            <Typography variant="body" className="text-white">
              Use Cash
            </Typography>
            <Typography
              variant="bodySmall"
              className={clsx('text-white', {
                '!text-[10px]': isMobile,
              })}
            >
              Build credit without <br /> breaking the bank — <br />
              starting at just $200
            </Typography>

            <div
              onClick={onCashClick}
              className="w-full cursor-pointer rounded-lg bg-offWhite pb-2 pl-4 pr-4 pt-2 text-center text-basic"
            >
              Apply now
            </div>
          </div>
        </CardFlow>

        <CardFlow srcSVG={UseItemsImageBg}>
          <div className="mt-4 flex flex-col justify-center gap-2 text-left">
            <Typography variant="body" className="text-white">
              Use Items
            </Typography>

            <Typography
              variant="bodySmall"
              className={clsx('text-white', {
                '!text-[10px]': isMobile,
              })}
            >
              Get access to spending <br /> power and still maintain <br /> ownership—no need to sell
            </Typography>

            <div
              onClick={onJewelryClick}
              className="w-full cursor-pointer rounded-lg bg-offWhite pb-2 pl-4 pr-4 pt-2 text-center text-basic"
            >
              Apply now
            </div>
          </div>
        </CardFlow>
      </div>

      <CardDetailsModal
        onClose={() => {
          setIsModalCardDetailsOpen(false);
        }}
        isOpen={isCardDetailsModalOpen}
      />

      <SecureModal
        onClose={() => {
          setIsModalSecurityOpen(false);
        }}
        isOpen={isSecurityModalOpen}
      />

      <FAQModal
        onClose={() => {
          setIsFAQOpen(false);
        }}
        isOpen={isFAQModalOpen}
      />
    </div>
  );
};
