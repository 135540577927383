import { useState } from 'react';

import { useUser } from '../../hooks/useUser';
import SvgCardIcon from '../../icons/components/CardIcon';
import { formatToCurrency } from '../../util/formatterHelper';
import { Typography } from '../Typography/Typography';

import { CardDetailsModal } from './CardDetailsModal';

export const CardDetailsSmall = () => {
  const { user } = useUser();
  const card = user?.cards?.[0];

  const [isDetailsOpen, setIsDetailsOpen] = useState(false);
  const handleOpenCardDetails = () => {
    setIsDetailsOpen(true);
  };

  if (!card) return null;
  return (
    <>
      <div
        className="flex cursor-pointer items-center justify-between gap-1 whitespace-nowrap rounded border border-neutralInactive bg-white px-2 py-1 hover:border-neutral "
        onClick={handleOpenCardDetails}
      >
        <div className="flex items-center gap-1">
          <SvgCardIcon className="h-8 w-auto" />
          <Typography variant="body" className="pl-1 text-[#12884F]">
            <strong>{formatToCurrency(card.creditLimit || 0)}</strong>
          </Typography>
        </div>
      </div>
      <CardDetailsModal isOpen={isDetailsOpen} onClose={() => setIsDetailsOpen(!isDetailsOpen)} card={card} />
    </>
  );
};
