import * as React from 'react';
import type { SVGProps } from 'react';

interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgFeeVerifyIcon = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 12 12"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      stroke="#1C7C4E"
      strokeLinecap="round"
      strokeWidth={0.75}
      d="m2.5 6 2.353 2.781a.5.5 0 0 0 .776-.015L9.75 3.5"
    />
  </svg>
);
export default SvgFeeVerifyIcon;
