import { Link } from 'react-router-dom';

import { routes } from '../../../constants/routes';
import { LogoIcon } from '../../../icons/components';

const LogoContainer = () => {
  return (
    <Link to={routes.home}>
      <LogoIcon className="mx-auto h-[31px] w-[105px] sm:mx-0" />
    </Link>
  );
};

export default LogoContainer;
