import clsx from 'clsx';
import type { FC, ReactNode } from 'react';
import React from 'react';

import { ButtonContainer } from 'src/components/ButtonContainer/ButtonContainer';
import { BackButton } from 'src/components/UI/Button/BackButton';

import type { Brand } from '../../../../../../__generated__/graphql/api';
import { Typography } from '../../../../../../components/Typography/Typography';
import { watchBrands } from '../../../../../../data/brands';

interface WatchInnerScreenProps {
  className?: string;
  onNext: (data: Brand) => void;
  handleBack: () => void;
}

interface BrandButtonContentProps {
  logo?: ReactNode | string;
  text: string;
}

interface BrandButtonContentProps {
  logo?: ReactNode | string;
  text: string;
}

const BrandButtonContent: FC<BrandButtonContentProps> = ({ logo, text }) => {
  if (typeof logo === 'string') {
    return <img src={logo} alt={text || ''} />;
  }

  if (React.isValidElement(logo)) {
    return logo;
  }

  return <span className="text-center text-[10px] font-medium">{text}</span>;
};

export const WatchBrandScreen = (props: WatchInnerScreenProps) => {
  const { className, onNext, handleBack } = props;
  const rootStyles = clsx('flex flex-col gap-4', className);

  return (
    <div className={rootStyles}>
      <Typography variant={'headerMedium'}>What brand is your watch?</Typography>
      <div className="grid w-full max-w-[400px] grid-cols-2 justify-center gap-4 text-center">
        {watchBrands.map((watchBrand, index) => (
          <button
            key={index}
            className={clsx('h-[76px] w-auto rounded-sm border-[1px] bg-[#FAFAFC] px-8 py-3 hover:bg-gray-100')}
            value={watchBrand.value}
            onClick={() => onNext(watchBrand.value)}
          >
            <BrandButtonContent logo={watchBrand.logo} text={watchBrand.text} />
          </button>
        ))}
      </div>

      <ButtonContainer leftButton={<BackButton onClick={handleBack} rightButtonExist={false} />} />
    </div>
  );
};
