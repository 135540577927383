import * as React from 'react';
import type { SVGProps } from 'react';

interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgFreeDisabledVerifyIcon = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 14 14"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path fill="#fff" d="M.5 7a6.5 6.5 0 1 1 13 0 6.5 6.5 0 0 1-13 0Z" />
    <path stroke="#4B5157" d="M.5 7a6.5 6.5 0 1 1 13 0 6.5 6.5 0 0 1-13 0Z" />
    <path
      stroke="#4B5157"
      strokeLinecap="round"
      strokeWidth={0.75}
      d="m3.5 7 2.353 2.781a.5.5 0 0 0 .776-.015L10.75 4.5"
    />
  </svg>
);
export default SvgFreeDisabledVerifyIcon;
