import isEmpty from 'lodash.isempty';
import React, { useCallback, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { SwitchFlowBanner } from 'src/components/SwitchFlowBanner/SwitchFlowBanner';
import { BackButton } from 'src/components/UI/Button/BackButton';
import { useGetUserStatus } from 'src/hooks/useGetUserStatus';
import { useOnMount } from 'src/hooks/useOnMount';
import { useScrollTopOnMount } from 'src/hooks/useScrollTopOnMount';
import SvgActiveIcon from 'src/icons/components/ActiveIcon';
import SvgCoinCash from 'src/icons/components/CoinCash';
import SvgItemDeleted from 'src/icons/components/ItemDeleted';
import SvgItemRejected from 'src/icons/components/ItemRejected';
import { getItemText } from 'src/util/getItemText';

import { CreditApplicationStatus, SecurityType, useCurrentUserQuery } from '../../../../__generated__/graphql/api';
import { ButtonContainer } from '../../../../components/ButtonContainer/ButtonContainer';
import { Typography } from '../../../../components/Typography/Typography';
import Button from '../../../../components/UI/Button/Button';
import { Modal2 } from '../../../../components/UI/Modal/Modal2';
import { SKIP_ADD_ITEM_SIGN_UP } from '../../../../constants/application';
import { useGoRoutes } from '../../../../hooks/useGoRoutes';
import EmptyStateIcon from '../../../../images/empty-state-items.svg';
import logger from '../../../../util/logger';
import NewDiamondUserBlocked from '../QuickAppraisal/screens/Gemstone/NewDiamondUserBlocked';

import { ConnectedItemCard } from './components/ConnectedItemCard';
import { LoadingItemCart } from './components/LoadingItemCart';
import SwitchButton from './components/SwitchButton';
import { useFetchItems } from './hooks/useFetchItems';

export enum SwitchState {
  Active,
  Rejected,
  Deleted,
  Cash,
}

// The hideNavigation prop is implemented to control which elements should be visible to the user
// when this component is used for the /my-items route
interface ItemCartProps {
  hideNavigation?: boolean;
}

export const ItemCart = (props: ItemCartProps) => {
  useScrollTopOnMount();
  const { state } = useLocation();
  const user = useCurrentUserQuery();
  const { gotoApplicationQuickAppraisal, gotoPersonalAndAddressInfo, gotoPreQualified } = useGoRoutes();
  const { items, loading } = useFetchItems({
    errorPolicy: 'all',
    notifyOnNetworkStatusChange: true,
  });
  const { showSwapFlowBanner, userStatus } = useGetUserStatus();
  const [switchState, setSwitchState] = useState<SwitchState>(SwitchState.Active);

  const shouldModalBeOpen = state === SKIP_ADD_ITEM_SIGN_UP;
  const [isModalOpen, setIsModalOpen] = useState<boolean>(shouldModalBeOpen || false);
  const isCreditLimitTooLow = Boolean(items?.currentUser?.creditApplication?.creditTooLow);
  const isOtherItemInReview = Boolean(
    items?.currentUser?.creditApplication?.status === CreditApplicationStatus.ItemUnderReview,
  );
  const renderNextButtonText =
    userStatus === CreditApplicationStatus.Prequalified ? 'Get items to pesto' : 'Create Account';

  const goToAddItem = useCallback(() => {
    gotoApplicationQuickAppraisal();
  }, [gotoApplicationQuickAppraisal]);

  const handleNextStepClick = () => {
    if (userStatus === CreditApplicationStatus.Prequalified) {
      gotoPreQualified();
    } else {
      gotoPersonalAndAddressInfo();
    }
  };

  const renderWarning = () => {
    if (isOtherItemInReview) {
      return 'Item is currently being reviewed';
    } else if (isCreditLimitTooLow || noItems) {
      return 'Item value must exceed $300. Please add more items.';
    } else {
      return '';
    }
  };

  const activeItem = items?.currentUser?.creditApplication?.items?.filter(item => item?.active);

  const noItems = !activeItem || activeItem?.length === 0;
  logger.info('has items', activeItem);

  const activeItems = items?.currentUser?.creditApplication?.items?.filter(
    item => item?.active && !item?.qaRejectionReason,
  );
  const inactiveItems = items?.currentUser?.creditApplication?.items?.filter(
    item => !item?.active && !item?.qaRejectionReason,
  );
  const deletedItems = items?.currentUser?.creditApplication?.items?.filter(item => item?.deletedAt);
  const rejectedItems = items?.currentUser?.creditApplication?.items?.filter(item => item?.qaRejectionReason);
  const cashItems = items?.currentUser?.creditApplication?.items?.filter(item => item?.type === 'CASH');
  const isCashSecurityType = user?.data?.currentUser?.creditApplication?.securityType === SecurityType.Cash;

  useOnMount(() => {
    if (isCashSecurityType && !isEmpty(cashItems)) {
      setSwitchState(SwitchState.Cash);
    } else if (!isEmpty(deletedItems) && isEmpty(activeItems) && isEmpty(rejectedItems) && isEmpty(cashItems)) {
      setSwitchState(SwitchState.Deleted);
    } else if (!isEmpty(rejectedItems) && isEmpty(activeItems) && isEmpty(cashItems)) {
      setSwitchState(SwitchState.Rejected);
    } else if (!isEmpty(cashItems) && isEmpty(activeItems)) {
      setSwitchState(SwitchState.Cash);
    } else if (!isEmpty(activeItems)) {
      setSwitchState(SwitchState.Active);
    }
  });

  let headerText = '';
  let subText = '';

  if (switchState === SwitchState.Active) {
    headerText = `Your ${getItemText(activeItem)}`;
    subText = `Add or remove ${getItemText(activeItem)} to your credit value`;
  } else if (switchState === SwitchState.Deleted) {
    headerText = 'Deleted items';
    subText = 'These are the items you chose to remove from the active items list.';
  } else if (switchState === SwitchState.Rejected) {
    headerText = 'Rejected items';
    subText = 'Items that didn’t met the acceptance criteria';
  } else if (switchState === SwitchState.Cash) {
    const isCashState = user?.data?.currentUser?.creditApplication?.securityType === SecurityType.Cash;
    if (isCashState) {
      headerText = 'Cash Items';
      subText = '';
    } else {
      headerText = 'Cash (Inactive)';
    }
  }

  const itemsList =
    user?.data?.currentUser?.creditApplication?.securityType === SecurityType.Cash ? inactiveItems : activeItems;

  const hasItemsInAnyTab = !isEmpty(deletedItems) || !isEmpty(rejectedItems) || !isEmpty(cashItems);
  const titleState =
    user?.data?.currentUser?.creditApplication?.securityType === SecurityType.Cash ? 'inactive' : 'active';

  const buttons = [
    {
      component: (
        <SwitchButton
          switchState={switchState}
          setSwitchState={setSwitchState}
          currentState={SwitchState.Active}
          shouldDisplay={hasItemsInAnyTab}
        >
          <SvgActiveIcon />
          <Typography variant="captionSmall">{titleState}</Typography>
        </SwitchButton>
      ),
      id: SwitchState.Active,
    },
    {
      component: (
        <SwitchButton
          switchState={switchState}
          setSwitchState={setSwitchState}
          currentState={SwitchState.Rejected}
          shouldDisplay={!isEmpty(rejectedItems)}
        >
          <SvgItemRejected />
          <Typography variant="captionSmall">rejected</Typography>
        </SwitchButton>
      ),
      id: SwitchState.Rejected,
    },
    {
      component: (
        <SwitchButton
          switchState={switchState}
          setSwitchState={setSwitchState}
          currentState={SwitchState.Deleted}
          shouldDisplay={!isEmpty(deletedItems)}
        >
          <SvgItemDeleted />
          <Typography variant="captionSmall">deleted</Typography>
        </SwitchButton>
      ),
      id: SwitchState.Deleted,
    },
    {
      component: (
        <SwitchButton
          switchState={switchState}
          setSwitchState={setSwitchState}
          currentState={SwitchState.Cash}
          shouldDisplay={!isEmpty(cashItems)}
        >
          <SvgCoinCash />
          <Typography variant="captionSmall">cash</Typography>
        </SwitchButton>
      ),
      id: SwitchState.Cash,
    },
  ];

  // handling cash, find and move the cash button to the front of the array for priority display
  if (isCashSecurityType) {
    const cashIndex = buttons.findIndex(button => button.id === SwitchState.Cash);
    const cashButton = buttons.splice(cashIndex, 1)[0];
    buttons.unshift(cashButton);
  }

  if (loading) return <LoadingItemCart />;

  return (
    <>
      <div className="flex w-full max-w-[440px] flex-col gap-4">
        {hasItemsInAnyTab && (
          <div className="h-[40px] sm:h-auto">
            <div className="absolute left-0 flex w-[100vw] max-w-full flex-nowrap justify-center gap-2 overflow-x-auto px-4 pb-6 pt-2 sm:relative sm:ml-0 sm:w-full sm:flex-wrap sm:justify-center sm:pb-2">
              {buttons.map(button => React.cloneElement(button.component, { key: button.id }))}
            </div>
          </div>
        )}
        <div className="text-center">
          <Typography variant="headerLarge" className={'font-bold'}>
            {headerText}
          </Typography>
          <div className="text-md">{subText}</div>
        </div>
        <div className="flex flex-col gap-4">
          {switchState === SwitchState.Active && itemsList?.length ? (
            itemsList?.map(item => (
              <div key={item?.id}>
                <ConnectedItemCard item={item || {}} />
              </div>
            ))
          ) : switchState === SwitchState.Rejected && rejectedItems?.length ? (
            rejectedItems?.map(item => (
              <div key={item?.id}>
                <ConnectedItemCard switchState={switchState} item={item || {}} />
              </div>
            ))
          ) : switchState === SwitchState.Deleted && deletedItems?.length ? (
            deletedItems?.map(item => (
              <div key={item?.id}>
                <ConnectedItemCard switchState={switchState} item={item || {}} />
              </div>
            ))
          ) : switchState === SwitchState.Cash && cashItems?.length ? (
            cashItems?.map(item => (
              <div key={item?.id}>
                <ConnectedItemCard switchState={switchState} item={item || {}} />
              </div>
            ))
          ) : (
            <div className="text-center">
              <div className="mb-[32px] flex items-center justify-center">
                <img className="h-[160px] w-[160px]" src={EmptyStateIcon} alt="empty state" />
              </div>
              <p className="text-xs font-bold text-neutral">You have not submitted any items.</p>
            </div>
          )}
        </div>

        {props.hideNavigation ? null : (
          <div className="mx-auto mt-4 max-w-sm justify-center">
            <Button
              data-testid="add-another-item-button"
              onClick={goToAddItem}
              buttonType="secondary"
              text={`ADD ${noItems ? '' : 'ANOTHER'} ITEM +`}
            />
          </div>
        )}
      </div>

      {showSwapFlowBanner && <SwitchFlowBanner currentFlow="asset-backed" />}

      {props.hideNavigation ? null : (
        <ButtonContainer
          rightButton={
            <Button
              data-testid="create-account-button"
              buttonType="primary"
              text={renderNextButtonText.toUpperCase()}
              onClick={handleNextStepClick}
              disabled={isCreditLimitTooLow || noItems || isOtherItemInReview}
            />
          }
          isError={isCreditLimitTooLow || noItems || isOtherItemInReview}
          error={renderWarning()}
          leftButton={<BackButton dataTestId="item-cart-back-button" onClick={goToAddItem} rightButtonExist />}
        />
      )}
      <Modal2 isOpen={isModalOpen} onClose={() => setIsModalOpen(!isModalOpen)}>
        <NewDiamondUserBlocked onButtonClick={gotoApplicationQuickAppraisal} />
      </Modal2>
    </>
  );
};
