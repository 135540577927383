import * as React from 'react';
import type { SVGProps } from 'react';

interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgSettingsIcon = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 25 25"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M11.544 2.439a1.959 1.959 0 0 0 2.908 0l.94-1.042A1.959 1.959 0 0 1 18.8 2.81l-.072 1.4a1.958 1.958 0 0 0 2.057 2.057l1.4-.072A1.959 1.959 0 0 1 23.6 9.607l-1.042.94a1.959 1.959 0 0 0 0 2.908l1.042.94a1.959 1.959 0 0 1-1.413 3.41l-1.4-.071a1.96 1.96 0 0 0-2.057 2.057l.072 1.4a1.96 1.96 0 0 1-3.41 1.413l-.94-1.042a1.96 1.96 0 0 0-2.909 0l-.943 1.043a1.96 1.96 0 0 1-3.41-1.413l.071-1.4a1.96 1.96 0 0 0-2.057-2.057l-1.4.072a1.96 1.96 0 0 1-1.41-3.407l1.042-.94a1.959 1.959 0 0 0 0-2.908l-1.042-.94A1.959 1.959 0 0 1 3.807 6.2l1.4.072A1.96 1.96 0 0 0 7.265 4.21l-.072-1.4A1.959 1.959 0 0 1 10.6 1.4l.944 1.039Z"
      clipRule="evenodd"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M12.998 18.001a6 6 0 1 0 0-12 6 6 0 0 0 0 12Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgSettingsIcon;
