import isEmpty from 'lodash.isempty';

import { AssetType, CreditApplicationStatus, useCurrentUserQuery } from '../__generated__/graphql/api';
import { applicationRoutes, cashSecuredRoutes, loginRoutes } from '../constants/routes';

import { useUser } from './useUser';

const getCurrentPage = (): string => {
  return window.location.href;
};

const checkUrlIncludes = (routes: string[]): boolean => {
  const currentPageUrl = getCurrentPage();
  return routes.some(route => currentPageUrl.includes(route));
};

// For main flow
export const useGetUserStatus = () => {
  const { isLoading } = useUser();
  const { user } = useUser();

  const urlQuickAppraisal = checkUrlIncludes([applicationRoutes.quickAppraisal]);
  const urlItemCart = checkUrlIncludes([applicationRoutes.itemsCart]);
  const urlPersonalAndAddressInfo = checkUrlIncludes([applicationRoutes.addressAndIncome]);
  const urlPreQualified = checkUrlIncludes([applicationRoutes.preQualified]);
  const urlCreateAccount = checkUrlIncludes([loginRoutes.createAccount]);

  const userBeforeOffer = urlItemCart || urlPersonalAndAddressInfo || urlPreQualified;
  const isQuickAppraisal = urlQuickAppraisal && !isLoading;
  const isCreditEstimate = userBeforeOffer && !isQuickAppraisal && !isLoading;
  const isStepper = userBeforeOffer && !isQuickAppraisal && !isLoading;
  const isOtherItemInReview = user?.creditApplication?.status === CreditApplicationStatus.ItemUnderReview;

  const itemSwapRoutes =
    urlPreQualified ||
    checkUrlIncludes([applicationRoutes.quickAppraisal, applicationRoutes.preQualified, applicationRoutes.itemsCart]);

  const notCardholder = !user?.cards?.length;

  const showSwapFlowBanner = itemSwapRoutes && notCardholder;

  const isCurrentPage = {
    urlQuickAppraisal,
    urlCreateAccount,
    urlItemCart,
    urlPersonalAndAddressInfo,
    urlPreQualified,
  };

  return {
    userStatus: user?.creditApplication?.status,
    isCreditEstimate,
    isStepper,
    isQuickAppraisal,
    isOtherItemInReview,
    isCurrentPage,
    showSwapFlowBanner,
  };
};

// For cash secured flow
export const useGetUserStatusForCashFlow = () => {
  const { user } = useUser();
  const { data: securedPaymentsData } = useCurrentUserQuery();
  const payments = securedPaymentsData?.currentUser?.creditApplication?.payments;

  const isCurrentPageCash = {
    creditLine: checkUrlIncludes([cashSecuredRoutes.creditLine]),
    personalInfo: checkUrlIncludes([cashSecuredRoutes.personalInfo]),
    payment: checkUrlIncludes([cashSecuredRoutes.payment]),
    agreements: checkUrlIncludes([cashSecuredRoutes.agreements]),
  };

  const cashSecuredStep2 = checkUrlIncludes([cashSecuredRoutes.creditLine, cashSecuredRoutes.personalInfo]);
  const cashSecuredStep3 = checkUrlIncludes([cashSecuredRoutes.payment, cashSecuredRoutes.agreements]);

  const cashSwapRoutes = checkUrlIncludes([
    cashSecuredRoutes.payment,
    cashSecuredRoutes.creditLine,
    cashSecuredRoutes.fundOverTime,
  ]);

  const notCardholder = !user?.cards?.length;

  const showSwapCashFlowBanner = cashSwapRoutes && notCardholder && isEmpty(payments);

  const items = user?.creditApplication?.items;
  const noCashAmount = !items?.some(item => item?.type === AssetType.Cash && item?.active);

  return {
    isCurrentPageCash,
    cashSecuredStep2,
    cashSecuredStep3,
    showSwapCashFlowBanner,
    noCashAmount,
  };
};
