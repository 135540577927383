import { useEffect, useState } from 'react';

import { getUtm } from 'src/util/URL/utmParamsHelper';

import { REFERRAL_CODE_SENT } from '../constants/localStorageKeys';

// example of a referral code:
// https://app.getpesto.com/?referralCode=name-12345

export const useReferralCode = () => {
  const [referralCode, setReferralCode] = useState<string | null>(null);
  const [displayReferralCode, setDisplayReferralCode] = useState<boolean>(false);

  useEffect(() => {
    const { referralCode: rc } = getUtm();

    const processedReferralCode = rc?.split('-' || '#')[0] || null;

    setReferralCode(processedReferralCode);
    setDisplayReferralCode(Boolean(processedReferralCode));

    if (localStorage.getItem(REFERRAL_CODE_SENT)) {
      setDisplayReferralCode(false);
    }
  }, []);

  return { referralCode, displayReferralCode };
};
