import clsx from 'clsx';
import { useState } from 'react';

import { ButtonContainer } from 'src/components/ButtonContainer/ButtonContainer';
import { BackButton } from 'src/components/UI/Button/BackButton';

import CaratDropdown from '../../../../../../components/CaratDropdown/CaratDropdown';
import { MaxCaratValue } from '../../../../../../components/CaratSlider/CaratSlider';
import { Typography } from '../../../../../../components/Typography/Typography';
import Button from '../../../../../../components/UI/Button/Button';

interface GemstoneDetailsScreenProps {
  className?: string;
  onNext: (carat: number) => void;
  handleBack: () => void;
}

const GemstoneDetails = (props: GemstoneDetailsScreenProps) => {
  const { className, onNext, handleBack } = props;

  const [carat, setCarat] = useState<number>(0);

  const rootStyles = clsx('flex flex-col gap-4 max-w-[400px] w-full', className);

  return (
    <div className={rootStyles}>
      <Typography variant={'headerMedium'}>Stone details</Typography>
      <div className="flex w-full flex-col justify-center gap-2 text-center">
        <CaratDropdown maxCaratValue={MaxCaratValue.FIVE} onChange={setCarat} label="Carat size" alwaysOpen />
      </div>

      <ButtonContainer
        rightButton={
          <Button buttonType={'primary'} text={'Next'} disabled={carat === 0} onClick={() => onNext(carat)} />
        }
        leftButton={<BackButton rightButtonExist onClick={handleBack} className="!h-[40px]" />}
      />
    </div>
  );
};

export default GemstoneDetails;
