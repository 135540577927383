import type { ButtonHTMLAttributes, ReactNode } from 'react';

import { cn } from 'src/util/cn';
import { toSentenceCase } from 'src/util/toSenteceCase';

import LoadingSpinner from '../../LoadingSpinner/LoadingSpinner';

export type ButtonType = 'primary' | 'secondary' | 'success' | 'successOutlined' | 'tertiary';
export type ButtonSize = 'default' | 'small';

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  buttonType: ButtonType;
  text: string;
  disabled?: boolean;
  className?: string;
  iconRight?: ReactNode;
  iconLeft?: ReactNode;
  isLoading?: boolean;
  size?: ButtonSize;
}

const Button = (props: ButtonProps) => {
  const {
    onClick,
    isLoading = false,
    buttonType,
    text,
    disabled,
    className,
    iconLeft,
    iconRight,
    size = 'default',
    ...otherProps
  } = props;

  const buttonClass = {
    primary: `text-white place-items-end bg-black border-[#2A2A2A] border-[1px] active:bg-white active:text-[#2A2A2A] hover:border-black shadow-md hover:border-[1px] disabled:bg-slate-300 disabled:shadow-none disabled:border-slate-300 hover:bg-neutralStrong hover:border-[#2A2A2A] hover:bg-black hover:border-[1px] hover:shadow-none hover:border-neutralStrong font-bold `,

    secondary: `font-normal border-[1px] border-[#2A2A2A] text-[#2A2A2A] disabled:text-slate-300 disabled:border-slate-300 hover:bg-neutralInactive active:bg-[#2A2A2A] active:border-neutralStrong active:text-white`,

    success: `font-bold border-[1.5px] shadow-md border-basic bg-systemGreen disabled:bg-systemGreenSoft disabled:border-neutral disabled:text-neutral active:border-basicSoft active:bg-white active:text-basicSoft`,

    successOutlined: `font-normal border-[1.5px] text-basicSoft border-systemGreen bg-systemGreenSoft disabled:border-systemGreenSoft disabled:text-neutral disabled:bg-offWhite disabled:text-neutral hover:border-basicSoft active:border-systemGreen active:bg-systemGreen`,

    tertiary: `font-normal border-[1.5px] text-basicSoft border-transparent disabled:text-neutral hover:text-actionSoft hover:bg-white hover:border-white active:text-neutralStrong active:bg-white active:border-white`,
  };

  const buttonSizes = {
    default: 'px-6 py-2',
    small: 'px-4 py-1',
  };

  const buttonSize = buttonSizes[size];

  const iconColors = {
    primary: 'text-white',
    secondary: 'text-black',
    success: 'text-black',
    successOutlined: 'text-black',
    tertiary: 'text-basicSoft hover:text-actionSoft',
  };

  const iconLeftDiv = iconLeft ? <div className={cn('mr-1', iconColors[buttonType])}>{iconLeft}</div> : null;

  const iconRightDiv = iconRight ? <div className={cn('ml-1', iconColors[buttonType])}>{iconRight}</div> : null;

  const formattedText = toSentenceCase(text);

  const ariaLabel = isLoading ? `Loading... ${formattedText}` : formattedText;

  return (
    <button
      className={cn(
        className,
        buttonClass[buttonType],
        'flex w-full items-center justify-center rounded-lg text-center',
        buttonSize,
      )}
      onClick={onClick}
      disabled={isLoading ? true : disabled}
      aria-label={ariaLabel}
      aria-busy={isLoading}
      {...otherProps}
    >
      {iconLeftDiv}
      {isLoading ? null : formattedText}
      {iconRightDiv}
      {isLoading && (
        <div className="flex h-full w-full items-center justify-center">
          <LoadingSpinner />
        </div>
      )}
    </button>
  );
};

export default Button;
