import clsx from 'clsx';

import { AssetType } from '../../../../../__generated__/graphql/api';

import { IconButtonSelect } from './IconButtonSelect';

interface ButtonSelectProps {
  itemType: AssetType | null | undefined;
  selectedAssetType: (type: AssetType) => void;
  className?: string;
}

interface ButtonSelectInterface {
  type: AssetType;
  text: string;
  extraText?: string;
  dataTestId?: string;
}

const ItemsSelectDataMobile: ButtonSelectInterface[] = [
  {
    type: AssetType.PreciousMetals,
    text: 'Metal',
    extraText: 'Gold, Silver, and Platinum',
    dataTestId: 'metal-flow',
  },
  {
    type: AssetType.Gemstones,
    text: 'Gemstone',
    extraText: 'Cut, Clarity, Color, Carat',
    dataTestId: 'diamond-flow',
  },
  {
    type: AssetType.Ring,
    text: 'Ring',
    extraText: 'Engagement, Wedding, etc.',
    dataTestId: 'ring-flow',
  },
  {
    type: AssetType.Earrings,
    text: 'Earrings',
    extraText: 'Earrings',
    dataTestId: 'earrings-flow',
  },
  {
    type: AssetType.Necklace,
    text: 'Necklace',
    extraText: 'Necklace, Chain, etc.',
    dataTestId: 'earrings',
  },
  {
    type: AssetType.Watch,
    text: 'Watch',
    extraText: 'Rolex, Patek Philippe, etc.',
    dataTestId: 'watch-flow',
  },
  {
    type: AssetType.Other,
    text: 'Other',
    extraText: 'Coins, Antiques, etc.',
    dataTestId: 'other-flow',
  },
];

export const ButtonSelect = (props: ButtonSelectProps) => {
  const { selectedAssetType, itemType, className } = props;
  const style = clsx(className, 'w-full grid grid-cols-3 gap-2 justify-center text-center');

  return (
    <div className={style}>
      {ItemsSelectDataMobile.map((item, index) => {
        return (
          <IconButtonSelect
            key={index}
            onSelectType={selectedAssetType}
            activate={itemType === item.type}
            type={item.type}
            text={item.text}
            dataTestId={item.dataTestId}
            flowStarted={Boolean(itemType)}
          />
        );
      })}
    </div>
  );
};
