import type { ForwardedRef, HTMLInputTypeAttribute } from 'react';
import { forwardRef, useState } from 'react';

import { formatLabel } from 'src/util/formatterHelper';

import { maskSSN } from '../util/ssnHelper';

import { InfoTooltip } from './InfoTooltip/InfoTooltip';
import { TextField } from './UI/Form/TextField/TextField';

const ssnTooltipText =
  'SSN or Individual Taxpayer Identification Number (ITIN) – We use this information to identify you and process your application. We use encryption to protect your sensitive information.';

export interface SSNProps {
  onChange: (value: string) => void;
  defaultValue: string;
  label: string;
  type?: HTMLInputTypeAttribute;
  required?: boolean;
  error?: boolean;
  errorText?: string;
  compareToSsn?: string;
  isValid?: (value: boolean) => void;
  disabled?: boolean;
  prefilled?: string;
  readOnly?: boolean;
}

export const SSN = forwardRef((props: SSNProps, ref: ForwardedRef<HTMLInputElement>) => {
  const { onChange, defaultValue, label, required = false, disabled = false, readOnly = false, prefilled } = props;
  const [maskedSsn, setMaskedSsn] = useState('');

  const handleChange = (event: { target: { value: string } }) => {
    const prevValue = maskedSsn;
    const newValue = event.target.value;
    let actual = defaultValue;
    const isTooLong = newValue.length > 11;
    if (isTooLong) {
      return;
    }
    if (newValue.length > prevValue.length) {
      const newChar = newValue.split('').pop();
      actual = `${actual}${newChar}`;
    } else {
      const charsRemovedCount = prevValue.length - newValue.length;
      actual = actual.slice(0, -charsRemovedCount);
    }
    onChange(actual);
    setMaskedSsn(maskSSN(actual, 4));
  };

  return (
    <TextField
      ref={ref}
      name="ssn"
      inputMode="numeric"
      value={maskedSsn || prefilled || ''}
      onChange={handleChange}
      placeholder={'Enter ' + formatLabel(label)}
      data-testid={label}
      label={label}
      disabled={disabled}
      required={required}
      readOnly={readOnly}
      error={props.error}
      errorText={props.errorText}
      info={<InfoTooltip toolTipText={ssnTooltipText} toolTipDirection={'up'} />}
    />
  );
});
