import * as React from 'react';
import type { SVGProps } from 'react';

interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgBridalSetRingStyle = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <circle cx={12} cy={16} r={5.75} stroke="#101820" strokeLinecap="round" strokeWidth={1.5} />
    <circle cx={12} cy={12.546} r={5.75} stroke="#101820" strokeLinecap="round" strokeWidth={1.5} />
    <path stroke="#101820" strokeWidth={1.5} d="M10.028 4.64 12 6.074l1.972-1.432-.754-2.318h-2.436l-.754 2.318Z" />
  </svg>
);
export default SvgBridalSetRingStyle;
