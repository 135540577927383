import * as React from 'react';
import type { SVGProps } from 'react';

interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgDangle = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <circle cx={9.65} cy={20.5} r={2.25} stroke="#101820" strokeWidth={1.5} />
    <circle cx={9.65} cy={15.5} r={1.75} stroke="#101820" strokeWidth={1.5} />
    <path fill="#101820" d="M10.4 12.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Z" />
    <path
      stroke="#101820"
      strokeLinecap="round"
      strokeWidth={1.5}
      d="M9.65 10.508v0c0-1.286-.515-2.503-1.26-3.552-1.569-2.211-2.555-4.948 1.26-4.948 3.446 0 5.407-.371 6.204 4 .296 1.62.322 3.763 1.796 4.5v0"
    />
  </svg>
);
export default SvgDangle;
