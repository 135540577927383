import clsx from 'clsx';
import React from 'react';

type LayoutProps = {
  leftContent: React.ReactNode;
  rightContent: React.ReactNode;
  className?: string;
};

export const Layout: React.FC<LayoutProps> = ({ leftContent, rightContent, className }) => {
  return (
    <div className={clsx('flex h-full w-full flex-grow flex-wrap justify-center', className)}>
      <div
        className={clsx('w-full max-w-[320px] md:w-1/3 lg:w-[300px]', {
          'order-last md:order-first': true,
        })}
      >
        {leftContent}
      </div>
      <div className="w-full md:w-2/3">{rightContent}</div>
    </div>
  );
};
