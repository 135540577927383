import * as React from 'react';
import type { SVGProps } from 'react';

interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgPayNowLock = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <g fill="currentColor" clipPath="url(#PayNowLock_svg__a)">
      <path d="M4.093 10.493c.4-.399.942-.624 1.507-.626h.713V6.313a4.98 4.98 0 0 1 4.974-4.98h1.426a4.98 4.98 0 0 1 4.974 4.98v3.554h.713a2.12 2.12 0 0 1 1.507.626c.399.4.624.942.626 1.507v8.533a2.133 2.133 0 0 1-2.133 2.134H5.6a2.147 2.147 0 0 1-1.97-1.318 2.12 2.12 0 0 1-.163-.816V12a2.147 2.147 0 0 1 .626-1.507Zm12.174-4.18a3.553 3.553 0 0 0-3.554-3.553h-1.42a3.553 3.553 0 0 0-3.56 3.553v3.554h8.534V6.313ZM4.887 20.527a.714.714 0 0 0 .713.713h12.8a.713.713 0 0 0 .713-.713V12a.713.713 0 0 0-.713-.707H5.6a.714.714 0 0 0-.707.707l-.006 8.527Z" />
      <path d="M12 13.42a2.127 2.127 0 0 1 .713 4.133v1.554h-1.42V17.56A2.127 2.127 0 0 1 12 13.42Zm0 2.847a.713.713 0 1 0 0-1.427.713.713 0 0 0 0 1.427Z" />
    </g>
    <defs>
      <clipPath id="PayNowLock_svg__a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgPayNowLock;
