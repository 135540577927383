import {
  ADMIN_DEBUGGER_LS_KEY,
  LANDING_FINDER_LS_KEY,
  NEW_SIGN_UP_DEMO_LS_KEY,
  PERSONAL_INFO_DEMO_LS_KEY,
} from 'src/constants/localStorageKeys';

export const isDebuggerEnabled = localStorage.getItem(ADMIN_DEBUGGER_LS_KEY) === 'true';
export const isProtectedRoutesDisabled = sessionStorage.getItem('disableProtectedRoutesForTheSession') === 'true';

export const isFeatureFlagEnabled = (key: string): boolean => {
  const value = localStorage.getItem(key);
  return value === 'true';
};

export const isPersonalInfoDemoEnabled = () => isFeatureFlagEnabled(PERSONAL_INFO_DEMO_LS_KEY);
export const isNewSignUpDemoEnabled = () => isFeatureFlagEnabled(NEW_SIGN_UP_DEMO_LS_KEY);
export const isLandingFinderEnabled = () => isFeatureFlagEnabled(LANDING_FINDER_LS_KEY);
