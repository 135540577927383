import { Clarity, Color, Cut } from 'src/__generated__/graphql/api';
import type { OptionType } from 'src/pages/ApplicationFlow/steps/QuickAppraisal/types';

export function isCaratType(selectedItem: OptionType): boolean {
  const value = typeof selectedItem === 'string' ? selectedItem : selectedItem.value;

  const notColor = !Object.values(Color).includes(value as Color);
  const notCut = !Object.values(Cut).includes(value as Cut);
  const notClarity = !Object.values(Clarity).includes(value as Clarity);

  const isNumber = (): boolean => {
    if (typeof value === 'string') {
      return /^\d+(\.\d+)?$/.test(value);
    }
    return false;
  };
  return notColor && notCut && notClarity && isNumber();
}
