import type { ReactNode } from 'react';

import { ReferralHeader } from 'src/components/ReferralHeader/ReferralHeader';
import { useReferralCode } from 'src/hooks/useReferralCode';
import { cn } from 'src/util/cn';

import { useUser } from '../../hooks/useUser';
import { MeetWithComponent } from '../MeetWithButton/MeetWithComponent';
import { Wrapper1280 } from '../UI/Wrapper/Wrapper1280';

import Navigation from './components/Navigation';

interface HeaderTypeProps {
  className?: string;
  creditEstimate: ReactNode;
  stepper: ReactNode;
  divider?: boolean;
}

export const Header = ({ className, creditEstimate, stepper, divider = false }: Partial<HeaderTypeProps>) => {
  const { user } = useUser();
  const { referralCode, displayReferralCode } = useReferralCode();

  const rootStyles = cn(
    'sticky top-0 z-[9998] bg-white border-b border-neutralLight',
    {
      'border-neutralSoft': divider,
    },
    className,
  );

  return (
    <>
      <div className={rootStyles}>
        {user && <MeetWithComponent />}
        {displayReferralCode && !user && <ReferralHeader name={referralCode!} />}
        <div>
          <Wrapper1280>
            <Navigation />
          </Wrapper1280>
        </div>
      </div>
      {(creditEstimate || stepper) && (
        <div className="flex flex-col gap-2 border-b border-neutralLight bg-offWhite py-2">
          {stepper && <div className="relative flex items-center justify-center">{stepper}</div>}
          {creditEstimate && creditEstimate}
        </div>
      )}
    </>
  );
};
