import * as React from 'react';
import type { SVGProps } from 'react';

interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgPestoLogo = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 108 32"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <g fill="currentColor" clipPath="url(#pestoLogo_svg__a)">
      <path d="M.222 15.501c-.042-.333-.042-.706-.042-1.04C.18 5.943 5.582 0 13.437 0c6.858 0 11.927 4.53 11.927 11.305 0 7.77-6.608 12.593-14.588 12.593-.955 0-2.077-.082-3.2-.249.748 2.577 1.745 5.236 2.867 7.937H4.336c-1.288-3.116-2.32-6.381-3.159-9.516-.42-1.58-.86-4.713-.955-6.569Zm6.109 2.62c1.413.373 2.783.54 4.114.54 5.776 0 9.06-3.284 9.06-7.44 0-3.533-2.453-5.985-6.027-5.985-4.281 0-7.44 3.532-7.44 9.226 0 1.164.082 2.368.291 3.656l.002.002ZM25.973 21.28c0-6.319 4.447-10.973 10.763-10.973 6.315 0 10.347 4.114 10.347 10.1 0 .748-.082 1.578-.249 2.367H31.333c.54 3.241 2.66 4.696 5.651 4.696 2.744 0 4.323-1.206 5.03-2.992l4.862.998C45.63 29.59 42.014 32 36.984 32c-6.9 0-11.013-4.53-11.013-10.723l.002.002Zm5.527-2.536h10.056c-.457-2.659-2.161-4.072-4.82-4.072-2.41 0-4.488 1.289-5.236 4.072ZM48.887 25.767l5.362-1.08c.415 2.161 1.953 3.074 4.114 3.074 2.162 0 3.408-.83 3.408-2.326 0-1.58-2.244-1.788-4.739-2.243-3.365-.54-7.314-1.455-7.314-6.234 0-3.865 3.656-6.649 8.685-6.649 4.697 0 7.522 2.077 8.728 5.776l-5.196 1.08c-.415-1.786-1.661-2.619-3.532-2.619-2.077 0-3.325.915-3.325 2.161 0 1.538 1.912 1.87 4.28 2.244 3.49.582 7.813 1.288 7.813 6.151 0 3.948-2.91 6.9-8.81 6.9-4.654 0-8.312-1.786-9.476-6.234l.002-.001ZM76.405 6.027h1.413v4.738h6.61v4.572h-6.61v8.852c0 2.161 1.08 2.992 2.95 2.992.915 0 1.788-.249 2.826-.706l1.164 4.323a11.603 11.603 0 0 1-5.153 1.206c-4.406 0-7.025-2.453-7.025-6.609V15.338h-3.947v-4.571h3.947V6.029h3.824l.001-.002ZM107.819 21.153c0 6.275-4.696 10.847-11.096 10.847s-11.096-4.572-11.096-10.847c0-6.276 4.696-10.847 11.096-10.847s11.096 4.613 11.096 10.847Zm-16.705 0c0 3.656 2.203 6.067 5.61 6.067 3.405 0 5.61-2.41 5.61-6.067s-2.243-6.027-5.61-6.027c-3.368 0-5.61 2.41-5.61 6.027Z" />
    </g>
    <defs>
      <clipPath id="pestoLogo_svg__a">
        <path fill="#fff" d="M.18 0h107.64v32H.18z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgPestoLogo;
