const Cash = {
  AMOUNT_SET: 'Amount Set',
  PAYMENT_SUCCESSFUL: 'Payment Successful',
  PAYMENT_FAILED: 'Payment Failed',
  PAYMENT_PREFERENCE_SET: 'Payment Preference Set',
} as const;

const CashSecuredApplication = {
  CASH_SECURED_PARTNER: 'Cash Secured Partner',
} as const;

const Item = {
  QUICK_APPRAISAL_STARTED: 'Quick Appraisal Started',
  QUICK_APPRAISAL_COMPLETED: 'Quick Appraisal Completed',
  PRE_QUALIFICATION_STARTED: 'Pre-qualification Application Started',
  PRE_QUALIFICATION_COMPLETED: 'Pre-qualification Application Completed',
  SHIPPING_INFO_VIEWED: 'Shipping Info Viewed',
  SKIP_ADD_ITEM_SIGN_UP: 'Skip Add Item Sign Up',
} as const;

const Shared = {
  SECURITY_TYPE_SELECTED: 'Security Type Selected',
  SIGN_UP_COMPLETED: 'Cash Flow Sign Up Completed',
  INCOME_INFORMATION_SUBMITTED: 'Income Information Submitted',
  PERSONAL_INFORMATION_SUBMITTED: 'Personal Information Submitted',
  OFFER_ACCEPTED: 'Offer Accepted',
  OFFER_REJECTED: 'Offer Rejected',
  CREDIT_APPLICATION_COMPLETED: 'Credit Application Completed',
} as const;

const Page = {
  PAGE_LOADED: 'Page Loaded',
  PAGE_IDENTIFIED: 'IDENTIFIED_STATE_EVENT',
  PAGE_ANONYMOUS: 'ANONYMOUS_STATE_EVENT',
} as const;

export const AnalyticsEvent = {
  Item,
  Cash,
  Shared,
  Page,
  CashSecuredApplication,
} as const;
