import * as React from 'react';
import type { SVGProps } from 'react';

interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgTeardrop = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <circle cx={12} cy={4.5} r={1.5} stroke="#101820" strokeWidth={1.5} />
    <path stroke="#101820" strokeLinecap="round" strokeWidth={1.5} d="M12 8v2" />
    <path
      fill="#101820"
      fillRule="evenodd"
      d="M11.393 11.347A.751.751 0 0 1 12.03 11a.747.747 0 0 1 .636.347c.656.78 1.446 1.744 2.153 2.754.725 1.035 1.397 2.167 1.734 3.236.333 1.057.393 2.252-.432 3.166-.781.864-2.164 1.245-4.084 1.247h-.004c-1.925 0-3.312-.38-4.095-1.247-.825-.914-.766-2.109-.432-3.166.337-1.069 1.009-2.2 1.733-3.236.707-1.01 1.497-1.973 2.154-2.754Zm.636 1.58a33.794 33.794 0 0 0-1.56 2.034c-.698.997-1.265 1.979-1.533 2.827-.27.86-.18 1.383.115 1.71.336.372 1.155.75 2.973.752h.001c1.825 0 2.646-.38 2.983-.753.294-.326.385-.85.114-1.709-.267-.848-.834-1.83-1.531-2.827a33.753 33.753 0 0 0-1.562-2.034Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgTeardrop;
