import Slider from 'rc-slider';
import type { ReactNode } from 'react';

import noop from 'lodash/noop';
import type { MarkObj } from 'rc-slider/es/Marks';
import type { SliderProps } from 'rc-slider/lib/Slider';
import { cn } from 'src/util/cn';

import classes from './css/CaratsSlider.module.css';

type SliderMarks = Record<string | number, ReactNode | MarkObj>;
interface CaratSliderProps extends Omit<SliderProps, 'onChange'> {
  className?: string;
  marks: SliderMarks;
  onChange: ((value: number) => void) | undefined;
}
export const PestoSlider = (props: CaratSliderProps) => {
  const {
    className,
    value,
    onChange,
    marks,
    step = 0.01,
    startPoint = 0,
    min = 0,
    defaultValue = 0,
    ...sliderProps
  } = props;
  const rootStyle = cn(className, 'w-full flex');

  return (
    <div className={rootStyle}>
      <Slider
        className={classes.caratSlider}
        value={value}
        startPoint={startPoint}
        onChange={onChange || noop}
        step={step}
        min={min}
        marks={marks}
        defaultValue={defaultValue}
        {...sliderProps}
      />
    </div>
  );
};
