import { datadogLogs } from '@datadog/browser-logs';
import { yupResolver } from '@hookform/resolvers/yup';
import isEmpty from 'lodash.isempty';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

import capitalize from 'lodash/capitalize';
import { useAuth } from 'src/auth/AuthProvider';
import { ButtonContainer } from 'src/components/ButtonContainer/ButtonContainer';
import { Header } from 'src/components/Header/Header';
import Stepper from 'src/components/Stepper/Stepper';
import { Typography } from 'src/components/Typography/Typography';
import Button from 'src/components/UI/Button/Button';
import { Wrapper1280 } from 'src/components/UI/Wrapper/Wrapper1280';
import {
  CASH_SECURED_APPLICATION_AB_TEST,
  CASH_SECURED_APPLICATION_USER_FORM_LS_KEY,
  QUICK_APPRAISAL_LS_KEY,
} from 'src/constants/localStorageKeys';
import { useGoRoutes } from 'src/hooks/useGoRoutes';
import { useIsMobile } from 'src/hooks/useIsMobile';
import { useLocalStorage } from 'src/hooks/useLocalStorage';
import { useOnMount } from 'src/hooks/useOnMount';
import { useScrollTopOnMount } from 'src/hooks/useScrollTopOnMount';
import { CoinCash, PayNowLock, StepDone } from 'src/icons/components';
import { AnalyticsEvent } from 'src/providers/analytics/AnalyticsEvents';
import { useAnalytics } from 'src/providers/analytics/AnalyticsProvider';
import { cn } from 'src/util/cn';

import { Accordion } from '../landing/components/Accordion';

import BenefitItem from './components/BenefitItem';
import { CashSecuredHeroSelection } from './components/HeroScreens/CashSecuredHeroSelection';
import InfoCard from './components/InfoCard';
import { StepsToGetCard } from './components/StepsToGetCard';
import { VideoRow } from './components/VideoRow';
import { accordionItems, bankDepositFeatures, footer, fundOverTimeFeatures, stepsData } from './content';
import { useActiveScreen } from './context/CashSecuredActiveScreenProvider';
import { DEFAULT_FORM_DATA, PAYMENT_PREFERENCE, requiredFields, SECURITY_TYPE_AND_DETAILS } from './helpers';
import type { CashSecuredApplicationForm, PaymentOptions } from './types';
import { validationSchema } from './validationSchema';

enum ExperimentVariant {
  WithPreOnboardingScreen = 'with pre-onboarding screen',
  WithoutPreOnboardingScreen = 'without pre-onboarding screen',
}

interface AbTestCashSEcuredApplicationConfig {
  experimentWin: ExperimentVariant;
}

const CashSecuredApplication = () => {
  const isMobile = useIsMobile();
  useScrollTopOnMount();
  const { handleClick, setActiveScreen } = useActiveScreen();
  const { analytics } = useAnalytics();
  const [showMainContent, setShowMainContent] = useState<boolean>(false);

  const location = useLocation();
  const { sendCode } = useAuth();
  const { gotoLogin, gotoCreateAccount } = useGoRoutes();

  const inputRef = useRef<HTMLInputElement | null>(null);
  const targetRef = useRef<HTMLDivElement | null>(null);

  const [isEditing, setIsEditing] = useState(false);
  const [localStorageData, setLocalStorageData] = useLocalStorage<CashSecuredApplicationForm>(
    CASH_SECURED_APPLICATION_USER_FORM_LS_KEY,
    DEFAULT_FORM_DATA,
  );

  const [abTestConfig, setAbTestConfig] = useLocalStorage<AbTestCashSEcuredApplicationConfig>(
    CASH_SECURED_APPLICATION_AB_TEST,
    {
      experimentWin:
        Math.random() < 0.5 ? ExperimentVariant.WithPreOnboardingScreen : ExperimentVariant.WithoutPreOnboardingScreen,
    },
  );

  const initialFormData = useMemo(() => {
    const queryParams = new URLSearchParams(location.search);
    const storedData = { ...localStorageData };

    return {
      firstName: storedData.firstName || queryParams.get('firstName') || '',
      middleName: storedData.middleName || queryParams.get('middleName') || '',
      lastName: storedData.lastName || queryParams.get('lastName') || '',
      email: storedData.email || queryParams.get('email') || '',
      phoneNumber:
        storedData.phoneNumber || (queryParams.get('phoneNumber') ? '1' + queryParams.get('phoneNumber') : ''),
      income: storedData.income || (queryParams.get('income') ? Number(queryParams.get('income')) * 12 : 0),
      address: storedData.address || queryParams.get('address') || '',
      suite: storedData.suite || queryParams.get('suite') || '',
      city: storedData.city || queryParams.get('city') || '',
      state: storedData.state || queryParams.get('state') || '',
      zip: storedData.zip || queryParams.get('zip') || '',
      amount: storedData.amount || 0,
      paymentPreference: storedData.paymentPreference || PAYMENT_PREFERENCE.fundNow,
      lgSubId: storedData.lgSubId || queryParams.get('lgSubId') || '',
      lgLeadId: storedData.lgLeadId || queryParams.get('lgLeadId') || '',
    };
  }, [localStorageData, location.search]);

  const {
    handleSubmit,
    watch,
    setValue,
    trigger,
    formState: { errors, isSubmitted, isSubmitting },
  } = useForm<CashSecuredApplicationForm>({
    reValidateMode: 'onChange',
    defaultValues: {
      ...initialFormData,
    },
    resolver: yupResolver(validationSchema),
  });

  const formData = {
    firstName: capitalize(watch('firstName')),
    lastName: capitalize(watch('lastName')),
    middleName: capitalize(watch('middleName')),
    phoneNumber: watch('phoneNumber'),
    email: watch('email'),
    address: watch('address'),
    suite: watch('suite'),
    city: watch('city'),
    state: watch('state'),
    zip: watch('zip'),
    income: watch('income'),
    paymentPreference: watch('paymentPreference'),
    amount: watch('amount'),
  };
  useEffect(() => {
    if (isSubmitted || isSubmitting) {
      trigger();
    }
  }, [
    isSubmitted,
    isSubmitting,
    trigger,
    formData.firstName,
    formData.lastName,
    formData.email,
    formData.phoneNumber,
    formData.amount,
    formData.paymentPreference,
    formData.income,
    formData.address,
    formData.suite,
    formData.city,
    formData.state,
    formData.zip,
    formData.middleName,
  ]);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [formData.amount]);

  useOnMount(() => {
    localStorage.removeItem(QUICK_APPRAISAL_LS_KEY);
    setLocalStorageData(initialFormData);
  });

  const handlePaymentSelection = (option: PaymentOptions) => {
    setValue('paymentPreference', option);
  };

  useOnMount(() => {
    const queryParams = new URLSearchParams(location.search);
    const trackingData = {
      firstName: queryParams.get('firstName') || formData.firstName,
      lastName: queryParams.get('lastName') || formData.lastName,
      middleName: queryParams.get('middleName') || formData.middleName,
      phoneNumber: queryParams.get('phoneNumber') || formData.phoneNumber,
      email: queryParams.get('email') || formData.email,
      address: queryParams.get('address') || formData.address,
      suite: queryParams.get('suite') || formData.suite,
      city: queryParams.get('city') || formData.city,
      state: queryParams.get('state') || formData.state,
      zip: queryParams.get('zip') || formData.zip,
      income: queryParams.get('income') ? Number(queryParams.get('income')) : formData.income,
      paymentPreference: queryParams.get('paymentPreference') || formData.paymentPreference,
      amount: queryParams.get('amount') ? Number(queryParams.get('amount')) : formData.amount,
      type: SECURITY_TYPE_AND_DETAILS,
      lgSubId: queryParams.get('lgSubId'),
      lgLeadId: queryParams.get('lgLeadId'),
    };

    datadogLogs.logger.error('CashSecuredApplication payload', {
      cashSecuredApplication: {
        trackingData,
      },
    });

    analytics.event(AnalyticsEvent.CashSecuredApplication.CASH_SECURED_PARTNER, trackingData, {
      experimentWin: abTestConfig.experimentWin,
    });
  });

  const handleFormSubmit = (data: CashSecuredApplicationForm) => {
    const amount = Number(data.amount.toString().replace(/[^0-9.-]+/g, ''));

    sendCode(data.email)
      .then(() => {
        toast('Code has been sent successfully!', {
          position: 'top-right',
          autoClose: 2500,
          hideProgressBar: true,
          closeOnClick: false,
          closeButton: false,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          theme: 'light',
        });
        setLocalStorageData({
          ...data,
          amount,
          type: SECURITY_TYPE_AND_DETAILS,
        });
        gotoCreateAccount();
      })
      .catch(error => {
        toast.error('An error occurred: ' + error.message, {
          position: 'top-right',
          autoClose: 2500,
          hideProgressBar: true,
          closeOnClick: false,
          closeButton: false,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          theme: 'light',
        });
      });
  };

  const handleFormErrors = () => {
    setIsEditing(true);
  };

  const isPersonalInfoPresent = requiredFields.some(field =>
    Boolean(initialFormData[field as keyof typeof initialFormData]),
  );
  const isEditableForm = !isEmpty(errors) || isEditing || !isPersonalInfoPresent;

  const renderCashSecuredApplication = (() => (
    <>
      <Wrapper1280 className="flex min-h-[80vh] flex-col items-center gap-4 py-4 md:py-8">
        <Stepper
          currentStep={{ mainStep: 2 }}
          steps={[
            { mainStep: 1, title: 'Congrats' },
            { mainStep: 2, title: 'Confirm details' },
            { mainStep: 3, title: 'Use your card' },
          ]}
        />
        <CashSecuredHeroSelection
          onGotoLogin={gotoLogin}
          setIsEditing={setIsEditing}
          formData={formData}
          isEditableForm={isEditableForm}
          errors={errors}
          setValue={setValue}
          onSubmit={handleSubmit(handleFormSubmit, handleFormErrors)}
          targetRef={targetRef}
          inputRef={inputRef}
          userName={initialFormData.firstName}
          onPaymentOptionClick={handlePaymentSelection}
          onAmountChange={amount => {
            setValue('amount', amount);
          }}
          amount={String(formData.amount)}
        />

        <div className="flex justify-around space-x-4">
          <BenefitItem icon={<CoinCash />} title="Get Unlimited 1% Cash Back" />
          <BenefitItem icon={<StepDone />} title="No Minimum Credit Score" />
          <BenefitItem icon={<PayNowLock />} title="Secured Payments" />
        </div>

        <StepsToGetCard steps={stepsData} />

        <div className="my-8 text-center">
          <Typography variant={isMobile ? 'subHeroSmall' : 'subHeroLarge'} className="!text-darkShade">
            What are the options?
          </Typography>
          <Typography variant="bodyLarge" className="!text-darkShade">
            Choose the most convenient way to fund your card
          </Typography>
        </div>

        <InfoCard
          title="Instant Deposit"
          subTitle="Fastest way to get the card"
          description={
            <Typography variant="bodyLarge" className="mb-6">
              Pay an upfront minimum $200 deposit and start spending today.
            </Typography>
          }
          subDescription="No extra fees needed"
          featureTitle="Instant Deposit Features"
          features={bankDepositFeatures}
          buttonText="Apply now"
          onApplyClick={() => {
            handleClick(PAYMENT_PREFERENCE.fundNow);
            handlePaymentSelection(PAYMENT_PREFERENCE.fundNow);
            window.scrollTo(0, 0);
          }}
        />

        <InfoCard
          title="Fund over time"
          subTitle="Your card, your rules, your pace"
          description={
            <Typography variant="bodyLarge" className="mb-6">
              For a one time $20 fee* you can schedule <br /> your payments at your own pace.
            </Typography>
          }
          subDescription="$20 upfront payment fee*"
          featureTitle="Fund over time Features"
          features={fundOverTimeFeatures}
          buttonText="Apply now"
          onApplyClick={() => {
            setActiveScreen(2);
            handlePaymentSelection(PAYMENT_PREFERENCE.fundOverTime);
            window.scrollTo(0, 0);
          }}
        />

        <div>
          <div className="my-8 text-center">
            <Typography variant={isMobile ? 'subHeroSmall' : 'subHeroLarge'} className="!text-darkShade">
              What our clients say
            </Typography>
            <VideoRow />
          </div>
        </div>

        <div className="w-full">
          <Typography variant="bodyLarge" className="mb-2 mt-2 text-left">
            FAQ
          </Typography>
          <Accordion items={accordionItems} />
        </div>
        {footer}
      </Wrapper1280>
    </>
  ))();

  const renderPreOnboardingScreenForCashSecuredApplication = (() => (
    <>
      <Wrapper1280 className="flex min-h-[80vh] flex-col items-center gap-4 py-4 md:py-8">
        <Stepper
          currentStep={{ mainStep: 1 }}
          steps={[
            { mainStep: 1, title: 'Congrats' },
            { mainStep: 2, title: 'Confirm details' },
            { mainStep: 3, title: 'Use your card' },
          ]}
        />

        <div className="mx-auto w-full px-2 sm:px-6 lg:px-8">
          <div className="mt-4 flex flex-col gap-2 text-left">
            <Typography
              variant="bodyLarge"
              className={cn('leading-[10px]', isMobile ? '!text-[20px]' : '!text-[32px]')}
            >
              <b>Congratulations {formData.firstName}!</b>
            </Typography>
            <Typography variant="bodyLarge" className={cn(isMobile ? '!text-[20px]' : '!text-[32px]')}>
              <b>You are ready to get the Pesto Credit Booster Card 🚀</b>
            </Typography>

            <div className="flex h-full w-full flex-col justify-around gap-2 rounded-2xl border-[3px] border-basicSoft bg-[#E0E2E4] bg-[radial-gradient(50%_50%_at_50%_50%,#FFF_0%,_rgba(255,255,255,0.00)100%)] px-[50px] py-[10px] md:px-[100px] md:py-[80px]">
              <Typography variant={isMobile ? 'bodyLarge' : 'subHeroSmall'} className="whitespace-nowrap text-left">
                <b>Here’s how to start today:</b>
              </Typography>
              <ul className={cn('list-disc', { 'text-[11.5px]': isMobile })}>
                <li>
                  Choose a Pesto plan that works for you <b>(plans start at $20)</b>
                </li>
                <li>
                  Set up auto pay and have <b>positive payment history</b> reported to credit bureaus
                </li>
                <li>
                  After 6 months your account will be reviewed for a credit line increase of <b>up to 100%</b>
                </li>
                <li>All of your deposits are available when you close your account in good standing</li>
                <li>
                  Get cash from ATM’s and <b>1% cashback</b> on all purchases and cash advances
                </li>
                <li>
                  Watch your <b>credit boosting happen</b> 🚀
                </li>
              </ul>
            </div>
          </div>

          <ButtonContainer
            rightButton={
              <Button onClick={() => setShowMainContent(true)} text="Get Started 🚀" buttonType={'primary'} />
            }
            leftButton={null}
          />
        </div>
      </Wrapper1280>
    </>
  ))();

  const determineContentToShow = () => {
    if (abTestConfig.experimentWin === 'without pre-onboarding screen') {
      return renderCashSecuredApplication;
    } else if (abTestConfig.experimentWin === 'with pre-onboarding screen' && showMainContent) {
      return renderCashSecuredApplication;
    } else {
      return renderPreOnboardingScreenForCashSecuredApplication;
    }
  };

  useEffect(() => {
    const currentConfig = JSON.parse(localStorage.getItem(CASH_SECURED_APPLICATION_AB_TEST) || '{}');
    if (currentConfig.experimentWin !== abTestConfig.experimentWin) {
      setAbTestConfig(abTestConfig);
    }
  }, [abTestConfig, setAbTestConfig]);

  return (
    <div className="mx-auto w-full">
      <Header />

      <div>{determineContentToShow()}</div>
    </div>
  );
};

export default CashSecuredApplication;
