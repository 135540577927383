import { useState } from 'react';

import { Typography } from 'src/components/Typography/Typography';
import { ModalFrameBottom, ModalFrameTop } from 'src/icons/components';

import DropdownSelect from '../../pages/ApplicationFlow/steps/QuickAppraisal/components/DropDownSelect';
import { CaratSlider, MaxCaratValue } from '../CaratSlider/CaratSlider';
import Modal from '../UI/Modal/Modal';

interface CaratDropdownProps {
  onChange: (value: any) => void;
  inputLabel?: string;
  isOpen?: boolean;
  alwaysOpen?: boolean;
  label?: string;
  maxCaratValue?: MaxCaratValue;
}
export const MAX_CARAT = 3;
const CaratDropdown = ({
  onChange,
  inputLabel,
  isOpen = false,
  alwaysOpen,
  label,
  maxCaratValue,
}: CaratDropdownProps) => {
  const [inputCaratValue, setInputCaratValue] = useState<number | ''>('');
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  return (
    <DropdownSelect
      selectedItem={inputCaratValue === MAX_CARAT ? `${String(inputCaratValue)}+ ct` : String(inputCaratValue)}
      isShowSelectedItem={true}
      icon={null}
      label={label || 'Carat'}
      isOpen={isOpen}
      onOpen={() => onChange(inputCaratValue)}
      liveUpdateValue={true}
      alwaysOpen={alwaysOpen}
    >
      {() => (
        <>
          <Typography className="mt-[6px] font-bold text-neutral" variant="bodySmall">
            {inputLabel}
          </Typography>
          <CaratSlider
            maxCaratValue={maxCaratValue || MaxCaratValue.THREE}
            onChange={(value: number) => {
              onChange(value);
              setInputCaratValue(value);
            }}
          />

          <div
            role="button"
            onClick={openModal}
            className="mt-[12px] cursor-pointer text-center text-sm text-action underline"
          >
            How big is carat?
          </div>

          <Modal isOpen={isModalOpen} onClose={closeModal}>
            <div className="flex flex-col items-center justify-center">
              <div className="flex h-[80vh] w-full flex-col items-center justify-center sm:h-[300px] sm:flex-row">
                <ModalFrameTop className="h-[150px] w-auto" />
                <ModalFrameBottom className="h-[150px] w-auto" />
              </div>
              <p className="mb-[16px] text-center text-base text-altWhite">
                Carat shape in reference to your <br /> pointer finger
              </p>
            </div>
          </Modal>
        </>
      )}
    </DropdownSelect>
  );
};

export default CaratDropdown;
