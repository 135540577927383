import * as React from 'react';
import type { SVGProps } from 'react';

interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgPersonalInfoIcon = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 15 17"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <rect width={1.5} height={11} y={6} fill="#101820" rx={0.75} />
    <rect width={1.5} height={14} x={4.5} y={3} fill="#101820" rx={0.75} />
    <rect width={1.5} height={17} x={9} fill="#101820" rx={0.75} />
    <rect width={1.5} height={15} x={13.5} y={2} fill="#101820" rx={0.75} />
  </svg>
);
export default SvgPersonalInfoIcon;
