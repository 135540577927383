import type { FC } from 'react';

import { Typography } from 'src/components/Typography/Typography';
import Button from 'src/components/UI/Button/Button';
import { Modal2 } from 'src/components/UI/Modal/Modal2';

import { Accordion, type AccordionItem } from './Accordion';

interface FAQModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const FAQModal: FC<FAQModalProps> = ({ isOpen, onClose }) => {
  const accordionItems: AccordionItem[] = [
    {
      title: 'How does a cash-secured credit card work?',
      content: (
        <div>
          &#x2022; A cash secured credit card is just like a regular credit card, except that it uses your own money as
          collateral.
          <br />
          &#x2022; You deposit a certain amount (minimum of $200) with Pesto Mastercard®, known as a security deposit.{' '}
          <br />
          &#x2022; Your security deposit will be held in a non-interest bearing deposit account while you use your
          credit card. <br />
          &#x2022; Your security deposit is returned to you when you close your credit card account and your entire
          balance has been paid. <br />
        </div>
      ),
    },

    {
      title: 'How does a Pesto Mastercard® credit line compare to what a cash secured credit card would offer?',
      content: (
        <div>
          &#x2022; For cash-secured cards: New cardholders will be able to access and use their digital card immediately
          via Apple Pay, Google Pay, or Samsung Pay. (approvals can happen within minutes!) A physical card will follow
          in the mail.
          <br />
          &#x2022; For item-secured cards: After you’ve sent your items to us, they’re reviewed by a Pesto team member.
          Our reviews are often completed within 24 hours of receiving the items. Then, you’ll receive an email to
          accept the card, and after acceptance, a digital card will become available for you to use immediately via
          Apple Pay, Google Pay, or Samsung Pay. To access your digital card, please download our mobile app via the
          applicable app store.
        </div>
      ),
    },
    {
      title: 'Is Pesto Mastercard® a secured credit card?',
      content: (
        <div>
          &#x2022; Yes, Pesto Mastercard® is a secured credit card, backed by either an asset, like jewelry, gemstones
          or precious metals, or it can be backed by cash. Each customer chooses the option that works best for them.
        </div>
      ),
    },
    {
      title: 'What will the credit limit be on my Pesto Mastercard®?',
      content: (
        <div>
          &#x2022; For cash-secured cards: Your credit limit for the Pesto Mastercard® cash secured card will be equal
          to your deposit amount. If you deposit $500 in cash, you will be issued a $500 secured credit card. Cash
          secured cards have a minimum credit limit & deposit of $200 and an initial maximum of $2,000. After six
          months, qualifying customers can deposit more cash and get a credit limit up to $10,000. <br />
          &#x2022;For item-secured cards: Your credit limit depends on the value of the items you want to back the card
          with. The more valuable the item(s), the greater the credit limit. Credit limits for item-secured cards range
          from $300 to $25,000. Use our quick and simple estimator tool to see what credit limit you item(s) can secure.{' '}
        </div>
      ),
    },
    {
      title: 'How can I fund a cash security deposit?',
      content: (
        <div>
          &#x2022; Currently cash-secured cards can be funded by a debit card. Though not available yet, soon customers
          will be able to fund their cash-secured cards using ACH transfers direct from their bank account.
        </div>
      ),
    },
    {
      title: 'Is my cash security deposit refundable',
      content: (
        <div>
          &#x2022; Yes, absolutely! Your security deposit is returned to you when you close your credit card account and
          your entire balance has been paid.
        </div>
      ),
    },
  ];

  if (!isOpen) return null;

  return (
    <Modal2 isOpen={isOpen} onClose={onClose}>
      <div className="flex flex-col gap-4 text-left">
        <Typography variant="headerSmall" className="font-bold">
          FAQ
        </Typography>

        <Accordion items={accordionItems} />

        <Button
          buttonType="secondary"
          text="Continue"
          onClick={() => {
            onClose();
          }}
        />
      </div>
    </Modal2>
  );
};
