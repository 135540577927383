import { ReactAppEnvIsNotProd } from 'src/constants/global';
import { IGNORE_SEGMENT_AND_PESTO_TRACKING_DEV_MODE_LS_KEY } from 'src/constants/localStorageKeys';
import { getAllUrlParams } from 'src/util/getAllUrlParams';

import type { AnalyticsHookProps, AnalyticsHookReturn } from '../AnalyticsProvider';

export const segmentAnalytics = (props: AnalyticsHookProps): AnalyticsHookReturn => {
  const urlParams = getAllUrlParams();
  const { currentUser, heapCookie, marketingTracking } = props;
  const isSegmentTrackingIgnoredForDevMode =
    localStorage.getItem(IGNORE_SEGMENT_AND_PESTO_TRACKING_DEV_MODE_LS_KEY) === 'true';

  const segmentIdent = () => {
    if (currentUser && currentUser?.id) {
      window.analytics?.identify(currentUser.id, {
        name: currentUser?.displayName ?? '',
        email: currentUser?.email ?? '',
      });
    }
  };

  const event = (eventName: string, eventProperties: any) => {
    if (!isSegmentTrackingIgnoredForDevMode && ReactAppEnvIsNotProd) return;

    segmentIdent();
    const eventPropertiesWithMarketingParams = {
      eventProperties,
      urlParams: urlParams,
      ...marketingTracking,
      heapCookie: heapCookie,
    };
    window.analytics?.track(eventName, eventPropertiesWithMarketingParams);
  };

  const page = (pageName?: string, eventProperties?: any) => {
    if (!isSegmentTrackingIgnoredForDevMode && ReactAppEnvIsNotProd) return;

    const eventPropertiesWithMarketingParams = {
      eventProperties,
      ...marketingTracking,
      urlParams: urlParams,
      heapCookie: heapCookie,
    };
    segmentIdent();
    window.analytics?.page(pageName, eventPropertiesWithMarketingParams);
  };

  return { page, event };
};
