import * as React from 'react';
import type { SVGProps } from 'react';

interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgThreeStoneRingStyle = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <circle cx={12} cy={15} r={6.5} stroke="#101820" strokeLinecap="round" strokeWidth={1.5} />
    <path
      stroke="#101820"
      strokeWidth={1.5}
      d="M10.028 6.14 12 7.574l1.972-1.432-.754-2.318h-2.436l-.754 2.318ZM14.278 8.14l1.972 1.433 1.971-1.432-.753-2.318h-2.436l-.754 2.318ZM5.779 8.14 7.75 9.574 9.721 8.14l-.753-2.318H6.532L5.779 8.14Z"
    />
  </svg>
);
export default SvgThreeStoneRingStyle;
