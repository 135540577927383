import { cn } from 'src/util/cn';

import { Typography } from '../Typography/Typography';

interface DisclaimerCreditScoreProps {
  className?: string;
}
export const DisclaimerCreditScore = (props: DisclaimerCreditScoreProps) => {
  const { className } = props;
  const rootStyle = cn(className, 'px-2 py-6');
  return (
    <div className={rootStyle}>
      <Typography variant="captionSmall" className="text-neutral">
        Completing Pesto’s quick appraisal to get a credit estimate <br />
        <span className="font-bold underline">will not</span> impact your credit score.
      </Typography>
    </div>
  );
};
