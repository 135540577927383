import { Typography } from 'src/components/Typography/Typography';

export const stepsData = [
  {
    title: 'No risk application',
    description: 'Start with filling in your personal data. The connection is encrypted and secured with SSL protocol.',
  },
  {
    title: 'Fund your Pesto Secured Mastercard®',
    description: (
      <Typography variant="bodyLarge" className="text-center">
        With a minimum $200 deposit. You can choose an option to fund your card: <br /> - Deposit now <br /> - Fund over
        time
      </Typography>
    ),
  },
  {
    title: 'Spend with your new card',
    description:
      'Start spending immediately. After the completion of the application you’ll get a digital card. And your physical card will arrive soon.',
  },
];

export const bankDepositFeatures = [
  { text: 'Fast and easy application' },
  { text: 'No minimum credit score' },
  { text: 'Get 1% Cash back' },
  { text: 'Convenient and easy application' },
  { text: 'Account reviewed after 6 months for credit line increase' },
  {
    text: (
      <Typography variant="bodyLarge">
        Start spending immediately. <br />
        After the completion of the application you’ll get a digital card. And <br /> your physical card will arrive
        soon.
      </Typography>
    ),
  },
];

export const fundOverTimeFeatures = [
  { text: 'You will have one year to fund your deposit' },
  { text: 'No minimum credit score' },
  { text: 'Get 1% Cash back' },
  { text: 'Convenient and easy application' },
  { text: 'Account reviewed after 6 months for credit line increase' },
  {
    text: (
      <Typography variant="bodyLarge">
        Start spending immediately. <br />
        After the completion of the application you’ll get a digital card. And <br /> your physical card will arrive
        soon.
      </Typography>
    ),
  },
];

export const accordionItems = [
  {
    title: 'How does a cash-secured credit card work?',
    content: (
      <Typography variant="body" className="text-left">
        - A cash secured credit card is just like a regular credit card, except that it uses your own money as
        collateral. <br /> - You deposit a certain amount (minimum of $200) with Pesto Mastercard®, known as a security
        deposit. <br /> - Your security deposit will be held in a non-interest bearing deposit account while you use
        your credit card. <br /> - Your security deposit is returned to you when you close your credit card account and
        your entire balance has been paid.
      </Typography>
    ),
  },
  {
    title: 'How does a Pesto Mastercard® credit line compare to what a cash secured credit card would offer?',
    content: (
      <Typography variant="body" className="text-left">
        - For cash-secured cards: New cardholders will be able to access and use their digital card immediately via
        Apple Pay, Google Pay, or Samsung Pay. (approvals can happen within minutes!) A physical card will follow in the
        mail. <br />- For item-secured cards: After you’ve sent your items to us, they’re reviewed by a Pesto team
        member. Our reviews are often completed within 24 hours of receiving the items. Then, you’ll receive an email to
        accept the card, and after acceptance, a digital card will become available for you to use immediately via Apple
        Pay, Google Pay, or Samsung Pay. To access your digital card, please download our mobile app via the applicable
        app store.
      </Typography>
    ),
  },
  {
    title: 'Is Pesto Mastercard® a secured credit card?',
    content: (
      <Typography variant="body" className="text-left">
        -Yes, Pesto Mastercard® is a secured credit card, backed by either an asset, like jewelry, gemstones or precious
        metals, or it can be backed by cash. Each customer chooses the option that works best for them.
      </Typography>
    ),
  },
  {
    title: 'What will the credit limit be on my Pesto Mastercard®?',
    content: (
      <Typography variant="body" className="text-left">
        - For cash-secured cards: Your credit limit for the Pesto Mastercard® cash secured card will be equal to your
        deposit amount. If you deposit $500 in cash, you will be issued a $500 secured credit card. Cash secured cards
        have a minimum credit limit & deposit of $200 and an initial maximum of $2,000. After six months, qualifying
        customers can deposit more cash and get a credit limit up to $10,000. <br /> - For item-secured cards: Your
        credit limit depends on the value of the items you want to back the card with. The more valuable the item(s),
        the greater the credit limit. Credit limits for item-secured cards range from $300 to $25,000. Use our quick and
        simple estimator tool to see what credit limit you item(s) can secure.
      </Typography>
    ),
  },
  {
    title: 'How can I fund a cash security deposit?',
    content: (
      <Typography variant="body" className="text-left">
        - Currently cash-secured cards can be funded by a debit card. Though not available yet, soon customers will be
        able to fund their cash-secured cards using ACH transfers direct from their bank account.
      </Typography>
    ),
  },
  {
    title: 'Is my cash security deposit refundable?',
    content: (
      <Typography variant="body" className="text-left">
        - Yes, absolutely! Your security deposit is returned to you when you close your credit rd account and your
        entire balance has been paid.
      </Typography>
    ),
  },
];

export const footer = (
  <div className={'flex w-full flex-col justify-center gap-2 p-4'}>
    <Typography variant="caption">Deposit accounts and cards issued by Continental Bank, Member FDIC</Typography>
    <Typography variant="caption">
      *You are required to pay a separate $20 non-refundable processing fee if you choose to fund your minimum $200
      security deposit over time (within 1 year). Only in the event that an active bankruptcy appears during your credit
      check, your $20 processing fee will be refunded.
    </Typography>
  </div>
);
