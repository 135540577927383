import { Dropdown } from 'src/components/UI/Form/Dropdown/Dropdown';

import { TextArea } from '../../../../../../components/UI/Form/TextArea/TextArea';
import { OTHER_ITEM_DESCRIPTION_MAX_LENGTH } from '../../../../../../constants/application';

type FormError = {
  message?: string;
};

interface Errors {
  other?: {
    otherItem?: FormError;
    otherDescription?: FormError;
  };
  email?: FormError;
  photos?: FormError;
}

interface OtherFormProps {
  item: string;
  setItem: (item: string) => void;
  description: string;
  setOtherDescription: (other: string) => void;
  errors: Errors;
}

const otherItemNameList = [
  { label: 'Art', value: 'Art' },
  { label: 'Books & Other Print', value: 'Books & Other Print' },
  { label: 'Coins', value: 'Coins' },
  { label: 'Collectibles', value: 'Collectibles' },
  { label: 'Decor & Sculptures', value: 'Decor & Sculptures' },
  { label: 'Electronics', value: 'Electronics' },
  { label: 'Jewelry (Other)', value: 'Jewelry (Other)' },
  { label: 'Luxury Goods', value: 'Luxury Goods' },
  { label: 'Sports Memorabilia', value: 'Sports Memorabilia' },
  { label: 'Watches', value: 'Watches' },
  { label: 'Wine & Spirits', value: 'Wine & Spirits' },
  { label: 'Other', value: 'Other' },
];

export const OtherForm = (props: OtherFormProps) => {
  const { description, setOtherDescription, setItem, errors } = props;

  const handleItemChange = (option: any) => {
    setItem(option.value);
  };

  return (
    <div className={'flex flex-col gap-4 text-left'}>
      <div className="bg-white">
        <Dropdown
          options={otherItemNameList.map(item => ({ value: item.value, text: item.label }))}
          onChange={handleItemChange}
          label="Category"
          error={Boolean(errors?.other?.otherItem)}
          placeholder={'What is it?'}
          isSearchable
        />
      </div>
      <div className="text-left text-sm text-danger">{errors.other?.otherItem?.message}</div>

      <TextArea
        onChange={(e: any) => {
          setOtherDescription(e.target.value);
        }}
        maxLength={OTHER_ITEM_DESCRIPTION_MAX_LENGTH}
        type="text"
        defaultValue={description}
        error={Boolean(errors?.other?.otherDescription)}
        label="Item description"
        placeholder="What do you know about it?"
        caption={'Please provide any details you might know about this item. ex. year, collection, make, model, etc'}
      />
      <div className="text-left text-sm text-danger">{errors.other?.otherDescription?.message}</div>
    </div>
  );
};
