import clsx from 'clsx';

import type { StoneType } from 'src/__generated__/graphql/api';
import { ButtonContainer } from 'src/components/ButtonContainer/ButtonContainer';
import { Divider } from 'src/components/Devider/Devider';
import { BackButton } from 'src/components/UI/Button/BackButton';
import { diamondTypes, gemstoneTypes } from 'src/data/stones';

import { ListSelection } from '../../../../../../components/ListSelection/ListSelection';
import { Typography } from '../../../../../../components/Typography/Typography';

interface TypeOfStonesScreenProps {
  className?: string;
  onNext: (data: StoneType, stoneValueType: StoneType) => void;
  handleBack: () => void;
}

const TypeOfStones = (props: TypeOfStonesScreenProps) => {
  const { className, onNext, handleBack } = props;
  const rootStyles = clsx('flex flex-col gap-4 max-w-[400px] w-full', className);

  return (
    <div className={rootStyles}>
      <Typography variant={'headerMedium'}>What type of stones?</Typography>
      <Divider gap={2} className="w-[70%] self-center">
        <Typography variant={'bodySmall'} className={'text-neutral'}>
          Diamonds
        </Typography>
      </Divider>
      <div className="flex w-full flex-col justify-center gap-2 text-center">
        {diamondTypes.map((type, index) => (
          <ListSelection
            key={index}
            text={type.text}
            leftIcon={type.icon}
            onClick={() => onNext(type.value, type.fabric)}
          />
        ))}
      </div>

      <Divider gap={2} className="w-[70%] self-center">
        <Typography variant={'bodySmall'} className={'text-neutral'}>
          Gemstones
        </Typography>
      </Divider>

      <div className="flex w-full flex-col justify-center gap-2 text-center">
        {gemstoneTypes.map((type, index) => (
          <ListSelection
            key={index}
            text={type.text}
            leftIcon={type.icon}
            onClick={() => onNext(type.value, type.fabric)}
          />
        ))}
      </div>

      <ButtonContainer leftButton={<BackButton onClick={handleBack} rightButtonExist={false} />} />
    </div>
  );
};

export default TypeOfStones;
