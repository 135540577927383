import { RetryLink } from '@apollo/client/link/retry';

export const useHandleRetries = () => {
  return new RetryLink({
    delay: {
      initial: 300,
      max: 10000,
      jitter: true,
    },
    attempts: (count, operation, error) => {
      const maxRetryAttempts = 5;
      const retryableStatusCodes = [500, 502, 503, 504];
      const isRetryAllowed = error && retryableStatusCodes.includes(error.statusCode);

      const isRetryAllowedAndCountLessThanMax = isRetryAllowed && count < maxRetryAttempts;

      if (isRetryAllowedAndCountLessThanMax) {
        console.warn('[errorNetwork] - retrying', operation.operationName, error);
      } else {
        console.warn('[errorNetwork] - not retrying', operation.operationName, error, error.statusCode);
      }
      return isRetryAllowedAndCountLessThanMax;
    },
  });
};
