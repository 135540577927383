type User = {
  firstName: string;
  lastName: string;
};

export const extractInitials = (user: User): string => {
  const nameInitial = user.firstName.charAt(0).toUpperCase();
  const lastNameInitial = user.lastName.charAt(0).toUpperCase();

  return `${nameInitial}${lastNameInitial}`;
};
