import clsx from 'clsx';
import React from 'react';

import { Typography } from 'src/components/Typography/Typography';

interface ListProps {
  items: string[];
  className?: string;
  iconImageSrc?: string[];
}

export const List: React.FC<ListProps> = ({ items, className, iconImageSrc = [] }) => {
  return (
    <div className={clsx('space-y-2', className)}>
      {items.map((item, index) => (
        <div
          key={index}
          className={clsx(
            'flex items-start space-x-2',
            {
              'items-center': iconImageSrc,
            },
            className,
          )}
        >
          {iconImageSrc[index] ? <img src={iconImageSrc[index]} alt="icon" /> : <span>✅</span>}
          <Typography variant="bodySmall" className="!font-semibold">
            {item}
          </Typography>
        </div>
      ))}
    </div>
  );
};
