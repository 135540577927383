import { ButtonContainer } from 'src/components/ButtonContainer/ButtonContainer';
import { BackButton } from 'src/components/UI/Button/BackButton';

import { StoneType } from '../../../../../../__generated__/graphql/api';
import { Divider } from '../../../../../../components/Devider/Devider';
import { ListSelection } from '../../../../../../components/ListSelection/ListSelection';
import { Typography } from '../../../../../../components/Typography/Typography';
import { diamondTypes, gemstoneTypes } from '../../../../../../data/stones';
import SvgNoneIcon from '../../../../../../icons/components/stones/NoneIcon';
import { ScreenQuickAppraisal } from '../../components/ScreenQuickAppraisal';

interface RingCenterStoneScreenProps {
  className?: string;
  hasCenterStone: boolean;
  onNext: (data: StoneType) => void;
  handleBack: () => void;
}

export const RingCenterStoneScreen = (props: RingCenterStoneScreenProps) => {
  const { className, onNext, hasCenterStone = false, handleBack } = props;

  const screenTitle = hasCenterStone ? 'What’s your center stone?' : 'Does your ring have any stones?';

  return (
    <ScreenQuickAppraisal className={className} title={screenTitle}>
      <div className=" flex w-full flex-col justify-center gap-2 text-center">
        <Divider gap={2} className="w-[70%] self-center">
          <Typography variant={'bodySmall'} className={'text-neutral'}>
            Diamonds
          </Typography>
        </Divider>
        {diamondTypes.map((diamond, index) => {
          return (
            <ListSelection
              onClick={() => onNext(diamond.value)}
              leftIcon={diamond.icon}
              text={diamond.text}
              key={index}
            />
          );
        })}
        <Divider gap={2} className="w-[70%] self-center">
          <Typography variant={'bodySmall'} className={'text-neutral'}>
            Gemstones
          </Typography>
        </Divider>
        {gemstoneTypes.map((diamond, index) => {
          return (
            <ListSelection
              onClick={() => onNext(diamond.value)}
              leftIcon={diamond.icon}
              text={diamond.text}
              key={index}
            />
          );
        })}
        {!hasCenterStone && (
          <>
            <Divider gap={0} className="w-[70%] self-center" />
            <ListSelection
              // TODO: Need a "None" StoneType value
              onClick={() => onNext(StoneType.DiamondUnknown)}
              leftIcon={<SvgNoneIcon />}
              text={'None'}
            />
          </>
        )}

        <ButtonContainer leftButton={<BackButton onClick={handleBack} rightButtonExist={false} />} />
      </div>
    </ScreenQuickAppraisal>
  );
};
