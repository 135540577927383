import React from 'react';

import { CreditApplicationStatus } from 'src/__generated__/graphql/api';
import Button from 'src/components/UI/Button/Button';
import { useIsMobile } from 'src/hooks/useIsMobile';
import { useNavigateOnStatus } from 'src/hooks/useNavigateOnStatus';
import { CardIcon } from 'src/icons/components';
import { cn } from 'src/util/cn';

interface AppraisalStatusMessageProps {
  status: CreditApplicationStatus | null | undefined;
}

const AppraisalStatusMessage: React.FC<AppraisalStatusMessageProps> = ({ status }) => {
  const isMobile = useIsMobile();

  const navigateOnStatus = useNavigateOnStatus();

  const statusesToShowBanner = [
    CreditApplicationStatus.AppraisalInProgress,
    CreditApplicationStatus.Created,
    CreditApplicationStatus.Delivered,
    CreditApplicationStatus.FinInfoNeeded,
    CreditApplicationStatus.ItemUnderReview,
    CreditApplicationStatus.OfferAccepted,
    CreditApplicationStatus.OfferDecisionNeeded,
    CreditApplicationStatus.Prequalified,
    CreditApplicationStatus.PrequalInfoGiven,
    CreditApplicationStatus.PrequalItemValued,
    CreditApplicationStatus.Shipped,
    CreditApplicationStatus.VerifyDoc,
    CreditApplicationStatus.VerifyPayment,
  ];

  if (status == null || !statusesToShowBanner.includes(status)) {
    return null;
  }

  return (
    <div className="mb-4 flex w-full justify-between rounded-md border-lightPowder bg-powder p-4 text-center font-bold text-basicSoft">
      {!isMobile && <CardIcon className="!h-[78px] !w-[125px]" />}
      <div
        className={cn('flex flex-col items-center justify-center', {
          'w-full': isMobile,
          'w-[70%]': !isMobile,
        })}
      >
        {' '}
        <p className="mb-2">You have an application in progress</p>
        <Button text="Finish your application" buttonType="primary" onClick={navigateOnStatus} />
      </div>
    </div>
  );
};

export default AppraisalStatusMessage;
