import clsx from 'clsx';

import type { InputSize, TextAlign } from './FormTypes';

export interface InputStyleProps {
  className?: string;
  isErrorVisible: boolean;
  readOnly?: boolean;
  inputSize?: InputSize;
  textAlign?: TextAlign;
}

export const generateInputStyles = ({
  className,
  isErrorVisible,
  readOnly,
  inputSize = 'small',
  textAlign = 'left',
}: InputStyleProps) => {
  const align: Record<TextAlign, string> = {
    left: 'text-left',
    center: 'text-center',
    right: 'text-right',
  };

  return clsx(
    className,
    'p-[8px] relative !rounded-t-sm placeholder:text-neutralSoft focus:outline-none focus:border-basicSoft width-full border-t border-l border-r !border-b-2 border-b-neutralSoft',
    {
      'border-b-danger border-t-white border-l-white border-r-white': isErrorVisible,
      'border-t-white border-l-white border-r-white border-b-neutralSoft': !isErrorVisible,
      'bg-transparent !border-transparent focus:outline-none  focus:!border-transparent disabled:text-basic disabled:opacity-100':
        readOnly,
      'text-base': inputSize === 'small',
      'text-3xl': inputSize === 'big',
    },
    align[textAlign],
  );
};
