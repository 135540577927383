import { useCallback, useMemo, useState, type SetStateAction } from 'react';

import type {
  AssetType,
  Brand,
  NecklaceInput,
  NecklaceMetalType,
  NecklaceThickness,
  SecondaryStoneInput,
} from 'src/__generated__/graphql/api';
import { GetItemCartDocument, useAddNecklaceCurrentUserMutation } from 'src/__generated__/graphql/api';
import { QUICK_APPRAISAL_LS_KEY } from 'src/constants/localStorageKeys';
import { useGoRoutes } from 'src/hooks/useGoRoutes';

import { apolloClient } from '../../../../../../api/Apollo';
import { useLocalStorage } from '../../../../../../hooks/useLocalStorage';
import { useUser } from '../../../../../../hooks/useUser';
import ImageDetailsForm from '../../components/ImageDetailsForm/ImageDetailsForm';
import JewelryBrandsForm from '../../components/JewelryBrandsForm/JewelryBrandsForm';
import { SecondaryStonesDetailsForm } from '../../components/SecondaryStonesDetailsForm/SecondaryStonesDetailsForm';

import Length from './Length';
import MetalType from './MetalType';
import NecklaceThicknessScreen from './NecklaceThicknessScreen';

export type SetItemType = (itemType: AssetType | undefined) => void;

interface NecklaceProps {
  setItemType: SetItemType;
}

interface NecklacePayload {
  necklaceInput: NecklaceInput;
  photoLinks?: (string | undefined)[];
}

const NECKLACE_SCREENS = {
  NECKLACE_THICKNESS: 0,
  LENGTH: 1,
  METAL_TYPE: 2,
  SECONDARY_STONES: 3,
  NECKLACE_BRAND: 4,
  MORE_DETAILS: 5,
};

export const Necklace = ({ setItemType }: NecklaceProps) => {
  const [innerScreen, setInnerScreen] = useState<number>(0);
  const [, setPreviousScreen] = useState<number>(0);
  const { goToSignUp, gotoItemCart, goBack } = useGoRoutes();
  const { user } = useUser();
  const [, setLocalStorage] = useLocalStorage<NecklacePayload | null>(QUICK_APPRAISAL_LS_KEY, {} as NecklacePayload);

  const [addNecklaceForCurrentUser, { loading: isLoading }] = useAddNecklaceCurrentUserMutation({
    refetchQueries: [{ query: GetItemCartDocument }],
    errorPolicy: 'all',
    client: apolloClient,
  });

  const [payload, setPayload] = useState<NecklaceInput>({
    necklaceThickness: undefined,
    lengthInInches: undefined,
    necklaceMetalType: undefined,
    brand: undefined,
    secondaryStoneInputs: [],
  });

  const setScreen = useCallback(
    (newScreen: SetStateAction<number>) => {
      setPreviousScreen(innerScreen);
      setInnerScreen(newScreen);
    },
    [innerScreen],
  );

  const handleThickness = useCallback(
    (thickness: NecklaceThickness) => {
      setScreen(NECKLACE_SCREENS.LENGTH);
      setPayload(prevPayload => ({ ...prevPayload, necklaceThickness: thickness }));
    },
    [setScreen],
  );

  const handleNecklaceLength = useCallback(
    (length: number) => {
      setScreen(NECKLACE_SCREENS.METAL_TYPE);
      setPayload(prevPayload => ({ ...prevPayload, lengthInInches: length }));
    },
    [setScreen],
  );

  const handleNecklaceMetalType = useCallback(
    (metalType: NecklaceMetalType) => {
      setScreen(NECKLACE_SCREENS.SECONDARY_STONES);
      setPayload(prevPayload => ({ ...prevPayload, necklaceMetalType: metalType }));
    },
    [setScreen],
  );

  const handleSecondaryStones = useCallback(
    (secondaryStones: SecondaryStoneInput[] | undefined) => {
      setScreen(NECKLACE_SCREENS.NECKLACE_BRAND);
      setPayload(prevPayload => ({ ...prevPayload, secondaryStoneInputs: secondaryStones }));
    },
    [setScreen],
  );

  const handleNecklaceBrand = useCallback(
    (brand: Brand) => {
      setScreen(NECKLACE_SCREENS.MORE_DETAILS);
      setPayload(prevPayload => ({ ...prevPayload, brand: brand }));
    },
    [setScreen],
  );

  const handlePayload = useCallback(
    (images: string[] | undefined) => {
      const mainPayload: NecklacePayload = {
        necklaceInput: payload,
        photoLinks: images,
      };

      if (!user) {
        setLocalStorage(mainPayload);
        goToSignUp();
      } else {
        addNecklaceForCurrentUser({
          variables: {
            creditApplicationId: user.creditApplication?.id || '',
            necklaceInput: payload,
            photoLinks: images,
          },
        })
          .then(res => {
            if (res.errors) {
              console.error(res.errors);
            } else {
              gotoItemCart();
            }
          })
          .catch(error => {
            console.error(error);
          });
      }
    },
    [addNecklaceForCurrentUser, goToSignUp, gotoItemCart, payload, setLocalStorage, user],
  );

  const handleBack = useCallback(() => {
    const screenMap = {
      1: NECKLACE_SCREENS.NECKLACE_THICKNESS,
      2: NECKLACE_SCREENS.LENGTH,
      3: NECKLACE_SCREENS.METAL_TYPE,
      4: NECKLACE_SCREENS.SECONDARY_STONES,
      5: NECKLACE_SCREENS.NECKLACE_BRAND,

      default: NECKLACE_SCREENS.NECKLACE_THICKNESS,
    };

    setInnerScreen(prevInnerScreen => {
      const mappedValue = screenMap[prevInnerScreen as keyof typeof screenMap];

      if (mappedValue === undefined) {
        setTimeout(() => setItemType(undefined), 0);
        return screenMap['default'];
      }

      return mappedValue;
    });
  }, [setItemType]);

  const renderScreens = useMemo(
    () => ({
      [NECKLACE_SCREENS.NECKLACE_THICKNESS]: (
        <NecklaceThicknessScreen
          handleBack={() => {
            goBack();
            setItemType(undefined);
          }}
          onNext={handleThickness}
        />
      ),
      [NECKLACE_SCREENS.LENGTH]: <Length handleBack={handleBack} onNext={handleNecklaceLength} />,
      [NECKLACE_SCREENS.METAL_TYPE]: <MetalType handleBack={handleBack} onNext={handleNecklaceMetalType} />,
      [NECKLACE_SCREENS.SECONDARY_STONES]: (
        <SecondaryStonesDetailsForm
          title="What stones does your necklace have?"
          onNext={handleSecondaryStones}
          handleBack={handleBack}
        />
      ),

      [NECKLACE_SCREENS.NECKLACE_BRAND]: (
        <JewelryBrandsForm
          title={'What’s your necklace’s brand?'}
          onNext={handleNecklaceBrand}
          handleBack={handleBack}
        />
      ),
      [NECKLACE_SCREENS.MORE_DETAILS]: (
        <ImageDetailsForm
          listItems={['Photo of necklace', 'Certification', 'Appraisal']}
          onBack={handleBack}
          onNext={handlePayload}
          loadingMutation={isLoading}
        />
      ),
    }),
    [
      handleThickness,
      handleBack,
      handleNecklaceLength,
      handleNecklaceMetalType,
      handleSecondaryStones,
      handleNecklaceBrand,
      handlePayload,
      isLoading,
      goBack,
      setItemType,
    ],
  );

  return <>{renderScreens[innerScreen as keyof typeof renderScreens]}</>;
};
