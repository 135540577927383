import * as React from 'react';
import type { SVGProps } from 'react';

interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgOldmineIcon = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 22 22"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      stroke="#101820"
      strokeWidth={1.5}
      d="m5.876 20.306-.135.738.135-.738a.25.25 0 0 1-.11-.049l-3.992-3.105a.25.25 0 0 1-.093-.16l-.915-5.948a.25.25 0 0 1 0-.079l.916-5.493a.25.25 0 0 1 .08-.146l4.027-3.58-.483-.542.483.543a.25.25 0 0 1 .121-.06L10.963.77a.25.25 0 0 1 .086 0l5.512.918a.25.25 0 0 1 .135.07l3.547 3.547a.25.25 0 0 1 .07.135l.92 5.526a.253.253 0 0 1 .001.08l-.926 6.017a.25.25 0 0 1-.07.14l-3.037 3.036a.25.25 0 0 1-.139.07l-6.01.925a.252.252 0 0 1-.083 0l-5.093-.927Z"
    />
  </svg>
);
export default SvgOldmineIcon;
