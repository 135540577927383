import type { IconProps } from './IconHelper';

export const IconDiamond = (props: IconProps) => {
  const { className, stroke } = props;
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.7761 4.09581L20.3005 6.50545C20.7303 6.91571 20.8119 7.57182 20.4957 8.07486L13.0583 19.907C12.5682 20.6867 11.4318 20.6867 10.9417 19.907L3.50434 8.07486C3.18814 7.57182 3.26975 6.91571 3.69954 6.50545L6.22394 4.09581C6.45647 3.87384 6.76557 3.75 7.08703 3.75H12H16.913C17.2344 3.75 17.5435 3.87384 17.7761 4.09581Z"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};
