import { useCallback, useState } from 'react';

import type { AssetType, WatchInput } from '../../../../../../__generated__/graphql/api';
import { Brand, WatchCondition, useAddWatchCurrentUserMutation } from '../../../../../../__generated__/graphql/api';
import { QUICK_APPRAISAL_LS_KEY } from '../../../../../../constants/localStorageKeys';
import { useGoRoutes } from '../../../../../../hooks/useGoRoutes';
import { useUser } from '../../../../../../hooks/useUser';
import { handleError } from '../../../../../../util/errorTracking';

import { WatchBrandScreen } from './WatchBrandScreen';
import { WatchConditionScreen } from './WatchConditionScreen';
import { WatchDetailsScreen } from './WatchDetailsScreen';

export interface WatchDetailsPayload {
  hasOriginalBox: boolean;
  hasOriginalPapers: boolean;
  photoLinks: (string | undefined)[];
}

interface WatchPayload {
  watchInput: WatchInput;
  photoLinks: (string | undefined)[];
}

interface WatchProps {
  setItemType: (itemType: AssetType | undefined) => void;
}

export const Watch = (props: WatchProps) => {
  const { setItemType } = props;
  const { user } = useUser();
  const { goToSignUp, gotoItemCart, goBack } = useGoRoutes();
  const [watchInfo, setWatchInfo] = useState<{
    watchBrand: Brand;
    watchCondition: WatchCondition;
  }>({
    watchBrand: Brand.Other,
    watchCondition: WatchCondition.Other,
  });
  const [innerScreen, setInnerScreen] = useState(0);

  const [addWatchMutation, { loading }] = useAddWatchCurrentUserMutation({ errorPolicy: 'all' });

  const handleBack = () => {
    if (innerScreen === 0) {
      return setItemType(undefined);
    }
    setInnerScreen(innerScreen - 1);
  };

  const handleBrand = (brand: Brand) => {
    setWatchInfo({
      ...watchInfo,
      watchBrand: brand,
    });
    setInnerScreen(1);
  };

  const handleCondition = (condition: WatchCondition) => {
    setWatchInfo({
      ...watchInfo,
      watchCondition: condition,
    });
    setInnerScreen(2);
  };

  const setPayload = (watchPayload: WatchPayload) => {
    localStorage.setItem(QUICK_APPRAISAL_LS_KEY, JSON.stringify({ ...watchPayload }));
  };

  const processWatchSubmit = useCallback(
    (watchPayload: WatchPayload) => {
      if (!user) {
        setPayload(watchPayload);
        return goToSignUp();
      }
      return addWatchMutation({
        variables: {
          creditApplicationId: user.creditApplication?.id || '',
          watchInput: watchPayload.watchInput,
          photoLinks: watchPayload.photoLinks as string[],
        },
      })
        .then(res => {
          if (res.errors) {
            return handleError(res.errors[0]);
          } else {
            gotoItemCart();
          }
        })
        .catch(error => {
          handleError(error);
        });
    },
    [addWatchMutation, goToSignUp, gotoItemCart, user],
  );

  const handleDetails = useCallback(
    (details: WatchDetailsPayload) => {
      const watchPayload: WatchPayload = {
        watchInput: {
          brand: watchInfo.watchBrand,
          watchCondition: watchInfo.watchCondition,
          hasOriginalBox: details.hasOriginalBox,
          hasOriginalPapers: details.hasOriginalPapers,
        },
        photoLinks: details.photoLinks,
      };
      processWatchSubmit(watchPayload);
    },
    [processWatchSubmit, watchInfo.watchBrand, watchInfo.watchCondition],
  );

  const renderScreens = {
    0: (
      <WatchBrandScreen
        handleBack={() => {
          goBack();
          setItemType(undefined);
        }}
        onNext={handleBrand}
      />
    ),
    1: <WatchConditionScreen handleBack={handleBack} onNext={handleCondition} />,
    2: <WatchDetailsScreen handleBack={handleBack} onNext={handleDetails} isLoading={loading} />,
  };

  return <>{renderScreens[innerScreen as keyof typeof renderScreens]}</>;
};
