import type { Card } from '../../__generated__/graphql/api';
import SvgCardIcon from '../../icons/components/CardIcon';
import { formatToCurrency } from '../../util/formatterHelper';
import { Typography } from '../Typography/Typography';
import Button from '../UI/Button/Button';
import { Modal2 } from '../UI/Modal/Modal2';

interface CardDetailsModalProps {
  isOpen: boolean;
  onClose: () => void;
  card: Card;
}

export const CardDetailsModal = (props: CardDetailsModalProps) => {
  const { isOpen, onClose, card } = props;
  return (
    <>
      {isOpen && (
        <Modal2 isOpen={isOpen} onClose={onClose} className="w-full">
          <div className="flex flex-col items-center gap-4">
            <Typography variant="headerSmall">Your Card Details</Typography>
            <SvgCardIcon className="aspect-video h-auto w-[70%]" />
            <div className="flex flex-col gap-1">
              <Typography variant="body">Current Credit Limit:</Typography>
              <Typography variant="bodyLarge">
                <strong>{formatToCurrency(card.creditLimit || 0)}</strong>
              </Typography>{' '}
            </div>
            <div className="flex flex-col gap-1">
              <Typography variant="body">Card Reference Number:</Typography>
              <Typography variant="bodyLarge">
                <strong>{card.referenceId}</strong>
              </Typography>
            </div>
            <Button onClick={onClose} text="Continue" buttonType="secondary" />
          </div>
        </Modal2>
      )}
    </>
  );
};
