import clsx from 'clsx';
import { useState } from 'react';

import FormUser from 'src/components/FormUser/FormUser';
import { useFormUser } from 'src/components/FormUser/hooks/useFormUser';
import { Typography } from 'src/components/Typography/Typography';
import Button from 'src/components/UI/Button/Button';
import { useIsMobile } from 'src/hooks/useIsMobile';
import { getURLPathForUserApplicationFlowStatus } from 'src/util/navigationHelper';

import { CardDetailsModal } from '../../../components/CardDetailsModal';
import { FAQModal } from '../../../components/FAQModal';
import { SecureModal } from '../../../components/SecureModal';

export const PersonalInfoForm = () => {
  const isMobile = useIsMobile();
  const {
    setFocus,
    handleSubmit,
    onSubmit,
    loading,
    formErrorsIncomeInfo,
    formErrorsCreditBilling,
    formCreditBilling,
    formIncomeInfo,
    setValue,
  } = useFormUser(getURLPathForUserApplicationFlowStatus);

  const [isCardDetailsModalOpen, setIsModalCardDetailsOpen] = useState(false);
  const [isSecurityModalOpen, setIsModalSecurityOpen] = useState(false);
  const [isFAQModalOpen, setIsFAQOpen] = useState(false);

  return (
    <div className="flex flex-col gap-4 pl-0 md:pl-6">
      <Typography className="text-left" variant="headerLarge">
        Apply for the Pesto Cash Secured Card.
      </Typography>

      <Typography className="text-left" variant="headerMedium">
        No credit score required. Use your card anywhere Mastercard ® is accepted.
      </Typography>

      <div
        className={clsx('mb-4 mt-4 flex flex-row gap-6', {
          'overflow-y-scroll whitespace-nowrap': isMobile,
        })}
      >
        <div
          className="cursor-pointer"
          onClick={() => {
            setIsModalCardDetailsOpen(true);
          }}
        >
          💳 Card Details
        </div>
        <div
          className="cursor-pointer"
          onClick={() => {
            setIsModalSecurityOpen(true);
          }}
        >
          🔒 Secure
        </div>
        <div
          className="cursor-pointer"
          onClick={() => {
            setIsFAQOpen(true);
          }}
        >
          ℹ️ FAQ
        </div>
      </div>

      <form onSubmit={handleSubmit(onSubmit)}>
        <FormUser
          setFocus={setFocus}
          formErrorsIncomeInfo={formErrorsIncomeInfo}
          formErrorsCreditBilling={formErrorsCreditBilling}
          formCreditBilling={formCreditBilling}
          formIncomeInfo={formIncomeInfo}
          setValue={setValue}
        />

        <Button
          text={'See If I’m Prequalified'}
          type={'submit'}
          onClick={handleSubmit(onSubmit)}
          buttonType={'primary'}
          isLoading={loading}
          disabled={loading}
          className="mt-4 w-full md:w-[320px]"
        />
      </form>

      <CardDetailsModal
        onClose={() => {
          setIsModalCardDetailsOpen(false);
        }}
        isOpen={isCardDetailsModalOpen}
      />

      <SecureModal
        onClose={() => {
          setIsModalSecurityOpen(false);
        }}
        isOpen={isSecurityModalOpen}
      />

      <FAQModal
        onClose={() => {
          setIsFAQOpen(false);
        }}
        isOpen={isFAQModalOpen}
      />
    </div>
  );
};
