import SvgCoinCash from 'src/icons/components/CoinCash';
import { SwitchState } from 'src/pages/ApplicationFlow/steps/ItemCart/ItemCart';
import { cn } from 'src/util/cn';

import { type AssetType, SecurityType, useCurrentUserQuery } from '../../__generated__/graphql/api';
import { IconTrash } from '../../icons/iconsComponents/IconTrash';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import ProgressiveImage from '../ProgressiveImage';
import { Typography } from '../Typography/Typography';

import { IconDrawer } from './IconDrawer/IconDrawer';

export interface ItemCardProps {
  title: string;
  type?: AssetType;
  image: string;
  price: string;
  description: string;
  isInReview: boolean;
  onDelete: () => void;
  loadingDelete?: boolean;
  noDelete?: boolean;
  switchState?: SwitchState;
  rejectedReason?: string;
}

const ItemCard = (props: ItemCardProps) => {
  const {
    title,
    image,
    price,
    description,
    isInReview,
    type,
    onDelete,
    loadingDelete,
    noDelete = false,
    switchState,
    rejectedReason,
  } = props;

  const user = useCurrentUserQuery();
  const isCashSecurityType = user?.data?.currentUser?.creditApplication?.securityType === SecurityType.Cash;

  const renderImageOrIcon = () => {
    if (image) {
      return (
        <div className="aspect-square min-h-[100px] w-full max-w-[64px] overflow-hidden rounded object-cover">
          <ProgressiveImage highQualitySrc={image} alt={`Preview of ${title}`} className="" />
        </div>
      );
    }

    if (switchState === SwitchState.Cash) {
      return (
        <div className="flex min-h-[100px] w-full max-w-[64px]">
          <div className="flex flex-auto flex-col items-center justify-center rounded-md bg-lace">
            <div className="rounded-full border border-white bg-basic p-2 text-white">
              <SvgCoinCash />
            </div>
          </div>
        </div>
      );
    }
    return (
      <div className="flex min-h-[100px] w-full max-w-[64px]">
        <IconDrawer itemType={type} />
      </div>
    );
  };

  const renderDeleteButton = () => {
    if (noDelete || switchState === SwitchState.Rejected || switchState === SwitchState.Cash || isCashSecurityType)
      return null;
    if (switchState === SwitchState.Deleted) return null;
    if (loadingDelete) {
      return <LoadingSpinner />;
    }
    return (
      <button
        data-testid="remove-item-button"
        className="flex h-6 w-10 justify-end"
        onClick={onDelete}
        disabled={loadingDelete}
      >
        <div className="rounded-full p-2 hover:bg-gray-100">
          <IconTrash className="place-content-end" stroke={'black'} />
        </div>
      </button>
    );
  };

  return (
    <div
      data-testid="item-card"
      className={cn('border-inactive flex w-full justify-between rounded-lg border p-4 text-left', {
        'opacity-60': switchState === SwitchState.Deleted,
      })}
    >
      <div className="flex w-full gap-4">
        {renderImageOrIcon()}
        <div className="flex w-full flex-col gap-2">
          <Typography variant="bodyLarge" className="text-neutral">
            {title}
          </Typography>
          {switchState !== SwitchState.Rejected && (
            <Typography variant="bodyLarge" className={'font-bold'}>
              {isInReview ? 'In Review' : `${price}`}
            </Typography>
          )}
          <div className="text-xs text-slate-400">{description}</div>
          <div className="text-xs text-slate-400">
            {switchState === SwitchState.Rejected ? `Rejected: ${rejectedReason}` : null}
          </div>
        </div>
      </div>
      <div className="w-10">{renderDeleteButton()}</div>
    </div>
  );
};

export default ItemCard;
