import { MetalCategory, MetalType } from '../__generated__/graphql/api';
import SvgOtherIcon from '../icons/components/OtherIcon';
import SvgAmazonPinIcon from '../icons/components/metal-type/AmazonPinIcon';
import SvgCoinIcon from '../icons/components/metal-type/CoinIcon';
import SvgCufflinkIcon from '../icons/components/metal-type/CufflinkIcon';
import SvgGoldBarsIcon from '../icons/components/metal-type/GoldBarsIcon';
import SvgGoldPlatedIcon from '../icons/components/metal/GoldPlatedIcon';
import SvgPlatinumPlatedIcon from '../icons/components/metal/PlatinumPlatedIcon';
import SvgSilverPlatedIcon from '../icons/components/metal/SilverPlatedIcon';

export const metalTypes = [
  {
    value: MetalType.Gold,
    text: 'Gold',
    icon: <SvgGoldPlatedIcon />,
  },
  {
    value: MetalType.Silver,
    text: 'Silver',
    icon: <SvgSilverPlatedIcon />,
  },
  {
    value: MetalType.Platinum,
    text: 'Platinum',
    icon: <SvgPlatinumPlatedIcon />,
  },
  {
    value: MetalType.Other,
    text: 'Other',
    icon: <SvgOtherIcon />,
  },
];

export const metalCategories = [
  {
    text: 'Coin',
    value: MetalCategory.Coin,
    icon: <SvgCoinIcon />,
  },
  {
    text: 'Pin/Brooch',
    value: MetalCategory.PinOrBrooch,
    icon: <SvgAmazonPinIcon />,
  },
  {
    text: 'Cufflinks',
    value: MetalCategory.Cufflinks,
    icon: <SvgCufflinkIcon />,
  },
  {
    text: 'Bar',
    value: MetalCategory.Bar,
    icon: <SvgGoldBarsIcon />,
  },
  {
    text: 'Other',
    value: MetalCategory.Other,
    icon: <SvgOtherIcon />,
  },
];
