import clsx from 'clsx';

import { ButtonContainer } from 'src/components/ButtonContainer/ButtonContainer';
import { BackButton } from 'src/components/UI/Button/BackButton';

import { NecklaceThickness } from '../../../../../../__generated__/graphql/api';
import { ListSelection } from '../../../../../../components/ListSelection/ListSelection';
import { Typography } from '../../../../../../components/Typography/Typography';
import OtherStar from '../../../../../../icons/components/OtherStar';
import { Medium, Thick, Thin, VeryThick } from '../../../../../../icons/components/necklace';

interface NecklaceThicknessScreenProps {
  className?: string;
  onNext: (thickness: NecklaceThickness) => void;
  handleBack: () => void;
}

export const necklaceList = [
  {
    value: NecklaceThickness.Thin,
    text: 'Thin',
    icon: <Thin />,
  },
  {
    value: NecklaceThickness.Medium,
    text: 'Medium',
    icon: <Medium />,
  },
  {
    value: NecklaceThickness.Thick,
    text: 'Thick',
    icon: <Thick />,
  },
  {
    value: NecklaceThickness.VeryThick,
    text: 'Very Thick',
    icon: <VeryThick />,
  },
  {
    value: NecklaceThickness.Other,
    text: 'Other/Unsure',
    icon: <OtherStar />,
  },
];

const NecklaceThicknessScreen = (props: NecklaceThicknessScreenProps) => {
  const { className, onNext, handleBack } = props;
  const rootStyles = clsx('flex flex-col gap-4 max-w-[400px] w-full', className);

  return (
    <div className={rootStyles}>
      <Typography variant={'headerMedium'}>How thick is your necklace?</Typography>
      <div className="flex w-full flex-col justify-center gap-2 text-center">
        {necklaceList.map((type, index) => (
          <ListSelection
            key={index}
            text={type.text}
            leftIcon={type.icon}
            onClick={() => typeof onNext === 'function' && onNext(type.value)}
          />
        ))}
      </div>

      <ButtonContainer leftButton={<BackButton onClick={handleBack} rightButtonExist={false} />} />
    </div>
  );
};

export default NecklaceThicknessScreen;
