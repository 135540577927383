import { useCurrentUserQuery } from 'src/__generated__/graphql/api';
import { ItemCart } from 'src/pages/ApplicationFlow/steps/ItemCart/ItemCart';

import AppraisalStatusMessage from '../components/AppraisalStatusMessage';

const MyItems = () => {
  const { data } = useCurrentUserQuery();
  const user = data?.currentUser;
  return (
    <div className="flex w-full max-w-[550px] flex-col gap-4 text-left">
      <AppraisalStatusMessage status={user?.creditApplication?.status} />
      <ItemCart />
    </div>
  );
};

export default MyItems;
