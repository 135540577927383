import clsx from 'clsx';
import { useState } from 'react';

import { useIsMobile } from 'src/hooks/useIsMobile';

import { StoneSize } from '../../../../../../../__generated__/graphql/api';
import { Typography } from '../../../../../../../components/Typography/Typography';
import { Dropdown } from '../../../../../../../components/UI/Form/Dropdown/Dropdown';
import { TextField } from '../../../../../../../components/UI/Form/TextField/TextField';
import { StoneSizeModal } from '../../../modals/StoneSizeModal/StoneSizeModal';

interface InnerStoneSizeAndCountProps {
  className?: string;
  size: StoneSize;
  count: number;
  onSizeChange: (size: StoneSize) => void;
  onCountChange: (count: number) => void;
  sizeError?: string;
  countError?: string;
}
export const InnerStoneSizeAndCount = (props: InnerStoneSizeAndCountProps) => {
  const { className, size, count, onSizeChange, onCountChange, sizeError, countError } = props;
  const rootStyle = clsx('flex gap-2', className);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const isMobile = useIsMobile();

  const stoneSizeInfo = (
    <div className={'mt-1 flex h-auto w-full justify-start'}>
      <button
        type={'button'}
        onClick={() => {
          setIsModalOpen(true);
        }}
        className={'w-auto text-action no-underline hover:underline'}
      >
        <Typography variant={'caption'} className={'text-primary'}>
          View Size Guide
        </Typography>
      </button>
    </div>
  );

  return (
    <div className={rootStyle}>
      <Dropdown
        className={`p-[${isMobile || !sizeError ? '0' : '1.5'}px]`}
        label="Size"
        placeholder="Select size"
        options={[
          { value: StoneSize.Small, text: 'Small' },
          { value: StoneSize.Medium, text: 'Medium' },
          { value: StoneSize.Large, text: 'Large' },
        ]}
        selectedValue={size}
        onChange={event => onSizeChange(event.value as StoneSize)}
        error={!!sizeError}
        errorText={sizeError}
        caption={stoneSizeInfo}
        required
      />
      <TextField
        className="h-[39px] sm:h-auto"
        label="Enter count"
        type="number"
        value={count}
        onChange={event => onCountChange(parseInt(event.target.value))}
        min={0}
        inputMode="numeric"
        error={!!countError}
        errorText={countError}
        required
      />
      <StoneSizeModal
        isModalOpen={isModalOpen}
        closeModal={() => {
          setIsModalOpen(false);
        }}
      />
    </div>
  );
};
