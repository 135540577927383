import * as React from 'react';
import type { SVGProps } from 'react';

interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgStud = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="#101820"
      fillRule="evenodd"
      d="M5.516 10.99c.038-.537.149-1.11.368-1.608.185-.42.433-.759.754-.993C6.95 8.161 7.375 8 7.973 8V6.5c-.889 0-1.629.246-2.22.678-.584.427-.98 1.003-1.242 1.6-.409.93-.526 1.977-.51 2.817.003.815.137 1.796.53 2.671.268.597.668 1.171 1.254 1.597.594.43 1.335.676 2.224.676v-1.5c-.598 0-1.026-.16-1.343-.39-.325-.236-.578-.577-.766-.996-.224-.498-.338-1.07-.38-1.603l.011-.002a5.522 5.522 0 0 1-.03-.47c0-.211.011-.41.03-.587l-.015-.002Z"
      clipRule="evenodd"
    />
    <path stroke="#101820" strokeLinecap="round" strokeWidth={1.5} d="M8.614 6v11M9.114 8.5V15" />
    <path fill="#101820" d="M19.614 12.25a.75.75 0 0 0 0-1.5v1.5Zm-11 0h11v-1.5h-11v1.5Z" />
    <path
      fill="#101820"
      d="M19.25 7.321a.75.75 0 0 0 1.5 0h-1.5Zm-.224-1.64c-.016-.015.054.05.12.347.062.282.104.7.104 1.293h1.5c0-.652-.045-1.188-.14-1.615-.09-.412-.245-.812-.534-1.095l-1.05 1.07ZM17.75 11c0-1.15.202-2.958.597-4.244.208-.675.425-1.052.575-1.184.05-.043.028-.005-.04-.011-.049-.004-.001-.022.144.12l1.05-1.07c-.285-.28-.64-.507-1.062-.544-.443-.04-.812.141-1.083.38-.49.43-.802 1.17-1.017 1.868-.45 1.461-.664 3.424-.664 4.685h1.5ZM19.25 15.679a.75.75 0 1 1 1.5 0h-1.5Zm-.224 1.64c-.016.015.054-.05.12-.347.062-.282.104-.7.104-1.293h1.5c0 .652-.045 1.188-.14 1.615-.09.412-.245.812-.534 1.095l-1.05-1.07ZM17.75 12c0 1.15.202 2.958.597 4.244.208.675.425 1.052.575 1.184.05.043.028.005-.04.011-.049.004-.001.022.144-.12l1.05 1.07c-.285.28-.64.507-1.062.544-.443.04-.812-.141-1.083-.38-.49-.43-.802-1.17-1.017-1.868-.45-1.461-.664-3.424-.664-4.685h1.5Z"
    />
  </svg>
);
export default SvgStud;
