import React from 'react';
import type { UseFormSetValue } from 'react-hook-form';

import { PaymentPreference } from 'src/__generated__/graphql/api';
import { AsSeenOnBrands } from 'src/components/HeroSelection/AsSeenOnBrands/AsSeenOnBrands';
import { HeroCard } from 'src/components/HeroSelection/HeroCard/HeroCard';
import { TrustedUsers } from 'src/components/HeroSelection/TrustedUsers/TrustedUsers';
import { Typography } from 'src/components/Typography/Typography';
import { useIsMobile } from 'src/hooks/useIsMobile';
import { Calendar, Coins, Toggle } from 'src/icons/components/split';
import SvgSpeed from 'src/icons/components/split/Speed';

import SvgCardIcon from '../../../../icons/components/CardIcon';
import { useActiveScreen } from '../../context/CashSecuredActiveScreenProvider';
import type { CashSecuredApplicationForm, PaymentOptions } from '../../types';

import { AmountHeroInput } from './AmountHeroInput';
import { BenefitItem } from './BenefitItem';
import { ButtonOptions } from './ButtonOptions';
import { VerifyApplicationForm } from './VerifyApplicationForm';

interface CashSecuredHeroSelectionProps {
  inputRef: React.RefObject<HTMLInputElement>;
  targetRef: React.RefObject<HTMLDivElement>;
  formData: CashSecuredApplicationForm;
  errors: any;
  isEditableForm: boolean;
  onGotoLogin: () => void;
  setIsEditing: React.Dispatch<React.SetStateAction<boolean>>;
  setValue: UseFormSetValue<CashSecuredApplicationForm>;
  userName?: string;
  onPaymentOptionClick: (option: PaymentOptions) => void;
  amount?: string;
  onAmountChange: (amount: string) => void;
  onSubmit: (e?: React.BaseSyntheticEvent) => Promise<void>;
}

export const CashSecuredHeroSelection = (props: CashSecuredHeroSelectionProps) => {
  const {
    userName,
    onPaymentOptionClick,
    amount,
    onAmountChange,
    inputRef,
    targetRef,
    setValue,
    errors,
    formData,
    onSubmit,
    isEditableForm,
    setIsEditing,
    onGotoLogin,
  } = props;
  const isMobile = useIsMobile();
  const { activeScreen, handleClick, handleContinue, setActiveScreen } = useActiveScreen();

  const handleClickOption = (option: PaymentPreference) => {
    if (option === PaymentPreference.PayOverTime) {
      setActiveScreen(2);
      onPaymentOptionClick(option);
    } else {
      handleClick(option);
      onPaymentOptionClick(option);
    }
  };

  const activeScreenMap = {
    0: <ButtonOptions onOptionClick={handleClickOption} />,
    1: (
      <AmountHeroInput
        inputRef={inputRef}
        amount={amount}
        errorAmount={null}
        onAmountChange={onAmountChange}
        onContinue={handleContinue}
      />
    ),
    2: (
      <VerifyApplicationForm
        onSubmit={onSubmit}
        editableForm={isEditableForm}
        formData={formData}
        setValue={setValue}
        errors={errors}
        onClick={() => setIsEditing(true)}
        handlePaymentSelection={onPaymentOptionClick}
        inputRef={inputRef}
        targetRef={targetRef}
        gotoLogin={onGotoLogin}
      />
    ),
  };

  return (
    <HeroCard>
      <Typography variant={isMobile ? 'headerSmall' : 'heroLarge'} className={'max-w-4xl'}>
        {userName || 'Hey'}, a few more steps to get the <SvgCardIcon className={'inline'} /> Mastercard
        <span className={'font-light'}>&#174;</span>
      </Typography>
      <Typography variant={isMobile ? 'headerSmall' : 'headerMedium'}>
        A Rocket Ship 🚀 for your credit score. One card, so many benefits!
      </Typography>

      <div className={`grid ${isMobile ? 'grid-cols-1' : 'grid-cols-2'} items-center justify-around gap-4 p-4`}>
        <BenefitItem
          icon={<SvgSpeed />}
          text={
            <Typography variant="bodyLarge" className="ml-2 text-basicSoft">
              No minimum credit score
            </Typography>
          }
        />
        <BenefitItem
          icon={<Coins />}
          text={
            <Typography variant="bodyLarge" className="ml-2 text-basicSoft">
              Get 1% Cash back
            </Typography>
          }
        />
        <BenefitItem
          icon={<Toggle />}
          text={
            <Typography variant="bodyLarge" className="ml-2 text-basicSoft">
              Build your credit with on <br /> time payments
            </Typography>
          }
        />
        <BenefitItem
          icon={<Calendar />}
          text={
            <Typography variant="bodyLarge" className="ml-2 text-basicSoft">
              Account reviewed after 6 months <br /> for credit line increase
            </Typography>
          }
        />
      </div>

      {activeScreenMap[activeScreen as keyof typeof activeScreenMap]}
      <TrustedUsers />
      <AsSeenOnBrands />
    </HeroCard>
  );
};
