import { Typography } from 'src/components/Typography/Typography';

import Forbes from '../../../assets/Forbes.svg';
import NerdWallet from '../../../assets/Nerdwallet.svg';
import TechCrunch from '../../../assets/TechCrunch.svg';

const PressTestimonials = () => {
  return (
    <div className="flex flex-col justify-center gap-4 bg-white px-4 py-12 md:flex-row md:gap-8 md:pb-[92px] md:pt-[92px]">
      <div className="max-w-full md:w-[360px]">
        <Typography variant="headerMedium" className="!font-thin text-[#1D1E2C]">
          “Stanford grad James Savoldelli formulated the idea after being offered a high-interest loan as a college
          student and logging time at pawnshops”
        </Typography>
        <div className="mt-4">
          <img src={TechCrunch} alt="TechCrunch logo" className="mx-auto block" />
        </div>
      </div>

      <div className="max-w-full md:w-[360px]">
        <Typography variant="headerMedium" className="!font-thin text-[#1D1E2C]">
          “If you don’t have enough liquid cash for a security deposit, this could be an option for getting access to
          credit.”
        </Typography>
        <div className="mt-4">
          <img src={NerdWallet} alt="NerdWallet logo" className="mx-auto block" />
        </div>
      </div>

      <div className="max-w-full md:w-[360px]">
        <Typography variant="headerMedium" className="!font-thin text-[#1D1E2C]">
          “For those in dire financial straits, pawn shops are a bank of last resort. Pesto hopes to capture those
          individuals online before they start down that path.”
        </Typography>
        <div className="mt-4">
          <img src={Forbes} alt="Forbes logo" className="mx-auto block" />
        </div>
      </div>
    </div>
  );
};

export default PressTestimonials;
