import * as React from 'react';
import type { SVGProps } from 'react';

interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgStepDone = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 19 18"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      stroke="#101820"
      strokeLinecap="round"
      strokeWidth={0.75}
      d="m6 9 2.353 2.781a.5.5 0 0 0 .776-.015L13.25 6.5"
    />
    <rect width={17} height={17} x={1} y={0.5} stroke="#101820" rx={8.5} />
  </svg>
);
export default SvgStepDone;
