import { useRoutes } from 'react-router-dom';

import { Header } from 'src/components/Header/Header';
import { Wrapper1280 } from 'src/components/UI/Wrapper/Wrapper1280';
import { settingsRoutesExact } from 'src/constants/routes';
import { useHasPersonalInfoData } from 'src/hooks/useHasPersonalInfoData';
import { MyItemsIcon, PersonalInfoIcon } from 'src/icons/components';

import MyItems from './MyItems/MyItems';
import PersonalInfoSettings from './PersonalInfoSettings/PersonalInfoSettings';
import NavItem from './components/NavItem';

<NavItem itemName="My Items" itemRoute={settingsRoutesExact.myItems} Icon={MyItemsIcon} />;

const Settings = () => {
  const hasPersonalInfoData = useHasPersonalInfoData();

  const settingsFlow = useRoutes([
    { path: settingsRoutesExact.root, element: <PersonalInfoSettings /> },
    { path: settingsRoutesExact.personalInfo, element: <PersonalInfoSettings /> },
    { path: settingsRoutesExact.myItems, element: <MyItems /> },
  ]);

  return (
    <div className="mx-auto">
      <Header divider />
      <Wrapper1280 className="flex flex-col items-start gap-4 pb-[150px] pt-4 md:flex-row md:pt-8">
        <div className="w-full md:w-1/4">
          <div className="h-[40px] sm:h-auto">
            <div className="absolute mx-auto -ml-6 flex w-[100vw] max-w-full flex-nowrap justify-center gap-2 overflow-x-auto px-4 pb-6 pt-2 sm:relative sm:ml-0 sm:w-full sm:flex-wrap sm:justify-center sm:pb-2">
              {hasPersonalInfoData && (
                <NavItem
                  itemName="Personal Info"
                  itemRoute={settingsRoutesExact.personalInfo}
                  Icon={PersonalInfoIcon}
                />
              )}
              <NavItem itemName="My Items" itemRoute={settingsRoutesExact.myItems} Icon={MyItemsIcon} />
            </div>
          </div>
        </div>

        <div className="w-full bg-white p-4 md:w-3/4">{settingsFlow}</div>
      </Wrapper1280>
    </div>
  );
};
export default Settings;
