import * as yup from 'yup';

import { firstNameValidation, lastNameValidation } from 'src/util/sharedFieldValidations';
import { stringSpecialCharactersTest } from 'src/util/stringSpecialCharactersTest';

export const replaceNonDigits = (value: string) => (value ? value.replace(/\D/g, '') : '');

export const PaymentSchema = yup.object().shape({
  firstName: firstNameValidation,
  lastName: lastNameValidation,
  address: stringSpecialCharactersTest('Address').when('isExistingAddress', {
    is: false,
    then: schema => schema.required('Address is required'),
  }),
  city: yup.string().when('isExistingAddress', {
    is: false,
    then: schema => schema.required('City is required'),
  }),
  state: yup.string().when('isExistingAddress', {
    is: false,
    then: schema => schema.required('State is required'),
  }),
  zip: yup.string().when('isExistingAddress', {
    is: false,
    then: schema => schema.required('Zip is required'),
  }),
});
