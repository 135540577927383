import type { FC, ReactNode } from 'react';

import { cn } from 'src/util/cn';

type DividerProps = {
  className?: string;
  children?: ReactNode;
  gap?: number;
  lineColor?: string;
  lineThickness?: number;
};

export const Divider: FC<DividerProps> = ({
  className,
  children,
  gap,
  lineThickness = 1,
  lineColor = 'neutralSoft',
}) => {
  const rootStyle = cn(className, `flex w-full items-center gap-${gap}`);
  const lineStyle = `h-[${lineThickness}px] w-full bg-${lineColor}`;
  return (
    <div className={rootStyle}>
      <div className={lineStyle}></div>
      <div>{children}</div>
      <div className={lineStyle}></div>
    </div>
  );
};
