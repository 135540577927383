import * as React from 'react';
import type { SVGProps } from 'react';

interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgRingQaIcon = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 37 50"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      stroke="currentColor"
      strokeWidth={1.5}
      d="M18.538 1c-3.244 0-5.515.974-5.515.974l-1.785 4.22s3.017 5.4 7.166 7.716h.27c4.148-2.316 7.165-7.716 7.165-7.716l-1.785-4.22S21.784 1 18.538 1Z"
    />
    <path
      stroke="currentColor"
      strokeWidth={1.5}
      d="M6.137 19.048c6.85-6.851 17.954-6.851 24.803 0 6.85 6.852 6.85 17.961 0 24.813s-17.954 6.852-24.803 0c-6.85-6.852-6.85-17.96 0-24.813Z"
    />
    <path
      stroke="currentColor"
      strokeWidth={1.5}
      d="M10.053 22.966c4.686-4.688 12.284-4.688 16.97 0 4.687 4.688 4.687 12.29 0 16.977-4.686 4.688-12.284 4.688-16.97 0-4.686-4.688-4.686-12.289 0-16.977Z"
    />
  </svg>
);
export default SvgRingQaIcon;
