import { cn } from 'src/util/cn';

import { useIsMobile } from '../../../hooks/useIsMobile';
import { Typography } from '../../Typography/Typography';

interface MainSelectionProps {
  className?: string;
  image?: string;
  title?: string;
  description?: string;
  onClick?: () => void;
  disabled?: boolean;
}
export const MainSelectionButton = (props: MainSelectionProps) => {
  const { className, image, title, onClick, description, disabled, ...otherProps } = props;

  const isMobile = useIsMobile();

  const rootStyle = cn(
    'flex flex-col sm:gap-4 w-full cursor-pointer rounded-2xl items-center gap-2 bg-[#2A2A2A] p-4 sm:px-6 sm:py-10 shadow-xl text-white',
    'transition-all duration-200 ease-in-out',
    'hover:bg-[linear-gradient(_180deg,_rgba(0,0,0,0.20)_0%,_rgba(255,255,255,0.20)100%)] hover:shadow-2xl sm:hover:scale-105',
    className,
    {
      'opacity-50': disabled,
    },
  );

  const handleClick = () => {
    if (disabled || !onClick) return;
    onClick();
  };

  return (
    <div className={rootStyle} onClick={handleClick} {...otherProps}>
      {image && <img src={image} alt={title} className="h-auto max-w-[100px] sm:max-w-[200px]" />}
      <div className="flex flex-col gap-4 text-center">
        <Typography variant={isMobile ? 'headerSmall' : 'headerLarge'}>{title}</Typography>
        <Typography variant={isMobile ? 'bodySmall' : 'body'}>{description}</Typography>
      </div>
    </div>
  );
};
