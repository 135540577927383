import { yupResolver } from '@hookform/resolvers/yup';
import clsx from 'clsx';
import CryptoJS from 'crypto-js';
import isEmpty from 'lodash.isempty';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import capitalize from 'lodash/capitalize';
import { useAuth } from 'src/auth/AuthProvider';
import { Typography } from 'src/components/Typography/Typography';
import Button from 'src/components/UI/Button/Button';
import { PhoneField } from 'src/components/UI/Form/PhoneField/PhoneField';
import { TextField } from 'src/components/UI/Form/TextField/TextField';
import { CASH_SECURED_FLOW } from 'src/constants/application';
import { QUICK_APPRAISAL_LS_KEY, SIGN_UP_USER_DATA_LS_KEY } from 'src/constants/localStorageKeys';
import { loginRoutes } from 'src/constants/routes';
import { useGoRoutes } from 'src/hooks/useGoRoutes';
import { useIsMobile } from 'src/hooks/useIsMobile';
import { useLocalStorage } from 'src/hooks/useLocalStorage';
import { useScrollTopOnMount } from 'src/hooks/useScrollTopOnMount';
import { decryptSignUpUserData } from 'src/util/decryptedLSData';

import {
  emailValidation,
  firstNameValidation,
  lastNameValidation,
  middleNameValidation,
  phoneNumberValidation,
} from '../../../../../util/sharedFieldValidations';
import type { QuickAppraisalLocalStorage } from '../../../../Login/SignUp/SignUp';
import { CardDetailsModal } from '../../../components/CardDetailsModal';
import { FAQModal } from '../../../components/FAQModal';
import { SecureModal } from '../../../components/SecureModal';

interface SignUpFormData {
  firstName: string;
  middleName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
}

const validationSchema = yup.object().shape({
  firstName: firstNameValidation,
  middleName: middleNameValidation,
  lastName: lastNameValidation,
  email: emailValidation,
  phoneNumber: phoneNumberValidation,
});

interface SignUpProps {
  skipUseEffect?: boolean;
}

const SignUp = (props: SignUpProps) => {
  const { skipUseEffect = false } = props;
  const { gotoHomepage } = useGoRoutes();
  const navigate = useNavigate();
  useScrollTopOnMount();
  const { state } = useLocation();
  const { sendCode } = useAuth();
  const isMobile = useIsMobile();
  const storedData = window.localStorage.getItem(SIGN_UP_USER_DATA_LS_KEY);
  const decrypted = decryptSignUpUserData(storedData);

  const [isCardDetailsModalOpen, setIsModalCardDetailsOpen] = useState(false);
  const [isSecurityModalOpen, setIsModalSecurityOpen] = useState(false);
  const [isFAQModalOpen, setIsFAQOpen] = useState(false);

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors, isSubmitted },
    trigger,
  } = useForm<SignUpFormData>({
    defaultValues: {
      firstName: decrypted?.firstName || '',
      middleName: decrypted?.middleName || '',
      lastName: decrypted?.lastName || '',
      email: decrypted?.email || '',
      phoneNumber: decrypted?.phone || '',
    },
    resolver: yupResolver(validationSchema),
  });

  const formData = {
    firstName: capitalize(watch('firstName')),
    lastName: capitalize(watch('lastName')),
    middleName: capitalize(watch('middleName')),
    phoneNumber: watch('phoneNumber'),
    email: watch('email'),
  };

  const { firstName, lastName, middleName, phoneNumber, email } = formData;
  const [getLocalStoragePayload] = useLocalStorage<QuickAppraisalLocalStorage | null>(QUICK_APPRAISAL_LS_KEY, null);

  useEffect(() => {
    if (!skipUseEffect) {
      if (!getLocalStoragePayload || isEmpty(getLocalStoragePayload)) {
        gotoHomepage();
      }
    }
  }, [getLocalStoragePayload, gotoHomepage, skipUseEffect, state]);

  useEffect(() => {
    if (isSubmitted) {
      trigger();
    }
  }, [isSubmitted, trigger, firstName, middleName, lastName, phoneNumber, email]);

  const encryptData = (data: string) => {
    return CryptoJS.AES.encrypt(data, 'secret key 123').toString();
  };

  const onSubmit = (signUpUserData: SignUpFormData) => {
    try {
      const serializedData = JSON.stringify(signUpUserData);
      const encryptedData = encryptData(serializedData);
      window.localStorage.setItem(SIGN_UP_USER_DATA_LS_KEY, encryptedData);
      sendCode(signUpUserData.email);
      navigate(loginRoutes.createAccount, { state: CASH_SECURED_FLOW });
    } catch (error) {
      console.error('Error storing data', error);
    }
  };

  return (
    <div className="flex flex-col gap-4 pl-0 md:pl-6">
      <Typography className="text-left" variant="headerLarge">
        Apply for the Pesto Mastercard®
      </Typography>
      {!isMobile && (
        <Typography className="text-left" variant="headerMedium">
          No credit score required. <br />
          Use your card anywhere Mastercard® is accepted.
        </Typography>
      )}

      <div
        className={clsx('mb-4 mt-4 flex flex-row gap-6', {
          'overflow-y-scroll whitespace-nowrap': isMobile,
        })}
      >
        <div
          className="cursor-pointer"
          onClick={() => {
            setIsModalCardDetailsOpen(true);
          }}
        >
          💳 Card Details
        </div>
        <div
          className="cursor-pointer"
          onClick={() => {
            setIsModalSecurityOpen(true);
          }}
        >
          🔒 Secure
        </div>
        <div
          className="cursor-pointer"
          onClick={() => {
            setIsFAQOpen(true);
          }}
        >
          ℹ️ FAQ
        </div>
      </div>

      <Typography className="mb-4 text-left" variant="bodyLarge">
        Let's start your application
      </Typography>

      <form onSubmit={handleSubmit(onSubmit)} className="w-full md:w-[620px]">
        <div className="mb-[24px] flex flex-row justify-around gap-x-4 text-left">
          <TextField
            {...register('firstName')}
            name="firstName"
            type="text"
            label="First name"
            placeholder="First"
            value={formData.firstName}
            onChange={event => setValue('firstName', event.target.value)}
            onBlur={event => setValue('firstName', event.target.value.trim())}
            error={!!errors.firstName}
            errorText={errors.firstName?.message}
            required
          />

          <TextField
            {...register('middleName')}
            name="middleName"
            type="text"
            label="Middle name"
            placeholder="Middle"
            value={formData.middleName}
            onChange={event => setValue('middleName', event.target.value)}
            onBlur={event => setValue('middleName', event.target.value.trim())}
            error={!!errors.middleName}
            errorText={errors.middleName?.message}
          />
        </div>
        <div className="mb-[24px] flex flex-col gap-4  text-left">
          <TextField
            {...register('lastName')}
            name="lastName"
            type="text"
            label="Last name"
            placeholder="Last"
            value={formData.lastName}
            onChange={event => setValue('lastName', event.target.value)}
            onBlur={event => setValue('lastName', event.target.value.trim())}
            error={!!errors.lastName}
            errorText={errors.lastName?.message}
            required
          />

          <TextField
            {...register('email')}
            name="email"
            type="email"
            label="Email"
            value={formData.email}
            placeholder="email@example.com"
            onChange={event => setValue('email', event.target.value)}
            error={!!errors.email}
            errorText={errors.email?.message}
            required
          />
        </div>

        <div className="flex flex-col gap-4 text-left">
          <PhoneField
            {...register('phoneNumber')}
            label="Phone number"
            value={formData.phoneNumber}
            onChange={value => setValue('phoneNumber', value)}
            error={!!errors.phoneNumber}
            errorText={errors.phoneNumber?.message}
            required
          />
        </div>

        <Button text="Start Application" className="mt-4 w-full md:w-[320px]" buttonType={'primary'} />
      </form>

      <CardDetailsModal
        onClose={() => {
          setIsModalCardDetailsOpen(false);
        }}
        isOpen={isCardDetailsModalOpen}
      />

      <SecureModal
        onClose={() => {
          setIsModalSecurityOpen(false);
        }}
        isOpen={isSecurityModalOpen}
      />

      <FAQModal
        onClose={() => {
          setIsFAQOpen(false);
        }}
        isOpen={isFAQModalOpen}
      />
    </div>
  );
};

export default SignUp;
