import type { FC } from 'react';

import { Typography } from 'src/components/Typography/Typography';
import Button from 'src/components/UI/Button/Button';
import { Modal2 } from 'src/components/UI/Modal/Modal2';

import Card from '../assets/Card.svg';

import { List } from './List';

interface CardDetailsModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const CardDetailsModal: FC<CardDetailsModalProps> = ({ isOpen, onClose }) => {
  const items = [
    'No credit check to see if you prequalify.',
    'We review your account for a credit line increase after 6 months.',
    'Use items like diamond rings, gold, luxury watches, or use your debit card for your refundable security deposit.',
    'Your credit line will be based on the total value of your items or cash deposit.',
    'Use your card anywhere Mastercard® is accepted.',
  ];

  if (!isOpen) return null;

  return (
    <Modal2 isOpen={isOpen} onClose={onClose}>
      <div className="flex flex-col gap-4">
        <img src={Card} alt="pesto card" />

        <div className="mt-2 flex flex-col gap-2 text-left">
          <Typography variant="body">No Minimum Credit Score.</Typography>
          <Typography variant="bodySmall">
            We've Built the Best Secured Credit Card: Use Cash or an Item to Get Your Card.
          </Typography>

          <List items={items} />
        </div>

        <Button
          buttonType="secondary"
          text="Continue"
          onClick={() => {
            onClose();
          }}
        />
      </div>
    </Modal2>
  );
};
