import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import type { NavigateOptions } from 'react-router/dist/lib/context';

import {
  applicationRoutes,
  cashPaymentRoutes,
  cashSecuredRoutes,
  loginRoutes,
  offerRoutes,
  routes,
  userStatusRoutes,
} from '../constants/routes';

export const useGoRoutes = () => {
  const navigate = useNavigate();

  return useMemo(() => {
    return {
      navigate: (route: string, options?: NavigateOptions | undefined) => navigate(route, { ...options }),

      goBack: () => navigate(-1),

      gotoHomepage: () => navigate(routes.home),
      gotoLogin: () => navigate(routes.login),
      gotoApplication: () => navigate(routes.application),
      gotoCreateAccount: (options?: NavigateOptions | undefined) => navigate(loginRoutes.createAccount, { ...options }),
      goToSignUp: () => navigate(loginRoutes.signUp),
      gotoPersonalInfo: () => navigate(loginRoutes.personalInfo),
      gotoChooseFlow: () => navigate(loginRoutes.chooseFlow),

      gotoApplicationQuickAppraisal: () => navigate(applicationRoutes.quickAppraisal),
      gotoQuickAppraisal: () => navigate(routes.quickAppraisal),
      gotoItemCart: () => navigate(applicationRoutes.itemsCart),
      gotoItemCartWithOptions: (options?: NavigateOptions | undefined) =>
        navigate(applicationRoutes.itemsCart, { ...options }),
      gotoPersonalAndAddressInfo: () => navigate(applicationRoutes.addressAndIncome),
      gotoPreQualified: () => navigate(applicationRoutes.preQualified),
      gotoOffer: () => navigate(offerRoutes.root),
      gotoRejectOffer: () => navigate(offerRoutes.rejectOffer),
      gotoVerifyInfo: () => navigate(offerRoutes.verifyInfo),
      gotoVerifyIncome: () => navigate(offerRoutes.verifyIncome),
      gotoFinalAgreements: () => navigate(offerRoutes.finalAgreements),
      gotoCompletion: () => navigate(applicationRoutes.completion),
      gotoDontOperateInState: () => navigate(userStatusRoutes.dontOperateInState),

      gotoCashSecured: () => navigate(cashSecuredRoutes.root),
      gotoCreditLine: () => navigate(cashSecuredRoutes.creditLine),
      gotoCashPersonalInfo: () => navigate(cashSecuredRoutes.personalInfo),
      gotoFundOverTime: () => navigate(cashPaymentRoutes.fundOverTime),
      gotoPayment: () => navigate(cashSecuredRoutes.payment),
      gotoPaymentWithOptions: (options?: NavigateOptions | undefined) =>
        navigate(cashSecuredRoutes.payment, { ...options }),
      gotoFundNow: () => navigate(cashPaymentRoutes.fundNow),
      gotoAgreements: () => navigate(cashSecuredRoutes.agreements),
      gotoCongratulations: () => navigate(cashSecuredRoutes.congratulations),
    };
  }, [navigate]);
};
