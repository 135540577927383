import { Menu as M, Transition } from '@headlessui/react';
import clsx from 'clsx';
import { Fragment } from 'react';
import { useNavigate } from 'react-router-dom';

import Button from 'src/components/UI/Button/Button';
import { useHasPersonalInfoData } from 'src/hooks/useHasPersonalInfoData';
import { useNavigateOnStatus } from 'src/hooks/useNavigateOnStatus';
import SvgHamburgerMenu from 'src/icons/components/HamburgerMenu';
import { cn } from 'src/util/cn';
import { extractInitials } from 'src/util/extractInitials';

import { useAuth } from '../../../auth/AuthProvider';
import { links, routes, settingsRoutes } from '../../../constants/routes';
import { useUser } from '../../../hooks/useUser';

const Menu = () => {
  const navigateOnStatus = useNavigateOnStatus();
  const navigate = useNavigate();
  const { signOut } = useAuth();
  const { user } = useUser();

  const hasPersonalInfoData = useHasPersonalInfoData();

  const navigateToLogin = () => {
    navigate(routes.login);
  };

  const navigateToMyItems = (event: { preventDefault: () => void }) => {
    event?.preventDefault();
    navigate(settingsRoutes.myItems);
  };

  const navigateToPersonalInfo = (event: { preventDefault: () => void }) => {
    event?.preventDefault();
    navigate(settingsRoutes.personalInfo);
  };

  const logOutHandler = () => {
    signOut(true);
  };

  const userInitials = {
    firstName: user?.firstName || '',
    lastName: user?.lastName || '',
  };

  const renderUserSignInButton = () => {
    return <Button text="Sign In" buttonType="secondary" className="bg-white" onClick={navigateToLogin} />;
  };

  const renderWhenUserIsLoggined = () => {
    return (
      <M as="div" className="relative">
        <M.Button className="flex w-full pl-3">
          <div className="flex h-[29px] w-[29px] cursor-pointer items-center justify-center rounded-full border-[1px] border-basic bg-salsa text-[15px] text-white">
            {extractInitials(userInitials)}
          </div>
        </M.Button>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <M.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <M.Item>
              {({ active }) => (
                <>
                  {user ? (
                    <>
                      <a
                        href=""
                        onClick={() => window.open(links.talkToTheTeam, '_blank')}
                        className={clsx(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                      >
                        <span className="relative ml-auto flex h-3 w-3">
                          <span className="absolute inline-flex h-full w-full animate-ping rounded-full bg-success opacity-75"></span>
                          <span className="relative inline-flex h-3 w-3 rounded-full bg-success"></span>
                        </span>
                        <b>Schedule call</b>
                      </a>
                      <a
                        href=""
                        onClick={navigateOnStatus}
                        className={clsx(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                      >
                        Application
                      </a>
                      {hasPersonalInfoData && (
                        <a
                          href=""
                          onClick={navigateToPersonalInfo}
                          className={clsx(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                        >
                          Personal Info
                        </a>
                      )}

                      <a
                        href=""
                        onClick={navigateToMyItems}
                        className={clsx(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                      >
                        My Items
                      </a>
                      <a
                        href=""
                        onClick={logOutHandler}
                        className={cn({ 'bg-gray-100': active }, 'block px-4 py-2 text-sm text-gray-700')}
                      >
                        Sign out
                      </a>
                    </>
                  ) : (
                    <a
                      href=""
                      onClick={navigateToLogin}
                      className={cn({ 'bg-gray-100': active }, 'block px-4 py-2 text-sm text-gray-700')}
                    >
                      Sign in
                    </a>
                  )}
                </>
              )}
            </M.Item>
          </M.Items>
        </Transition>
      </M>
    );
  };

  const renderHamburgerMenu = () => {
    return (
      <M as="div" className="relative">
        <div>
          <M.Button className="flex w-full pl-3">
            <span className="sr-only">Open user menu</span>
            <SvgHamburgerMenu className={'h-[20px] w-[20px]'} />
          </M.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <M.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <M.Item>
              {({ active }) => (
                <>
                  {user ? (
                    <a
                      href=""
                      onClick={logOutHandler}
                      className={clsx(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                    >
                      Sign out
                    </a>
                  ) : (
                    <a
                      href=""
                      onClick={navigateToLogin}
                      className={clsx(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                    >
                      Sign in
                    </a>
                  )}
                </>
              )}
            </M.Item>
          </M.Items>
        </Transition>
      </M>
    );
  };

  if (user?.firstName === null && user?.lastName === null) {
    return renderHamburgerMenu();
  }

  if (user) {
    return renderWhenUserIsLoggined();
  }

  if (!user) {
    return renderUserSignInButton();
  }
};

export default Menu;
