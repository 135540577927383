import Player from '@vimeo/player';
import React, { useEffect, useRef, useState } from 'react';

import { useIsMobile } from 'src/hooks/useIsMobile';
import { cn } from 'src/util/cn';

interface VideoItemProps {
  videoSrc: string;
}

const VideoItem: React.FC<VideoItemProps> = ({ videoSrc }) => {
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const playerRef = useRef<Player | null>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const isMobile = useIsMobile();

  useEffect(() => {
    if (iframeRef.current) {
      playerRef.current = new Player(iframeRef.current);
      playerRef.current.on('play', () => setIsPlaying(true));
      playerRef.current.on('pause', () => setIsPlaying(false));
      playerRef.current.on('ended', () => setIsPlaying(false));
    }
  }, [videoSrc]);

  const togglePlayPause = () => {
    if (isPlaying) {
      playerRef.current?.pause();
    } else {
      playerRef.current?.play();
    }
  };

  return (
    <div className="relative w-full">
      <iframe
        ref={iframeRef}
        src={`${videoSrc}&enablejsapi=1`}
        className={cn('relative mr-5 overflow-hidden rounded-lg', {
          'h-[400px] w-full': isMobile,
          'md:mb-0 md:mr-0 md:h-[500px] lg:mx-auto lg:w-[300px] xl:w-[350px] 2xl:w-[400px]': !isMobile,
        })}
        allow="autoplay; encrypted-media"
        allowFullScreen
      ></iframe>
      <div className="mt-4 flex justify-center space-x-4">
        <button
          onClick={togglePlayPause}
          className={cn(
            'absolute bottom-[25px] left-[60px] rounded px-4 py-2 font-bold text-white',
            isPlaying ? 'bg-neutralStrong' : 'bg-salsa',
          )}
        >
          {isPlaying ? 'Pause' : 'Play'}
        </button>
      </div>
    </div>
  );
};

export const VideoRow: React.FC = () => {
  return (
    <ul
      role="list"
      className="mx-auto grid max-w-2xl grid-cols-1 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-3 lg:gap-4"
    >
      <li className="px-8 py-4 sm:py-8">
        <VideoItem videoSrc="https://player.vimeo.com/video/899546420?h=af5603b387&muted=0&controls=1&app_id=122963" />
      </li>
      <li className="px-8 py-4 sm:py-8">
        <VideoItem videoSrc="https://player.vimeo.com/video/899546370?h=96c999ce00&muted=0&controls=1&app_id=122963" />
      </li>
      <li className="px-8 py-4 sm:py-8">
        <VideoItem videoSrc="https://player.vimeo.com/video/899546321?h=d92e5424ed&muted=0&controls=1&app_id=122963" />
      </li>
    </ul>
  );
};
