import * as React from 'react';
import type { SVGProps } from 'react';

interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgHiper = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 780 500"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <g clipPath="url(#hiper_svg__a)">
      <path
        fill="#F37421"
        d="M724.3 0H55.7C25.4 0 .8 24.6.8 54.9v390.2c0 30.3 24.6 54.9 54.9 54.9h668.7c30.3 0 54.9-24.6 54.9-54.9V54.9c-.1-30.3-24.7-54.9-55-54.9Z"
      />
      <path
        fill="#fff"
        d="M100 139.9v175h39.7v-73.2h65.2v73.2h39.5v-175h-39.5v67.3h-65.2v-67.3H100ZM586.2 264c.5-2.9 1.3-8.8 1.3-15.6 0-31.4-15.6-63.4-56.6-63.4-44.1 0-64.1 35.6-64.1 67.8 0 39.7 24.7 64.7 67.8 64.7 17.1 0 33-2.6 46-7.8l-5.2-26.7c-10.6 3.4-21.6 5.2-35.1 5.2-18.4 0-34.5-7.8-35.8-24.1h81.7v-.1Zm-82.1-27c1-10.6 7.8-25.7 24.4-25.7 18.2 0 22.3 16.1 22.3 25.7h-46.7ZM600.1 314.901h39.5v-64.4c0-3.1.3-6.2.8-8.8 2.6-12.2 12.5-20 27-20 4.4 0 7.8.5 10.6 1v-37.1c-2.9-.5-4.7-.5-8.3-.5-12.2 0-27.8 7.8-34.3 26.2h-1l-1.3-23.4h-34c.5 10.9 1 23.1 1 41.8v85.2Z"
      />
      <path
        fill="#FFE700"
        fillRule="evenodd"
        d="M284.5 132.9c11.2 0 20.3 9.1 20.3 20.3 0 11.2-9.1 20.3-20.3 20.3-11.2 0-20.3-9.1-20.3-20.3 0-11.2 9.1-20.3 20.3-20.3Z"
        clipRule="evenodd"
      />
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M367.9 285.3h19.8c19.9 0 28.9-12.7 28.9-26s-1-43.3-25.5-43.3c-28.2 0-23.7 37.3-23.5 56.8-.1 4.2.2 8.4.3 12.5Zm-103.7-97.5h40.6v71.6c0 13.3 7.4 26 23.7 26 .1-32.2 0-65.4-1.1-97.6h33.9c.7 6.2 1.4 12.4 2 18.6 16-32 66.5-25 83.5 2.1 17.5 27.9 23.5 107.9-59.1 107.9h-19.2c.2 16.2.2 32.5.2 48.8h-40.6c0-15.6.1-32 .2-48.8-43.9-.2-64.1-28-64.1-57v-71.6Z"
        clipRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="hiper_svg__a">
        <path fill="#fff" d="M0 0h780v500H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgHiper;
