import clsx from 'clsx';
import React from 'react';
import type { UseFormSetValue } from 'react-hook-form';

import { PaymentPreference } from 'src/__generated__/graphql/api';
import AmountSelector from 'src/components/AmountSelector/AmountSelector';
import { Typography } from 'src/components/Typography/Typography';
import { Amount } from 'src/components/UI/Form/Amount/Amount';
import { MINIMUM_PAYMENT_AMOUNT } from 'src/constants/application';
import { RadioSelect } from 'src/pages/CashSecuredFlow/components/RadioSelect';

import { PAYMENT_PREFERENCE } from '../helpers';
import type { CashSecuredApplicationForm } from '../types';

interface CreditLineFormProps {
  formData: CashSecuredApplicationForm;
  errors: any;
  setValue: UseFormSetValue<CashSecuredApplicationForm>;
  handlePaymentSelection: (option: PaymentPreference) => void;
  inputRef: React.RefObject<HTMLInputElement>;
}

const CreditLineForm: React.FC<CreditLineFormProps> = ({
  formData,
  errors,
  setValue,
  handlePaymentSelection,
  inputRef,
}) => {
  return (
    <div className="text-left">
      <div className="mb-6">
        <Typography variant="headerSmall" className="!font-bold">
          Choose your credit line
        </Typography>
        <Typography variant="body">
          Your refundable security deposit determines the credit line for your Pesto Mastercard®.
        </Typography>
      </div>
      <div className="flex flex-col items-center gap-4">
        <Amount
          ref={inputRef}
          key={formData.amount}
          error={errors.amount}
          inputSize="big"
          textAlign="center"
          inputMode="numeric"
          onChange={event => {
            const newValue = event.target.value;
            setValue('amount', newValue);
          }}
          placeholder="$0.00"
          label="Amount"
          value={formData.amount}
          required={true}
        />
        <div
          className={clsx('text-center text-[10px] font-light', {
            'text-neutralStrong': !errors.amount,
            'text-danger': errors.amount,
          })}
        >
          {errors.amount ? errors.amount.message : 'Amount must be between $200 - $2,000'}
        </div>

        <AmountSelector
          useHighAmounts={true}
          onAmountSelected={amount => {
            if (amount !== null) {
              const newValue = amount.toString();
              setValue('amount', newValue);
            }
          }}
        />

        <RadioSelect
          title="Fund full deposit"
          checked={formData.paymentPreference === PaymentPreference.PayInFull}
          onChange={() => {
            handlePaymentSelection(PAYMENT_PREFERENCE.fundNow);
          }}
          labelText="fast"
          type="fast"
        />

        <RadioSelect
          title="Fund over time"
          checked={formData.paymentPreference === PaymentPreference.PayOverTime}
          onChange={() => {
            handlePaymentSelection(PAYMENT_PREFERENCE.fundOverTime);
          }}
          labelText={`$${MINIMUM_PAYMENT_AMOUNT} fee`}
          type="fee"
        />
      </div>
    </div>
  );
};

export default CreditLineForm;
