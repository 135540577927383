import { type FC, useEffect } from 'react';

import type { SocureProps } from './types';

export const Socure: FC<SocureProps> = ({
  className,
  step = 1,
  inputData = {},
  processConfig = {},
  sdkKey,
  onSuccessCallback,
  onErrorCallback,
  onProgressCallback,
  onLoaded,
}) => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://websdk.socure.com/bundle.js';
    script.type = 'text/javascript';
    script.onload = initSocure;
    document.head.appendChild(script);

    let sdkInitiated = false;
    let eventId = '';

    const onProgress = (progress: any) => {
      if (typeof onProgressCallback === 'function') {
        onProgressCallback(progress);
      }
    };

    const onSuccess = (response: any) => {
      if (eventId !== '') {
        if (typeof onSuccessCallback === 'function') {
          onSuccessCallback(response);
          eventId = '';

          const resultsElement = document.getElementById('results');
          const websdkElement = document.getElementById('websdk');

          if (resultsElement) {
            resultsElement.innerHTML = 'Document Verification Process succesfully completed!';
          }

          if (websdkElement) {
            websdkElement.style.display = 'none';
          }
        }
      }
    };

    const onError = (error: any) => {
      if (eventId !== '') {
        if (typeof onErrorCallback === 'function') {
          onErrorCallback(error);
          eventId = '';

          const resultsElement = document.getElementById('results');
          const websdkElement = document.getElementById('websdk');

          if (resultsElement) {
            resultsElement.innerHTML = 'Document Verification Process failed!';
          }

          if (websdkElement) {
            websdkElement.style.display = 'none';
          }
        }
      }
    };

    const config = {
      onSuccess: onSuccess,
      onProgress: onProgress,
      onError: onError,
      qrCodeNeeded: true,
    };

    function initSocure() {
      if (!sdkKey) {
        console.error('SDK key is required');
        return;
      }

      window.SocureInitializer.init(sdkKey).then((lib: any) => {
        lib.init(sdkKey, '#websdk', config).then(() => {
          lib.start(step, inputData, processConfig).then(
            (response: any) => {
              onLoaded && onLoaded(true);
              console.log(response);
              sdkInitiated = true;
              eventId = response.eventId;

              const websdkElement = document.getElementById('websdk');
              const resultsElement = document.getElementById('results');

              if (websdkElement) {
                websdkElement.style.display = 'block';
              }

              if (resultsElement) {
                resultsElement.style.display = 'none';
              }
            },
            (error: any) => {
              onLoaded && onLoaded(true);
              console.error(error);
            },
          );
        });
      });
    }

    return () => {
      if (sdkInitiated) {
        window.Socure.cleanup();
        window.Socure.reset();
      }
    };
  }, [inputData, onErrorCallback, onLoaded, onProgressCallback, onSuccessCallback, processConfig, sdkKey, step]);

  return (
    <div className={className}>
      <div id="websdk"></div>
      <div id="results"></div>
    </div>
  );
};
