import * as React from 'react';
import type { SVGProps } from 'react';

interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgColorDIcon = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 20 19"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="#fff"
      stroke="#101820"
      strokeLinecap="round"
      strokeWidth={1.5}
      d="m15.776 1.096 2.524 2.41c.43.41.512 1.066.196 1.569l-7.438 11.832a1.25 1.25 0 0 1-2.116 0L1.504 5.075a1.25 1.25 0 0 1 .196-1.57l2.524-2.41A1.25 1.25 0 0 1 5.087.75h9.826c.321 0 .63.124.863.346Z"
    />
  </svg>
);
export default SvgColorDIcon;
