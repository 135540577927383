import type { Ref } from 'react';
import { forwardRef } from 'react';

import type { TextFieldProps } from '../UI/Form/FormTypes';
import { TextField } from '../UI/Form/TextField/TextField';

interface ZipCodeProps extends Omit<TextFieldProps, 'onChange'> {
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const ZipCode = forwardRef((props: ZipCodeProps, ref: Ref<HTMLInputElement>) => {
  const { onChange, value, placeholder, ...inputProps } = props;

  const handleZipChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange && onChange(e);
  };

  return (
    <div>
      <TextField
        ref={ref}
        onChange={handleZipChange}
        inputMode="numeric"
        value={value}
        placeholder={placeholder ?? 'Enter zip code'}
        data-testid={'zip-code-component'}
        maxLength={5}
        {...inputProps}
      />
    </div>
  );
});

export default ZipCode;
