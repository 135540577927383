import type { IconProps } from './IconHelper';

export const IconCross = (props: IconProps) => {
  const { className, fill } = props;
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.87348 5.81282C6.58058 5.51992 6.10571 5.51992 5.81282 5.81282C5.51992 6.10571 5.51992 6.58058 5.81282 6.87348L10.5858 11.6464L5.45926 16.773C5.16637 17.0659 5.16637 17.5407 5.45926 17.8336C5.75216 18.1265 6.22703 18.1265 6.51992 17.8336L11.6464 12.7071L17.1265 18.1872C17.4194 18.4801 17.8943 18.4801 18.1872 18.1872C18.4801 17.8943 18.4801 17.4194 18.1872 17.1265L12.7071 11.6464L17.8336 6.51992C18.1265 6.22703 18.1265 5.75216 17.8336 5.45926C17.5407 5.16637 17.0659 5.16637 16.773 5.45926L11.6464 10.5858L6.87348 5.81282Z"
        fill={fill}
      />
    </svg>
  );
};
