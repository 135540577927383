import { datadogLogs } from '@datadog/browser-logs';
import { createContext, type FC, type ReactNode, useContext, useState } from 'react';

import { useAuth } from 'src/auth/AuthProvider';
import DuplicateSSNModal from 'src/components/DuplicateSSNModal/DuplicateSSNModal';
import SessionExpiredModal from 'src/components/SessionExpiredModal';

import type { UpdateIssues } from '../__generated__/graphql/api';
import { UpdateIssuesModal } from '../components/Modals/UpdateIssuesModal';

import { useSessionExpiredContext } from './SessionExpiredProvider';

interface ModalContextProps {
  openDuplicateSSNModal: (blocking: boolean, userId?: string) => void;
  openUpdateIssuesModal: (updateIssues: UpdateIssues) => void;
  openSessionExpiredModal: () => void;
}

const ModalContext = createContext<ModalContextProps | undefined>(undefined);

interface ModalProviderProps {
  children: ReactNode;
}

interface ModalState {
  isOpen: boolean;
  blocking: boolean;
}
interface UpdateIssuesModalState extends ModalState {
  updateIssues: UpdateIssues;
}

const updateIssuesModalStateDefault: UpdateIssuesModalState = {
  isOpen: false,
  blocking: false,
  updateIssues: {
    email: false,
    phone: false,
  },
};

const modalStateDefault: ModalState = {
  isOpen: false,
  blocking: false,
};

export const ModalProvider: FC<ModalProviderProps> = ({ children }) => {
  const [ssnModalState, setSsnModalState] = useState<ModalState>(modalStateDefault);

  const [updateIssuesModalState, setUpdateIssuesModalState] =
    useState<UpdateIssuesModalState>(updateIssuesModalStateDefault);

  const { signOut } = useAuth();
  const { isModalOpen, setSkipError, handleIsOpenModal, setErrorStatus } = useSessionExpiredContext();

  const handleSignOut = () => {
    signOut(true);
  };

  const openDuplicateSSNModal = (blocking: boolean, userId?: string) => {
    datadogLogs.logger.log('Duplicate SSN Modal shown', {
      userId: userId || 'Unknown User',
      modalState: {
        isOpen: true,
        blocking: blocking,
      },
      pageUrl: window.location.href,
      userAgent: window.navigator.userAgent,
      timestamp: new Date().toISOString(),
    });
    setSsnModalState({
      isOpen: true,
      blocking: blocking,
    });
  };

  const openUpdateIssuesModal = (updateIssues: UpdateIssues) => {
    setUpdateIssuesModalState({
      isOpen: true,
      blocking: false,
      updateIssues: updateIssues,
    });
  };
  const closeUpdateIssuesModal = () => {
    setUpdateIssuesModalState(updateIssuesModalStateDefault);
  };

  const closeDuplicateSSNModal = () =>
    setSsnModalState({
      isOpen: false,
      blocking: false,
    });
  const openSessionExpiredModal = () => handleIsOpenModal(true);
  const closeSessionExpiredModal = () => handleIsOpenModal(false);

  return (
    <ModalContext.Provider
      value={{
        openDuplicateSSNModal,
        openUpdateIssuesModal,
        openSessionExpiredModal,
      }}
    >
      {children}
      <SessionExpiredModal
        onClose={() => {
          setSkipError(true);
          setErrorStatus('None');
          closeSessionExpiredModal();
        }}
        isOpen={isModalOpen}
      />
      <DuplicateSSNModal
        isOpen={ssnModalState.isOpen}
        onClose={closeDuplicateSSNModal}
        onSignOut={handleSignOut}
        blocking={ssnModalState.blocking}
      />
      <UpdateIssuesModal
        isOpen={updateIssuesModalState.isOpen}
        onClose={closeUpdateIssuesModal}
        onSignOut={handleSignOut}
        updateIssues={updateIssuesModalState.updateIssues}
      />
    </ModalContext.Provider>
  );
};

export const useModal = (): ModalContextProps => {
  const context = useContext(ModalContext);
  if (!context) {
    throw new Error('useModal must be used within a ModalProvider');
  }
  return context;
};
