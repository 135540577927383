import type { Operation } from '@apollo/client';
import { ApolloClient, ApolloLink, ApolloProvider, createHttpLink, from, InMemoryCache } from '@apollo/client';
import { loadDevMessages, loadErrorMessages } from '@apollo/client/dev';
import { setContext } from '@apollo/client/link/context';
import React, { type ReactNode } from 'react';

import { getAccessToken } from 'src/auth/AuthProvider';

import { ReactAppEnv, ReactAppEnvIsNotProd, ReactEnv } from '../constants/global';

import { useHandleApolloErrors } from './hooks/useHandleApolloErrors';
import { useHandleApolloLogs } from './hooks/useHandleApolloLogs';
import { useHandleRetries } from './hooks/useHandleRetries';

if (ReactAppEnv === ReactEnv.LOCAL) {
  loadDevMessages();
  loadErrorMessages();
}
const authLink = setContext((_, { headers }) => {
  const token = getAccessToken();
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const path = process.env.REACT_APP_GQL_PATH || '';
const direct_uri = process.env.REACT_APP_APP_URI || '';
const proxy_uri = process.env.REACT_APP_VGS_INBOUND_URI || '';
const directLink = authLink.concat(createHttpLink({ uri: direct_uri + path }));
const proxyLink = authLink.concat(createHttpLink({ uri: proxy_uri + path }));

const shouldUseProxy = (operation: Operation) => {
  const sensitive = operation.getContext().containsSensitiveData;
  if (sensitive && process.env.REACT_APP_VGS_INBOUND_URI) return true;
  if (sensitive) {
    console.error(
      'Making PII request without setting REACT_APP_VGS_INBOUND_URI.\nSensitive fields will not be tokenized',
    );
  }
  return false;
};

const apoLink = ApolloLink.split(shouldUseProxy, proxyLink, directLink);

export let apolloClient: ApolloClient<any>;
export const ApolloProviderWrapper = (props: { children: ReactNode }) => {
  const errorLink = useHandleApolloErrors();
  const retryLink = useHandleRetries();
  const apolloLogger = useHandleApolloLogs();

  const combinedLink = from([errorLink, retryLink, apolloLogger, apoLink]);

  apolloClient = new ApolloClient({
    connectToDevTools: ReactAppEnvIsNotProd,
    link: combinedLink,
    cache: new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            currentUser: {
              merge(existing, incoming) {
                if (!existing) return incoming;
                return { ...existing, ...incoming };
              },
            },
          },
        },
      },
    }),
    defaultOptions: {
      query: {
        errorPolicy: 'all',
      },
      mutate: {
        errorPolicy: 'all',
      },
      watchQuery: {
        errorPolicy: 'all',
      },
    },
  });

  return <ApolloProvider client={apolloClient}>{props.children}</ApolloProvider>;
};
