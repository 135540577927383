import * as React from 'react';
import type { SVGProps } from 'react';

interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgHeartIcon = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 19 17"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      stroke="#101820"
      strokeWidth={1.5}
      d="M1.503 4.494C1.243 1.89 4.01.06 6.303 1.323l2.854 1.57a1.75 1.75 0 0 0 1.686 0l2.498-1.375c2.348-1.291 5.162.655 4.783 3.308l-.195 1.369a3.25 3.25 0 0 1-.795 1.707l-6.207 6.937a1.25 1.25 0 0 1-1.86.003l-6.633-7.37a3.25 3.25 0 0 1-.818-1.85l-.113-1.128Z"
    />
  </svg>
);
export default SvgHeartIcon;
