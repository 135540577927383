import { MetalType } from '../__generated__/graphql/api';
import { I_DONT_KNOW } from '../constants/application';

export const goldKarats = [
  { value: 24, text: '24K' },
  { value: 22, text: '22K' },
  { value: 18, text: '18K' },
  { value: 14, text: '14K' },
  { value: 10, text: '10K' },
  { value: 0, text: I_DONT_KNOW },
];

export const metalTypes = [
  { value: MetalType.Gold, text: 'Gold' },
  { value: MetalType.Silver, text: 'Silver' },
  { value: MetalType.Platinum, text: 'Platinum' },
];

export const metalTypesWithSelect = [{ value: MetalType.None, text: 'Select' }, ...metalTypes];
