import clsx from 'clsx';

import { Typography } from 'src/components/Typography/Typography';
import { useIsMobile } from 'src/hooks/useIsMobile';

import Card from '../assets/Card.svg';

import { List } from './List';

interface SidebarProps {
  cardImage?: string;
  cardAlt?: string;
  title?: string;
  subtitle?: string;
  listItems?: string[];
  iconImageSrc?: string[];
}

export const Sidebar: React.FC<SidebarProps> = ({
  cardImage = Card,
  cardAlt = 'pesto card',
  title = 'No Minimum Credit Score.',
  subtitle = "We've Built the Best Secured Credit Card: Use Cash or an Item to Get Your Card.",
  listItems = [
    'No credit check to see if you prequalify.',
    'We review your account for a credit line increase after 6 months.',
    'Use items like diamond rings, gold, luxury watches, or use your debit card for your refundable security deposit.',
    'Your credit line will be based on the total value of your items or cash deposit.',
    'Use your card anywhere Mastercard® is accepted.',
  ],
  iconImageSrc,
}) => {
  const isMobile = useIsMobile();

  return (
    <div
      className={clsx('border-r-[1px] border-neutralInactive px-6 py-4 md:py-0', {
        'mx-auto mt-2 flex w-full flex-col justify-center !border-0 px-0': isMobile,
      })}
    >
      <img
        src={cardImage}
        alt={cardAlt}
        className={clsx('w-full md:w-auto', {
          'mx-auto w-3/4': isMobile,
        })}
      />

      <div className="mt-2 flex flex-col gap-2 text-left">
        <Typography variant="body">{title}</Typography>
        <Typography variant="bodySmall">{subtitle}</Typography>

        <List items={listItems} iconImageSrc={iconImageSrc} />
      </div>
    </div>
  );
};
