import * as React from 'react';
import type { SVGProps } from 'react';

interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgModalFrameBottom = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 126 195"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="#fff"
      d="M13.557 179.15c.735 0 1.26-.48 1.26-1.215 0-.75-.525-1.245-1.26-1.245s-1.275.495-1.275 1.245c0 .735.54 1.215 1.275 1.215Zm3.099-.15h2.13l4.53-9.54v-1.71H15.92v1.815h5.235l-4.5 9.435Zm15.288-3.72c0-2.115-1.41-3.63-3.63-3.63-.6 0-1.11.105-1.59.345l.375-2.43h4.335v-1.815H25.48l-.915 5.985 1.695.45c.285-.525.945-.825 1.68-.825 1.155 0 1.95.765 1.95 1.98 0 1.155-.765 1.95-1.98 1.95-.975 0-1.83-.51-1.935-1.635l-2.01.51c.33 2.04 2.07 2.985 3.945 2.985 2.475 0 4.035-1.635 4.035-3.87Zm4.444-.03c0 2.25 1.65 3.9 3.9 3.9 1.89 0 3.33-1.17 3.6-2.715l-1.875-.315c-.18.795-.795 1.305-1.74 1.305-1.14 0-1.905-.84-1.905-2.175 0-1.35.795-2.19 1.935-2.19.84 0 1.455.48 1.65 1.29l1.89-.345c-.345-1.605-1.695-2.685-3.525-2.685-2.265 0-3.93 1.665-3.93 3.93Zm7.915-2.655v.54h1.425v3.63c0 1.5.944 2.385 2.535 2.385.645 0 1.29-.15 1.86-.435l-.42-1.56c-.376.165-.69.255-1.02.255-.675 0-1.066-.3-1.066-1.08v-3.195h2.61v-1.65h-2.61v-1.71h-.51l-2.804 2.82Z"
    />
    <g clipPath="url(#modal-frame-bottom_svg__a)" opacity={0.7}>
      <path
        fill="#FFD2B3"
        stroke="#101820"
        d="M2 26.5C2 12.14 13.64.5 28 .5s26 11.64 26 26v110.05c0 .635-.42 1.193-1.032 1.368a49.488 49.488 0 0 1-46.7-10.8l-3.683-3.314A1.768 1.768 0 0 1 2 122.49V26.5Z"
      />
      <g opacity={0.9}>
        <path
          fill="url(#modal-frame-bottom_svg__b)"
          d="M4.5 23.859c0-11.051 10.298-20.01 23-20.01 12.703 0 23 8.959 23 20.01V52.36c0 1.332-9.217 6.411-10.5 7.139-5.5 2.5-11 3.5-25.5-1L7.14 55.758C5.51 54.98 4.5 53.492 4.5 51.877V23.859Z"
        />
        <path
          stroke="#101820"
          strokeOpacity={0.8}
          strokeWidth={1.5}
          d="M5.25 23.859c0-10.542 9.86-19.26 22.25-19.26s22.25 8.718 22.25 19.26V52.27c-.032.05-.092.127-.192.233a8.605 8.605 0 0 1-.973.836c-.855.652-2.023 1.42-3.249 2.18-2.112 1.308-4.342 2.56-5.332 3.117l-.344.194c-2.671 1.211-5.309 2.044-9.035 2.044-3.763 0-8.676-.85-15.883-3.086l-7.309-2.721c-1.387-.676-2.183-1.913-2.183-3.191V23.859Zm44.522 28.373-.002.007a.04.04 0 0 1 .002-.006Z"
        />
      </g>
      <path
        fill="#fff"
        fillOpacity={0.7}
        stroke="#494848"
        strokeWidth={1.5}
        d="M27.47 3.748h.024c6.317-.21 12.264 2.576 16.388 6.662 3.723 3.688 5.878 8.345 5.584 12.71-3.17-8.33-11.51-15.94-21.948-16.613l-.024-.002h-.024c-9.879 0-18.624 6.429-21.88 14.885a17.187 17.187 0 0 1 4.771-11.175c3.688-3.87 9.468-6.467 17.108-6.467Z"
      />
      <g filter="url(#modal-frame-bottom_svg__c)">
        <path fill="#FDFDFC" d="M39.5 55.5c0 1.38-4.477 4-10 4s-10-2.62-10-4 4.477-2.5 10-2.5 10 1.12 10 2.5Z" />
      </g>
      <path
        stroke="#101820"
        strokeLinecap="round"
        strokeOpacity={0.8}
        strokeWidth={2}
        d="M21.5 111h12M19.5 118h11M34.5 118h3M24.5 125h11"
      />
      <path
        fill="#101820"
        fillOpacity={0.06}
        fillRule="evenodd"
        d="M50.736 145.131c9.368.619 18.959-29.099 21.421-66.376 2.463-37.278-3.136-67.999-12.504-68.618-13.395 0-12.801 6.507-11.831 17.138C48.71 37.005 49.913 50.187 41 65c-.857 12.982-1.073 26.565-.594 38.782-3.086-3.595-6.558-6.07-9.853-6.287-9.368-.619-17.553 7.815-18.28 18.836-.729 11.022 6.276 20.459 15.644 21.077l.497.033c5.465.363 11.722.779 16.095-1.144 1.607 5.407 3.674 8.666 6.227 8.834Z"
        clipRule="evenodd"
      />
    </g>
    <g filter="url(#modal-frame-bottom_svg__d)">
      <path
        fill="#DDEDFB"
        stroke="#AFA7A3"
        strokeWidth={1.5}
        d="M29.053 24.474a2.75 2.75 0 0 0-2.105 0l-10.243 4.243a2.75 2.75 0 0 0-1.488 1.488l-4.242 10.243a2.75 2.75 0 0 0 0 2.105l4.242 10.242a2.75 2.75 0 0 0 1.488 1.488l10.243 4.243c.674.28 1.43.28 2.105 0l10.242-4.243a2.75 2.75 0 0 0 1.489-1.488l4.242-10.242a2.75 2.75 0 0 0 0-2.105l-4.242-10.243a2.75 2.75 0 0 0-1.489-1.488l-10.242-4.243Z"
      />
      <path
        fill="#91BBDA"
        stroke="#9BC4EE"
        strokeWidth={0.5}
        d="m28 26.76 3.023 6.987.097.222.225-.089 7.078-2.802-2.803 7.078-.09.225.223.096L42.74 41.5l-6.987 3.023-.222.096.09.226 2.802 7.078-7.078-2.803-.225-.09-.097.223L28 56.24l-3.023-6.987-.096-.222-.225.089-7.078 2.803 2.803-7.078.089-.226-.223-.096-6.986-3.023 6.986-3.023.223-.096-.09-.225-2.802-7.078 7.078 2.802.225.09.096-.223L28 26.76Z"
      />
      <path
        fill="#C8DDEC"
        stroke="#7EA2BC"
        strokeWidth={0.5}
        d="M23.663 37.163 28 35.367l4.337 1.796 1.796 4.337-1.796 4.337L28 47.633l-4.337-1.796-1.796-4.337 1.796-4.337Z"
      />
      <path
        fill="#E3EEF5"
        stroke="#ADBBC6"
        strokeWidth={0.5}
        d="M24.512 41.5 28 38.011 31.49 41.5 28 44.989 24.512 41.5Z"
      />
    </g>
    <path
      fill="#fff"
      d="M88.136 177h1.98v-11.25h-1.605c-.27.915-1.185 1.86-2.67 1.935v1.23h2.295V177Zm7.318-3.75c0 2.25 1.65 3.9 3.9 3.9 1.89 0 3.33-1.17 3.6-2.715l-1.875-.315c-.18.795-.795 1.305-1.74 1.305-1.14 0-1.905-.84-1.905-2.175 0-1.35.795-2.19 1.935-2.19.84 0 1.455.48 1.65 1.29l1.89-.345c-.345-1.605-1.695-2.685-3.525-2.685-2.265 0-3.93 1.665-3.93 3.93Zm7.914-2.655v.54h1.425v3.63c0 1.5.945 2.385 2.535 2.385.645 0 1.29-.15 1.86-.435l-.42-1.56c-.375.165-.69.255-1.02.255-.675 0-1.065-.3-1.065-1.08v-3.195h2.61v-1.65h-2.61v-1.71h-.51l-2.805 2.82Z"
    />
    <g clipPath="url(#modal-frame-bottom_svg__e)" opacity={0.7}>
      <path
        fill="#FFD2B3"
        stroke="#101820"
        d="M72 26.5c0-14.36 11.64-26 26-26 14.359 0 26 11.64 26 26v110.05c0 .635-.421 1.193-1.032 1.368a49.487 49.487 0 0 1-46.7-10.8l-3.683-3.314A1.768 1.768 0 0 1 72 122.49V26.5Z"
      />
      <g opacity={0.9}>
        <path
          fill="url(#modal-frame-bottom_svg__f)"
          d="M74.5 23.859c0-11.051 10.297-20.01 23-20.01s23 8.959 23 20.01V52.36c0 1.332-9.217 6.411-10.5 7.139-5.5 2.5-11 3.5-25.5-1l-7.362-2.742c-1.627-.778-2.638-2.266-2.638-3.881V23.859Z"
        />
        <path
          stroke="#101820"
          strokeOpacity={0.8}
          strokeWidth={1.5}
          d="M75.25 23.859c0-10.542 9.86-19.26 22.25-19.26s22.25 8.718 22.25 19.26V52.27c-.032.05-.092.127-.192.233a8.645 8.645 0 0 1-.973.836c-.855.652-2.023 1.42-3.249 2.18-2.112 1.308-4.342 2.56-5.333 3.117l-.344.194c-2.671 1.211-5.308 2.044-9.034 2.044-3.764 0-8.676-.85-15.883-3.086l-7.31-2.721c-1.386-.676-2.182-1.913-2.182-3.191V23.859Zm44.522 28.373-.003.007a.13.13 0 0 0 .003-.006Z"
        />
      </g>
      <path
        fill="#fff"
        fillOpacity={0.7}
        stroke="#494848"
        strokeWidth={1.5}
        d="M97.47 3.748h.024c6.316-.21 12.263 2.576 16.388 6.662 3.722 3.688 5.878 8.345 5.584 12.71-3.171-8.33-11.51-15.94-21.948-16.613l-.025-.002h-.024c-9.878 0-18.623 6.429-21.879 14.885a17.187 17.187 0 0 1 4.77-11.175c3.69-3.87 9.469-6.467 17.11-6.467Z"
      />
      <g filter="url(#modal-frame-bottom_svg__g)">
        <path fill="#FDFDFC" d="M109.5 55.5c0 1.38-4.477 4-10 4s-10-2.62-10-4 4.477-2.5 10-2.5 10 1.12 10 2.5Z" />
      </g>
      <path
        stroke="#101820"
        strokeLinecap="round"
        strokeOpacity={0.8}
        strokeWidth={2}
        d="M91.5 111h12M89.5 118h11M104.5 118h3M94.5 125h11"
      />
      <path
        fill="#101820"
        fillOpacity={0.06}
        fillRule="evenodd"
        d="M120.736 145.131c9.368.619 18.959-29.099 21.421-66.376 2.462-37.278-3.136-67.999-12.505-68.618-13.394 0-12.801 6.507-11.83 17.138.888 9.73 2.091 22.912-6.822 37.725-.857 12.982-1.073 26.565-.594 38.782-3.086-3.595-6.558-6.07-9.853-6.287-9.368-.619-17.553 7.815-18.281 18.836-.728 11.022 6.276 20.459 15.645 21.077l.497.033c5.465.363 11.722.779 16.094-1.144 1.607 5.407 3.675 8.666 6.228 8.834Z"
        clipRule="evenodd"
      />
    </g>
    <g filter="url(#modal-frame-bottom_svg__h)">
      <path
        fill="#DDEDFB"
        stroke="#AFA7A3"
        strokeWidth={1.5}
        d="M99.052 16.67a2.75 2.75 0 0 0-2.104 0l-12.933 5.356a2.75 2.75 0 0 0-1.489 1.489L77.17 36.448a2.75 2.75 0 0 0 0 2.104l5.356 12.933a2.75 2.75 0 0 0 1.489 1.488l12.933 5.357c.673.28 1.43.28 2.104 0l12.933-5.357a2.748 2.748 0 0 0 1.488-1.488l5.357-12.933a2.75 2.75 0 0 0 0-2.104l-5.357-12.933a2.748 2.748 0 0 0-1.488-1.489L99.052 16.67Z"
      />
      <path
        fill="#91BBDA"
        stroke="#9BC4EE"
        strokeWidth={0.5}
        d="m98 19.248 3.766 8.705.096.222.226-.09 8.818-3.491-3.492 8.818-.089.225.222.096 8.705 3.767-8.705 3.766-.222.096.089.225 3.492 8.819-8.818-3.492-.226-.09-.096.223L98 55.751l-3.766-8.704-.096-.222-.226.089-8.818 3.492 3.492-8.819.09-.225-.223-.096-8.705-3.766 8.705-3.767.222-.096-.089-.225-3.492-8.818 8.818 3.492.225.089.097-.222L98 19.248Z"
      />
      <path
        fill="#C8DDEC"
        stroke="#7EA2BC"
        strokeWidth={0.5}
        d="M92.628 32.128 98 29.903l5.372 2.225 2.224 5.372-2.224 5.371L98 45.096l-5.372-2.224-2.225-5.372 2.225-5.372Z"
      />
      <path
        fill="#E3EEF5"
        stroke="#ADBBC6"
        strokeWidth={0.5}
        d="M93.633 37.5 98 33.133l4.367 4.367L98 41.867 93.633 37.5Z"
      />
    </g>
    <defs>
      <filter
        id="modal-frame-bottom_svg__c"
        width={44}
        height={30.5}
        x={7.5}
        y={41}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation={6} />
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_1114_34176" />
        <feBlend in="SourceGraphic" in2="effect1_backgroundBlur_1114_34176" result="shape" />
      </filter>
      <filter
        id="modal-frame-bottom_svg__d"
        width={39}
        height={40}
        x={9.5}
        y={23}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
        <feOffset dx={1} dy={2} />
        <feGaussianBlur stdDeviation={0.5} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_1114_34176" />
        <feBlend in="SourceGraphic" in2="effect1_dropShadow_1114_34176" result="shape" />
        <feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
        <feOffset dx={-4} dy={-2} />
        <feComposite in2="hardAlpha" k2={-1} k3={1} operator="arithmetic" />
        <feColorMatrix values="0 0 0 0 0.0901961 0 0 0 0 0.137255 0 0 0 0 0.184314 0 0 0 0.1 0" />
        <feBlend in2="shape" result="effect2_innerShadow_1114_34176" />
      </filter>
      <filter
        id="modal-frame-bottom_svg__g"
        width={44}
        height={30.5}
        x={77.5}
        y={41}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation={6} />
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_1114_34176" />
        <feBlend in="SourceGraphic" in2="effect1_backgroundBlur_1114_34176" result="shape" />
      </filter>
      <filter
        id="modal-frame-bottom_svg__h"
        width={47}
        height={48}
        x={75.5}
        y={15}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
        <feOffset dx={1} dy={2} />
        <feGaussianBlur stdDeviation={0.5} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_1114_34176" />
        <feBlend in="SourceGraphic" in2="effect1_dropShadow_1114_34176" result="shape" />
        <feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
        <feOffset dx={-4} dy={-2} />
        <feComposite in2="hardAlpha" k2={-1} k3={1} operator="arithmetic" />
        <feColorMatrix values="0 0 0 0 0.0901961 0 0 0 0 0.137255 0 0 0 0 0.184314 0 0 0 0.1 0" />
        <feBlend in2="shape" result="effect2_innerShadow_1114_34176" />
      </filter>
      <linearGradient
        id="modal-frame-bottom_svg__b"
        x1={23.096}
        x2={38.13}
        y1={11.087}
        y2={50.521}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F2D1B9" stopOpacity={0.84} />
        <stop offset={0.291} stopColor="#E7E7E8" stopOpacity={0.44} />
        <stop offset={0.487} stopColor="#fff" stopOpacity={0.67} />
        <stop offset={0.682} stopColor="#fff" stopOpacity={0.33} />
        <stop offset={1} stopColor="#F5D3BB" />
      </linearGradient>
      <linearGradient
        id="modal-frame-bottom_svg__f"
        x1={93.096}
        x2={108.129}
        y1={11.087}
        y2={50.521}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F2D1B9" stopOpacity={0.84} />
        <stop offset={0.291} stopColor="#E7E7E8" stopOpacity={0.44} />
        <stop offset={0.487} stopColor="#fff" stopOpacity={0.67} />
        <stop offset={0.682} stopColor="#fff" stopOpacity={0.33} />
        <stop offset={1} stopColor="#F5D3BB" />
      </linearGradient>
      <clipPath id="modal-frame-bottom_svg__a">
        <path fill="#fff" d="M1.5 0h53v141h-53z" />
      </clipPath>
      <clipPath id="modal-frame-bottom_svg__e">
        <path fill="#fff" d="M71.5 0h53v141h-53z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgModalFrameBottom;
