import cardValidator from 'card-validator';
import clsx from 'clsx';

import { CardCvv } from '../../../../../components/UI/Form/CreditCard/CardCvv';
import { CardDate } from '../../../../../components/UI/Form/CreditCard/CardDate';
import { CardNumber } from '../../../../../components/UI/Form/CreditCard/CardNumber';
import type { CardType } from '../../../../../components/screens/Payment/Payment';
import { CARD_TYPE_LOGOS } from '../PaymentTypeConstants';

interface CardInputFormProps {
  className?: string;
  formData: any;
  setFormData: (field: any, value: any) => void;
  onCardTypeChange: (cardType: CardType) => void;
  errors: any;
  register: any;
  setFocus: any;
}

export const CardInputForm = (props: CardInputFormProps) => {
  const { className, formData, onCardTypeChange, setFormData, errors, register, setFocus } = props;
  const rootStyle = clsx(className, 'grid');

  const clearValue = (value: string) => {
    if (!value) return value;
    return value.replace(/\s/g, '').replace(/_/g, '');
  };

  const cleanCardNumber = clearValue(formData.cardNumber);
  const cardInfo = cardValidator.number(cleanCardNumber).card;
  const cardType: CardType = (cardInfo?.type as CardType) || 'default';
  const cvvName = cardInfo?.code?.name;
  const cardNumberLengths = cardInfo?.lengths || [];
  const maxCvvLength = cardInfo?.code?.size;
  const lastCardNumberLength = cardNumberLengths[cardNumberLengths.length - 1];

  return (
    <div className={rootStyle}>
      <div
        className={
          'col-start-1 row-start-1 row-end-1 min-h-[200px] w-full rounded-xl border border-neutralInactive bg-offWhite px-6 py-8'
        }
      />

      <div className="col-start-1 row-start-1 row-end-1 m-2 flex flex-col gap-6 rounded-lg  bg-offWhite bg-opacity-90 px-4 py-8">
        <CardNumber
          label="Debit card number"
          {...register('cardNumber')}
          value={formData.cardNumber}
          onChange={(value: string) => {
            setFormData('cardNumber', value);
            onCardTypeChange(cardType);
            if (clearValue(value).length === lastCardNumberLength) {
              setFocus('expirationDate');
            }
          }}
          cardLengths={cardNumberLengths}
          error={!!errors.cardNumber}
          errorText={errors.cardNumber?.message}
          cardTypeIcon={CARD_TYPE_LOGOS[cardType || 'default']}
          required
        />
        <div className="flex flex-row justify-between gap-4">
          <CardDate
            {...register('expirationDate')}
            label="Expiration date"
            value={formData.expirationDate}
            onChange={value => {
              setFormData('expirationDate', value);
              if (clearValue(value).length === 5) {
                setFocus('cvc');
              }
            }}
            error={!!errors.expirationDate}
            errorText={errors.expirationDate?.message}
            required
          />
          <CardCvv
            {...register('cvc')}
            label={cvvName || 'CVC'}
            cvvLength={maxCvvLength}
            value={formData.cvc}
            onChange={value => {
              setFormData('cvc', value);
            }}
            error={!!errors.cvc}
            errorText={errors.cvc?.message}
            required
          />
        </div>
      </div>
    </div>
  );
};
